import { useEffect, useState } from "react";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useImmer } from "use-immer";

const useBudgetLookups = ({isCCOwner}) => {
    const [lookups, setLookups] = useImmer({expenseTypes: [], accounts: [], costCentres: [], vendors: []});
    const [loading, setLoading] = useState(false);
    const handleError = useHandleError();

    const fetchLookups = async () => {
        setLoading(true);
        if(lookups.expenseTypes.length === 0){
            const [err, expenseTypes] = await fetchRequest.get(`/api/budgets/lookup/expenseType`)
            if (err) {
                handleError(err);
            }
            else {
                if (expenseTypes) {
                    setLookups(draft => {draft.expenseTypes = expenseTypes});
                }
            }
        }
        if(lookups.accounts.length === 0){
            let err, accounts
            if (isCCOwner) 
                [err, accounts] = await fetchRequest.get(`/api/budgets/lookup/accountList`)
            else
                [err, accounts] = await fetchRequest.get(`/api/budgets/lookup/account`)

            if (err) {
                handleError(err);
            }
            else {
                if (accounts) {
                    setLookups(draft => {draft.accounts = accounts});
                }
            }
        }
        if(lookups.costCentres.length === 0){
            let err, costCentres
            if (isCCOwner) 
                [err, costCentres] = await fetchRequest.get(`/api/budgets/lookup/costCentreList`)
            else
                [err, costCentres] = await fetchRequest.get(`/api/budgets/lookup/costCentre`)

            if (err) {
                handleError(err);
            }
            else {
                if (costCentres) {
                    setLookups(draft => {draft.costCentres = costCentres});
                }
            }
        }
        if(lookups.vendors.length === 0){
            const [err, vendors] = await fetchRequest.get(`/api/budgets/lookup/vendor`)
            if (err) {
                handleError(err);
            }
            else {
                if (vendors) {
                    setLookups(draft => {draft.vendors = vendors});
                }
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchLookups();
    }, []);

    return { loading, ...lookups, fetchLookups };
};

export default useBudgetLookups;