import _ from "lodash";
import { Chip, Icon, Tooltip } from "@mui/material";
// import { Alert, Chip, CircularProgress, Icon, IconButton, InputAdornment, MenuItem, TextField, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import moment from "moment";
import { useEffect, useState } from "react";
import { parseJsonString } from "utils";
import fetchRequest from "utils/fetchRequest";
import RuleBuilder from "./RuleBuilder";
import { Controller } from "react-hook-form";
import MDTypography from "components/MDTypography";

const filterTypeLabels = {
    eq: "is equal to",
    equals: "is equal to",
    ne: "is not equal to",
    notEquals: "is not equal to",
    contains: "contains",
    notContains: "does not contain",
    startsWith: "starts with",
    endsWith: "ends with",
    set: "is set",
    notSet: "is not set",
    gt: "is greater than",
    gte: "is greater than or equal to",
    lt: "is less than",
    lte: "is less than or equal to",
    between: "is in between",
    notBetween: "is not in between",
    before: "is before",
    after: "is after",
};

const getFilterDescription = (field, dataType = "string", operator, values,tableCols, displayName) => {
    let valueDescription = "";
    let fld = _.find(tableCols, { "name": field})??_.find(tableCols, { "schemaName": field})??displayName
    if (Array.isArray(values) && values?.length > 1) {
        if (["between", "notBetween"].includes(operator)) {
            if (dataType === "date")
                valueDescription = `${moment(values[0]).format(
                    "MMM DD YYYY"
                )} and ${moment(values[1]).format("MMM DD YYYY")}`;
            else
                valueDescription = `${values[0]} and ${values[1]}`;
        }
        else
            valueDescription = values.slice(0, values.length - 1).join(", ") + " or " + values[values.length - 1];
    }
    else if (Array.isArray(values) && values?.length === 1) {
        if (dataType === "date")
            valueDescription = moment(values).format("MMM DD YYYY");
        else valueDescription = values[0];
    }
    else if (["set", "notSet"].includes(operator)) { valueDescription = ""; }
    else { valueDescription = values; }
    return `${_.startCase(fld?.displayName)} ${filterTypeLabels[operator]} ${valueDescription}`;
};

const Rule = (props) => {
    const { control, fieldDef: { name, required },tableName, condition, fromSourceChange, setOnClear, onClear, onConditionChange, errorMessage, disabled, yearNameId, monthNameId } = props;

    const [showCondition, setShowCondition] = useState(false);
    const [tableCols, setTableCols] = useState([]);
    const conditionsArr = parseJsonString(condition) || [];
    const conditions = conditionsArr?.map((c) => <Tooltip key={conditionsArr.indexOf(c)} title={getFilterDescription(c.field, c.type, c.operator, c.value,tableCols, c.displayName)} ><Chip size="small" key={c.field} sx={{ margin: 0.3 }} label={getFilterDescription(c.field, c.type, c.operator, c.value,tableCols, c.displayName)} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" /></Tooltip>);

    
    useEffect(async () => {
        if (tableName && tableName !== "") {
            let [_err, data] = await fetchRequest.get(`/api/uploadRules/resource/${tableName}`)
            if (_err)
                console.error(_err)
            setTableCols(data.fields)
        }

    }, [tableName])

    const handleCondition = () => {
        setShowCondition(true);
    }

    const handleConditionClose = () => {
        setShowCondition(false)
    }

    const hanldeConditionSave = (_condition) => {
        onConditionChange(_condition)
        setShowCondition(false)
    }


    const renderListConfig = () => {
        return (
            <>
                <MDBox display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
                    <MDBox display="flex" flexDirection="column"
                        mt={tableName && tableName !== "" ? 2 : 1}
                        textAlign={tableName && tableName !== "" ? "left" : "center"}
                    >
                        {(!conditions || conditions.length === 0) &&
                            <MDButton ml={2} disabled={!tableName} startIcon={<Icon>add</Icon>} variant="text" color="info" onClick={(e) => handleCondition(e)}>Add Filter</MDButton>
                        }
                    </MDBox>
                </MDBox>
                {
                    tableName && tableName !== "" && (
                        <>
                            {conditions && conditions.length > 0 && (
                                <MDBox sx={{
                                    py: 1,
                                    px: 2,
                                    m: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    borderRadius: 2,
                                    border: "2px dashed #ddd",
                                    width: "500px",
                                }}
                                >
                                    {
                                        conditions
                                    }
                                </MDBox>
                            )}
                            {conditions && conditions.length > 0 &&
                                <MDBox>
                                    <MDButton disabled={disabled} ml={2} mb={2} startIcon={<Icon>edit</Icon>} variant="text" color="text" onClick={(e) => handleCondition(e)}>Edit Filter</MDButton>
                                    <MDButton disabled={disabled} ml={2} mb={2} startIcon={<Icon>cancel</Icon>} variant="text" color="text" onClick={() => {onConditionChange(null); setOnClear(true); handleCondition()}}>Clear</MDButton>
                                </MDBox>
                            }
                        </>
                    )
                }
            </>
        );
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) =>
            <>
            {!onClear && !fromSourceChange && onConditionChange(value)}
            <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{border: required && errorMessage ? '1px solid red' : '', pb: '7px' }}>
            {showCondition && <RuleBuilder onChange={onChange} value={value} conditionType={"Rule"} conditionJson={condition} fromSource={tableName} tableCols={tableCols} onConditionClose={handleConditionClose} onConditionSave={hanldeConditionSave} onClear={onClear} yearNameId={yearNameId} monthNameId={monthNameId}/>}
            {
                renderListConfig()
            }
            </MDBox>
            {errorMessage && <MDTypography variant="caption" fontWeight="medium" color={errorMessage ? "error" : "dark"}>{errorMessage}</MDTypography>}
            </>}
        />
    );
}

export default Rule;