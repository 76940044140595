import { Controller } from "react-hook-form";
import { useState } from "react";
import { Icon, IconButton, Tooltip, InputAdornment, TextField} from "@mui/material";
import MDBox from "components/MDBox";
import FieldDetails from "../FieldDetails";

const Textbox = (props) => {
  const { control, textarea, rows, disabled, fieldDef: { name, displayName, required, variant, width, placeholder, suffixText, toolTip, readOnly, impact, unique, type, severity }, errorMessage, passwordEnable } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let inputProps = passwordEnable ? {
    endAdornment: suffixText ? <InputAdornment disableTypography sx={{ fontSize: 13 }} position="end">{suffixText}</InputAdornment> : undefined,
    ...(passwordEnable && {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            size="small"
            onClick={togglePasswordVisibility}
          >
            <Icon>{showPassword ? "visibility" : "visibility_off"}</Icon>
          </IconButton>
        </InputAdornment>
      ),
    }),
  } : suffixText ? {
    endAdornment: <InputAdornment disableTypography sx={{ fontSize: 13 }} position="end">{suffixText}</InputAdornment>,
  } : {}

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) =>
        <>
          <MDBox sx={{ "&:hover": { "& .helpIcon": { visibility: 'visible' } } }} display='flex' flexDirection='row'>
            <TextField
              type={passwordEnable ? (showPassword ? "text" : "password") : "text"}
              multiline={textarea}
              rows={textarea ? rows || 3 : 1}
              name={name}
              value={value || ""}
              label={displayName}
              placeholder={placeholder}
              required={required}
              error={errorMessage && true}
              helperText={errorMessage}
              onChange={onChange}
              variant={variant || "standard"}
              sx={width ? { "& .MuiInputBase-root": { width: width } } : undefined}
              disabled={readOnly || disabled}
              fullWidth={width ? false : true}
              InputProps={inputProps}
            />
            {toolTip?.length >= 0 &&
              <IconButton className="helpIcon"
                sx={({ palette: { text } }) => ({
                  // marginLeft: .15,
                  // marginBottom: .1,
                  marginRight: -2,
                  color: "#979191",
                  visibility: 'hidden',
                  "&:hover": {
                    color: text.main
                  }
                })}
                size="small"
                onClick={()=> {setOpen(true)}}
              // onClick={() => {
              //     // showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, pageName) :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp') :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
              //     window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
              // }}
              >
                <Tooltip placement="left" title={toolTip ? toolTip : displayName}>
                  <Icon>help</Icon>
                </Tooltip>
              </IconButton>
            }
            {
              open && (<FieldDetails impact = {impact} unique = {unique} type = {type} setOpen= {setOpen} toolTip= {toolTip} displayName= {displayName} required= {required} severity= {severity}/>)
            }

          </MDBox>
        </>}
    />
  )
}

export default Textbox;