import { Icon } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import treemap from "highcharts/modules/treemap.js";
import moment from 'moment';
import { memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { generateChartOptions } from './chartUtils';
import { useWidgetItemContext } from 'context/WidgetItemContext';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import { debounce } from 'lodash';
import { useCustomDashboardContext } from 'context/CustomDashboardContext';
import { useNavigate } from 'react-router-dom';
import { useCustomDashboardEditorContext } from 'context/CustomDashboardEditorContext';
import { useAppController } from "context";

// eslint-disable-next-line no-undef
require("highcharts/modules/exporting.src.js")(Highcharts);
// eslint-disable-next-line no-undef
require("highcharts/modules/offline-exporting.src.js")(Highcharts);

treemap(Highcharts);

const splitDimensionValues = (dimensions, value) => {
    if ((dimensions || []).length === 0 || !value)
        return [];

    if (dimensions.length === 1)
        return [{ queryName: dimensions[0].name, values: [value] }];

    if (dimensions.length > 1) {
        const splitValues = value.split("_br_")
        return dimensions.map((d, i) => ({ queryName: d.name, values: [splitValues?.[i]] }));
    }

    return [];
}

const getDrilldownStateFilters = (chartType, vizOptions, drilldownState) => {
    let filters = [];

    const categoryDimensions = splitDimensionValues(vizOptions.axis, drilldownState?.category);
    filters = filters.concat(categoryDimensions);

    const seriesDimensions = splitDimensionValues(vizOptions.legend, drilldownState?.series);
    filters = filters.concat(seriesDimensions);

    return filters;
}

const getDrilldownFilters = (chartType, vizOptions, selectedFilters, drilldownState) => {
    let filters = {};
    if (!vizOptions.drilldown)
        return { filters: [] };

    if (vizOptions.drilldown?.dependencies) {
        (vizOptions.drilldown?.dependencies || []).forEach(df => {
            const selectedFilter = (selectedFilters || []).find(f => f.queryName === df);
            if (selectedFilter && selectedFilter.values?.length > 0) {
                filters[df] = { queryName: selectedFilter.queryName, values: selectedFilter.values };
            }
        });
    }

    const drilldownStateFilters = getDrilldownStateFilters(chartType, vizOptions, drilldownState);
    drilldownStateFilters.forEach(df => { filters[df.queryName] = df });

    return { filters: Object.values(filters) };
}

const isEmptyResult = (result) => {
    if (!result) return true;
    const resultSets = Array.isArray(result) ? result : [result];
    let emptyResult = true;
    resultSets.forEach(rs => {
        if (rs?.tablePivot()?.length > 0) {
            emptyResult = false;
            return;
        }
    });
    return emptyResult;
}

const getData = (result) => {
    if (!result) return [];
    if (!Array.isArray(result))
        return result?.tablePivot();
    return result.map(rs => rs?.tablePivot());
}

const ChartRenderer = memo(({ chartType, vizOptions, resultSet }) => {
    const navigate = useNavigate();
    const prevVizOptionsRef = useRef(vizOptions);
    const [reRender, setReRender] = useState();
    const chartRef = useRef(null);
    const widgetRef = useRef(null);
    const { width, height, ref: containerRef } = useResizeDetector();
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;

    const analyticsContext = useAnalyticsContext();
    const widgetItemContext = useWidgetItemContext();
    const customDashboardContext = useCustomDashboardContext();
    const customDashboardEditorContext = useCustomDashboardEditorContext();

    const standardVariables = analyticsContext?.variables || customDashboardContext?.[0]?.variables;
    const parentDashboardDef = customDashboardContext?.[0].dashboardDef;
    const selectedFilters = customDashboardContext?.[0].selectedFilters;

    const navigateTo = useCallback((resourceId, drilldownState) => {
        let navigationState = getDrilldownFilters(chartType, vizOptions, selectedFilters, drilldownState);
        navigationState = {
            ...navigationState,
            parentDashboard: {
                id: parentDashboardDef.id,
                name: parentDashboardDef.name
            }
        };
        navigate(`/dashboard/custom/${resourceId}`, { state: navigationState });
    }, [navigate, vizOptions, parentDashboardDef]);

    const rerenderChart = useCallback(
        debounce(() => {
            setReRender(Math.random());
        }, 600)
        , [setReRender])

    useEffect(() => {
        if (analyticsContext)
            analyticsContext.vizRef.current = widgetRef.current;
        else if (widgetItemContext)
            widgetItemContext.vizRef.current = widgetRef.current;
    }, [widgetRef])

    useEffect(() => {
        const prevChartConfig = prevVizOptionsRef.current?.config;
        const chartConfig = vizOptions?.config;
        if (prevChartConfig?.refLine_enable !== undefined) {
            if (
                prevChartConfig?.refLine_enable !== chartConfig?.refLine_enable
                || (prevChartConfig?.refLine_enable === true && prevChartConfig?.refLine_value !== chartConfig?.refLine_value)
            )
                rerenderChart();
        }
        prevVizOptionsRef.current = vizOptions;
    }, [vizOptions])

    const handleExport = useCallback(() => {
        chartRef.current.chart.options.chart.backgroundColor = 'white';
        chartRef.current.chart.options.chart.style.fontFamily = "apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif";
        chartRef.current.chart.options.chart.style.fontSize = '14px';
        chartRef.current.chart.options.chart.spacingTop = 8;
        chartRef.current.chart.options.exporting.fallbackToExportServer = false;
        chartRef.current.chart.options.exporting.filename = `${chartType}_${moment().format("YYYYMMDDHHmmss")}`;
        chartRef.current.chart.options.exporting.scale = 10;
        chartRef.current.chart.options.title.text = vizOptions?.config?.card_title;
        chartRef.current.chart.options.subtitle.text = vizOptions?.config?.card_subtitle;
        chartRef.current.chart.exportChartLocal({ type: "image/jpeg" });
        chartRef.current.chart.options.exporting.error = () => { alert("There is an Error While Exporting") };
    }, [widgetRef, chartRef]);

    useImperativeHandle(widgetRef, () => ({
        export() {
            handleExport();
        },
    }));

    if (isEmptyResult(resultSet))
        return <MDBox sx={{ height: '100%' }}>
            <MDBox width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Icon sx={{ color: "#d0cdcd", fontSize: "64px!important" }}>leaderboard</Icon>
                <MDTypography variant="subtitle2" color="text">No Data</MDTypography>
            </MDBox>
        </MDBox>

    let chartOptions = generateChartOptions(chartType, { height, width }, vizOptions, getData(resultSet), standardVariables, customDashboardEditorContext ? null : navigateTo, systemCurrencyDetails);
    const options = useMemo(() => chartOptions, [chartOptions]);

    return <div ref={containerRef} style={{ position: 'relative', height: '100%' }}>
        <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 }}>
            <HighchartsReact key={reRender} ref={chartRef} highcharts={Highcharts} options={options} />
        </div>
    </div>
});

export default ChartRenderer