import { Accordion, AccordionDetails, AccordionSummary, Icon, List, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { memo, useState } from "react";
import DataField, { DataFieldTypes } from "../../../../components/DataField";

const fieldsListStyles = (theme, { dashboardMode }) => {
    const { palette, functions, borders } = theme;
    const { black } = palette;
    const { pxToRem, rgba } = functions;
    const { borderWidth } = borders;
    return {
        boxShadow: "none",
        "&::before": {
            height: 0
        },
        "& .MuiListItem-root": {
            cursor: dashboardMode ? 'auto' : 'grab',
            userSelect: 'none',
            border: `${borderWidth[1]} solid transparent`,
        },
        "& .MuiListItem-root:hover": {
            borderRadius: pxToRem(4),
            backgroundColor: '#facd35',
            // backgroundColor: info.main,
            // color: white.main,
            border: `${borderWidth[1]} solid ${rgba(black.main, 0.09)}`,

        },
        "& .MuiListItem-root .dragIcon": {
            visibility: 'hidden'
        },
        "& .MuiListItem-root:hover .dragIcon": {
            visibility: dashboardMode ? 'hidden' : 'visible'
        },
        "& .MuiListItem-root:hover .MuiTypography-root, & .MuiListItem-root:hover .MuiIcon-root": {
            color: black.main
        },
        "& .MuiListItem-root:hover .MuiCheckbox-root .MuiSvgIcon-root": {
            border: `${borderWidth[1]} solid ${black.main}`,
        },
        "& .MuiListItemIcon-root": {
            minWidth: pxToRem(20),
            margin: `${pxToRem(4)} ${pxToRem(8)}`
        },
        "& .MuiAccordionDetails-root": {
            padding: `0 ${pxToRem(8)} 0 ${pxToRem(8)}`
        },
        "& .MuiAccordionSummary-content": {
            margin: `${pxToRem(8)} 0`
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: pxToRem(8)
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: pxToRem(32)
        },
        "& + .Mui-expanded": {
            margin: `0 0 ${pxToRem(8)} 0`
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0
        },
        "& .MuiAccordionSummary-root": {
            padding: `0 ${pxToRem(8)} 0 ${pxToRem(16)}`,
            minHeight: pxToRem(32),
            flexDirection: "row-reverse"
        }
    };
};

const aggFieldsListStyles = (theme, { dashboardMode }) => {
    const { palette, functions, borders } = theme;
    const { black } = palette;
    const { pxToRem, rgba } = functions;
    const { borderWidth } = borders;
    return {
        boxShadow: "none",
        margin: `0px!important`,
        "&::before": {
            height: 0
        },
        "& .MuiListItem-root": {
            cursor: dashboardMode ? 'auto' : 'grab',
            userSelect: 'none',
            border: `${borderWidth[1]} solid transparent`,
        },
        "& .MuiListItem-root:hover": {
            borderRadius: pxToRem(4),
            backgroundColor: '#facd35',
            // backgroundColor: info.main,
            // color: white.main,
            border: `${borderWidth[1]} solid ${rgba(black.main, 0.09)}`,

        },
        "& .MuiListItem-root .dragIcon": {
            visibility: 'hidden'
        },
        "& .MuiListItem-root:hover .dragIcon": {
            visibility: dashboardMode ? 'hidden' : 'visible'
        },
        "& .MuiListItem-root:hover .MuiTypography-root, & .MuiListItem-root:hover .MuiIcon-root": {
            color: black.main
        },
        "& .MuiListItem-root:hover .MuiCheckbox-root .MuiSvgIcon-root": {
            border: `${borderWidth[1]} solid ${black.main}`,
        },
        "& .MuiListItemIcon-root": {
            minWidth: pxToRem(20),
            margin: `${pxToRem(4)} ${pxToRem(8)}`
        },
        "& .MuiAccordionDetails-root": {
            padding: `0 0 0 ${pxToRem(8)}`
        },
        "& .MuiAccordionSummary-content": {
            fontSize: pxToRem(16),
            margin: `${pxToRem(8)} 0`
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: pxToRem(8)
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: pxToRem(32)
        },
        "& + .Mui-expanded": {
            margin: `0 0 ${pxToRem(8)} 0`
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0
        },
        "& .MuiAccordionSummary-root": {
            padding: `0 0 0 ${pxToRem(36)}`,
            minHeight: pxToRem(32),
            flexDirection: "row"
        }
    };
};

const DataFieldsPanel = memo(({ cubes, selectedFields, onSelection, filter = [] }) => {
    const [searchText, setSearchText] = useState("");
    const selectedFieldNames = selectedFields?.map(f => f.name);
    const dashboardMode = filter?.length > 0;
    let initialPanelId = null;

    const renderCubeFields = (cubeName, dimensions, measures) => {
        return <List key={`${cubeName}_l`} dense>
            {
                dimensions?.map(d => (
                    <DataField key={`${d.cubeName || cubeName}_${d.name}_d`} type={DataFieldTypes.DIMENSION} name={`${d.cubeName || cubeName}.${d.name}`} title={d.title} dataType={d.type} isDropped={selectedFieldNames?.includes(`${d.cubeName || cubeName}.${d.name}`)} onSelection={onSelection} operator={d?.operator} />
                ))
            }
            {
                measures?.map(m => {
                    if (m.showAggregates === true) {
                        const limitAggregatesTo = (m.limitAggregates || []);
                        const enableSum = limitAggregatesTo.length === 0 || limitAggregatesTo.includes("sum");
                        const enableAvg = limitAggregatesTo.length === 0 || limitAggregatesTo.includes("avg");
                        const enableMin = limitAggregatesTo.length === 0 || limitAggregatesTo.includes("min");
                        const enableMax = limitAggregatesTo.length === 0 || limitAggregatesTo.includes("max");
                        return (
                            <Accordion key={`${m.cubeName || cubeName}_${m.name}_m_g`} defaultExpanded={false} sx={(theme) => aggFieldsListStyles(theme, { dashboardMode })}>
                                <AccordionSummary expandIcon={<Icon>keyboard_arrow_down</Icon>}>
                                    <Icon>numbers</Icon>
                                    <MDTypography data-testid={m.title?.toLowerCase().replaceAll(' ', '')} ml={.5} variant="caption">{m.title}</MDTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {enableSum && <DataField key={`${m.cubeName || cubeName}_${m.name}_m`} hideTotal={m.hideTotal} type={DataFieldTypes.MEASURE} name={`${m.cubeName || cubeName}.${m.name}`} title={`${m.title} (Sum)`} dataType={m.type} decimalPoints={m.decimalPoints} isDropped={selectedFieldNames?.includes(`${m.cubeName || cubeName}.${m.name}`)} onSelection={onSelection} />}
                                    {enableAvg && <DataField key={`${m.cubeName || cubeName}_${m.name}_avg_m`} hideTotal={m.hideTotal} type={DataFieldTypes.MEASURE} name={`${m.cubeName || cubeName}.${m.name}_avg`} title={`${m.title} (Avg)`} dataType={m.type} decimalPoints={m.decimalPoints} isDropped={selectedFieldNames?.includes(`${m.cubeName || cubeName}.${m.name}_avg`)} onSelection={onSelection} />}
                                    {enableMin && <DataField key={`${m.cubeName || cubeName}_${m.name}_min_m`} hideTotal={m.hideTotal} type={DataFieldTypes.MEASURE} name={`${m.cubeName || cubeName}.${m.name}_min`} title={`${m.title} (Min)`} dataType={m.type} decimalPoints={m.decimalPoints} isDropped={selectedFieldNames?.includes(`${m.cubeName || cubeName}.${m.name}_min`)} onSelection={onSelection} />}
                                    {enableMax && <DataField key={`${m.cubeName || cubeName}_${m.name}_max_m`} hideTotal={m.hideTotal} type={DataFieldTypes.MEASURE} name={`${m.cubeName || cubeName}.${m.name}_max`} title={`${m.title} (Max)`} dataType={m.type} decimalPoints={m.decimalPoints} isDropped={selectedFieldNames?.includes(`${m.cubeName || cubeName}.${m.name}_max`)} onSelection={onSelection} />}
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                    return <DataField key={`${m.cubeName || cubeName}_${m.name}_m`} hideTotal={m.hideTotal} type={DataFieldTypes.MEASURE} name={`${m.cubeName || cubeName}.${m.name}`} title={m.title} dataType={m.type} decimalPoints={m.decimalPoints} isDropped={selectedFieldNames?.includes(`${m.cubeName || cubeName}.${m.name}`)} onSelection={onSelection} />
                })
            }
        </List>
    };

    return (
        <MDBox position="relative" pb={2}>
            <MDBox sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff!important", px: 2, pb: 1 }}>
                <TextField
                    value={searchText || ""}
                    fullWidth
                    size="small"
                    variant="outlined"
                    inputProps={{ type: "search" }}
                    placeholder="Type to filter.."
                    onChange={({ target: { value } }) => setSearchText(value)}
                    onBlur={({ target: { value } }) => setSearchText(value?.trim())}
                    onFocus={({ target }) => { target.select() }}
                />
            </MDBox>
            {
                cubes && Object.keys(cubes)?.map((ck, cki) => {
                    const c = cubes[ck];
                    let filteredDimensions = (c.dimensions || []).filter((d) => !d.hidden);
                    let filteredMeasures = (c.measures || []).filter((m) => !m.hidden);
                    if (filter?.length > 0) {
                        filteredDimensions = filter.includes(DataFieldTypes.DIMENSION) ? filteredDimensions : [];
                        filteredMeasures = filter.includes(DataFieldTypes.MEASURE) ? filteredMeasures : [];
                    }
                    filteredDimensions = filteredDimensions?.filter(d => searchText?.trim() === "" || d.title.toLowerCase().indexOf(searchText?.trim().toLowerCase()) > -1);
                    filteredMeasures = filteredMeasures?.filter(m => searchText?.trim() === "" || m.title.toLowerCase().indexOf(searchText?.trim().toLowerCase()) > -1);
                    if (filteredDimensions?.length > 0 || filteredMeasures?.length > 0) {
                        if (initialPanelId === null)
                            initialPanelId = cki;
                        if (searchText?.trim() !== "") {
                            return <Accordion key={`${ck}_e`} defaultExpanded={true} sx={(theme) => fieldsListStyles(theme, { dashboardMode })}>
                                <AccordionSummary expandIcon={<Icon>keyboard_arrow_down</Icon>}>
                                    <MDTypography data-testid={c.title?.toLowerCase().replaceAll(' ', '')} variant="caption" color="text" fontWeight="medium">{c.title}</MDTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {renderCubeFields(c.name, filteredDimensions, filteredMeasures)}
                                </AccordionDetails>
                            </Accordion>
                        }
                        return <Accordion key={`${ck}_ne`} defaultExpanded={initialPanelId === cki} sx={(theme) => fieldsListStyles(theme, { dashboardMode })}>
                            <AccordionSummary expandIcon={<Icon>keyboard_arrow_down</Icon>}>
                                <MDTypography data-testid={c.title?.toLowerCase().replaceAll(' ', '')} variant="caption" color="text" fontWeight="medium">{c.title}</MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {renderCubeFields(c.name, filteredDimensions, filteredMeasures)}
                            </AccordionDetails>
                        </Accordion>
                    }
                    return null;
                })
            }
        </MDBox >
    )
});

export default DataFieldsPanel;