import fetchRequest from "utils/fetchRequest";
import InterfaceForm from "pages/Interfaces/components/InterfaceForm";
import { Alert, DialogContentText } from "@mui/material";
// import DataStoreMappingeName from "pages/Interfaces/components/DataStoreMappingeName";

export const DataStoreInterfaceHandler = async (modelData, showAlert, showPrompt, showSnackbar, showCustomForm, enableInterfaceForm, handleMappingNext) => {
    const interfaceHandle = async (dataStoreMapping, dataTemplateCheck, dataTempupdated) => {
        let interfaceCheck
        const [err1, data] = await fetchRequest.get('/api/interface/list')
        if (!err1) {
            interfaceCheck = data.find(obj => obj.name === dataStoreMapping.Name)
        }
        if (interfaceCheck) {
            // showAlert("Integration", "An integration with the same name already exists.");
            if (dataTemplateCheck) {
                showSnackbar("An integration and a data mapping  with the same name already exist.", "info");
            }
            else
                showSnackbar("An integration with the same name already exist.", "info");
        } else {
            showCustomForm("New Integration", () => <InterfaceForm dataStoreMapping={dataStoreMapping} handleMappingNext={handleMappingNext} dataTempupdated={dataTempupdated} />, null, null, null, 'sm');
        }
    }

    const DataStoreReplace = async (id, modelData) => {
        const [err, response] = await fetchRequest.post(`/api/interface/dataStoreMapping/edit/${id}`, JSON.stringify(modelData));
        if (err) {
            showAlert("Automate", err?.data?.message || "Something went wrong. Contact your administrator.");
            console.error(err);
        }
        else {
            if (enableInterfaceForm)
                interfaceHandle(modelData, false, true)
            showSnackbar(response.message, "success");
            if (handleMappingNext)
                handleMappingNext()
        }
    }
    const DataStoreCreateNew = async (modelData, resourceData) => {
        let dataTemplates = resourceData.filter(obj => obj.destinationTable === modelData.DestinationTable);
        let newModelData = { ...modelData }
        newModelData.Name = `${modelData.Name}_Copy${dataTemplates.length}`
        const [err,] = await fetchRequest.post('/api/master/data-store-mapping/new', JSON.stringify(newModelData));

        if (err) {
            showAlert("Automate", err?.data?.message || "Something went wrong. Contact your administrator.");
            console.error(err);
        }
        else {
            if (enableInterfaceForm)
                interfaceHandle(newModelData)
            showSnackbar(`Data Mapping Template [${modelData.Name}_Copy${dataTemplates.length}] is created successfully`, "success");
            if (handleMappingNext)
                handleMappingNext()
        }
    }
    const DataTemplateAlert = (interfaceData, dataStoreMapping) => {
        return (
            <>
                <DialogContentText sx={{ width: 'fit-content' }}>
                    A data mapping template named {`[${dataStoreMapping}]`} already exists with different mapping fields. Do you want to replace it or create a new data mapping template?
                </DialogContentText>
                {interfaceData && interfaceData.find(obj => obj["dataStoreMapping.name"] === dataStoreMapping) && (
                    <Alert severity="info" sx={{ marginTop: 1, marginBottom: 1, fontSize: "14px", textAlign: "left" }}>
                        An integration is already linked to this data mapping template.
                    </Alert>
                )}
            </>
        );
    };
    let sourceDataCheck
    const [err1, data] = await fetchRequest.get('/api/master/data-store-mapping/list')
    if (!err1) {
        sourceDataCheck = data.find(obj => obj.name === modelData.Name)
    }
    else {
        showAlert("Automate", err1?.data?.message || "Something went wrong. Contact your administrator.");
        console.error(err1);
    }
    if (sourceDataCheck) {
        // let showInterfaceForm = async (sourceName) => {
        //     let newModelData = modelData
        //     newModelData.Name = sourceName
        //     const [error,] = await fetchRequest.post('/api/master/data-store-mapping/new', JSON.stringify(newModelData));
        //     if (error) {
        //         showAlert("Automate", error?.data?.message || "Something went wrong. Contact your administrator.");
        //         console.error(error);
        //     } else {
        //         if (handleMappingNext)
        //             handleMappingNext()
        //     }
        // }

        let areMappingFieldsSame = (sourceDataCheck, modelData) => {
            let sourceMapping = JSON.parse(sourceDataCheck.mappingFields || '[]');
            let modelMapping = JSON.parse(modelData.MappingFields || '[]');
            const normalizeAndSort = (arr) => {
                return arr
                    .map(item => JSON.stringify(sortObjectKeys(item)))
                    .sort();
            };
            const sortObjectKeys = (obj) => {
                const sortedKeys = Object.keys(obj).sort();
                const sortedObj = {};
                for (let key of sortedKeys) {
                    sortedObj[key] = obj[key];
                }
                return sortedObj;
            };
            const normalizedSource = normalizeAndSort(sourceMapping);
            const normalizedModel = normalizeAndSort(modelMapping);
            return JSON.stringify(normalizedSource) === JSON.stringify(normalizedModel);
        };

        let mappingFiledCheck = areMappingFieldsSame(sourceDataCheck, modelData);

        // if (enableInterfaceForm || mappingFiledCheck) {
        //     interfaceHandle(modelData)
        // } 
        // else {
        if (!mappingFiledCheck) {
            // showCustomForm("New Data Mapping Template", () => <DataStoreMappingeName resourceData={data} interfaceHandle={interfaceHandle} modelData={modelData} />, null, null, null, 'sm');
            const [, data2] = await fetchRequest.get('/api/interface/list')
            showPrompt("Data Template", DataTemplateAlert(data2, sourceDataCheck.name), () => DataStoreReplace(sourceDataCheck.id, modelData), () => DataStoreCreateNew(modelData, data), "Create New", "Replace");
        } else {
            if (!enableInterfaceForm)
                showSnackbar("Data mapping template with the same name already exist.", "info");
            if (enableInterfaceForm)
                interfaceHandle(modelData, true)
            if (handleMappingNext)
                handleMappingNext()
        }
        // }
    } else {
        const [error, response] = await fetchRequest.post('/api/master/data-store-mapping/new', JSON.stringify(modelData));
        if (!error && response) {
            if (enableInterfaceForm)
                interfaceHandle(modelData)

            if (handleMappingNext)
                handleMappingNext()
            // navigate("/admin/interfaces")
        } else {
            showAlert("Automate", error?.data?.message || "Something went wrong. Contact your administrator.");
            console.error(error);
        }
    }
}
