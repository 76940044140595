import { Icon, Stack } from "@mui/material";
import AnimatedComponent from "components/AnimatedComponent";
import EmptyState from "components/EmptyState";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useYADialog } from "components/YADialog";
import YASkeleton from "components/YASkeleton";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import fetchRequest from "utils/fetchRequest";

const dropBoxStyles = ({ palette: { white, info }, functions: { pxToRem } }, { isDragActive }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  border: `${pxToRem(2)} dashed #ddd`,
  borderRadius: pxToRem(6),
  py: 3,
  maxWidth: 400,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f0f8ff",
  },
  "& .dropBox-icon": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    height: pxToRem(56),
    width: pxToRem(56),
    fontSize: pxToRem(56),
    mb: 1.5,
    color: info.main,
    ...(isDragActive && {
      fontSize: pxToRem(36),
      color: white.main,
      backgroundColor: info.main,
    })
  }
})

const Logo = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [validFile, setValidFile] = useState(true);
  const [logoFile, setLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const { showAlert, showSnackbar } = useYADialog();
  const [refresh, setRefresh] = useState(null);


  const MAX_FILE_SIZE = 100 * 1024; // 100 KB

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    maxFiles: 1,
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      try {
        const file = acceptedFiles[0];
        const fileExtension = (file?.name || "").split(".").pop().toLowerCase();
        const validExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
        if (!validExtensions.includes(fileExtension)) {
          setValidFile(false);
          return;
        }
        if (file.size > MAX_FILE_SIZE) {
          setValidFile(false);
          return;
        }
          setValidFile(true);
          setLogoFile(file);
          setLogoUrl(URL.createObjectURL(file));        
      } catch (err) {
        showAlert("Upload a valid image file");
        setValidFile(false);
      }
    }
  });


  useEffect(() => {
    async function getLogoDetails() {
      var [err, data] = await fetchRequest.get(`/api/appPersonalization/logo`);
      if (err) {
        console.error(err);
        setError(err);
      }
      else {
        setLogoUrl(data);
      }
      setLoading(false);
    }
    setLoading(true);
    getLogoDetails();
  }, [refresh])

  const handleOnLogoFileUpload = async () => {
    if (logoFile) {
      const data = new FormData();
      data.append("logoFile", logoFile);

      const [err, response] = await fetchRequest.post(`/api/appPersonalization/logo/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (err) {
        showAlert('Upload Logo', 'Something went wrong. Contact your administrator.');
      } else if (response && response.result === true) {
        showSnackbar(response.message || 'Logo uploaded successfully', 'success');
        window.location.reload();
        setRefresh(Math.random());
        setLogoFile(null);
      } else if (response && response.result === false) {
        showAlert('Upload Logo', response.message || 'Something went wrong. Contact your administrator.');
      }
    } else {
      showAlert('Upload Logo', 'Please select a logo file to upload.');
    }
  };

  const handleOnLogoFileDelete = async () => {
    const [err, response] = await fetchRequest.delete(`/api/appPersonalization/logo/delete`);

    if (err) {
      showAlert('Delete Logo', 'Something went wrong. Contact your administrator.');
    } else if (response && response.result === true) {
      setLogoUrl(null);
      window.location.reload();
      showSnackbar(response.message || 'Logo deleted successfully', 'success');
    } else if (response && response.result === false) {
      showAlert('Delete Logo', response.message || 'Something went wrong. Contact your administrator.');
    }
  }

  if (loading) {
    return <YASkeleton variant="loading" />;
  }

  if (error) {
    return <MDBox width="100%" display="flex" alignItems="center" justifyContent="center" p={4}>
      <EmptyState
        size="medium"
        variant="error"
        iconName="error"
        title={"Oops something went wrong!"}
        description={"Contact your administrator for more information."}
      />
    </MDBox>;
  }
  

  return <MDBox flex={1} px={4} pt={3}>
    <MDBox display="flex" flexDirection="column">
      <MDTypography mt={2} mb={2} variant="caption" color="text" fontWeight="medium">Logo</MDTypography>
      {!logoUrl &&
        <>
          <MDBox {...getRootProps({ className: 'dropzone' })} sx={theme => dropBoxStyles(theme, { isDragActive })}>
            <input {...getInputProps()} />
            <MDBox className="dropBox-icon">
              <Icon>cloud_upload</Icon>
            </MDBox>
            <MDTypography variant="button" color="dark">{"Drag & drop your logo here or click to select a file"}</MDTypography>
            <MDTypography variant="caption" color="text" component="span" textAlign="center" mt={.5}>
              {"Image files only (jpg, jpeg, png, gif, svg)"}
            </MDTypography>
          </MDBox>
        </>
      }
      {
        logoUrl && <MDBox>
          <img src={logoUrl} alt="Uploaded Logo" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </MDBox>
      }
      {
        logoUrl && logoFile &&
        <MDBox display="flex">
          <MDButton variant="gradient" color="info" size="small" onClick={handleOnLogoFileUpload}>
            {"save"}
          </MDButton>
        </MDBox>
      }
      {logoUrl && !logoFile &&
        <MDBox display="flex">
          <MDButton sx={{ mt: 1, mr: 1 }} variant="gradient" color="info" size="small" onClick={() => setLogoUrl(null)}>
            <Icon>edit</Icon>
          </MDButton>
          <MDButton sx={{ mt: 1 }} variant="gradient" color="info" size="small" onClick={handleOnLogoFileDelete}>
            <Icon>delete</Icon>
          </MDButton>
        </MDBox>
      }
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" maxWidth={600} mt={1} px={1}>
        {
          !validFile && <MDTypography variant="button" color="error" fontWeight="medium">Upload a valid image with maximum size 100KB.</MDTypography>
        }
      </Stack>
    </MDBox>
  </MDBox>
}

export default AnimatedComponent(Logo);