import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate } from "react-router-dom";
import { useYADialog } from "components/YADialog";
import DashboardItem from 'components/DashboardItem';
import { createRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { isTimeDimensionQuery } from 'utils/dashboard';
import { removeSessionFilter, formatAmount } from 'utils';
import { useDashboardContext } from 'components/DashboardContext';
import { parseTableResultset, convertRStoGraph, multiCategoryGraphData, creatToggleTableCubeOptions } from 'utils/charts';
import colors from "assets/theme/base/colors";
import { useAppController } from "context";



const FixedplacementBarChart = ({ loading, title, subtitle, resultSet, vizOptions, vizState, cubeOptions, chartHelpContextKey}) => {

    const [state,] = useDashboardContext();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const chartRef = createRef();
    const { width, height, ref: rref } = useResizeDetector();
    const [tableCubeOptions, setTableCubeOptions] = useState(null)
    const {showReport} = useYADialog();
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;

    let navigate = useNavigate()

 
    if(loading)
       return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>
    
    let scrollablePlotAreaMinHeight = 300;
    let currentFilters;

    let parsedResultset = [];
    if (isTimeDimensionQuery(resultSet.loadResponses[0].query)) {
        parsedResultset = parseTableResultset(resultSet, state, vizOptions)
        currentFilters = removeSessionFilter(resultSet.loadResponses[0].query.filters, vizOptions)
    }
    else {
        parsedResultset = resultSet.tablePivot();
        currentFilters = removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions)
    }


    var graphData =  convertRStoGraph(parsedResultset, colors.forecastDeviationColors, "bar", vizOptions)
    let graphDataMultiple = multiCategoryGraphData(vizOptions, parsedResultset, colors)
    graphDataMultiple ? graphData = graphDataMultiple : graphData
    
    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        let isInArray = (name, array) => {
            return array.some(item => item.name === name);
        }
        let parsedColumns = []
        parsedResultset.length ?
            parsedResultset.forEach(item => {
                Object.keys(item).map(key => {
                    let col = {
                        "name": key,
                        "displayName": String(key).match(/[^|]*$/g)[0],
                        "type": !isNaN(Number(item[key])) ? (vizOptions.units ? 'number' : "currency") : 'string'
                    }
                    if (!isInArray(col.name, parsedColumns))
                        parsedColumns.push(col)
                })
            })
            : parsedColumns = null
            
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = parsedResultset.length && parsedColumns.length ? [...parsedColumns] : [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = vizOptions?.tableHeight ? vizOptions?.tableHeight : 5.8
        if (cubeOptions) {
            let tableCubeOptions = creatToggleTableCubeOptions(cubeOptions)
            setTableCubeOptions(tableCubeOptions)
        }
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])

    //calculate scrollablePlotAreaMinHeight
    scrollablePlotAreaMinHeight = vizOptions.queryType === "AllAboveYears" ? 40 * graphData.categories.length * Object.keys(graphData.range).length : 40 * graphData.categories.size * Object.keys(graphData.range).length;
    var opts = {
        chart: {  
            type: 'bar',
            width: width, 
            height: height,
        
            scrollablePlotArea: {
                 minHeight:scrollablePlotAreaMinHeight,

            },
            style: { fontFamily: 'inherit', fontSize: '14px', },
            spacingBottom: 0,
            spacingRight: 0,
            spacingLeft: 0,
            backgroundColor:colors.chartBackground
        },
        legend: {
            enabled: true
        },
        title: { text: '' },
        exporting: { enabled: false },
        lang: { thousandsSep: ',' },        
        credits: { enabled: false },
        tooltip: {
            outside: false,
            followPointer:true,
            formatter: function () {
                if(vizOptions?.categoryId)
                   return `<b>${Array.from(graphData?.categoriesWithId)?.find(o => {return o?.category === this.point.category}).id}</b><br/>${this.point.series.name}: <b>${ systemCurrencyDetails?.code || '$'}${Highcharts.numberFormat(this.point.y,0,".",",")}</b>`;
                else if(vizOptions?.seriesPercentage){
                    return `<b>${this.point.category}</b><br/>${this.point.series.name}: <b>${Highcharts.numberFormat(this.point.y, 2)}%</b>`;
                }
                else 
                return `<b>${this.point.category}</b><br/>${this.point.series.name}: <b>${ systemCurrencyDetails?.code || '$'}${Highcharts.numberFormat(this.point.y,0,".",",")}</b>`;
            }
        },
        xAxis: {
            categories: Array.from(graphData.categories),
        },
        yAxis: [{
            reversed: false,
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return formatAmount(Math.abs(this.value), null, systemCurrencyDetails).replace(/ /g, '').replace('.0', '');
                }
            },
        },
    ],
    plotOptions: {
        bar: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
            borderRadius: 2,
            cursor: "pointer",
            point: {
                events: {
                    click: function () {
                        var obj = Object.assign([], [...currentFilters]);
                        if (vizOptions.category)
                            obj.push({ "name": vizOptions.category, "values": [event.point.category] });
                        if (vizOptions["drillTo"] && vizOptions["drillTo"] !== "")
                            navigate(vizOptions.drillTo, { state: obj });
                        
                        if (vizOptions["popupTo"] && vizOptions["popupTo"] !== "")
                            showReport(vizOptions["popupTo"], obj, null);
                        if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                            vizOptions.excludeFilters.map((fil) => {
                                if (obj.find(({ name }) => name === fil))
                                    _.remove(obj, { name: fil });
                            });
                        }
                    }
                }
            }
        }
    },
        series: Object.values(graphData.range)
    }
    
    const nodata = vizOptions.queryType === "AllAboveYears"  && graphData.categories.length == 0 ? true : graphData.categories?.size === 0;

    return (toggleType === 'table' && tableVizOptions ?
        <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet} parsedResultset={parsedResultset} cubeOptions={tableCubeOptions}/> :
        <DashboardItem nodata={nodata} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={parsedResultset} cubeOptions={cubeOptions}>
            <div ref={rref} style={{position: 'relative', height: '100%'}}>
                <div style={{position: 'absolute', left: 0, top: 0, bottom: 0, right: 0}}>
                    <HighchartsReact key={_.uniqueId()} ref={chartRef} highcharts={Highcharts} options={opts} />
                </div>
            </div>
        </DashboardItem>
    )
}

export default FixedplacementBarChart;