export const NotificationType = {
    Alert: 'ALERT',
    Interface: 'Interface',
    ProcessFile: 'ProcessFile',
    ReloadFile: 'ReloadFile',
    CopyRules: 'CopyRules'
};

export const NotificationStatus = {
    Processing: 'PROCESSING',
    Success: 'SUCCESS',
    Error: 'ERROR',
    Info: 'INFO',
    Partial: 'PARTIAL',
};