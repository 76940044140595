import { useRef } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { formatCurrencyNumeral } from "utils";

// Material Dashboard 2 React example components
import { Tooltip } from "@mui/material";
import MDBadge from "components/MDBadge";
import { useAppController } from "context";
import moment from "moment";

// import numeral from "numeral";
import colors from "assets/theme/base/colors";

const getFilterDescription = (defaultDateFormat, dataType = "textbox", operator, values, valueLimit, name, options, sessionFilters, systemCurrencyDetails) => {
    let valueDescription = "";
    if (Array.isArray(values) && values?.length > 1) {
        if (["between", "notBetween"].includes(operator)) {
            if (dataType === "currency")
                valueDescription = `${formatCurrencyNumeral(values[0], systemCurrencyDetails)} - ${formatCurrencyNumeral(values[1], systemCurrencyDetails)}`;
            else if (dataType === "datepicker")
                valueDescription = `${moment(values[0]).format(
                    defaultDateFormat
                )} - ${moment(values[1]).format(defaultDateFormat)}`;
            else
                valueDescription = `${values[0]} - ${values[1]}`;
        }
        else if (valueLimit > 1 && values?.length <= valueLimit)
            valueDescription = values.slice(0, values.length - 1).join(", ") + ", " + values[values.length - 1]
        else if (name === 'Month' && sessionFilters) {
            valueDescription = options[0] !== undefined ? options[0] + " - " + values[values?.length - 1] : '';
        }
        else
            valueDescription = values[0];
    }
    else if (Array.isArray(values) && values?.length === 1) {
        if (dataType === "currency")
            valueDescription = formatCurrencyNumeral(values[0], systemCurrencyDetails);
        else if (dataType === "datepicker")
            valueDescription = moment(values[0]).format(defaultDateFormat);
        else if (dataType === "string")
            return `Like "${values[0] || ""}"`;
        else if (name === 'Month' && sessionFilters)
            valueDescription = options[0] + " - " + values[values.length - 1];
        else valueDescription = values[0];
    }
    else if (["set", "notSet"].includes(operator)) { valueDescription = ""; }
    else if (name === 'Month' && sessionFilters) {
        valueDescription = "";
        // if (values === undefined)
        valueDescription = options[0] + " - " + options[options.length - 1];
    }
    else {
        valueDescription = "All";
    }
    return `${valueDescription || ""}`;
};

const getFilterName = (name) => {
    let formattedName = name;
    let cubeName = name.substring(0, name.indexOf('.'))
    if (cubeName === 'SpendCube' || cubeName === 'CloudConsumptions' || cubeName === 'AssetTcoWithBudget')
        cubeName = ''
    else {
        cubeName = name.replace('Duck','').replace('entre','enter')
        cubeName = cubeName.substring(0, cubeName.indexOf('.') - 1)
    }

    if (cubeName === 'Year' || cubeName === 'Month' || cubeName === 'ExpenseType')
        formattedName = cubeName.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }) ;
    else if (cubeName === 'CostPool' || cubeName === 'SubCostPool' || cubeName === 'Tower' || cubeName === 'SubTower' || cubeName === 'SolutionOffering' || cubeName === 'BusinessUnit' || cubeName === 'SolutionCategorie' || cubeName === 'SolutionName' || cubeName === 'SolutionType'  || cubeName === 'AssetType')
        formattedName = name.substring(name.lastIndexOf('.') + 1).replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }).replace('Total ', '').replace('Full ', '').replace('Codeandname', '').replace('Name', '').replace('Upper', '')  
    else
        formattedName = cubeName.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }) + ' ' + name.substring(name.lastIndexOf('.') + 1).replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }).replace('Total ', '').replace('Full ', '').replace('Codeandname', '').replace('Name', '').replace('Upper', '').replace('Capability', 'Asset')
    return `${formattedName || ""}`;
};


const chipStyles = () => ({
    cursor: 'pointer',
    border: '1px solid #c7c4c4',
    borderRadius: '9px' ,
    display: 'flex',
    flexDirection: 'row',
    minWidth: '40px',
    py: 1.63,
    pl: 1.25,
    pr: 1.4,
    margin: .5,
    mb: 1,
    mt: '',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    fontSize:  '10px',
    backgroundColor:'#f6f6f6',
    height: 16,
    position: 'relative',
    "&:hover": {
        backgroundColor: 'none',
    },
    "& .MuiTypography-root, & .MuiIcon-root":{ color: '#000000!important' },
    "& .chipDesc": { ml: .5, py: 1.5, maxWidth: '150px', minWidth: '35px', fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 600, color:  '#3F4165!important'},
    "& .closeIcon": { height: 25, width: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 600, fontSize: '15px!important', marginTop: .25, marginLeft: .25 },
    "& .fil": {
        position: 'absolute',
        top: '-1em',
        zIndex: 999,
        left: '0.30em',
        fontSize: '13px',
        backgroundColor: colors.dashboardBackground,
        color: "#83849C",
        padding: '0 3px',
        "&:hover": {
            // backgroundColor: !dismissible ? '#cbd2eb!important' : '#eaeaea',
            // borderRadius: 1
        },
    }
})

const FilterPopupChip = (props) => {
    const { name, dataType, field,  options,  filterValue, valueLimit, sessionFilters } = props;
    const [controller,] = useAppController();
    const { appDef: { settings }, systemCurrencyDetails } = controller;
    const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const chipRef = useRef(null);
    const id = 'filter-chip' 
    return <>
        {
            filterValue?.values.length ? 
                <MDBox
                    ref={chipRef}
                    data-testid={field?.toLowerCase().replaceAll(' ', '')}
                    aria-describedby={id}
                    sx={() => chipStyles()}>
                    {
                        <MDTypography className="fil">{/*field}*/}</MDTypography>
                    }
                    <Tooltip placement="bottom"
                        title = {
                            <>
                                <b>{getFilterName(filterValue.name)}</b><br/>{options.map(o => <>{o}<br/></>)}
                            </>
                        }            
                    >
                        <MDTypography variant="caption" className="chipDesc">{getFilterDescription(defaultDateFormat, dataType, filterValue?.operator, filterValue?.values, valueLimit, name, options, sessionFilters, systemCurrencyDetails)}</MDTypography>
                    </Tooltip>
                    {
                        filterValue?.values.length > valueLimit &&
                        <MDBadge circular badgeContent={`+${filterValue?.values.length - 1}`} color="light" size="xs"></MDBadge>
                    }
                </MDBox>
            : <></>
        }
    </>
}

FilterPopupChip.defaultProps = {
    valueLimit: 1,
    openOnMount: true,
    dismissible: true,
    defaultSelected: false,
    isCubeFilter: false,
    optionsLoading: false,
    sessionFilters: false
}

export default FilterPopupChip;
