import { useContext, useEffect, useRef, useState } from 'react';
import { useImmer } from "use-immer";
import DataTable from 'components/DataTable';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import numeral from "numeral";
import _ from 'lodash';
import { useYADialog } from "components/YADialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDBadge from 'components/MDBadge';
import DashboardItem from 'components/DashboardItem';
import { CubeContext } from '@cubejs-client/react';
import ErrorBox from 'components/ErrorBox';
import ColumnOptions from './components/ColumnOptions';
import moment from 'moment';
import SubChartRenderer from './components/SubChartRenderer';
import { getDrilldownPath, removeSessionFilter } from 'utils';
import { useAppController } from 'context';
import { Icon } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import { normalizeCurrency } from 'utils/table';
import { parseTableColumnValue } from 'utils/charts';
import fetchRequest from "utils/fetchRequest";
import Tooltip from '@mui/material/Tooltip';
import { formatCurrencyNumeral } from "utils";

const getValue = (row, propName) => row.values[propName.replace(/\./g, "__")];
  
const loadQueryResult = async (cubeApi, query, pagingOptions, disableServerSidePaging) => {
  let newQuery = { ...query };
  if (pagingOptions) {
    const { pageSize, currentPage, selectedColumns } = pagingOptions;
    newQuery.dimensions = newQuery.dimensions.filter(d => selectedColumns.includes(d));
    newQuery.measures = newQuery.measures.filter(m => selectedColumns.includes(m));
    if (!disableServerSidePaging) {
      newQuery["limit"] = pageSize + 1;
      newQuery["offset"] = currentPage === 0 ? 0 : (currentPage * pageSize);
    }
  }

  try {
    const response = await cubeApi.load(newQuery);
    return {
      resultSet: response,
      error: null
    };
  } catch (error) {
    return {
      resultSet: null,
      error
    };
  }
}

// const defaultSelector = (columns) => {
//   const selector = [
//     {title: "Transaction Date", dataType: "date", optional: true},
//     {title: "Journal ID", dataType: "textbox", optional: true},
//     {title: "Journal Line", dataType: "string", optional: true},
//     {title: "Expense Type", dataType: "textbox", optional: true},
//     {title: "Account Code", dataType: "textbox", optional: true},
//     {title: "Account Name", dataType: "textbox", optional: true},
//     {title: "Cost Center Code", dataType: "textbox", optional: true},
//     {title: "Cost Center Name", dataType: "textbox", optional: true},
//     {title: "Vendor Code", dataType: "textbox", optional: true},
//     {title: "Vendor Name", dataType: "textbox", optional: true},
//     {title: "Cost Pool", dataType: "textbox", optional: true},
//     {title: "Sub Cost Pool", dataType: "textbox", optional: true},
//     {title: "Tower", dataType: "textbox", optional: true},
//     {title: "Sub Tower", dataType: "textbox", optional: true},
//     {title: "Solution Type", dataType: "textbox", optional: true},
//     {title: "Solution Category", dataType: "textbox", optional: true},
//     {title: "Solution Name", dataType: "textbox", optional: true},
//     {title: "Solution Offering ID", dataType: "textbox"},
//     {title: "Solution Offering", dataType: "textbox"},
//     {title: "Business Unit ID", dataType: "textbox"},
//     {title: "Business Unit", dataType: "textbox"},
//     {title: "BU Cost Centre", dataType: "textbox"},
//     {title: "Unit Of Measure", dataType: "textbox"},
//     {title: "Unit Spend", dataType: "currency"},
//     {title: "Usage", dataType: "number"},
//     {title: "Spend", dataType: "currency"},
//   ]
//   columns.map((column) => {
//     const found = _.filter(selector, {title: column.title})
//     if (found) {
//       found.map((object) => {
//         Object.keys(object).map((key) => {
//           column[key] = object[key]
//         })
//       })
//     }
//   })
// }

// const TableRenderer = ({ title, subtitle, chartHelpContextKey, vizState, vizOptions, setAppliedFilters }) => {
  const TableRenderer = ({ title, subtitle, chartHelpContextKey, vizState, vizOptions }) => {
  const { reportId } = useParams();
  // defaultSelector(vizOptions.columns);
  const columnsDef = vizOptions.columns
  const selectedColumnsInitial = columnsDef?.filter((c) => !c.optional).map((c) => c.name);
  const mounted = useRef(false);
  const { cubeApi } = useContext(CubeContext);
  const [result, setResult] = useState(null);
  const [pageOptions, setPageOptions] = useState({ selectedColumns: selectedColumnsInitial, currentPage: 0, pageSize: vizOptions.defaultPageSize || 50 });
  const [pgIndx, setPgIndx] = useState(0)
  const [widgetName, setWidgetName] = useState('');
  const [search, setSearch] = useState(null);
  const [filtersState, setFiltersState] = useImmer({
    globalFilter: undefined,
    filters: [],
  });

  // const appliedFilters = filtersState?.filters.map((filter) => {
  //   let r={}
  //   r.member = filter.id.replace(/__/g, ".");
  //   r.operator = filter?.value.operator && filter?.value.operator === 'eq' ? 'equals' : filter?.value.operator;
  //   r.values = filter?.value?.values;
  //   return r
  // })

  // useEffect(() => {
  //   if (appliedFilters) {
  //     setAppliedFilters(appliedFilters)
  //   }
  // }, [filtersState])
  
  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState((draft) => {
        draft.globalFilter = latestGlobalFilter;
        draft.filters = latestFilters;
    });
  };

  const onPageOptionsChange = (pageOptionsVal) => {
    setPageOptions(prev => ({ ...prev, ...pageOptionsVal }));
  };

  useEffect(() => {
    setPageOptions({ selectedColumns: selectedColumnsInitial, currentPage: 0, pageSize: vizOptions.defaultPageSize || 50 })
  }, [vizState ])
  
  useEffect(async () => {
    if (!mounted.current) {
      mounted.current = true;
      let widgetRef = reportId + '-' + title.toLowerCase().replaceAll(' ', '-');
      setWidgetName(widgetRef);
      if (widgetRef.indexOf("undefined") === -1 && sessionStorage[widgetRef])
        onPageOptionsChange({ selectedColumns: sessionStorage[widgetRef].split(',') })
    }

    setResult(null);
    const result = await loadQueryResult(cubeApi, vizState.query, pageOptions, vizOptions.disableServerSidePaging);
    setResult(result);
  }, [pageOptions]);

  const loading = !result;

  if (result?.error) {
    return <ErrorBox error={result?.error} />
  }

  if (loading)
    return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

  return <TableRenderer1 {...({ title, subtitle, filtersState, handleOnFiltersStateUpdate, resultSet: result.resultSet, error: result.error, chartHelpContextKey, vizState, vizOptions, pageOptions, onPageOptionsChange, widgetName, setPgIndx, pgIndx, search, setSearch, setPageOptions, selectedColumnsInitial })} />
}

const TableRenderer1 = ({ title, subtitle, filtersState, handleOnFiltersStateUpdate, resultSet, error, chartHelpContextKey, vizState, vizOptions, pageOptions, onPageOptionsChange, widgetName, setPgIndx, search, setSearch }) => {
  const { columns: columnsDef } = vizOptions;
  const { showReport } = useYADialog();
  let navigate = useNavigate()
  let location = useLocation()

  const [controller,] = useAppController();
  const { userInfo, appDef: { settings }, systemCurrencyDetails } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

  const [showOptions, setShowOptions] = useState(false);

  const queryColumns = columnsDef?.filter(c => pageOptions.selectedColumns?.includes(c.name));

  const subChartColumns = columnsDef?.filter((c) => c.dataType === "graph");
  const hasSubCharts = subChartColumns && subChartColumns.length > 0;


  useEffect(()=>{
   setPgIndx(null)
  },[resultSet.loadResponse.pivotQuery.filters])
  
  const loading = !resultSet;
  const subQueriesLoading = hasSubCharts

  if (error ) {
    return <ErrorBox error={error} />
  }

  if (loading || subQueriesLoading)
    return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

  let currentFilters
  currentFilters = removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions)

  // const cardHeight = heightUnits ? (heightUnits * 56) + ((heightUnits - 1) * 8) : null;

  // const containerMaxHeight = cardHeight ? cardHeight - 176 : null;

  const columns = queryColumns?.filter((col) => !col.hidden).map(
    (c) => ({
      Header: c.title,
      dataType: c.dataType,
      accessor: c.name.replace(/\./g, "__"),
      align: (c.dataType === "number" || c.dataType === "currency" || c.dataType === "variance" || c.dataType === "graph") ? "right" : c.align || "left",
      disableSorting: (c.dataType === "graph") ? true : c.disableSorting || false,
      Cell: ({ row: { original }, cell: { value } }) => {
        if (c.dataType === "number") {
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{numeral(normalizeCurrency(value)).format('0,0')}</MDTypography>
        }
        else if (c.dataType === "currency") {
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography>
        }
        else if (c.tooltip) {
          return <Tooltip title = {value} placement="bottom"><MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{value}</MDTypography></Tooltip>
        }
        else if (c.dataType === "date")
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{value ? moment(value).format(defaultDateFormat || c.format) : ""}</MDTypography>
        else if (c.dataType === "boolean")
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{value === true ? "Yes" : "No"}</MDTypography>
        else if (c.dataType === "variance") {
          if (c.variant === "dot")
            return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"} display="flex" alignItems="center" justifyContent="flex-end">{formatCurrencyNumeral(!value ? 0 : normalizeCurrency(value), systemCurrencyDetails)}<MDBox ml={0.8} sx={({ palette: { success, error } }) => ({ height: 10, width: 10, borderRadius: "50%", backgroundColor: Number(value) <= 0 ? success.main : error.main })}></MDBox></MDTypography>
          else if (c.variant === "text")
            return <>{Number(value) <= 0 ? <Icon color="success">arrow_downward</Icon> : <Icon color="error">arrow_upward</Icon>} &nbsp;&nbsp;<MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{formatCurrencyNumeral(!value ? 0 : normalizeCurrency(value), systemCurrencyDetails)}</MDTypography></>
          return <MDBadge container circular key={c.name.replace(/\./g, "__")} badgeContent={formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)} color={Number(value) <= 0 ? "success" : "error"} variant="gradient" size="xs" />
        }
        else if (c.dataType === "calculated") {
          const result = parseTableColumnValue(c, original, systemCurrencyDetails);
          if (c.trendColumn) {
            let trendValueDirectionUpVal = (result.originalValue && result.originalValue > 0);
            const trendColor = c.trendColumn.negateColorLogic ?
              (trendValueDirectionUpVal ? "error" : "success")
              :
              (trendValueDirectionUpVal ? "success" : "error");

            return <>
              {result.originalValue !== null && <Icon color={trendColor} sx={{ mx: .5, lineHeight: "18px" }}>{trendValueDirectionUpVal ? 'arrow_upward' : 'arrow_downward'}</Icon>}
              <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color={"dark"} fontWeight={c.emphasize && "medium"}>
                {result.value}
              </MDTypography>
            </>;
          }
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color={"dark"} fontWeight={c.emphasize && "medium"}>{result.value}</MDTypography>
        }
        else if (c.dataType === "graph") {
          const { filteredRow, data: { resultSet } } = value;
          const { vizState: { chartType }, vizOptions } = c;
          return resultSet ? <SubChartRenderer key={c.name.replace(/\./g, "__")} chartType={chartType} tableRow={filteredRow} resultSet={resultSet} vizOptions={vizOptions} /> : <></>
        }
        if (c.avatar) {
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" display="flex" color="dark" alignItems="center" justifyContent="flex-start" fontWeight={c.emphasize && "medium"}><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>
        }
        return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{value}</MDTypography>
      }

    })
  );

  let i = 1;
  let rows = [];
  rows = resultSet?.tablePivot().map((row) => {
    if (i === 1 && vizOptions.changeMonths) {
      let months = row["Months.allMonths"].split(",")
      columns.splice(columns.findIndex(item => item.Header == "Months"), 1);
      months.map((mth, idx) => {
        columns[columns.findIndex(item => item.Header == idx + 1)].Header = mth;
      });
      i = 0;
    }
    let r = {};
    resultSet.tableColumns().forEach((c) => {
      if (c.key !== queryColumns?.filter((col) => col.hidden).name)
        r[c.key.replace(/\./g, "__")] = row[c.key];
    });

    return r;
  });

  const _onRowClick = async (e, row) => {
    const currentFilterNames = currentFilters.map(f => f.name);
    var obj = Object.assign([], [...currentFilters]);
    vizOptions.params.filter((col) => pageOptions.selectedColumns?.includes(col) && currentFilterNames?.includes(col)).forEach((col) => _.remove(obj, { name: col }))
    vizOptions.params.filter((col) => pageOptions.selectedColumns?.includes(col)).forEach((col) => obj.push({ "name": col, "values": [getValue(row, col)] }))
    if (vizOptions["popupTo"] && row.values["Months__month"]) {
      if (obj.find((({ name }) => name === "Months.month"))) {
        _.remove(obj, { name: "Months.month" })
        obj.push({ name: "Months.month", "values": [row.values["Months__month"]] })
      }
    }
    const replaceEmailId = (email)=> {
      const [, domain] = email.split('@')
      let userNames = `#@${domain}`;
      return userNames 
    }
    let url = ""
    if(vizOptions.url) {
      const [error, users] = await fetchRequest.get(`/api/users/lookup/user`);
      if(error) console.error(Error);
      let user = users?.find(item => item. id === userInfo.sub)
      let userName = replaceEmailId(user?.email)
      let urlValue = obj[obj.length-1]["values"];
      let providerDomain = obj[obj.length-2]["values"][0];
      url = `${providerDomain}${userName}/resource${urlValue}`
      if(providerDomain)
      window.open(url)
    }
    var popupkey = ""
    var popupTo = vizOptions["popupTo"] ? vizOptions["popupTo"] : ''
    if (vizOptions["popupToKey"]) {
        popupkey = obj.find((({ name }) => name === vizOptions["popupToKey"])) ? obj.find((({ name }) => name === vizOptions["popupToKey"])).values[0] : ''
        popupTo =  vizOptions["popupTo"] ? vizOptions["popupTo"].replace("changeme",popupkey.toLowerCase()).replace(' ','') : ''
    }
    if (vizOptions.queryType && (vizOptions.queryType === "CompareWithPrevYearTrend" || vizOptions.queryType === "CompareWithPrevYearTrendFiltered") && vizOptions["popupTo"] && vizOptions["popupTo"] !== "") {
      if (obj.find((({ name }) => name === "Years.year")))
        _.remove(obj, { name: "Years.year" })
      obj.unshift({ name: "Years.year", "values": [sessionStorage["Year"]] })
    }
    if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
      vizOptions.excludeFilters.map((fil) => {
        if (obj.find((({ name }) => name === fil)))
          _.remove(obj, { name: fil })
      })
    }
    vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath(location.pathname, vizOptions.drillTo), { state: obj })
    vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && (vizOptions["popupToKey"] ? showReport(popupTo, obj, null) : showReport(vizOptions["popupTo"], obj, null));
  };

  const bindOnClick = vizOptions["drillTo"] || vizOptions["popupTo"] || vizOptions["url"];

  // const handleOnOptionsClick = () => {
  //   setShowOptions(true);
  // }
  const handleOnOptionsClose = () => {
    setShowOptions(false);
  }

  const handleOnOptionsApply = (selectedColumnIds) => {
    if (setPgIndx) {
      setPgIndx(null)
    }
    onPageOptionsChange({ ...pageOptions, selectedColumns: selectedColumnIds })
  }

  const nodata = !rows?.length > 0;
  // let handlePageOption = () => {
  //   setPageOptions({ selectedColumns: selectedColumnsInitial, currentPage: 0, pageSize: vizOptions.defaultPageSize || 20 })
  // }

  var canDownload = false
  var isTable = vizState?.chartType === "table"? true : false
  return <DashboardItem nodata={nodata} table title={''} download={canDownload} isTable={isTable} subtitle={''} primaryRender={false} chartHelpContextKey={chartHelpContextKey} noLink={true} hideToggle={true}>
    <>
      <DataTable
        variant="tile"
        table={{ columns, rows }}
        containerMaxHeight={350}
        newStyle1={true}
        showTotalEntries={true}
        isSorted={true}
        noEndBorder
        canSearch={true}
        onRowClick={bindOnClick && _onRowClick}
        // onOptionsClick={vizOptions.hideColumnOptions ? '' : handleOnOptionsClick}
        srch={search}
        filtersState={filtersState}
        onFiltersStateUpdate={handleOnFiltersStateUpdate}
        canFilter={true}
        setSrch={setSearch}
        onPageOptionsChange={onPageOptionsChange}
        // handlePageOption={handlePageOption}
      >
      </DataTable>
      {/* {(!hidefooter && !disableServerSidePaging) && (
        <MDBox
          display="flex"
          flexDirection={{ xs: "row", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          margin={{ lg: 1.3, md: 1.3 }}
          px={{ lg: 3, md: 3, sm: 0, xs: 0 }}
          py={1.5}
        >
          <MDBox>
            <MDPagination variant={""} color="secondary" active>
              <MDPagination item onClick={() => !canPreviousPage ? null : previousPage()} disabled={!canPreviousPage} active pointerAction="auto"  >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
              <MDBox display="flex">
                <MDTypography variant="button" color="secondary" width="100%" height="100%" mx={1}>
                  {pageOptions.currentPage + 1}
                </MDTypography>
              </MDBox>
              <MDPagination item onClick={() => !canNextPage ? null : nextPage()} disabled={!canNextPage} active pointerAction="auto">
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            </MDPagination>
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="right">
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;Rows per page:
            </MDTypography>
            <Autocomplete
              disableClearable
              value={pageOptions?.pageSize}
              options={entries}
              onChange={(event, newValue) => {
                onPageOptionsChange({ currentPage: 0, pageSize: parseInt(newValue) })
              }}
              getOptionLabel={option => option?.toString()}
              size="small"
              sx={{ width: "4rem" }}
              renderInput={(params) => <MDInput sx={(theme) => chipStyles(theme)} {...params} />}
            />
          </MDBox>
        </MDBox>
      )
      } */}
    </>
    {showOptions && (
      <ColumnOptions
        columnsDef={columnsDef}
        selectedColumnsInitial={pageOptions.selectedColumns}
        widgetName={widgetName}
        onOptionsClose={handleOnOptionsClose}
        onOptionsApply={handleOnOptionsApply}
      />
    )}
  </DashboardItem>;
}

export default TableRenderer;