import { Stack } from "@mui/material";
import { AnimatedComponentInline } from "components/AnimatedComponent";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import numeral from "numeral";
import ErrorBoundary from "components/ErrorBoundary";
import ThinkingBox from "./ThinkingBox";
import StatAnswer from "./StatAnswer";
import TextAnswer from "./TextAnswer";
import AnswerRenderer from "./AnswerRenderer";
import { useAppController } from "context";
import { formatCurrencyNumeral } from "utils";

const isCurrenyColumn = (columnName) => {
    if (["cost centre", "cost center", "costcentre", "costcenter", "cost pool", "costpool"].some(s => columnName.toLowerCase().indexOf(s) !== -1))
        return false;

    return ["spend", "spent", "expense", "expenditure", "variance", "budget", "amount", "cost"].some(s => columnName.toLowerCase().indexOf(s) !== -1)
};


const checkAndParseSingleValueResult = (data) => {

    if (isSingleValuedResult(data)) {
        const firstColName = Object.keys(data[0])[0] || "";
        const firstColValue = data[0][firstColName] || "";

        return ({
            result: true,
            name: firstColName,
            value: firstColValue
        });
    }
    else
        return ({
            result: false,
            name: null,
            value: null
        });

}

const isSingleValuedResult = (result) => {
    if (Array.isArray(result) && result?.length === 1 && Object.keys(result[0]).length === 1)
        return true;
    return false;
}

const ConversationContainer = ({ thinking, username, question, children }) => {
    return <MDBox p={1} position="relative">
        <ErrorBoundary>
            <Stack direction={"column"} spacing={1} sx={{ px: 2, mb: 2 }}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <MDAvatar name={username} size="xs" />
                    <MDTypography variant="button" fontWeight="medium" color="text">{username}</MDTypography>
                    {/* <MDTypography variant="caption" color="text">12 min ago</MDTypography> */}
                    {/* <IconButton title="Delete Converasation" size="small" sx={{ ml: "auto!important" }}><Icon>delete</Icon></IconButton> */}
                </Stack>
                <MDTypography variant="button" component="p" fontWeight="regular" py={.5} lineHeight={1.5}>{question}</MDTypography>
            </Stack>
            <Stack direction={"column"} spacing={1} sx={{ px: 2 }}>
                {!thinking && (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <MDAvatar name={"Y ä"} size="xs" sx={{ background: "#facd35", color: "#000000", fontWeight: "500" }} />
                        <MDTypography variant="button" fontWeight="medium" color="text">YäRKEN</MDTypography>
                    </Stack>
                )}
                {children}
            </Stack>
        </ErrorBoundary>
    </MDBox>
}

const Conversation = ({ username, question, answer, thinking, progress, lastAnswer, error, onRetry }) => {

    let stringResult = typeof answer.data === "string";
    let parsedResult = checkAndParseSingleValueResult(answer.data);
    let singleValueResult = parsedResult.result;
    let singleValueResultColumnName = parsedResult.name;
    let singleValueResultValue = parsedResult.value;
    let statAnswer = false;
    let emptyResult = (singleValueResult && singleValueResultValue === "") || (!Array.isArray(answer.data) || answer.data?.length === 0);
    const [controller] = useAppController();
    const { systemCurrencyDetails } = controller;


    if (singleValueResult) {
        if (isCurrenyColumn(singleValueResultColumnName)) {
            singleValueResultValue = isNaN(singleValueResultValue) ? 0 : formatCurrencyNumeral(singleValueResultValue, systemCurrencyDetails);
            statAnswer = true;
        }
        else {
            singleValueResultValue = isNaN(singleValueResultValue) ? 0 : numeral(singleValueResultValue).format("0,0");
            statAnswer = true;
        }
    }

    // if (thinking)
    //     return <ConversationContainer thinking={thinking} username={username} question={question}><ThinkingBox progressStage={progress?.stage} /></ConversationContainer>

    if (stringResult)
        return <ConversationContainer thinking={thinking} username={username} question={question}>
            <TextAnswer question={question} answer={answer.data} lastAnswer={lastAnswer} error={error} onRetry={onRetry} />
            {thinking && <ThinkingBox progressStage={progress?.stage} />}
        </ConversationContainer>

    if (!thinking && emptyResult)
        return <ConversationContainer thinking={thinking} username={username} question={question}>
            <TextAnswer question={question} answer={answer.noRecordsMessage || "I'm sorry, I don't have that information at the moment. Is there anything else I can assist you with?"} lastAnswer={lastAnswer} error={error} onRetry={onRetry} />
        </ConversationContainer>

    if (statAnswer)
        return <ConversationContainer username={username} question={question}>
            <Stack spacing={1} alignItems={"flex-start"}>
                <StatAnswer answer={singleValueResultValue} />
                <TextAnswer answer={answer.summary} />
                {thinking && <ThinkingBox progressStage={progress?.stage} />}
            </Stack>
        </ConversationContainer>

    return <ConversationContainer username={username} question={question}>
        <AnswerRenderer answer={answer} />
        {thinking && <ThinkingBox progressStage={progress?.stage} />}
    </ConversationContainer>;
};

export default AnimatedComponentInline(Conversation);