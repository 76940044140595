import AnimatedRoute from 'components/AnimatedRoute';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import PageHeader from 'components/PageHeader';
import YASkeleton from 'components/YASkeleton';
// import DraggablePanel from "components/DraggablePanel";
import { useEffect, useState } from 'react';
import useFetchRequest from "hooks/useFetchRequest";
import useHandleError from 'hooks/useHandleError';
import _ from "lodash";
import { Card, CircularProgress, circularProgressClasses, Divider, Icon, Menu, MenuItem, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import MDTypography from 'components/MDTypography';
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import { formatAmount } from 'utils';
import Add_Map from "assets/images/Add_Map.png";
import CostPoolMappingView from 'components/CostPoolMappingView';
import AddCostPoolRule from 'components/AddCostPoolRule';
import AddTowerRule from 'components/AddTowerRule';
import FilteredUploadedFiles from 'components/FilteredUploadedFiles';
import TowerMappingView from 'components/TowerMappingView';
import DataUploadDialog from 'pages/Dataflow/components/DataUploadDialog';
import CopyFilesDialog from 'pages/Dataflow/components/CopyFilesDialog';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import colors from 'assets/theme/base/colors';
import { downloadCPRules, downloadTRRules, downloadBURules, downloadSolutionMapping, downloadSolutionRules, downloadCPBudgets, downloadTowerBudgets } from "utils"
import AllocationFiles from '../AllocationFiles';
import { backgroundProcessCheck } from '../../../../utils'
import BUMappingView from 'components/BUMappingView';
import OfferingMappingView from 'components/OfferingMappingView';
import AddBURule from 'components/AddBURule';
import SpendDialog from '../SpendDialog';
import AssetMapping from '../AssetMapping';
import UnallocatedSpend from '../UnallocatedSpend';
import SolutionOfferingMapping from '../SolutionOfferingMapping'
import { toInt } from 'utils';
import moment from 'moment';
import DataloadDialog from 'components/DataloadDialog';
import Consumption from '../Consumption';
import CloudSpend from '../CloudSpend';
import FilteredUploadRules from 'components/FilteredUploadRules';
import PiesChartRenderer from '../ChartRenderer/PiesChartRenderer';
import ErrorBoundary from 'components/ErrorBoundary';
import AssetSpendView from 'components/AssetSpendView';
import CopyRulesDialog from 'components/CopyRules';
import { useNotificationSubscription } from "hooks/useNotificationManager";
import { NotificationType } from "constants";
import { useAppController } from "context";


const MapRule = (props) => {
  const { yearFilter, yearFilterName, categoryFilter, tabStyles, menuItem, setMenuItem, monthFilter, setMonthFilter, monthFilterName, setMonthFilterName, action, setAction, setRefresh, setSubCategoryFilter, subCategoryFilter, chargeBackMonthly, publishedMonthly, uploadType, serverSideDataPaging } = props
  const [typeFilter, setTypeFilter] = useState("Spend");
  const [ controller ] = useAppController();
  const { systemCurrencyDetails } = controller;

  useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
    if(notification?.details?.destinationTable === "towerConsumption") {
      setAction("view");
    }
});


  let displayName = (categoryFilter !== 'Mobile Usage' && categoryFilter !== 'Cloud Usage') ? categoryFilter + 's' : `${categoryFilter} Data`;
  if (action != null) {
    if (menuItem) {
      displayName = <>
        <MDBox display="flex">
          <MDTypography
            component="span"
            variant="h3"
            fontWeight="medium"
            lineHeight="1"
            sx={() => ({

              "&:hover": {
                color: colors.linkColour,
                cursor: 'pointer'
              }
            })} onClick={() => { setMenuItem(menuItem); setMonthFilter(null); setAction(null) }}>{displayName}</MDTypography>
          <ChevronRightIcon fontSize="medium" sx={{ marginTop: 0.3 }} />
          <MDTypography
            component="span"
            variant="h3"
            fontWeight="medium"

            // sx={{ lineHeight: 1.25,}}
            sx={{ lineHeight: 1, "&:hover": { "& .helpIcon": { visibility: 'visible' } } }}
          >{monthFilterName}</MDTypography>
          {categoryFilter !== 'Cloud Usage' && categoryFilter !== 'Mobile Usage' && <MDTypography
            component="span"
            variant="h5"
            fontWeight="medium"
            pl="3px"
            pt="5px"

            // sx={{ lineHeight: 1.25,}}
            sx={{ lineHeight: 1.2, "&:hover": { "& .helpIcon": { visibility: 'visible' } } }}
          >{' (' + typeFilter + ')'}</MDTypography>}
        </MDBox></>
    }
  }


  const handleAction = (month, monthName, action) => {
    setMonthFilter(month)
    setMonthFilterName(monthName)
    // setTitle(displayName + ">" + monthName)
    setAction(action)
  }

  const subtitle = categoryFilter !== 'Cloud Usage' && categoryFilter !== 'Mobile Usage' ?
    `Add your monthly ${categoryFilter.toLowerCase()} rules for allocation and mapping` :
    `Manage your monthly ${categoryFilter.toLowerCase()} data`
  return (
    <>
        <div>
          <PageHeader
            title={displayName}
            subtitle={subtitle}
            hideBreadcrumbs={true}
            anchor={categoryFilter + 's'}
          />
          {!monthFilter && <ShowAllMonths yearFilter={yearFilter} yearFilterName={yearFilterName} tabStyles={tabStyles} categoryFilter={categoryFilter} setTypeFilter={setTypeFilter} typeFilter={typeFilter} handleAction={handleAction} setRefresh={setRefresh} subCategoryFilter={subCategoryFilter} setSubCategoryFilter={setSubCategoryFilter} chargeBackMonthly={chargeBackMonthly} publishedMonthly={publishedMonthly} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging}  systemCurrencyDetails = {systemCurrencyDetails}/>}
          {action && action !== "" && <ShowRules action={action} yearFilter={yearFilter} yearFilterName={yearFilterName} tabStyles={tabStyles} monthFilter={monthFilter} setTypeFilter={setTypeFilter} handleAction={handleAction} monthFilterName={monthFilterName} setAction={setAction} typeFilter={typeFilter} categoryFilter={categoryFilter} setRefresh={setRefresh} subCategoryFilter={subCategoryFilter} setSubCategoryFilter={setSubCategoryFilter} chargeBackMonthly={chargeBackMonthly} publishedMonthly={publishedMonthly} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging} systemCurrencyDetails = {systemCurrencyDetails} />}
        </div>
    </>
  );
}

const ShowRules = (props) => {
  const { yearFilter, yearFilterName, monthFilter, monthFilterName, action, setAction, categoryFilter, tabStyles, typeFilter, setRefresh, handleAction, setTypeFilter, setSubCategoryFilter, subCategoryFilter, chargeBackMonthly, uploadType, serverSideDataPaging, systemCurrencyDetails } = props
  const [originalFileName, setOriginalFileName] = useState(null)
  const [ edit, setEdit ] = useState(false)
  const [editValue, setEditValue] = useState(null)

  const handleUploadDialogClose = () => {
    action == "addAssetMapping" || action === "viewAssetMapping" ? setAction(categoryFilter) : subCategoryFilter === "Asset Rule" ? setAction("assetRuleview") : subCategoryFilter === "Solution Rule" ? setAction("solutionOfferingRulesview") : setAction("view");
    setSubCategoryFilter(null)
    setRefresh(Math.random());
  };

  let txt = "UNALLOCATED " + typeFilter.toUpperCase()
  return (
    <>
      {action !== "choose" && action !== categoryFilter && <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", borderBottom: "1px solid #edeef3", borderTop: "1px solid #e3e3e3", display: "inline-flex" }} justifyContent="space-between">
        <MDBox display="flex">
          { (['Solution', 'Business Unit'].includes(categoryFilter) && typeFilter === 'Spend' ? false : true) && categoryFilter !== 'Cloud Usage' && categoryFilter !== 'Mobile Usage' && <MDButton data-testid={txt?.toLowerCase().replaceAll(' ', '')} sx={(theme) => tabStyles(theme, { selected: action === "add" && !subCategoryFilter })} onClick={() => { setAction("add"); setSubCategoryFilter(null); setEdit(false) }} >
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}> {txt === "UNALLOCATED SPEND" ? "running_with_errors" : "account_balance_wallet"}</Icon>
            {txt}</MDButton>}
          {categoryFilter === 'Cloud Usage' &&
            <MDButton data-testid={"cloudSpend"} sx={(theme) => tabStyles(theme, { selected: action === "cloudSpend" })} onClick={() => { setAction("cloudSpend"); setSubCategoryFilter(null); setEdit(false) }}>
              <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>cloud_done</Icon>
              CLOUD SPEND</MDButton>
          }
          {/* {categoryFilter === 'Mobile Usage' &&
            <MDButton data-testid = {"mobileSpend"} sx={(theme) => tabStyles(theme, { selected: action === "mobileSpend" })} onClick={() => {setAction("mobileSpend"); setSubCategoryFilter(null)}}>
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>cloud_done</Icon>
            Mobile SPEND</MDButton>
          } */}
          {(categoryFilter === 'Cloud Usage' || categoryFilter === 'Mobile Usage') &&
            <MDButton data-testid={"view"} sx={(theme) => tabStyles(theme, { selected: action === "view" })} onClick={() => { setAction("view"); setSubCategoryFilter(null); setEdit(false) }}>
              <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>file_present</Icon>
              UPLOADED FILES</MDButton>
          }
          {/* {(categoryFilter === 'Cloud Usage' || categoryFilter === 'Mobile Usage') && 
            <MDButton data-testid = {"uploadRules"} sx={(theme) => tabStyles(theme, { selected: action === "uploadRules" })} onClick={() => setAction("uploadRules")}>
            <Icon color="dark" fontSize="small" sx={{ transform: "rotate(360deg)", marginRight: "6px" }}>file_present</Icon>
            UPLOAD RULES</MDButton>} */}
          {categoryFilter !== 'Cloud Usage' && categoryFilter !== 'Mobile Usage' && <MDButton data-testid={"rules"} sx={(theme) => tabStyles(theme, { selected: action === "view" })} onClick={() => { setAction("view"); setSubCategoryFilter(null); setEdit(false) }}>
            <Icon color="dark" fontSize="small" sx={{ transform: "rotate(90deg)", marginRight: "6px" }}>merge</Icon>
            {typeFilter == "Forecast" ? "ALLOCATED FORECAST" : typeFilter === "Budget" ? "ALLOCATED BUDGET" : ['Cost Pool', 'Tower'].includes(categoryFilter) ? `${(categoryFilter).toUpperCase()} RULES` : `${(categoryFilter).toUpperCase()} MAPPING`}</MDButton>}
          {categoryFilter !== "Cost Pool" && categoryFilter !== "Business Unit" && categoryFilter !== "Solution" && categoryFilter !== 'Cloud Usage' && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter!=="Forecast" && <MDButton data-testid={"rules"} sx={(theme) => tabStyles(theme, { selected: action === "assetRuleview" })} onClick={() => { setAction("assetRuleview"); setSubCategoryFilter("Asset Rule"); setEdit(false) }}>
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>gavel</Icon>
            ASSET RULES</MDButton>}
          {categoryFilter !== "Cost Pool" && categoryFilter !== "Business Unit" && categoryFilter !== "Solution" && categoryFilter !== 'Mobile Usage' && categoryFilter !== 'Cloud Usage' && typeFilter !== "Budget" && typeFilter!=="Forecast" && <MDButton data-testid={"rules"} sx={(theme) => tabStyles(theme, { selected: action === "viewUnallocatedAssetSpend" })} onClick={() => { setAction("viewUnallocatedAssetSpend"); setSubCategoryFilter("unAllocatedAssetSepend"); setEdit(false) }}>
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>production_quantity_limits</Icon>
            UNALLOCATED ASSET SPEND</MDButton>}
          {categoryFilter !== "Cost Pool" && categoryFilter !== "Business Unit" && categoryFilter !== "Solution" && categoryFilter !== 'Mobile Usage' && categoryFilter !== 'Cloud Usage' && typeFilter !== "Budget" && typeFilter!=="Forecast" && <MDButton data-testid={"rules"} sx={(theme) => tabStyles(theme, { selected: action === "viewAssetSpend" })} onClick={() => { setAction("viewAssetSpend"); setSubCategoryFilter("assetSpend"); setEdit(false) }}>
          <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>production_quantity_limits</Icon>
            ASSET MAPPING</MDButton>}
          {typeFilter === 'Spend' && categoryFilter === 'Solution' && categoryFilter !== 'Cloud Usage' && categoryFilter !== 'Mobile Usage' && <MDButton data-testid={"solutionofferingrules"} sx={(theme) => tabStyles(theme, { selected: action === "solutionOfferingRulesview" })} onClick={() => { setAction("solutionOfferingRulesview"); setEdit(false)}}>
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>gavel</Icon>
            RULES</MDButton>}
          {categoryFilter !== 'Cloud Usage' && categoryFilter !== 'Mobile Usage' && <MDButton data-testid={"uploadedfiles"} sx={(theme) => tabStyles(theme, { selected: action === "uploaded" || action === 'consumption' })} onClick={() => setAction("uploaded")}>
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>file_present</Icon>
            UPLOADED FILES</MDButton>}
          {categoryFilter !== "Cost Pool" && categoryFilter !== "Solution" && categoryFilter !== 'Cloud Usage' && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter!=="Forecast" && <MDButton data-testid={"allocationfiles"} sx={(theme) => tabStyles(theme, { selected: action === "allocation" })} onClick={() => {setAction("allocation"); setEdit(false)}}>
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>library_books</Icon>
            ALLOCATION FILES</MDButton>}
        </MDBox>
      </MDBox>}
      {action === "choose" && <ShowChoice setAction={setAction} categoryFilter={categoryFilter} yearFilter={yearFilter} monthFilter={monthFilter} subCategoryFilter={subCategoryFilter} />}
      {action === "upload" && categoryFilter === "Cost Pool" && <DataUploadDialog uploadType={"costPoolMapping"} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} onClose={handleUploadDialogClose} />}
      {action === "copy" && categoryFilter === "Cost Pool" && <CopyFilesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyCPRules"} onClose={handleUploadDialogClose} />}
      {action === "copyTo" && categoryFilter === "Cost Pool" && <CopyRulesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyCPRules"} onClose={handleUploadDialogClose} />}
      {action === "add" && categoryFilter === "Cost Pool" && <AddCostPoolRule yearFilter={yearFilter} monthFilter={monthFilter} mt={2} containerHeight={"calc(100vh - 400px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} edit={edit} editValue={editValue} setEdit={setEdit} serverSideDataPaging={serverSideDataPaging} />}
      {action === "view" && categoryFilter === "Cost Pool" && <CostPoolMappingView yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 370px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} setAction={setAction} download={typeFilter === 'Spend' ? downloadCPRules : downloadCPBudgets} setEdit={setEdit} setEditValue={setEditValue} serverSideDataPaging={ typeFilter === 'Spend' ? false :  serverSideDataPaging } />}
      {action === "upload" && categoryFilter === "Solution" && !subCategoryFilter && <DataUploadDialog uploadType={"capabilityOffering"} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} onClose={handleUploadDialogClose} />}
      {action === "upload" && categoryFilter === "Solution" && subCategoryFilter === "Solution Rule" && <DataUploadDialog uploadType={"solutionOfferingRules"} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} onClose={handleUploadDialogClose} />}
      {action === "copy" && categoryFilter === "Solution" && !subCategoryFilter && <CopyFilesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyOfferingRules"} onClose={handleUploadDialogClose} subCategoryFilter={subCategoryFilter} />}
      {action === "copy" && categoryFilter === "Solution" && subCategoryFilter === "Solution Rule" && <CopyFilesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copySolutionOfferingRules"} onClose={handleUploadDialogClose} subCategoryFilter={subCategoryFilter} />}
      {action === "upload" && categoryFilter === "Tower" && !subCategoryFilter && <DataUploadDialog uploadType={"towerMapping"} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} onClose={handleUploadDialogClose} />}
      {action === "upload" && categoryFilter === "Tower" && subCategoryFilter === "Asset Rule" && <DataUploadDialog uploadType={"assetRules"} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} onClose={handleUploadDialogClose} subCategoryFilter={subCategoryFilter} />}
      {action === "copy" && categoryFilter === "Tower" && !subCategoryFilter && <CopyFilesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} subCategoryFilter={subCategoryFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyTRRules"} onClose={handleUploadDialogClose} />}
      {action === "copyTo" && categoryFilter === "Tower" && !subCategoryFilter && <CopyRulesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyTRRules"} onClose={handleUploadDialogClose} />}
      {action === "copyTo" && categoryFilter === "Solution" && !subCategoryFilter && <CopyRulesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyOfferingRules"} onClose={handleUploadDialogClose} />}


      {action === "copy" && categoryFilter === "Tower" && subCategoryFilter === "Asset Rule" && <CopyFilesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} subCategoryFilter={subCategoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyAssetRules"} onClose={handleUploadDialogClose} />}
      {action === "copyTo" && categoryFilter === "Tower" && subCategoryFilter === "Asset Rule" && <CopyRulesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyAssetRules"} onClose={handleUploadDialogClose} />}

      {action === "add" && categoryFilter === "Tower" && subCategoryFilter === "Asset Rule" && <AssetMapping yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} masterId="asset-rules" uploadType="assetRules" uploadName={'Asset Rule'} setRefresh={setRefresh} tabStyles={tabStyles} chargeBackMonthly={chargeBackMonthly} subCategoryFilter={subCategoryFilter} />}
      {action === "add" && categoryFilter === "Tower" && !subCategoryFilter && <AddTowerRule yearFilter={yearFilter} monthFilter={monthFilter} mt={2} containerHeight={"calc(100vh - 400px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} edit={edit} editValue={editValue} setEdit={setEdit} serverSideDataPaging={serverSideDataPaging} />}
      {action === "assetRuleview" && categoryFilter === "Tower" && <AssetMapping yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} masterId="asset-rules" uploadType="assetRules" uploadName={'Asset Rule'} setRefresh={setRefresh} tabStyles={tabStyles} chargeBackMonthly={chargeBackMonthly} />}
      {action === "viewUnallocatedAssetSpend" && categoryFilter === "Tower" && <UnallocatedSpend yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} serverSideDataPaging={serverSideDataPaging} />}
      {action === "solutionOfferingRulesview" && categoryFilter === "Solution" && <SolutionOfferingMapping yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} masterId="solution-offering-rules" uploadType="solutionOfferingRules" uploadName={'Solution Rule'} setRefresh={setRefresh} tabStyles={tabStyles} chargeBackMonthly={chargeBackMonthly} setAction={setAction} setSubCategoryFilter={setSubCategoryFilter} serverSideDataPaging={serverSideDataPaging} />}
      {action === "view" && categoryFilter === "Tower" && !subCategoryFilter && <TowerMappingView yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 370px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} setAction={setAction} download={typeFilter === 'Spend' ? downloadTRRules : downloadTowerBudgets} setEdit={setEdit} setEditValue={setEditValue} serverSideDataPaging={ typeFilter === 'Spend' ? false :  serverSideDataPaging } />}
      {action === "viewAssetSpend" && categoryFilter === "Tower" && subCategoryFilter && <AssetSpendView yearFilter={yearFilter} monthFilter={monthFilter} containerHeight={"calc(100vh - 370px)"} typeFilter={typeFilter} setAction={setAction} chargeBackMonthly={chargeBackMonthly} serverSideDataPaging={serverSideDataPaging} />}
      {action === "allocation" && categoryFilter === "Tower" && <AllocationFiles chargeBackMonthly={chargeBackMonthly} yearFilter={yearFilter} monthFilter={monthFilter} setRefresh={setRefresh} setAction={setAction} categoryFilter={categoryFilter} setOriginalFileName={setOriginalFileName} />}
      {action === "addAssetMapping" && <DataUploadDialog uploadType={"assetRelationship"} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} onClose={handleUploadDialogClose} />}
      {action === "addCloudConsumption" && <DataloadDialog title={`Upload for ${monthFilterName}, ${yearFilterName}`} uploadConfig = {{ monthFilter: monthFilter, yearFilter: yearFilter, yearFilterName: yearFilterName, monthFilterName: monthFilterName, uploadType: null, uploadCategory: "Cloud Consumption" }} onClose={handleUploadDialogClose} />}
      {action === "addMobileConsumption" && <DataloadDialog title={`Upload for ${monthFilterName}, ${yearFilterName}`} uploadConfig = {{ monthFilter: monthFilter, yearFilter: yearFilter, yearFilterName: yearFilterName, monthFilterName: monthFilterName, uploadType: 'mobileConsumption', uploadCategory: "Mobile Consumption" }} onClose={handleUploadDialogClose} />}
      {action === "view" && categoryFilter === "Cloud Usage" && <Consumption yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 485px)"} uploadType={'cloudConsumption'} uploadCategory={"Cloud Consumption"} chargeBackMonthly = {chargeBackMonthly} serverSideDataPaging={serverSideDataPaging} />}
      {action === "view" && categoryFilter === "Mobile Usage" && <Consumption yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 485px)"} uploadType={'mobileConsumption'} uploadCategory={"Mobile Consumption"} chargeBackMonthly = {chargeBackMonthly} serverSideDataPaging={serverSideDataPaging} />}
      {action === "cloudSpend" && categoryFilter === "Cloud Usage" && <CloudSpend yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 370px)"} chargeBackMonthly = {chargeBackMonthly} serverSideDataPaging={false} />}
      {/* {action === "mobileSpend" && categoryFilter === "Mobile Usage" && <MobileSpend yearFilter={yearFilter} monthFilter={monthFilter} yearFilterName={yearFilterName} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 370px)"} chargeBackMonthly = {chargeBackMonthly}/>} */}
      {/* {action === "uploadRules" && categoryFilter === "Cloud Usage" && <FilteredUploadRules containerHeight="calc(100vh - 500px)"  tableName={"cloudConsumption"} />}
      {action === "uploadRules" && categoryFilter === "Mobile Usage" && <FilteredUploadRules containerHeight="calc(100vh - 500px)"  tableName={"mobileConsumption"} />} */}
      {action === "viewAssetMapping" && <SpendDialog typeFilter={"Capabilities"} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} reloadData={setRefresh} onClose={handleUploadDialogClose} serverSideDataPaging={serverSideDataPaging} />}
      {action === "Tower" && <ShowAllMonths yearFilter={yearFilter} yearFilterName={yearFilterName} tabStyles={tabStyles} categoryFilter={categoryFilter} setTypeFilter={setTypeFilter} typeFilter={typeFilter} handleAction={handleAction} setRefresh={setRefresh} setSubCategoryFilter={setSubCategoryFilter} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging} systemCurrencyDetails ={systemCurrencyDetails}/>}



      {action === "upload" && categoryFilter === "Business Unit" && <DataUploadDialog uploadType={"businessUnitOffering"} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} onClose={handleUploadDialogClose} />}
      {action === "copy" && categoryFilter === "Business Unit" && <CopyFilesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyBURules"} onClose={handleUploadDialogClose} />}
      {action === "copyTo" && categoryFilter === "Business Unit" && <CopyRulesDialog typeFilter={typeFilter} categoryFilter={categoryFilter} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} variant={"copyBURules"} onClose={handleUploadDialogClose} />}

      {action === "add" && categoryFilter === "Business Unit" && typeFilter !== 'Budget' && <AddBURule yearFilter={yearFilter} monthFilter={monthFilter} mt={2} containerHeight={"calc(100vh - 400px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} />}
      {(action === "view" ||  typeFilter !== 'Spend' )&& categoryFilter === "Business Unit" && <BUMappingView yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 370px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} setAction={setAction} download={downloadBURules} serverSideDataPaging={serverSideDataPaging} />}
      {/* {action === "add" && categoryFilter === "Business Unit" &&  <BUMappingView action={action} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 370px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} setAction={setAction} download={downloadBURules} />} */}
      {action === "view" && categoryFilter === "Solution" && <OfferingMappingView yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 370px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} setAction={setAction} download={downloadSolutionMapping} serverSideDataPaging={serverSideDataPaging} />}
      {action === "add" && categoryFilter === "Solution" && <OfferingMappingView action={action} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} containerHeight={"calc(100vh - 370px)"} typeFilter={typeFilter} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} setAction={setAction} download={downloadSolutionRules} serverSideDataPaging={serverSideDataPaging} />}
      {action === "allocation" && categoryFilter === "Business Unit" && <AllocationFiles chargeBackMonthly={chargeBackMonthly} yearFilter={yearFilter} monthFilter={monthFilter} setRefresh={setRefresh} setAction={setAction} categoryFilter={categoryFilter} setOriginalFileName={setOriginalFileName} />}
      {action === "allocation" && categoryFilter === "Solution" && <AllocationFiles chargeBackMonthly={chargeBackMonthly} yearFilter={yearFilter} monthFilter={monthFilter} setRefresh={setRefresh} setAction={setAction} categoryFilter={categoryFilter} setOriginalFileName={setOriginalFileName} />}
      {action === "uploaded" && <FilteredUploadedFiles containerHeight="calc(100vh - 370px)" canFilter={true} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly} fileName={typeFilter=="Budget" ? "Budget Allocation": typeFilter=="Forecast" ? "" : categoryFilter === "Cost Pool" ? "Cost Pool Mapping" : categoryFilter === "Solution" ? ["Capability Offering Mapping", "Solution Rules"] : categoryFilter === "Business Unit" ? ["Business Unit Offering Mapping", "Business Unit Consumption"] : categoryFilter === "Mobile Usage" ? ["Mobile Usage"] : ['Tower Mapping', 'Asset Mapping', 'Asset Rules', 'Tower Consumption']} fileType={ typeFilter === "Budget" ? "Spend" : typeFilter == "Forecast" ? "Forecast" : ""} />}
      {action === "consumption" && <FilteredUploadedFiles containerHeight="calc(100vh - 370px)" canFilter={true} yearFilter={yearFilter} yearFilterName={yearFilterName} monthFilter={monthFilter} monthFilterName={monthFilterName} setRefresh={setRefresh} chargeBackMonthly={chargeBackMonthly}  fileName={categoryFilter === "Business Unit" ? ["Business Unit Consumption"] : ['Tower Consumption']} originalFileName={originalFileName} />}
    </>
  )
}

const CostPoolStyles = (disabled) => ({
  width: "500px",
  marginBottom: "10px",
  padding: "10px",
  height: "150px",
  borderRadius: "12px",
  border: "1px solid #edeef3",
  display: "inline-flex",
  "&:hover": {
    backgroundColor: disabled ? "#EBEBE4" : "#e9edf8",
    border: disabled ? "1px solid #EBEBE4" : "1px solid #5472c7",
    cursor: disabled ? null : "pointer"
  }

});

const ShowChoice = (props) => {

  const { setAction, yearFilter, monthFilter, categoryFilter, subCategoryFilter } = props
  const { showPrompt, showAlert } = useYADialog();
  const [ existingRules, setExistingRules ] = useState()

  useEffect(async() => {
    if (categoryFilter == "Cost Pool") {
      let [, response]  = await fetchRequest.post(`/api/dataflow/costPoolMapping/${yearFilter}/${monthFilter}`)
      setExistingRules(response.data?.filter(item => item.source !== 'cloud')?.length)
    }
  
    if (categoryFilter == "Tower" && !subCategoryFilter) {
      let [, response] = await fetchRequest.post(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}`)
      setExistingRules(response.data?.filter(item => item.source !== 'cloud')?.length)
    }
  
    if (categoryFilter == "Business Unit") {
      let [, response] = await fetchRequest.get(`/api/dataflow/businessunitMapping/${yearFilter}/${monthFilter}`)
      setExistingRules(response.data?.length)
    }
  
    if (categoryFilter == "Solution") {
      let [, response] = await fetchRequest.get(`/api/dataflow/offeringRules/${yearFilter}/${monthFilter}`)
      setExistingRules(response?.length)
    }
  },[categoryFilter])
  

  const handleUpload = async () => {
    let bgData = await backgroundProcessCheck(monthFilter, yearFilter);
    if (bgData.length > 0) {
      showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
    }
    else {
      existingRules && categoryFilter !== "Business Unit" && categoryFilter !== 'Solution' && subCategoryFilter !== 'Asset Rule' ? showPrompt("Alert", "You have " + existingRules + " existing mapping. These mapping will be deleted during the load of new rules. Are you sure you want to continue?", () => setAction("upload"), () => { }, "NO", "YES")
        : setAction("upload")
    }
  }
  const handleMapaction = async (action) => {
    let bgData = await backgroundProcessCheck(monthFilter, yearFilter);
    if (bgData.length > 0) {
      showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
    }
    else {
      if (action === "copy") {
        existingRules && categoryFilter !== "Business Unit" && !subCategoryFilter ? showPrompt("Alert", "You have " + existingRules + " existing mapping. These mapping will be deleted during the load of new mapping. Are you sure you want to continue?", () => setAction("copy"), () => { }, "NO", "YES")
          : setAction(action)
      }
      else {
        setAction(action)
      }
    }
  }
  return (
    <MDBox display="grid" sx={{ padding: "16px", backgroundColor: "#F7F8FD" }} >
      <MDTypography data-testid={`How would you like your ${props.subCategoryFilter ? props.subCategoryFilter : props.categoryFilter} mapping to start?`.toLowerCase().replaceAll(' ', '')} variant="h3" gutterBottom>{(props.subCategoryFilter) ? `How would you like your ${props.subCategoryFilter} to start?` : `How would you like your ${props.categoryFilter} mapping to start`}</MDTypography>
      <MDBox
        sx={() => CostPoolStyles()}
        onClick={handleUpload}>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox sx={{ padding: "25px" }}>
            <img src={Add_Map} style={{ width: 125, }} />
          </MDBox>
          <MDBox sx={{ padding: "25px" }}>
            <MDTypography data-testid={"addmappingfile"} variant="h6" >{subCategoryFilter === 'Asset Rule' ? "Add asset rules file" : subCategoryFilter === 'Solution Rule' ? 'Add solution rule file' : "Add mapping file"}</MDTypography>
            <MDTypography data-testid={"uploadamappingfiletocreaterules"} sx={{ fontSize: "12px;" }} gutterBottom>{subCategoryFilter === 'Solution Rule' ? 'Upload a  solution rule file to create rules' : subCategoryFilter === 'Asset Rule' ? 'Upload a  asset rule file to create rules' : 'Upload a mapping file to create mapping'} </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        sx={() => CostPoolStyles()}
        onClick={() => handleMapaction("copy")}>
        <MDBox display="flex" justifyContent="space-between" component="">
          <MDBox sx={{ padding: "25px" }}>
            <img src={Add_Map} style={{ width: 125, }} />
          </MDBox>
          <MDBox sx={{ padding: "25px" }}>
            <MDTypography data-testid={"copyrulesfromanothermonth"} variant="h6" >{(!subCategoryFilter) ? 'Copy mapping from another month' : 'Copy rules from another month'}</MDTypography>
            <MDTypography data-testid={"selectamonthyouhavealreadymappedtousethesamerules"} sx={{ fontSize: "12px;" }} gutterBottom>{(!subCategoryFilter) ? 'Select a month that you already have mapped to be reuse in this month.' : 'Select a month for which you already have set of rules to be reuse in this month.'}</MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      {(categoryFilter === 'Solution' && !subCategoryFilter) ? <MDBox sx={{ width: "500px", marginBottom: "10px", padding: "10px", height: "150px", }} ></MDBox> :
        <MDBox sx={() => CostPoolStyles(categoryFilter === 'Solution' && !subCategoryFilter)} onClick={() => categoryFilter === 'Solution' && !subCategoryFilter ? "" : categoryFilter === "Solution" && subCategoryFilter === 'Solution Rule' ? handleMapaction("solutionOfferingRulesview") : subCategoryFilter === 'Asset Rule' ? handleMapaction("assetRuleview") : handleMapaction("add")}>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox sx={{ padding: "25px" }}>
              <img src={Add_Map} style={{ width: 125, }} />
            </MDBox>
            {subCategoryFilter === 'Solution Rule' ?
              <MDBox sx={{ padding: "25px" }}>
                <MDTypography data-testid={"mapmanually"} variant="h6" >Create rules manually</MDTypography>
                <MDTypography data-testid={"usemappingscreentocreaterules"} sx={{ fontSize: "12px;" }} gutterBottom>Use solution rule screen to create rules </MDTypography>
              </MDBox> :
              subCategoryFilter === 'Asset Rule' ?
                <MDBox sx={{ padding: "25px" }}>
                  <MDTypography data-testid={"mapmanually"} variant="h6" >Create rules manually</MDTypography>
                  <MDTypography data-testid={"usemappingscreentocreaterules"} sx={{ fontSize: "12px;" }} gutterBottom>Use asset rule screen to create rules </MDTypography>
                </MDBox> :
                <MDBox sx={{ padding: "25px" }}>
                  <MDTypography data-testid={"mapmanually"} variant="h6" >Map manually</MDTypography>
                  <MDTypography data-testid={"usemappingscreentocreaterules"} sx={{ fontSize: "12px;" }} gutterBottom>Use mapping screen to create mapping </MDTypography>
                </MDBox>
            }
          </MDBox>
        </MDBox>
      }
    </MDBox>
  )
}

const ShowAllMonths = (props) => {
  const { yearFilter, yearFilterName, categoryFilter, setTypeFilter, typeFilter, tabStyles, handleAction, setRefresh, setSubCategoryFilter, subCategoryFilter, chargeBackMonthly, publishedMonthly, uploadType, serverSideDataPaging, systemCurrencyDetails } = props
  // const { response, error, loading, reloadData } = categoryFilter === 'Solution' || categoryFilter === 'Cloud Usage' || categoryFilter === 'Mobile Usage' ? useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}Monthly/${yearFilter}`) : useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}${typeFilter}Monthly/${yearFilter}`);
  // const { response, error, loading, reloadData } = categoryFilter === 'Solution' || categoryFilter === 'Cloud Usage' || categoryFilter === 'Mobile Usage' ? useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}Monthly/${yearFilter}`) : typeFilter=="Forecast" || typeFilter == 'Budget' ? useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}BudgetMonthly/${yearFilter}?type=${typeFilter.toLowerCase()}`) : useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}${typeFilter}Monthly/${yearFilter}`); 
  const { response, error, loading, reloadData } = ((categoryFilter === 'Solution' && typeFilter === "Spend") || categoryFilter === 'Cloud Usage' || categoryFilter === 'Mobile Usage') ? useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}Monthly/${yearFilter}`) : (typeFilter === "Forecast" || typeFilter === 'Budget') ? useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}BudgetMonthly/${yearFilter}?type=${typeFilter.toLowerCase()}`) : useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}${typeFilter}Monthly/${yearFilter}`); 
  const handleError = useHandleError();
  const [data, setData] = useState(null)
  useEffect(() => {
    if (!loading) {
      if (error !== null) {
        handleError(error);
      }
      else if (response !== null) {
        setData(response);
      }
    }
  }, [loading, data, yearFilter]);
  if (loading) {
    return <YASkeleton variant="dashboard-loading" />;
  }

  if (loading === false && data === null) {
    return (
      <div>
        no data
      </div>
    );
  }
  return (
    <>
      <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", borderBottom: "1px solid #edeef3", borderTop: "1px solid #e3e3e3", display: "inline-flex" }} justifyContent="space-between">
        <MDBox display="flex">
          {categoryFilter !== 'Cloud Usage' && categoryFilter !== "Mobile Usage" && <MDButton data-testid={"spend"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "Spend" })} onClick={() => setTypeFilter("Spend")} >
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>account_balance_wallet</Icon>
            SPEND</MDButton>}
          {(categoryFilter === 'Cloud Usage' || categoryFilter === "Mobile Usage") && <MDButton data-testid={"spend"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "Spend" })} onClick={() => setTypeFilter("Spend")} >
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>{categoryFilter === 'Cloud Usage' ? "cloud" : categoryFilter === "Mobile Usage" ? "phone_iphone" : "account_balance_wallet"}</Icon>
            {categoryFilter.toUpperCase()}</MDButton>}
          {(categoryFilter === 'Cloud Usage' || categoryFilter === "Mobile Usage") && <MDButton data-testid={"uploadRules"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "uploadRules" })} onClick={() => setTypeFilter("uploadRules")} >
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>fact_check</Icon>
            UPLOAD RULES</MDButton>}
          {(categoryFilter !== 'Cloud Usage' && categoryFilter !== "Mobile Usage") && <MDButton data-testid={"budget"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "Budget" })} onClick={() => setTypeFilter("Budget")}>
            <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>card_travel</Icon>
            BUDGET</MDButton>}
          {(categoryFilter !== 'Cloud Usage' && categoryFilter !== "Mobile Usage") && <MDButton data-testid={"Forecast"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "Forecast" })} onClick={() => setTypeFilter("Forecast")}>
          <Icon color="dark" fontSize="small" sx={{ marginRight: "6px" }}>online_prediction</Icon>
          FORECAST</MDButton>}
        </MDBox>
      </MDBox>
      {typeFilter !== 'uploadRules' && <MDBox p={3} pt={1} sx={{ backgroundColor: "#F7F8FD", }}>
        {data.map(item => {
          if (categoryFilter === 'Solution') {
            if (item["appDirect"] || item["appIndirect"] || item["direct"] || item["indirect"]  || item["spendAmount"])
              return (<Month key={item["id"]} handleAction={handleAction} yearFilter={yearFilter} yearFilterName={yearFilterName} item={item} reloadData={reloadData} categoryFilter={categoryFilter} typeFilter={typeFilter} setRefresh={setRefresh} setSubCategoryFilter={setSubCategoryFilter} subCategoryFilter={subCategoryFilter} chargeBackMonthly={chargeBackMonthly} handleError={handleError} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging} systemCurrencyDetails={systemCurrencyDetails} />)
            else
              return (<MonthEmpty key={item["id"]} handleAction={handleAction} yearFilter={yearFilter} yearFilterName={yearFilterName} item={item} reloadData={reloadData} categoryFilter={categoryFilter} typeFilter={typeFilter} setRefresh={setRefresh} setSubCategoryFilter={setSubCategoryFilter} subCategoryFilter={subCategoryFilter} chargeBackMonthly={chargeBackMonthly} handleError={handleError} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging} systemCurrencyDetails={systemCurrencyDetails}/>)
          }
          if (categoryFilter === 'Cloud Usage' || categoryFilter === 'Mobile Usage') {
            if (item["amount"])
              return (<Month key={item["id"]} handleAction={handleAction} yearFilter={yearFilter} yearFilterName={yearFilterName} item={item} reloadData={reloadData} categoryFilter={categoryFilter} typeFilter={typeFilter} setRefresh={setRefresh} setSubCategoryFilter={setSubCategoryFilter} subCategoryFilter={subCategoryFilter} chargeBackMonthly={chargeBackMonthly} publishedMonthly={publishedMonthly} handleError={handleError} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging} systemCurrencyDetails={systemCurrencyDetails}/>)
            else
              return (<MonthEmpty key={item["id"]} handleAction={handleAction} yearFilter={yearFilter} yearFilterName={yearFilterName} item={item} reloadData={reloadData} categoryFilter={categoryFilter} typeFilter={typeFilter} setRefresh={setRefresh} setSubCategoryFilter={setSubCategoryFilter} subCategoryFilter={subCategoryFilter} chargeBackMonthly={chargeBackMonthly} publishedMonthly={publishedMonthly} handleError={handleError} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging} systemCurrencyDetails={systemCurrencyDetails}/>)
          }
          else {
            if (item["spendAmount"])
              return (<Month key={item["id"]} handleAction={handleAction} yearFilter={yearFilter} yearFilterName={yearFilterName} item={item} reloadData={reloadData} categoryFilter={categoryFilter} typeFilter={typeFilter} setRefresh={setRefresh} setSubCategoryFilter={setSubCategoryFilter} subCategoryFilter={subCategoryFilter} chargeBackMonthly={chargeBackMonthly} handleError={handleError} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging} systemCurrencyDetails={systemCurrencyDetails} />)
            else
              return (<MonthEmpty key={item["id"]} handleAction={handleAction} yearFilter={yearFilter} yearFilterName={yearFilterName} item={item} reloadData={reloadData} categoryFilter={categoryFilter} typeFilter={typeFilter} setRefresh={setRefresh} setSubCategoryFilter={setSubCategoryFilter} subCategoryFilter={subCategoryFilter} chargeBackMonthly={chargeBackMonthly} handleError={handleError} uploadType={uploadType} serverSideDataPaging={serverSideDataPaging} systemCurrencyDetails={systemCurrencyDetails}/>)
          }
        })
        }
      </MDBox>}
      {(categoryFilter === 'Cloud Usage' || categoryFilter === 'Mobile Usage') && typeFilter === 'uploadRules' && <FilteredUploadRules containerHeight="calc(100vh - 500px)" tableName={categoryFilter === 'Cloud Usage' ? "cloudConsumption" : "mobileConsumption"} />}
    </>
  )
}
const MonthEmpty = (props) => {
  const { showAlert, showPrompt, showSnackbar } = useYADialog();
  const { item, handleAction, yearFilter, yearFilterName, reloadData, categoryFilter, typeFilter, setRefresh, setSubCategoryFilter, chargeBackMonthly, handleError, subCategoryFilter, publishedMonthly, uploadType, systemCurrencyDetails } = props
  const [openMenu, setOpenMenu] = useState(false);
  const [data, setData] = useState(null)
  const solSpend = toInt(item?.indirect + item?.direct) ?? 0;
  const appSpend = toInt(item?.appDirect + item?.appIndirect) ?? 0;
  const chargeBack = _.find(chargeBackMonthly, { monthNameId: item["id"], yearNameId: yearFilter }) ? true : false
  const published = _.find(publishedMonthly, { monthNameId: item["id"], yearNameId: yearFilter }) ? true : false

  const { response, error, loading } = categoryFilter === "Tower" && useFetchRequest(`/api/dataflow/towerRuleNames/${yearFilter}/${item["id"]}`)
  useEffect(() => {
    if (!loading) {
      if (error !== null) {
        handleError(error);
      }
      else if (response !== null) {
        setData(response);
      }
    }
  }, [loading, data, yearFilter]);


  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  };
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };
  const handleDownload = () => {
    if (categoryFilter === "Cost Pool")
      downloadCPRules(yearFilter, item["id"], yearFilterName, item['name'])
    if (categoryFilter === "Tower")
      downloadTRRules(yearFilter, item["id"], yearFilterName, item['name'])
    if (categoryFilter === "Business Unit")
      downloadBURules(yearFilter, item["id"], yearFilterName, item['name'])
    if (categoryFilter === "Solution" && !subCategoryFilter)
      downloadSolutionMapping(yearFilter, item["id"], yearFilterName, item['name'])
    if (categoryFilter === "Solution" && subCategoryFilter === 'Solution Rule')
      downloadSolutionRules(yearFilter, item["id"], yearFilterName, item['name'])
  }

  const handleDelete = (item, yearFilter, yearFilterName, reloadData, categoryFilter) => {
    showPrompt("Delete", "Are you sure you want to delete " + categoryFilter.toLowerCase() + " mapping for - [ " + item["name"] + " " + yearFilterName + " ]", async () => {
      let [err, data] = categoryFilter === "Solution" ? await fetchRequest.delete(`/api/dataflow/offeringRules/${yearFilter}/${item["id"]}`) : await fetchRequest.delete(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}Rules/${yearFilter}/${item["id"]}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          setRefresh(Math.random());
          reloadData()
        } else {
          console.error(err)
          showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        showSnackbar(data, "success")
        setOpenMenu(false)
        setRefresh(Math.random());
        reloadData()
      }
    });
  }

  const handleConsumptionDelete = (item, yearFilter, yearFilterName, reloadData, categoryFilter, uploadType) => {
    showPrompt("Delete", "Are you sure you want to delete all " + categoryFilter.toLowerCase() + " data for - [ " + item["name"] + " " + yearFilterName + " ]", async (checked) => {
      var [err, data] = await fetchRequest.delete(`/api/dataflow/${uploadType.toLowerCase().replace(' ', '')}/${yearFilter}/${item["id"]}?checked=${checked}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          reloadData()
          setRefresh(Math.random());
        } else {
          console.error(err)
          showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        showSnackbar(`[${item["name"]} ${yearFilterName}] ` + data, "success")
        setOpenMenu(false)
        reloadData()
        setRefresh(Math.random());
      }
    }, null, null, null, null, categoryFilter === 'Cloud Usage' ? { label: "also delete uploaded files" } : null, "md")
  }

  const handleSolutionRuleDelete = async (item, yearFilter, yearFilterName, reloadData) => {
    showPrompt("Delete", "Are you sure you want to delete solution rules for - [ " + item["name"] + " " + yearFilterName + " ]", async () => {
      let [err, data] = await fetchRequest.delete(`/api/solutionOfferingRules/deleteAll/${yearFilter}/${item["id"]}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          setRefresh(Math.random());
          reloadData()
        } else {
          console.error(err)
          showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        showSnackbar(data, "success")
        setOpenMenu(false)
        setRefresh(Math.random());
        reloadData()
      }

    })
  }
  let disaplayAmount = item.direct ? toInt(item.direct) : 0
  disaplayAmount = disaplayAmount + (item.indirect ? toInt(item?.indirect) : 0);

  const chartOptions = {
    "graphData": [],
    "wigetType": "doughnut",
    "providerview": true,
    "showTitle": false,
    "innerSize": "50%",
    "dataLabels": false,
    "backgroundColor": "#F4F4F4",
    "width": 150,
    "height": 150,
    "series": [
      {
        "name": "ProviderName",
        "displayName": "ProviderDisplayName",
        "value": "amount"
      }
    ]
  }

  return (
    <Card
      sx={{
        minHeight: "150px",
        minWidth: categoryFilter === 'Cloud Usage' ? "300px" : "270px",
        margin: "10px",
        display: "inline-block",
        overflow: "hidden",
        position: "relative",
        backgroundColor: "#F4F4F4",
        border: "1px dashed #ADADAD",
        "&:hover": {
          border: "1px dashed #495dbd",

        }
      }}>
      <MDBox px={3} pb={1} pt={2} display="flex" justifyContent="space-between" alignItems="flex-start">
        <MDBox display="flex" width="100%" flexDirection="column" justifyContent="space-between" >
          <MDBox color="text" pt={0} mt={0} display="flex" justifyContent="space-between" flexDirection="row">
            <MDTypography data-testid={item["name"].toLowerCase().replaceAll(' ', '')} variant="h6" component="span" color="#4c4c4c" display="flex">{item["name"]}</MDTypography>
            {chargeBack && categoryFilter !== "Mobile Usage" ? <Tooltip title={"Month Locked (Chargeback)"}><Icon color={'error'} >lock</Icon></Tooltip> : ''}
            {typeFilter !== "Budget" && data && data.rules.length > 0 && data.rules.filter(rule => rule['allocationFileTowerMapping.originalFileName'] === null && rule['amount'] === 0).length > 0 ? <Tooltip title={"Allocation File is missing"}><Icon color={'error'}>warning_amber</Icon></Tooltip> : ''}
            <Menu
              anchorEl={openMenu}
              anchorReference={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(openMenu)}
              onClose={handleCloseMenu}
            >
                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} data-testid={"mappings"} key={'add'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? `Add Rules` : `Add Mapping`}</MenuItem>}
                {/* {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && <MenuItem data-testid={"viewmappings"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? (['Budget', 'Forecast'].includes(typeFilter) ? `View ${typeFilter}` : `View Rule`) : `View Mapping`}</MenuItem>} */}
                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && <MenuItem data-testid={"viewmappings"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower', 'Solution', 'Business Unit'].includes(categoryFilter) ? (['Budget', 'Forecast'].includes(typeFilter) ? `View ${typeFilter}` : (['Cost Pool', 'Tower'].includes(categoryFilter)  ? `View Rules` : `View Mapping`)) : `View Mapping`}</MenuItem>}
                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} data-testid={"mappings"} key={'add'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "copyTo"); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? `Copy Rules To` : `Copy Mapping To`}</MenuItem>}

                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} data-testid={"deletemapping"} key={'delete'} onClick={() => handleCloseMenuItem(() => { handleDelete(item, yearFilter, yearFilterName, reloadData, categoryFilter); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? `Delete Rules` : `Delete Mapping`}</MenuItem>}
                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem data-testid={"downloadmapping"} key={'download'} onClick={() => handleCloseMenuItem(() => { handleDownload(); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? `Download Rules` : `Download Mapping`}</MenuItem>}
                {(categoryFilter === "Solution" || categoryFilter === "Tower") && (typeFilter !== "Budget") && (typeFilter !== "Forecast") && <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />}
                {(categoryFilter === "Solution" && typeFilter === "Spend") && <MenuItem disabled={chargeBack} key={'addSolutionOfferingRules'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter('Solution Rule') })}>Add Rules</MenuItem>}
                {(categoryFilter === "Solution" && typeFilter === "Spend") && <MenuItem key={'viewAssetMapping'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "solutionOfferingRulesview"); setSubCategoryFilter('Solution Rule') })}>View Rules</MenuItem>}
                {(categoryFilter === "Solution" && typeFilter === "Spend") && <MenuItem key={'deleteRules'} onClick={() => handleCloseMenuItem(() => { handleSolutionRuleDelete(item, yearFilter, yearFilterName, reloadData) })}>Delete Rules</MenuItem>}
                {(categoryFilter === "Solution" && typeFilter === "Spend") && <MenuItem data-testid={"download"} key={'download'} onClick={() => handleCloseMenuItem(() => { handleDownload('Solution Rule'); })}>Download Rules</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} key={'Asset Rule'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter('Asset Rule') })}>Add Asset Rules</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem key={'Asset Rule'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "assetRuleview"); setSubCategoryFilter('Asset Rule') })}>View Asset Rules</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} key={'Asset Rule'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "copyTo"); setSubCategoryFilter('Asset Rule') })}>Copy Asset Rules To</MenuItem>}

                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && categoryFilter !== "Cloud Usage" && categoryFilter !== "Mobile Usage"  && <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} key={'addAssetMapping'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "addAssetMapping"); setSubCategoryFilter(null) })}>Add Asset Mapping</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem key={'viewAssetMapping'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "viewAssetSpend"); setSubCategoryFilter("assetSpend") })}>View Asset Mapping</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem key={'viewUnallocatedAssetSpend'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "viewUnallocatedAssetSpend"); setSubCategoryFilter(null) })}>View Unallocated Asset Spend</MenuItem>}
                {categoryFilter !== "Cloud Usage" && categoryFilter !== "Mobile Usage" && <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />}
                {(categoryFilter !== "Cloud Usage" && categoryFilter !== "Mobile Usage") && <MenuItem key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "uploaded"); setSubCategoryFilter(null) })}>View Uploaded Files</MenuItem>}
                {categoryFilter === "Cloud Usage" && <MenuItem disabled={chargeBack || published} data-testid={"cloudConsumption"} key={'add'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "addCloudConsumption"); setSubCategoryFilter(null) })}>Add Usage Data</MenuItem>}
                {categoryFilter === "Cloud Usage" && <MenuItem disabled={chargeBack || published} data-testid={"cloudConsumption"} key={'delete'} onClick={() => handleCloseMenuItem(() => { handleConsumptionDelete(item, yearFilter, yearFilterName, reloadData, categoryFilter, uploadType); setSubCategoryFilter(null) })}>Delete Usage Data</MenuItem>}
                {categoryFilter === "Mobile Usage" && <MenuItem disabled={false} data-testid={"mobileConsumption"} key={'add'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "addMobileConsumption"); setSubCategoryFilter(null) })}>Add Usage Data</MenuItem>}
                {categoryFilter === "Mobile Usage" && <MenuItem disabled={false} data-testid={"mobileConsumption"} key={'delete'} onClick={() => handleCloseMenuItem(() => { handleConsumptionDelete(item, yearFilter, yearFilterName, reloadData, categoryFilter, uploadType); setSubCategoryFilter(null) })}>Delete Usage Data</MenuItem>}
                {/* <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" /> */}
                {categoryFilter === "Cloud Usage" && <MenuItem disabled={false} data-testid={"cloudConsumption"} key={'cloudSpend'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "cloudSpend"); setSubCategoryFilter(null) })}>Manage Cloud Spend</MenuItem>}
                {/* {categoryFilter === "Mobile Usage" && <MenuItem disabled={false} data-testid={"mobileConsumption"} key={'mobileSpend'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "mobileSpend");setSubCategoryFilter(null)})}>Manage Mobile Spend</MenuItem> } */}
                {(categoryFilter === "Cloud Usage"  || categoryFilter === "Mobile Usage") && <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />}
                {categoryFilter === "Cloud Usage" && <MenuItem disabled={false} data-testid={"cloudConsumption"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) })}>View Uploaded Files</MenuItem>}
                {categoryFilter === "Mobile Usage" && <MenuItem disabled={false} data-testid={"mobileConsumption"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) })}>View Uploaded Files</MenuItem>}
                {/* {(categoryFilter === "Cloud Usage" || categoryFilter === "Mobile Usage") && <MenuItem disabled={chargeBack} data-testid={"uploadRules"} key={'uploadRules'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "uploadRules");setSubCategoryFilter(null)})}>View Upload Rules</MenuItem> } */}
            </Menu>
            <MDBox mt={-1} mr={-1} pt={0}>
              <MDButton
                data-testid={`${item["name"]?.toLowerCase().replaceAll(' ', '')}-horizIcon`}
                disableRipple
                color="dark"
                variant="text"
                onClick={handleOpenMenu}
                sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                iconOnly
                aria-label="options"
              >
                <Icon px={0} py={0} alignItems="end">more_horiz</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
          {(categoryFilter === "Solution" && typeFilter === "Spend" ) ?
            <>
              <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" >
                <MDBox pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
                  Allocated<br />
                  {formatAmount(disaplayAmount, 2, systemCurrencyDetails)}&nbsp;
                </MDBox>
                <CircularProgressWithLabel value={100 * disaplayAmount / toInt(item?.appDirect + item?.appIndirect + item?.direct)} />
              </MDBox>
              {!item["solutionSpend"] && <>
                <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" component="li" />

                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>Direct Allocation :</MDTypography>
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{item["direct"] ? formatAmount(item["direct"], 2, systemCurrencyDetails) : `${systemCurrencyDetails?.code || '$'} 0`}</MDTypography>
                </MDBox>
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{"Asset Spend :"}</MDTypography>
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{item["assetSpend"] ? formatAmount(item["assetSpend"], 2, systemCurrencyDetails) : item["appDirect"] || item["appIndirect"] ? formatAmount(item["appDirect"] + item["appIndirect"], 2, systemCurrencyDetails) : `${systemCurrencyDetails?.code || '$'} 0`}</MDTypography>
                </MDBox></>
              }
              <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
              <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" pb="8px" >
                <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{"Spend :"}</MDTypography>
                <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{formatAmount(item?.appDirect + item?.appIndirect + item?.direct, 2, systemCurrencyDetails)}</MDTypography>
              </MDBox>
              <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                <MDTypography sx={{ fontSize: "14px", pt: "8px", pb: "8px" }}>Mapping</MDTypography>
                <MDTypography variant="link"
                  sx={{
                    fontSize: "14px",
                    padding: "8px",
                    borderRadius: "5px",
                    color: (appSpend <= solSpend) && chargeBack ? '#EBEBE4' : "#4A5AED",
                    "&:hover": {
                      cursor: (appSpend <= solSpend) && chargeBack ? null : "pointer",
                      color: (appSpend <= solSpend) && chargeBack ? null : "#435EC3",
                      backgroundColor: (appSpend <= solSpend) && chargeBack ? null : "#eceff8"
                    }
                  }} fontWeight="medium" onClick={(appSpend > solSpend && !chargeBack) ? () => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) } : () => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter(null) }}>{(appSpend > solSpend && !chargeBack) ? "View >" : "+ Add"}</MDTypography>

              </MDBox>
            </> :
            (categoryFilter === "Cloud Usage" || categoryFilter === 'Mobile Usage') ?
              <>
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" >
                  <MDBox data-testid={"allocated"} pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
                    Spend<br />
                    {item["amount"] ? formatAmount(item["amount"], null, systemCurrencyDetails) : formatAmount(0, null, systemCurrencyDetails)}&nbsp;
                  </MDBox>
                  {categoryFilter === "Cloud Usage" &&
                    <ErrorBoundary>
                      <PiesChartRenderer vizOptions={chartOptions} />
                    </ErrorBoundary>
                  }
                </MDBox>
                <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>Last Load: {item["lastLoad"] ? moment(item["lastLoad"]).format("MMM DD YYYY HH:mm:ss") : item["lastLoad_asset"] ? moment(item["lastLoad_asset"]).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography>
                </MDBox>
              </> :
              <>
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" >
                  <MDBox data-testid={"allocated"} pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
                    Allocated<br />
                    {item["amount"] ? formatAmount(item["amount"], null, systemCurrencyDetails) : formatAmount(0, null, systemCurrencyDetails)}&nbsp;
                  </MDBox>
                  <CircularProgressWithLabel value={100 * item["amount"] / (item["spendAmount"] ?? 1)} />
                </MDBox>
                <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{(categoryFilter === "Tower" ? "Cost Pool " : ' ') + typeFilter}:</MDTypography>
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{item["spendAmount"] ? formatAmount(item["spendAmount"], null, systemCurrencyDetails) : 'No ' + typeFilter}</MDTypography>
                </MDBox>
                {typeFilter === "Spend" &&
                  <>
                    <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                      <MDTypography data-testid={"mappingrules"} sx={{ fontSize: "14px", pt: "8px", pb: "8px" }}>Mapping</MDTypography>
                      <MDTypography
                        data-testid={item["rules"] > 0 ? "viewrules>" : "+addrules"}
                        variant="link"
                        sx={{
                          fontSize: "14px",
                          padding: "8px",
                          color: item["rules"] >= 0 && chargeBack ? '#EBEBE4' : "#4A5AED",
                          borderRadius: "5px",
                          "&:hover": {
                            cursor: item["rules"] >= 0 && chargeBack ? null : "pointer",
                            color: item["rules"] >= 0 && chargeBack ? null : "#435EC3",
                            backgroundColor: item["rules"] >= 0 && chargeBack ? null : "#eceff8"
                          }
                        }} fontWeight="medium" onClick={item["rules"] > 0 ? () => { handleAction(item["id"], item["name"], "view"), setSubCategoryFilter(null) } : () => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter(null) }}
                      >{item["rules"] > 0 ? "View >" : "+ Add"}</MDTypography>
                    </MDBox>
                  </>
                }
              </>
          }

        </MDBox>
      </MDBox>
    </Card>
  )
}
const Month = (props) => {
  const { showAlert, showPrompt, showSnackbar } = useYADialog();
  const [openMenu, setOpenMenu] = useState(false);
  const { item, handleAction, yearFilter, yearFilterName, reloadData, categoryFilter, typeFilter, setRefresh, setSubCategoryFilter, chargeBackMonthly, handleError, publishedMonthly, uploadType, systemCurrencyDetails } = props
  const [data, setData] = useState(null)
  const solSpend = toInt(item?.indirect + item?.direct) ?? 0;
  const appSpend = toInt(item?.appDirect + item?.appIndirect) ?? 0;
  const chargeBack = _.find(chargeBackMonthly, { monthNameId: item["id"], yearNameId: yearFilter }) ? true : false
  let disaplayAmount = item.direct ? toInt(item.direct) : 0
  disaplayAmount = disaplayAmount + (item.indirect ? toInt(item?.indirect) : 0);
  const published = _.find(publishedMonthly, { monthNameId: item["id"], yearNameId: yearFilter }) ? true : false
  const { response, error, loading } = categoryFilter === "Tower" && useFetchRequest(`/api/dataflow/towerRuleNames/${yearFilter}/${item["id"]}`)
  useEffect(() => {
    if (!loading) {
      if (error !== null) {
        handleError(error);
      }
      else if (response !== null) {
        setData(response);
      }
    }
  }, [loading, data, yearFilter]);


  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  };
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };
  const handleDownload = (subCategoryFilter) => {
    if (categoryFilter === "Cost Pool")
      downloadCPRules(yearFilter, item["id"], yearFilterName, item['name'])
    if (categoryFilter === "Tower")
      downloadTRRules(yearFilter, item["id"], yearFilterName, item['name'])
    if (categoryFilter === "Business Unit")
      downloadBURules(yearFilter, item["id"], yearFilterName, item['name'])
    if (categoryFilter === "Solution" && !subCategoryFilter)
      downloadSolutionMapping(yearFilter, item["id"], yearFilterName, item['name'])
    if (categoryFilter === "Solution" && subCategoryFilter === 'Solution Rule')
      downloadSolutionRules(yearFilter, item["id"], yearFilterName, item['name'])
  }

  const handleDelete = (item, yearFilter, yearFilterName, reloadData, categoryFilter) => {
    showPrompt("Delete", "Are you sure you want to delete " + categoryFilter.toLowerCase() + " mapping for - [ " + item["name"] + " " + yearFilterName + " ]", async () => {
      let [err, data] = categoryFilter === "Solution" ? await fetchRequest.delete(`/api/dataflow/offeringRules/${yearFilter}/${item["id"]}`) : await fetchRequest.delete(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}Rules/${yearFilter}/${item["id"]}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          setRefresh(Math.random());
          reloadData()
        } else {
          console.error(err)
          showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        showSnackbar(data, "success")
        setOpenMenu(false)
        setRefresh(Math.random());
        reloadData()
      }
    });
  }

  const handleConsumptionDelete = (item, yearFilter, yearFilterName, reloadData, categoryFilter, uploadType) => {
    showPrompt("Delete", "Are you sure you want to delete all " + categoryFilter.toLowerCase() + " data for - [ " + item["name"] + " " + yearFilterName + " ]", async (checked) => {
      var [err, data] = await fetchRequest.delete(`/api/dataflow/${uploadType.toLowerCase().replace(' ', '')}/${yearFilter}/${item["id"]}?checked=${checked}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          reloadData()
          setRefresh(Math.random());
        } else {
          console.error(err)
          showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        showSnackbar(`[${item["name"]} ${yearFilterName}] ` + data, "success")
        setOpenMenu(false)
        reloadData()
        setRefresh(Math.random());
      }
    }, null, null, null, null, { label: "also delete uploaded files" }, "md")
  }

  const handleSolutionRuleDelete = async (item, yearFilter, yearFilterName, reloadData) => {
    showPrompt("Delete", "Are you sure you want to delete solution rules for - [ " + item["name"] + " " + yearFilterName + " ]", async () => {
      let [err, data] = await fetchRequest.delete(`/api/solutionOfferingRules/deleteAll/${yearFilter}/${item["id"]}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          setRefresh(Math.random());
          reloadData()
        } else {
          console.error(err)
          showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        showSnackbar(data, "success")
        setOpenMenu(false)
        setRefresh(Math.random());
        reloadData()
      }
    })
  }

  const chartOptions = {
    "graphData": item['graphData'],
    "wigetType": "doughnut",
    "showTitle": false,
    "innerSize": "50%",
    "dataLabels": false,
    "providerview": true,
    "width": 150,
    "height": 150,
    "series": [
      {
        "name": "ProviderName",
        "displayName": "ProviderDisplayName",
        "value": "amount"
      }
    ]
  }

  return (
    <Card
      sx={{
        minHeight: "150px",
        minWidth: categoryFilter === 'Cloud Usage' ? "300px" : "270px",
        margin: "10px",
        display: "inline-block",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        border: "none",
        overflow: "hidden",
        position: "relative",
        "&:hover": {
          "& .helpIcon": {
            visibility: 'visible'
          }
        }
      }}>
      <MDBox px={3} pb={1} pt={2} display="flex" justifyContent="space-between" alignItems="flex-start">
        <MDBox display="flex" width="100%" flexDirection="column" justifyContent="space-between" >
          <MDBox color="text" pt={0} mt={0} display="flex" justifyContent="space-between" flexDirection="row">
            <MDTypography data-testid={item["name"]?.toLowerCase().replaceAll(' ', '')} variant="h6" component="span" color="#4c4c4c" display="flex">{item["name"]}</MDTypography>
            {chargeBack && categoryFilter !== "Mobile Usage" ? <Tooltip title={"Month Locked (Chargeback)"}><Icon color={'error'} >lock</Icon></Tooltip> : ''}
            {categoryFilter === "Cloud Usage" && published ? <Tooltip title={"Cloud Spend Published"}><Icon fontSize="small" color='success'>published_with_changes</Icon></Tooltip> : ''}
            {typeFilter !== "Budget" && data && data.rules.length > 0 && data.rules.filter(rule => rule['allocationFileTowerMapping.originalFileName'] === null && rule['amount']).length > 0 ? <Tooltip title={"Allocation File Missing"}><Icon fontSize="small" color={'error'}>warning_amber</Icon></Tooltip> : ''}
            <Menu
              anchorEl={openMenu}
              anchorReference={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(openMenu)}
              onClose={handleCloseMenu}
            >
                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} data-testid={"mappings"} key={'add'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? `Add Rules` : `Add Mapping`}</MenuItem>}
                {/* {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && <MenuItem data-testid={"viewmappings"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? (['Budget', 'Forecast'].includes(typeFilter) ? `View ${typeFilter}` : `View Rule`) : `View Mapping`}</MenuItem>} */}
                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && <MenuItem data-testid={"viewmappings"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower', 'Solution', 'Business Unit'].includes(categoryFilter) ? (['Budget', 'Forecast'].includes(typeFilter) ? `View ${typeFilter}` : (['Cost Pool', 'Tower'].includes(categoryFilter)  ? `View Rules` : `View Mapping`)) : `View Mapping`}</MenuItem>}
                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} data-testid={"mappings"} key={'add'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "copyTo"); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? `Copy Rules To` : `Copy Mapping To`}</MenuItem>}

                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} data-testid={"deletemappings"} key={'delete'} onClick={() => handleCloseMenuItem(() => { handleDelete(item, yearFilter, yearFilterName, reloadData, categoryFilter); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? `Delete Rules` : `Delete Mapping`}</MenuItem>}
                {categoryFilter !== "Cloud Usage" && categoryFilter !== 'Mobile Usage' && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem data-testid={"download"} key={'download'} onClick={() => handleCloseMenuItem(() => { handleDownload(); setSubCategoryFilter(null) })}>{['Cost Pool', 'Tower'].includes(categoryFilter) ? `Download Rules` : `Download Mapping`}</MenuItem>}
                {(categoryFilter === "Solution" || categoryFilter === "Tower") && (typeFilter !== "Budget") && (typeFilter !== "Forecast") && <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />}
                {(categoryFilter === "Solution" && typeFilter === "Spend") && <MenuItem disabled={chargeBack} key={'addSolutionOfferingRules'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter('Solution Rule') })}>Add Rules</MenuItem>}
                {(categoryFilter === "Solution" && typeFilter === "Spend") && <MenuItem key={'viewSolutionOfferingRule'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "solutionOfferingRulesview"); setSubCategoryFilter('Solution Rule') })}>View Rules</MenuItem>}
                {(categoryFilter === "Solution" && typeFilter === "Spend") && <MenuItem key={'deleteRules'} onClick={() => handleCloseMenuItem(() => { handleSolutionRuleDelete(item, yearFilter, yearFilterName, reloadData) })}>Delete Rules</MenuItem>}
                {(categoryFilter === "Solution" && typeFilter === "Spend") && <MenuItem data-testid={"download"} key={'download'} onClick={() => handleCloseMenuItem(() => { handleDownload('Solution Rule'); })}>Download Rules</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} key={'Asset Rule'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter('Asset Rule') })}>Add Asset Rules</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem key={'Asset Rule'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "assetRuleview"); setSubCategoryFilter('Asset Rule') })}>View Asset Rules</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} key={'Asset Rule'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "copyTo"); setSubCategoryFilter('Asset Rule') })}>Copy Asset Rules To</MenuItem>}

                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem disabled={chargeBack} key={'addAssetMapping'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "addAssetMapping"); setSubCategoryFilter(null) })}>Add Asset Mapping</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem key={'viewAssetMapping'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "viewAssetSpend"); setSubCategoryFilter("assetSpend") })}>View Asset Mapping</MenuItem>}
                {categoryFilter === "Tower" && typeFilter !== "Budget" && typeFilter !== "Forecast" && <MenuItem key={'viewUnallocatedAssetSpend'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "viewUnallocatedAssetSpend"); setSubCategoryFilter(null) })}>View Unallocated Asset Spend</MenuItem>}
                {categoryFilter === "Cloud Usage" && <MenuItem disabled={chargeBack || published} data-testid={"cloudConsumption"} key={'add'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "addCloudConsumption"); setSubCategoryFilter(null) })}>Add Usage Data</MenuItem>}
                {categoryFilter === "Cloud Usage" && <MenuItem disabled={chargeBack || published} data-testid={"cloudConsumption"} key={'delete'} onClick={() => handleCloseMenuItem(() => { handleConsumptionDelete(item, yearFilter, yearFilterName, reloadData, categoryFilter, uploadType); setSubCategoryFilter(null) })}>Delete Usage Data</MenuItem>}
                {categoryFilter === "Mobile Usage" && <MenuItem disabled={false} data-testid={"mobileConsumption"} key={'add'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "addMobileConsumption"); setSubCategoryFilter(null) })}>Add Usage Data</MenuItem>}
                {categoryFilter === "Mobile Usage" && <MenuItem disabled={false} data-testid={"mobileConsumption"} key={'delete'} onClick={() => handleCloseMenuItem(() => { handleConsumptionDelete(item, yearFilter, yearFilterName, reloadData, categoryFilter, uploadType); setSubCategoryFilter(null) })}>Delete Usage Data</MenuItem>}
                <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
                {categoryFilter !== "Cloud Usage" && categoryFilter !== "Mobile Usage" && <MenuItem data-testid={"viewuploadedfiles"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "uploaded"); setSubCategoryFilter(null) })}>View Uploaded Files</MenuItem>}
                {(categoryFilter === "Cost Pool" || categoryFilter === "Solution" || categoryFilter === "Cloud Usage" || categoryFilter === "Mobile Usage" || typeFilter === "Budget" || typeFilter == "Forecast") ? " " : <MenuItem data-testid={"viewallocationfiles"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "allocation"); setSubCategoryFilter(null) })}>View Allocation Files</MenuItem>}
                {categoryFilter === "Cloud Usage" && <MenuItem disabled={false} data-testid={"cloudConsumption"} key={'cloudSpend'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "cloudSpend"); setSubCategoryFilter(null) })}>Manage Cloud Spend</MenuItem>}
                {/* {categoryFilter === "Mobile Usage" && <MenuItem disabled={false} data-testid={"mobileConsumption"} key={'mobileSpend'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "mobileSpend");setSubCategoryFilter(null)})}>Manage Mobile Spend</MenuItem> } */}
                {categoryFilter === "Cloud Usage" && <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />}
                {categoryFilter === "Cloud Usage" && <MenuItem disabled={false} data-testid={"cloudConsumption"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) })}>View Uploaded Files</MenuItem>}
                {categoryFilter === "Mobile Usage" && <MenuItem disabled={false} data-testid={"mobileConsumption"} key={'view'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) })}>View Uploaded Files</MenuItem>}
                {/* {(categoryFilter === "Cloud Usage" || categoryFilter === "Mobile Usage") && <MenuItem disabled={chargeBack} data-testid={"uploadRules"} key={'uploadRules'} onClick={() => handleCloseMenuItem(() => { handleAction(item["id"], item["name"], "uploadRules");setSubCategoryFilter(null)})}>View Upload Rules</MenuItem> } */}

                {/* <MenuItem key={'view'} onClick={() => handleCloseMenuItem(() => {  handleAction(item["id"], item["name"], "allocation") })}>View Allocation Files</MenuItem> */}
            </Menu>

            <MDBox mt={-1} mr={-1} pt={0}>
              <MDButton
                // size="medium"
                disableRipple
                color="dark"
                variant="text"
                onClick={handleOpenMenu}
                data-testid={`${item["name"]?.toLowerCase().replaceAll(' ', '')}-horizIcon`}
                sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                iconOnly
                aria-label="options"
              >
                <Icon px={0} py={0} alignItems="end">more_horiz</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
          {(categoryFilter === "Solution" && typeFilter === "Spend" ) ?
            <>
              <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" >
                <MDBox pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
                  {disaplayAmount === (item["assetSpend"] ? toInt(item?.assetSpend + item?.direct) : item["towerSpend"] ? toInt(item?.appDirect + item?.appIndirect + item?.direct) : toInt(item["solutionSpend"])) ? "Fully Allocated" : "Allocated"}<br />
                  {formatAmount(disaplayAmount, 2, systemCurrencyDetails)}&nbsp;
                </MDBox>
                {disaplayAmount === toInt(item?.appDirect + item?.appIndirect + item?.direct) ? <Icon fontSize="large" color='success'>done</Icon> : <CircularProgressWithLabel value={100 * disaplayAmount / (toInt(item?.appDirect + item?.appIndirect + item?.direct))} />}
              </MDBox>
              {!item["solutionSpend"] && <>
                <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" component="li" />

                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>Direct Allocation :</MDTypography>
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{item["direct"] ? formatAmount(item["direct"], 2, systemCurrencyDetails) : `${systemCurrencyDetails?.code || '$'} 0`}</MDTypography>
                </MDBox>
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{"Asset Spend :"}</MDTypography>
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{item["assetSpend"] ? formatAmount(item["assetSpend"], 2, systemCurrencyDetails) : item["appDirect"] || item["appIndirect"] ? formatAmount(item["appDirect"] + item["appIndirect"], 2, systemCurrencyDetails) : `${systemCurrencyDetails?.code || '$'} 0`}</MDTypography>
                </MDBox></>
              }
              <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
              <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" pb="8px" >
                <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{"Spend :"}</MDTypography>
                <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{formatAmount(item?.appDirect + item?.appIndirect + item?.direct, 2, systemCurrencyDetails)}</MDTypography>
              </MDBox>
              <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                <MDTypography sx={{ fontSize: "14px", pt: "8px", pb: "8px" }}>Mapping</MDTypography>
                <MDTypography variant="link"
                  sx={{
                    fontSize: "14px",
                    padding: "8px",
                    borderRadius: "5px",
                    color: (appSpend <= solSpend) && chargeBack ? '#EBEBE4' : "#4A5AED",
                    "&:hover": {
                      cursor: (appSpend <= solSpend) && chargeBack ? null : "pointer",
                      color: (appSpend <= solSpend) && chargeBack ? null : "#435EC3",
                      backgroundColor: (appSpend <= solSpend) && chargeBack ? null : "#eceff8"
                    }
                  }} fontWeight="medium" onClick={(appSpend > solSpend && !chargeBack) ? () => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter(null) } : () => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) }}>{(appSpend > solSpend && !chargeBack) ? "+ Add" : "View >"}</MDTypography>
              </MDBox>
            </> :
            (categoryFilter === "Cloud Usage" || categoryFilter === 'Mobile Usage') ?
              <>
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" >
                  <MDBox data-testid={"allocated"} pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
                    Spend<br />
                    {item["amount"] ? formatAmount(item["amount"], null, systemCurrencyDetails) : formatAmount(0, null, systemCurrencyDetails)}&nbsp;
                  </MDBox>
                  {categoryFilter === "Cloud Usage" && chartOptions &&
                    <ErrorBoundary>
                      <PiesChartRenderer vizOptions={chartOptions} />
                    </ErrorBoundary>
                  }
                </MDBox>
                <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                  <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>Last Load: {item["lastLoad"] ? moment(item["lastLoad"]).format("MMM DD YYYY HH:mm:ss") : item["lastLoad_asset"] ? moment(item["lastLoad_asset"]).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography>
                </MDBox>
              </> :
              <>
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" >
                  <MDBox data-testid={((Math.round(item["amount"], 0) === Math.round(item["spendAmount"], 0) ? "Fully Allocated" : item["amount"] < item["spendAmount"] ? "Allocated" : "Allocated")).toLowerCase().replaceAll(' ', '')} pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
                    {Math.round(item["amount"], 0) === Math.round(item["spendAmount"], 0) ? "Fully Allocated" : item["amount"] < item["spendAmount"] ? "Allocated" : "Allocated"}<br />
                    {item["amount"] ? formatAmount(item["amount"], 2, systemCurrencyDetails) : formatAmount(0, null, systemCurrencyDetails)}&nbsp;
                  </MDBox>
                  {Math.round(item["amount"], 0) === Math.round(item["spendAmount"], 0) ? <Icon fontSize="large" color='success'>done</Icon> : <CircularProgressWithLabel value={100 * item["amount"] / (item["spendAmount"] ?? 1)} />}
                </MDBox>
                <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
                <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                  <MDTypography data-testid={((categoryFilter === "Tower" ? "Cost Pool " : (categoryFilter === "Business Unit" ? "Solution " : ' ')) + typeFilter).toLowerCase().replaceAll(' ', '')} sx={{ fontSize: "14px", pt: "8px" }}>{(categoryFilter === "Tower" ? "Cost Pool " : (categoryFilter === "Business Unit" ? "Solution " : ' ')) + typeFilter}:</MDTypography>
                  <MDTypography data-testid={(item["spendAmount"] ? formatAmount(item["spendAmount"], 2, systemCurrencyDetails) : 'No ' + typeFilter).toLowerCase().replaceAll(' ', '')} sx={{ fontSize: "14px", pt: "8px" }}>{item["spendAmount"] ? formatAmount(item["spendAmount"], 2, systemCurrencyDetails) : 'No ' + typeFilter}</MDTypography>
                  {/* <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{categoryFilter === "Tower" ? "Cost Pool Spend" : typeFilter}</MDTypography>
            <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{item["spendAmount"] ? formatAmount(item["spendAmount"],2) : 'No '+typeFilter}</MDTypography> */}
                </MDBox>
                {typeFilter === "Spend" &&
                  <>
                    <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                      <MDTypography data-testid={"mappingrules"} sx={{ fontSize: "14px", pt: "8px", pb: "8px" }}>Mapping</MDTypography>
                      <MDTypography
                        data-testid={~~item["spendAmount"] > ~~item["amount"] ? "+addrules" : "viewrules>"}
                        variant="link"
                        sx={{
                          fontSize: "14px",
                          padding: "8px",
                          borderRadius: "5px",
                          color: (item["calcAmount"] ? ~~item["spendAmount"] > ~~item["calcAmount"] : ~~item["spendAmount"] > ~~item["amount"]) && chargeBack ? '#EBEBE4' : "#4A5AED",
                          "&:hover": {
                            cursor: (item["calcAmount"] ? ~~item["spendAmount"] > ~~item["calcAmount"] : ~~item["spendAmount"] > ~~item["amount"]) && chargeBack ? null : "pointer",
                            color: (item["calcAmount"] ? ~~item["spendAmount"] > ~~item["calcAmount"] : ~~item["spendAmount"] > ~~item["amount"]) && chargeBack ? null : "#435EC3",
                            backgroundColor: (item["calcAmount"] ? ~~item["spendAmount"] > ~~item["calcAmount"] : ~~item["spendAmount"] > ~~item["amount"]) && chargeBack ? null : "#eceff8"
                          }
                        }} fontWeight="medium" onClick={(item["calcAmount"] ? ~~item["calcAmount"] >= ~~item["spendAmount"] : ~~item["amount"] >= ~~item["spendAmount"]) ? () => { handleAction(item["id"], item["name"], "view"); setSubCategoryFilter(null) } : chargeBack ? null : () => { handleAction(item["id"], item["name"], "choose"); setSubCategoryFilter(null) }}
                      >{(item["calcAmount"] ? ~~item["spendAmount"] > ~~item["calcAmount"] : ~~item["spendAmount"] > ~~item["amount"]) ? "+ Add" : "View >"}</MDTypography>
                    </MDBox>
                  </>
                }
              </>}
        </MDBox>
      </MDBox>
    </Card>
  )
}
function CircularProgressWithLabel(props) {
  const { value, others } = { ...props }
  return (
    <MDBox sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={80} thickness={2} sx={{
        color: (theme) =>
          theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
      }} variant="determinate" value={100} {...others} aria-label="percent" />
      <CircularProgress size={80} thickness={2} sx={{
        color: (theme) => (theme.palette.mode === 'light' ? '' : '#308fe8'),
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }} disableShrink color={value > 100 ? "error" : "dprogress"} variant="determinate" value={isNaN(value) ? 0 : value > 100 ? 100 : value} {...others} aria-label="percent" />
      <MDBox
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MDTypography variant="caption" component="div" color="text.secondary">
          {isNaN(props.value) ? `0%` : props.value <= 100 ? `${Math.floor(props.value)}%` : `${Math.ceil(props.value)}%`}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default AnimatedRoute(MapRule);