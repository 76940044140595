import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { Icon, Card, IconButton, Modal, Alert, CircularProgress, Tooltip } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import EmptyState from "components/EmptyState";
import new_item_img from "assets/svg/add_new.svg";
import MDButton from "components/MDButton";
import moment from "moment";
import useFetchRequest from "hooks/useFetchRequest";
import DataloadErrorsDialog from "components/DataloadErrorsDialog";
import MDAvatar from "components/MDAvatar";
import Axios from "axios";
import useHandleError from "hooks/useHandleError";
import { useImmer } from "use-immer";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import DataloadDialog from "components/DataloadDialog";
// import { useNavigate } from "react-router-dom";
// import InterfaceForm from '../Interfaces/components/InterfaceForm';
import { DataStoreInterfaceHandler } from "components/FilteredUploadedFiles/helpers/DataStoreInterfaceHandler";
// import { useAppController } from "context";
import { getChargeBackByMonth, getPublishedByMonth } from "utils"
import _ from "lodash";

const ConsumptionDataloadPage = (props) => {
    const timer = useRef()
    const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
    const [openErr, setOpenErr] = useState(false);
    const [errors, setErrors] = useState([]);
    const [ufData, setUFData] = useState({});
    const { response: rulesRes, error: rulesErr, loading: rulesLoading } = useFetchRequest(`/api/dataflow/categories`);
    const handleError = useHandleError();
    const [levels, setLevels] = useImmer([]);
    const [yearFilter, setYearFilter] = useState(null);
    const [monthFilter, setMonthFilter] = useState(null);
    const [yearFilterName, setYearFilterName] = useState(null);
    const [monthFilterName, setMonthFilterName] = useState(null);
    const [act, setAct] = useState(null);
    const [destinationTable, setDestinationTable] = useState("")
    const [popup, setpopup] = useState(false)
    const [popupColumns, setpopupColums] = useState([])
    const [popupRows, setpopupRows] = useState([])
    // const [controller,] = useAppController();
    const [interfaceList, setInterfaceList] = useState([])
    const [chargeBack, setChargeBack] = useState(false);
    const [published, setPublished] = useState(false);
    const [reloadId, setReloadId] = useState(null);
    const uploadConfig = { monthFilter: monthFilter, yearFilter: yearFilter, yearFilterName: yearFilterName, monthFilterName: monthFilterName, uploadType: null, uploadCategory: "Consumption" }

    useEffect(async () => {
        if (yearFilter && monthFilter) {
            const response = await getChargeBackByMonth(yearFilter, monthFilter)
            if (response) {
                setChargeBack(true)
            } else {
                setChargeBack(false)
            }
        }
    }, [yearFilter, monthFilter])

    useEffect(async () => {
        if (yearFilter && monthFilter) {
            const response = await getPublishedByMonth(yearFilter, monthFilter)
            if (response) {
                setPublished(true)
            } else {
                setPublished(false)
            }
        }
    }, [yearFilter, monthFilter])
    // let navigate = useNavigate();

    useEffect(() => {
        if (!rulesLoading) {
            if (rulesErr !== null) {
                handleError(rulesErr);
            }
            else if (rulesRes !== null) {
                let currentYearIndex = rulesRes.years?.length - 1;
                const currentMonthNumber = (new Date().getMonth()) + 1;
                const currentMonth = rulesRes.months.find(m => m.id === currentMonthNumber);
                const currentFinancialYear = rulesRes.currentFinancialYear;
                if (currentFinancialYear) {
                    const index = rulesRes.years?.map(y => y.name).indexOf(currentFinancialYear.value);
                    if (index > -1)
                        currentYearIndex = index;
                }
                setLevels(rulesRes);
                setYearFilter(rulesRes.years[currentYearIndex]?.id);
                setYearFilterName(rulesRes.years[currentYearIndex]?.name);
                setMonthFilter(currentMonth?.id);
                setMonthFilterName(currentMonth?.name);
            }
        }
    }, [rulesLoading, rulesRes]);

    let getInterfaceList = async () => {
        const [err1, data] = await fetchRequest.get('/api/interface/list')
        if (!err1) {
            setInterfaceList(data)
        }

    }
    useEffect(() => {
        getInterfaceList()
    }, [])

    const { uploadType } = props

    const { response: rows, error: _err, loading, reloadData } = (uploadType) ? useFetchRequest(`/api/dataload/list/consumption/${uploadType}/${yearFilter}/${monthFilter}`) : useFetchRequest(`/api/dataload/list/consumption/${yearFilter}/${monthFilter}`);

    // const { response, error: _err1, loading1 } = useFetchRequest(`/api/dataload/dataloadTypes`);
    const handleErrDialogClose = () => {
        setOpenErr(false);
    }
    const handlePopup = () => {
        setpopup(false)
    }

    const buildPopupColumns = () => {
        let checkColumnName = (value) => {
            let valueSet = value?.split('--|--')
            if (valueSet && valueSet.length === 2) {
                if (valueSet[0] && valueSet[0] === valueSet[1])
                    return <MDTypography variant="caption" alignItems="center" fontWeight="medium" color="dark">{valueSet[0]}</MDTypography>
                return <MDTypography variant="caption" fontWeight="medium" color="dark">
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        {valueSet[0]}
                        <Tooltip title={"Source column name and the Destination column name are different."}>
                            <Icon color="warning" style={{ marginLeft: '4px' }}>warning</Icon>
                        </Tooltip>
                    </span>
                </MDTypography>
            }
            return (
                <MDTypography variant="caption" alignItems="center" fontWeight="medium" color="dark">{value}</MDTypography>
            )
        }
        
        let columns = [
            { Header: "Destination", accessor: "destination", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" alignItems="center" fontWeight="medium" color="dark">{value}</MDTypography> } },
            { Header: "Source", accessor: "source", Cell: ({ cell: { value } }) => { return checkColumnName(value) } }
        ]
        return columns;
    }

    const buildPopupRows = (item, def) => {
        let rows = item.filter(i => i?.master !== 'FOCUS Mapping').map(i => {
            let destination = "";
            if (def?.type == "Assets") {
                def?.fields.map(j => {
                    if (j.name == i.destinationColumn) {
                        destination = j.displayName;
                    }
                    if (!destination) {
                        if (j.schemaName == i.destinationColumn)
                            destination = j.displayName;
                    }
                })
            }
            else {
                def?.fields.map(j => {
                    if (j.name == i.destinationColumn) {
                        destination = j.displayName;
                    } else if (i?.master) {
                        destination = i?.master
                    }
                })
            }
            return {
                "destination": destination,
                "source": i.sourceColumn || i?.code ? i?.master ? i.sourceColumn || i?.code : `${i.sourceColumn || i?.code}--|--${destination}` : i.sourceColumn || i?.code
            }
        })
        return rows
    }

    const viewMapping = async (item, def, destination) => {
        setDestinationTable(destination)
        setpopupColums(buildPopupColumns());
        setpopupRows(buildPopupRows(JSON.parse(item), def));
        setpopup(true)
    }

    const handleDelete = (item) => {
        showPrompt("Delete", "Are you sure you want to delete - [" + item["originalFileName"] + "]", async () => {
            var [err1, data] = await fetchRequest.delete(`/api/dataflow/uploadedFile/${item["id"]}`)
            if (err1) {
                console.error(err1)
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
            else if (data) {
                showSnackbar(data, "success")
                reloadData()
            }
        })
    }

    const handleDownload = async (item) => {
        var [err1, data] = await fetchRequest.get(`/api/blob/presignedGet/${item["id"]}`)
        if (err1)
            showAlert(err1)
        if (data) {
            const response = await Axios.get(data, { responseType: "blob" });
            var blob = new Blob([response.data]);
            var url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute("href", url);
            link.setAttribute("download", item.originalFileName);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const handleViewErrors = async (item) => {
        if (item.errors) {
            setErrors(JSON.parse(item.errors.replace(/'/g, "")))
            setUFData(item);
            setOpenErr(true)
        }
    }

    const handleAutomate = async (item) => {
        const modelData = {};
        modelData["Name"] = item.displayName + " - " + _.find(JSON.parse(item?.mappingFields), { master: 'FOCUS Mapping' })?.code
        modelData["DestinationTable"] = item.destinationTable
        modelData["FileType"] = item.fileType
        modelData["MappingFields"] = item.mappingFields
        modelData["DefaultFields"] = "[]"
        await DataStoreInterfaceHandler(modelData, showAlert, showPrompt, showSnackbar, showCustomForm, true, getInterfaceList)
    }

    const getPresignedUrl = async (fId) => {
        return await fetchRequest.get(`/api/blob/presignedPost/${fId}?reload=${true}`);
    }

    const checkFileUploadStatus = async (fileId) => {
        const [error, data] = await fetchRequest.get(`/api/dataload/uploadstatus/${fileId}`);
        if (error) {
            console.error("An error occured while fetching upload status");
            console.error(error);
        }
        return data;
    }

    const handleRelod = async (item) => {
        showPrompt("Reload", "All upload rules will reapply, which may change consumption data. Do you wish to proceed?", async () => {
            setReloadId(item.id)
            var selectedFile = null;
            var [err, data] = await fetchRequest.get(`/api/blob/presignedGet/${item["id"]}?reload=${true}`)
            if (err)
                console.log(err);
            if (data) {
                const response = await Axios.get(data, { responseType: "blob" });
                selectedFile = response.data
            }

            var uf = {}
            uf.originalFileName = item.originalFileName
            uf.mappingFields = item.mappingFields
            uf.yearNameId = item.yearNameId
            uf.monthNameId = item.monthNameId
            uf.totalRecords = item.totalRecords
            uf.destinationTable = item.destinationTable
            uf.uploadSubType = item.destinationTable

            const [resError, response] = await fetchRequest.post(`/api/dataload/createupload?reload=${true}`, uf);
            if (resError) {
                console.error("An error occured while reloading file");
                console.error(resError);
                return false;
            }

            const [presignedUrlError, presignedUrlResponse] = await getPresignedUrl(response.id);
            if (presignedUrlError) {
                console.error("An error occured while getting presigned url");
                console.error(presignedUrlError);
                return false;
            }

            const options = new URL(presignedUrlResponse).host.indexOf("blob.core.windows.net") !== -1 ? {
                headers: {
                    'X-Ms-Blob-Type': 'BlockBlob',
                    'X-Ms-Version': '2023-11-03'
                }
            } : null;
            const [putError,] = await fetchRequest.put(presignedUrlResponse, selectedFile, options);
            if (putError) {
                console.error("An error occured while uploading to blob store");
                console.error(putError);
                return false;
            }

            const [relodError, reloadResponse] = await fetchRequest.post(`/api/dataload/uploaded`, { fileId: response.id });
            if (relodError) {
                console.error("An error occured while updating file status");
                console.error(relodError);
                return false;
            }
            else if (reloadResponse?.result === false) {
                console.error("An error occured while updating file status");
                return false;
            }

            timer.current = setInterval(async () => {
                const uploadFile = await checkFileUploadStatus(response.id);
                const uploadStatus = uploadFile?.fileStatus.toLowerCase();
                if (["loaded", "error", "partial"].includes(uploadStatus)) {
                    clearInterval(timer.current);
                    showSnackbar("File reloaded successfully", "success");
                    reloadData();
                    setReloadId(null)
                }

            }, 2000)

        })
    }


    let colorSet = (item) => {
        let color
        let sourceDataCheck = interfaceList.find(obj => obj.name === item.displayName)
        sourceDataCheck ? color = "info" : color = ""
        return color
    }
    const buildColumns = (onDownload, onDelete, onViewErrors) => {
        return [
            // { Header: "File Type", accessor: "fileType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Type", accessor: "displayName", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark"><b>{value}</b></MDTypography> } },
            { Header: "File Name", accessor: "originalFileName", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "File Status", accessor: "fileStatus", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Loaded Records", accessor: "loadedRecords", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Total Records", accessor: "totalRecords", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            {
                Header: "Errors", accessor: "errors", disableFilters: true, Cell: ({ cell: { row: { original } } }) => {
                    return original.errors !== '[]' &&
                        original?.errors !== '' &&
                        original?.errors !== '{}' &&
                        original?.errors !== null
                        ? <IconButton sx={{ padding: 0, paddingLeft: '8px' }} onClick={() => onViewErrors(original)}><Icon color="error">error</Icon></IconButton>
                        : ''
                }, "type": "showonvalue"
            },
            { Header: "Mapping fields", accessor: "mappingFields", disableFilters: true, Cell: ({ row }) => { return <IconButton sx={{ padding: 0, paddingLeft: '8px' }} onClick={() => { viewMapping(row.values.mappingFields, row.original.def, row.values.destinationTable) }}><Icon color="info">info</Icon></IconButton> } },
            { Header: "Uploaded On", accessor: "createdAt", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
            { Header: "Uploaded By", accessor: "createdByUser__name", disableFilters: true, dataType: "textbox", Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography> } } },
            { Header: "Automate", disableSorting: true, accessor: "", Cell: ({ row }) => { return <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(102, 187, 106, 0.2)' } }} onClick={() => handleAutomate(row.original)} color={colorSet(row.original)}><Icon>bolt</Icon></IconButton> } },
            { Header: "Reload", disableSorting: true, accessor: "", Cell: ({ row }) => { return row.original.destinationTable != "mobileConsumption" && (reloadId === row.original.id ? <CircularProgress color="info" size={20} /> : <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(25, 118, 210, 0.2)' } }} disabled={chargeBack || published} onClick={() => handleRelod(row.original)} color="info"><Icon>refresh</Icon></IconButton> )} },
            { Header: "Download", disableSorting: true, accessor: "", disableFilters: true, Cell: ({ cell: { row: { original } } }) => { return <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(66, 165, 245, 0.2)' } }} onClick={() => onDownload(original)}><Icon>download</Icon></IconButton> } },
            { Header: "Delete", disableSorting: true, accessor: "id", disableFilters: true, Cell: ({ row: { original } }) => { return <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(239, 83, 80, 0.2)' } }} disabled={chargeBack || published} onClick={() => onDelete(original)}><Icon color={(chargeBack || published) ? "disabled" : "error"}>delete</Icon></IconButton> } },
        ];
    }

    const deleteCloudConsumption = () => {
        showPrompt("Delete", "Are you sure you want to delete cloud consumption file ", async () => {
            var [err1, data] = await fetchRequest.post(`/api/dataflow/deleteCloudConsumption/${yearFilter}/${monthFilter}`)
            if (err1) {
                console.error(err1)
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
            else if (data) {
                showSnackbar(data, "success")
                reloadData()
            }
        })
    }


    const columns = buildColumns(handleDownload, handleDelete, handleViewErrors);

    const handleUploadDialogClose = (uploadSuccess) => {
        if (uploadSuccess)
            reloadData();
        setAct(false)
    };

    // const handleAddButtonClick = () => {
    //     showUploadDialog(`Upload for ${monthFilterName}, FY ${yearFilterName}`, {}, handleUploadDialogClose);
    // }

    const renderAddButton = () => (
        <>
            <MDButton disabled={chargeBack || published} variant="gradient" color="info" startIcon={<Icon>{chargeBack || published ? 'lock' : 'cloud_upload'}</Icon>} onClick={() => { setAct(true) }}>
                Upload
            </MDButton>
        </>
    )

    if (loading) {
        return <YASkeleton variant="dashboard-loading" />;
    }
    if (_err) {
        console.error(_err)
    }

    rows?.map((item) => {
        item["createdByUser__name"] = item["createdByUser.name"];
    })


    if (rulesLoading) {
        return <YASkeleton variant="dashboard-loading" />;
    }

    if (rulesLoading === false && levels === null) {
        return (
            <div>
                no data
            </div>
        );
    }
    const renderFilters = () => (
        <>
            <MDBox display="flex">
                {chargeBack || published ? null :
                    <MDButton data-testid={"delete"} variant="gradient" color="info" startIcon={<Icon>{'delete'}</Icon>} onClick={deleteCloudConsumption} sx={{ marginLeft: "5px" }}>Delete cloud consumption</MDButton>
                }
                {(chargeBack || published) && <Alert sx={{ fontSize: 13 }} severity="info"> {chargeBack || published ? chargeBack ? "Chargeback Created" : "Cloud Spend Published" : null}</Alert>}
                <Autocomplete
                    disableClearable={true}
                    value={yearFilter}
                    options={levels.years}
                    onChange={(event, newValue) => {
                        setYearFilter(newValue.id)
                        setYearFilterName(newValue.name)
                    }}
                    color="text"
                    fontWeight="medium"
                    sx={{
                        ml: 1.5,
                        "& .MuiOutlinedInput-root": {
                            height: 50,
                            minWidth: 130,
                            boxShadow: "0 8px 16px #1a488e1f"
                        },
                        "& .MuiOutlinedInput-input": {
                            fontSize: 14
                        },
                        "& .MuiOutlinedInput-input.MuiAutocomplete-input": {
                            padding: .5
                        }
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value
                    }}
                    getOptionLabel={option => {
                        if (typeof option === "number")
                            return levels.years.find(op => op.id === option)?.name;
                        return option.name
                    }}
                    renderInput={(params) => <MDInput data-testid={"yearfilter"} label="Year" {...params} />}
                />
                <Autocomplete
                    disableClearable={true}
                    value={monthFilter}
                    options={levels.months}
                    onChange={(event, newValue) => {
                        setMonthFilter(newValue.id)
                        setMonthFilterName(newValue.name)
                    }}
                    color="text"
                    fontWeight="medium"
                    sx={{
                        ml: 0.5,
                        "& .MuiOutlinedInput-root": {
                            height: 50,
                            width: 100,
                            boxShadow: "0 8px 16px #1a488e1f"
                        },
                        "& .MuiOutlinedInput-input": {
                            fontSize: 14
                        },
                        "& .MuiOutlinedInput-input.MuiAutocomplete-input": {
                            padding: .5
                        }
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value
                    }}
                    getOptionLabel={option => {
                        if (typeof option === "number")
                            return levels.months.find(op => op.id === option)?.name;
                        return option.name
                    }}
                    renderInput={(params) => <MDInput data-testid={"monthfilter"} label="Month"{...params} />}
                />
                <MDButton disabled={chargeBack || published} data-testid={"upload"} variant="gradient" color="info" startIcon={<Icon>{chargeBack || published ? 'lock' : 'cloud_upload'}</Icon>} onClick={() => { setAct(true) }} sx={{ marginLeft: "5px" }}>
                    Upload
                </MDButton>
            </MDBox>
        </>
    )

    return (
        <>

            {!uploadType && <PageHeader
                title={"Consumption Data Upload"}
                subtitle={"Screen to upload your consumption data"}
                primaryActionComponent={renderFilters}
            />}
            {act && <DataloadDialog title={`Upload for ${monthFilterName}, ${yearFilterName}`} uploadConfig={uploadConfig} onClose={handleUploadDialogClose} />}
            <MDBox p={3} pt={1}>
                {
                    (!loading && rows?.length > 0) &&

                    <Card sx={{ height: "100%" }} px={0}>
                        <DataTable
                            yearFilter={yearFilter}
                            monthFilter={monthFilter}
                            table={{ columns, rows }}
                            showTotalEntries={true}
                            isSorted={true}
                            newStyle1={true}
                            noEndBorder
                            entriesPerPage={true}
                            canSearch={true}
                            canFilter={true}
                        />
                    </Card>

                }
                {
                    (!loading && rows?.length === 0) && (
                        <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
                            <EmptyState
                                yearFilter={yearFilter}
                                monthFilter={monthFilter}
                                size="large"
                                image={new_item_img}
                                title={"No Dataloads Yet"}
                                description={"Click on the 'upload' button to start a new dataload."}
                                actions={renderAddButton}
                            />
                        </MDBox>
                    )
                }
            </MDBox>
            {
                (yearFilter && monthFilter) && openErr && <DataloadErrorsDialog info={ufData} rows={errors} onErrDialogClose={handleErrDialogClose} />
            }
            <Modal open={popup} onClose={handlePopup}>
                <MDBox pt={20} pl={50} pr={50} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                        <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                            <MDBox>
                                <MDTypography variant="h6" component="span" color="text">
                                    Mapping for {destinationTable}
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex">
                                <IconButton onClick={handlePopup} title="Close">
                                    <Icon>close</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                        <DataTable
                            table={{ columns: popupColumns, rows: popupRows }}
                            containerMaxHeight={474}
                            showTotalEntries={true}
                            entriesPerPage={true}
                        >
                        </DataTable>
                    </Card>
                </MDBox>
            </Modal>
        </>
    );
};

export default AnimatedRoute(ConsumptionDataloadPage);