import MDBox from "components/MDBox";
// import DataTable from "components/DataTable";
import ServeSideDataTable from "components/ServeSideDataTable";
import MDTypography from "components/MDTypography";
import { Icon, Card, IconButton, Alert, CircularProgress, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
// import { useState, useEffect, useRef } from "react";
// import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import EmptyState from "components/EmptyState";
import new_item_img from "assets/svg/add_new.svg";
import MDButton from "components/MDButton";
import moment from "moment";
import useFetchRequest from "hooks/useFetchRequest";
import DataloadErrorsDialog from "components/DataloadErrorsDialog";
import MDAvatar from "components/MDAvatar";
import Axios from "axios";
import useHandleError from "hooks/useHandleError";
import { useImmer } from "use-immer";
import DataloadDialog from "components/DataloadDialog";
import { DataStoreInterfaceHandler } from "components/FilteredUploadedFiles/helpers/DataStoreInterfaceHandler";
import { getChargeBackByMonth, getPublishedByMonth } from "utils"
import _ from "lodash";
import { useNotificationSubscription } from "hooks/useNotificationManager";
import DataloadMfDialog from "components/DataloadMfDialog";
import { generateUUID } from 'utils';
import { NotificationType, NotificationStatus } from 'constants';

const Consumption = (props) => {
    const { uploadType, yearFilter, monthFilter, yearFilterName, monthFilterName, uploadCategory, containerHeight, serverSideDataPaging } = props
    // const timer = useRef()
    const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
    const [openErr, setOpenErr] = useState(false);
    const [errors, setErrors] = useState([]);
    const [ufData, setUFData] = useState({});
    const [ufDef, setUFDef] = useState({});
    const [openMf, setOpenMf] = useState(false);
    const { response: rulesRes, error: rulesErr, loading: rulesLoading } = useFetchRequest(`/api/dataflow/categories`);
    const handleError = useHandleError();
    const [levels, setLevels] = useImmer([]);
    const [act, setAct] = useState(null);
    const [interfaceList, setInterfaceList] = useState([])
    const [chargeBack, setChargeBack] = useState(false);
    const [published, setPublished] = useState(false);
    const [reloadId, setReloadId] = useImmer([]);
    const [consumptionDelete, setConsumptionDelete] = useState(false)
    const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
    const [ pgIndx, setPgIndx] = useState( serverSideDataPaging ? 0 : null)
    const [ pageSize, setPageSize] = useState( serverSideDataPaging ? 50 : null)
    const [ totalNoOfPages, setTotalNoOfPages ] = useState(0)
    const [ totalItems, setTotalItems ] = useState(null)
    const [ search, setSearch ] = useState(null)
    const [lookUpList, setLookUpList] = useImmer({});
    const [ rows, setRows ] = useState([])
    const [ _err, setError ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ reload, setReload ] = useState(false);
    const { addNotification } = useYADialog();
    const [filters, setFilters] = useImmer([]);
    const [ filterDef, setFilterDef ] = useImmer([])
    const uploadConfig = { monthFilter: monthFilter, yearFilter: yearFilter, yearFilterName: yearFilterName, monthFilterName: monthFilterName, uploadType: uploadCategory === 'Mobile Consumption' ? uploadType : null, uploadCategory: uploadCategory }

    let handlePageOption = () => {
        onPageOptionsChange({ currentPage: 0, pageSize: pageSize })
    }

    const onPageOptionsChange = (pageOption) => {
        // if (serverSideDataPaging) {
        //     setLoading(true) 
        // }
        setPgIndx(pageOption.currentPage);
        setPageSize(pageOption.pageSize)
    }

    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState(draft => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
        if (serverSideDataPaging) {
            setFilters( draft => {
                const filters = latestFilters?.filters?.map(f => f.id)
                if ( filters && filters.length > 0) {
                draft.push(...filters) 
                }
            })
        }
    }

    useEffect(async () => {
        if (yearFilter && monthFilter) {
            const response = await getChargeBackByMonth(yearFilter, monthFilter)
            if (response) {
                setChargeBack(true)
            } else {
                setChargeBack(false)
            }
        }
    }, [yearFilter, monthFilter])

    useEffect(async () => {
        if (yearFilter && monthFilter) {
            const response = await getPublishedByMonth(yearFilter, monthFilter)
            if (response) {
                setPublished(true)
            } else {
                setPublished(false)
            }
        }
    }, [yearFilter, monthFilter])

    useEffect(() => {
        if (!rulesLoading) {
            if (rulesErr !== null) {
                handleError(rulesErr);
            }
            else if (rulesRes !== null) {
                setLevels(rulesRes);
            }
        }
    }, [rulesLoading, rulesRes]);

    const reloadData = () => setReload(Math.random())

    let getInterfaceList = async () => {
        const [err1, data] = await fetchRequest.get('/api/interface/list')
        if (!err1) {
            setInterfaceList(data)
        }

    }
    useEffect(() => {
        getInterfaceList()
    }, [])

    const getParsedAppliedFilters = () => {
        const filters = []
        filtersState['filters']?.forEach( filter => {
          if (filter.value.values?.length > 0) {
            const obj = {}
            obj['name'] = filter.id,
            obj['operator'] = filter.value.operator,
            obj['value'] = filter.value.values
            obj['type'] = filter.value.type
            filters.push(obj) 
          }
        })
        if (filters.length > 0) {
          return filters 
        } else {
          return null
        }
    };

    useEffect( async () => {
        if (serverSideDataPaging) {
            setLoading(true);
            if (yearFilter && monthFilter) {
                const appliedFilters = getParsedAppliedFilters()
                const filters = { filters: appliedFilters ? JSON.stringify(appliedFilters) : null, searchString: search ? search?.trim() : null}
                // const filters = { filters: null, searchString: null}
                var [err, response] = (uploadType) ? await fetchRequest.post(`/api/dataload/list/consumption/${uploadType}/${yearFilter}/${monthFilter}?pgIndx=${pgIndx}&pageSize=${pageSize}`, filters) : await fetchRequest.post(`/api/dataload/list/consumption/${yearFilter}/${monthFilter}?pgIndx=${pgIndx}&pageSize=${pageSize}`, filters);
                if (err) {
                    setError(err);
                } else {
                    const { data, totalItems, totalPages }  = response
                    if (data && Array.isArray(data) && data.length > 0) {
                        setRows(data)
                        setTotalItems(totalItems)
                        setTotalNoOfPages(totalPages)
                    } else {
                        setRows([]);
                        setTotalItems(totalItems)
                        setTotalNoOfPages(totalPages)
                    }
                }
                setLoading(false);
            }
        }
    }, [reload, yearFilter, monthFilter, pageSize, pgIndx, search, filtersState.filters]);

    useEffect( async () => {
    if (!serverSideDataPaging) {
        setLoading(true);
        if (yearFilter && monthFilter) {
        var [err, data] = (uploadType) ? await fetchRequest.get(`/api/dataload/list/consumption/${uploadType}/${yearFilter}/${monthFilter}`) : await fetchRequest.get(`/api/dataload/list/consumption/${yearFilter}/${monthFilter}`);
        if (err) {
            setError(err);
        } else {
            if (data && Array.isArray(data) && data.length > 0) {
                setRows(data)
                setTotalItems(totalItems)
            } else {
                setRows([]);
            }
        }
        setLoading(false);
        }
    }
    }, [reload, yearFilter, monthFilter]);

    useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
        if (notification.details?.destinationTable && ["cloudConsumption", "mobileConsumption"].includes(notification.details?.destinationTable)) {
            reloadData()
        }
    });

    useNotificationSubscription(NotificationType.ReloadFile, (notification) => {
        if (notification.details?.destinationTable && ["cloudConsumption", "mobileConsumption"].includes(notification.details?.destinationTable)) {
            // showSnackbar("File reloaded successfully", "success");
            setReloadId( draft => {
                return draft.filter( i => i !== notification.details.reloadId)
            })
            reloadData()
        }
    });

    const handleErrDialogClose = () => {
        setOpenErr(false);
    }

    const viewMapping = async (item, def) => {
        setUFDef(def);
        setUFData(item);
        setOpenMf(true)
    }

    const handleMfDialogClose = () => {
        setOpenMf(false)
    }

    const handleDelete = (item) => {
        showPrompt("Delete", "Are you sure you want to delete - [" + item["originalFileName"] + "]", async () => {
            var [err1, data] = await fetchRequest.delete(`/api/dataflow/uploadedFile/${item["id"]}`)
            if (err1) {
                console.error(err1)
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
            else if (data) {
                showSnackbar(data, "success")
                reloadData()
            }
        })
    }

    const handleDownload = async (item) => {
        var [err1, data] = await fetchRequest.get(`/api/blob/presignedGet/${item["id"]}`)
        if (err1)
            showAlert(err1)
        if (data) {
            const response = await Axios.get(data, { responseType: "blob" });
            var blob = new Blob([response.data]);
            var url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute("href", url);
            link.setAttribute("download", item.originalFileName);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const handleViewErrors = async (item) => {
        if (item.errors) {
            setErrors(JSON.parse(item.errors.replace(/'/g, "")))
            setUFData(item);
            setOpenErr(true)
        }
    }

    const handleAutomate = async (item) => {
        const modelData = {};
        modelData["Name"] = `${item.displayName}${uploadType !== "cloudConsumption" ? '' : ' - ' + _.find(JSON.parse(item?.mappingFields), { master: 'FOCUS Mapping' })?.code}`
        modelData["DestinationTable"] = item.destinationTable
        modelData["FileType"] = item.fileType
        modelData["MappingFields"] = item.mappingFields
        modelData["DefaultFields"] = "[]"
        await DataStoreInterfaceHandler(modelData, showAlert, showPrompt, showSnackbar, showCustomForm, true, getInterfaceList)
    }

    // const getPresignedUrl = async (fId) => {
    //     return await fetchRequest.get(`/api/blob/presignedPost/${fId}?reload=${true}`);
    // }

    // const checkFileUploadStatus = async (fileId) => {
    //     const [error, data] = await fetchRequest.get(`/api/dataload/uploadstatus/${fileId}`);
    //     if (error) {
    //         console.error("An error occured while fetching upload status");
    //         console.error(error);
    //     }
    //     return data;
    // }

    const handleRelod = async (item) => {
        showPrompt("Reload", "All upload rules will reapply, which may change usage data. Do you wish to proceed?", async () => {
            setReloadId(draft => { draft.push(item.id) })
            // var selectedFile = null;
            // var [err, data] = await fetchRequest.get(`/api/blob/presignedGet/${item["id"]}?reload=${true}`)
            // if (err)
            //     console.log(err);
            // if (data) {
            //     const response = await Axios.get(data, { responseType: "blob" });
            //     selectedFile = response.data
            // }

            var uf = {}
            uf.originalFileName = item.originalFileName
            uf.fileName = item.fileName
            uf.mappingFields = item.mappingFields
            uf.yearNameId = item.yearNameId
            uf.monthNameId = item.monthNameId
            uf.totalRecords = item.totalRecords
            uf.destinationTable = item.destinationTable
            uf.uploadSubType = item.destinationTable
            const requestId = generateUUID();
            const notification = ({ requestId, type: NotificationType.ReloadFile, message: ` File ${item.originalFileName} is reloading`, status: NotificationStatus.Processing, persist: true });
            addNotification(notification);

            const [resError, response] = await fetchRequest.post(`/api/dataload/createupload?reload=${true}`, uf);
            if (resError) {
                addNotification({
                    type: NotificationType.Error,
                    message: "Error in processing file",
                    status: NotificationStatus.Error,
                    persist: true
                });
                console.error("An error occured while reloading file");
                console.error(resError);
                setError(true);
                return false;
            }

            // const [presignedUrlError, presignedUrlResponse] = await getPresignedUrl(response.id);
            // if (presignedUrlError) {
            //     console.error("An error occured while getting presigned url");
            //     console.error(presignedUrlError);
            //     return false;
            // }

            // const options = new URL(presignedUrlResponse).host.indexOf("blob.core.windows.net") !== -1 ? {
            //     headers: {
            //         'X-Ms-Blob-Type': 'BlockBlob',
            //         'X-Ms-Version': '2023-11-03'
            //     }
            // } : null;
            // const [putError,] = await fetchRequest.put(presignedUrlResponse, selectedFile, options);
            // if (putError) {
            //     console.error("An error occured while uploading to blob store");
            //     console.error(putError);
            //     return false;
            // }

            const [relodError, reloadResponse] = await fetchRequest.post(`/api/dataload/uploaded`, { fileId: response.id, reload: true, requestId: requestId, NotificationType: NotificationType.ReloadFile, reloadId: item.id });
            if (relodError) {
                console.error("An error occured while updating file status");
                console.error(relodError);
                return false;
            }
            else if (reloadResponse?.result === false) {
                console.error("An error occured while updating file status");
                return false;
            }
            // timer.current = setInterval(async () => {
            //     const uploadFile = await checkFileUploadStatus(response.id);
            //     const uploadStatus = uploadFile?.fileStatus.toLowerCase();
            //     if (["loaded", "error", "partial"].includes(uploadStatus)) {
            //         clearInterval(timer.current);
            //         showSnackbar("File reloaded successfully", "success");
            //         setReloadId( draft => {
            //             return draft.filter( i => i !== item.id)
            //         })
            //     }   
            // }, 2000)
        })
    }

    let colorSet = (item) => {
        let color
        let sourceDataCheck = interfaceList.find(obj => {
            if (item.destinationTable === "cloudConsumption") {
                const parsedFields = JSON.parse(item.mappingFields)
                const code = parsedFields.filter(({ master }) => master === "FOCUS Mapping")[0].code
                if (obj.name === `${item.displayName} - ${code}`)
                    return obj
            } else {
                if (obj.name === item.displayName)
                    return obj
            }
        })
        sourceDataCheck ? color = "info" : color = ""
        return color
    }
    const buildColumns = (onDownload, onDelete, onViewErrors) => {
        return [
            // { Header: "File Type", accessor: "fileType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            {
                Header: "Actions",
                accessor: "id",
                align: "left",
                disableSorting: true,
                disableFilters: true,
                maxWidth: 150,
                Cell: ({ row }) => {
                    return (
                        <MDBox display="flex" alignItems="left" mt={{ xs: 2, sm: 0 }}>
                            <Tooltip title="Delete" placement="top">
                                <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(239, 83, 80, 0.2)' } }} disabled={((chargeBack || published) && uploadCategory === 'Cloud Consumption')} onClick={() => onDelete(row.original)}><Icon color={((chargeBack || published) && uploadCategory === 'Cloud Consumption') ? "disabled" : "error"} fontSize='small'>delete</Icon></IconButton>
                            </Tooltip>
                            <Tooltip title="Download" placement="top">
                                <IconButton sx={{ padding: 0.3 }} onClick={() => onDownload(row.original)} >
                                    <Icon fontSize='small'>download</Icon>
                                </IconButton>
                            </Tooltip>
                            {(reloadId.includes(row.original.id) ?
                                <CircularProgress color="info" size={20} /> :
                                <Tooltip title="Reload" placement="top">
                                    <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(25, 118, 210, 0.2)' } }} disabled={((chargeBack || published) && uploadCategory === 'Cloud Consumption') || !row.original.reload || row.original.fileStatus === 'Load'} onClick={() => handleRelod(row.original)} color="info">
                                        <Icon fontSize='small'>refresh</Icon>
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Automate" placement="top">
                                <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(102, 187, 106, 0.2)' } }} onClick={() => handleAutomate(row.original)} color={colorSet(row.original)}><Icon fontSize='small'>bolt</Icon></IconButton>
                            </Tooltip>
                        </MDBox>
                    );
                }
            },
            { Header: "Type", accessor: "displayName", dataType: "textbox", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark"><b>{value}</b></MDTypography> } },
            { Header: "File Name", accessor: "originalFileName", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "File Status", accessor: "fileStatus", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Loaded Records", accessor: "loadedRecords", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Total Records", accessor: "totalRecords", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            {
                Header: "Errors", accessor: "errors", disableFilters: true, Cell: ({ cell: { row: { original } } }) => {
                    return original.errors !== '[]' &&
                        original?.errors !== '' &&
                        original?.errors !== '{}' &&
                        original?.errors !== null
                        ? <IconButton sx={{ padding: 0, paddingLeft: '8px' }} onClick={() => onViewErrors(original)}><Icon color="error">error</Icon></IconButton>
                        : ''
                }, "type": "showonvalue"
            },
            { Header: "Mapping fields", accessor: "mappingFields", disableFilters: true, Cell: ({ row }) => { return <IconButton sx={{ padding: 0, paddingLeft: '8px' }} onClick={() => { viewMapping(row.values, row.original.def) }}><Icon color="info">info</Icon></IconButton> } },
            { Header: "Uploaded On", accessor: "createdAt", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
            { Header: "Uploaded By", accessor: "createdByUser__name", disableFilters: true, dataType: "textbox", Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography> } } },
            // { Header: "Automate", disableSorting: true, accessor: "", Cell: ({ row }) => { return <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(102, 187, 106, 0.2)' } }} onClick={() => handleAutomate(row.original)} color={colorSet(row.original)}><Icon>bolt</Icon></IconButton> } },
            // { Header: "Reload", disableSorting: true, accessor: "", Cell: ({ row }) => { return (reloadId === row.original.id ? <CircularProgress color="info" size={20} /> : <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(25, 118, 210, 0.2)' } }} disabled={((chargeBack || published) && uploadCategory === 'Cloud Consumption') || !row.original.reload || row.original.destinationTable === "mobileConsumption"} onClick={() => handleRelod(row.original)} color="info"><Icon>refresh</Icon></IconButton> )} },
            // { Header: "Download", disableSorting: true, accessor: "", disableFilters: true, Cell: ({ cell: { row: { original } } }) => { return <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(66, 165, 245, 0.2)' } }} onClick={() => onDownload(original)}><Icon>download</Icon></IconButton> } },
            // { Header: "Delete", disableSorting: true, accessor: "id", disableFilters: true, Cell: ({ row: { original } }) => { return <IconButton sx={{ padding: 0.3, ":hover": { bgcolor: 'rgba(239, 83, 80, 0.2)' } }} disabled={((chargeBack || published) && uploadCategory === 'Cloud Consumption')} onClick={() => onDelete(original)}><Icon color={((chargeBack || published) && uploadCategory === 'Cloud Consumption') ? "disabled" : "error"}>delete</Icon></IconButton> } },
        ];
    }

    const deleteConsumption = () => {
        showPrompt("Delete", `Are you sure you want to delete all ${uploadCategory.toLowerCase()} data for - [${monthFilterName} ${yearFilterName}]`, async (checked) => {
            setConsumptionDelete(true)
            var [err, data] = await fetchRequest.delete(`/api/dataflow/${uploadCategory.toLowerCase().replace(' ', '')}/${yearFilter}/${monthFilter}?checked=${checked}`)
            if (err) {
                if (err.data?.message) {
                    showSnackbar(err.data?.message, "error")
                    reloadData()
                } else {
                    console.error(err)
                    setConsumptionDelete(false)
                    showAlert("Delete", "Something went wrong. Contact your administrator.");
                }
            }
            else if (data) {
                showSnackbar(data, "success")
                setConsumptionDelete(false)
                reloadData()
            }
        }, () => setConsumptionDelete(false), null, null, null, { label: "also delete uploaded files" }, "md")
    }

    const columns = buildColumns(handleDownload, handleDelete, handleViewErrors);

    const handleUploadDialogClose = (uploadSuccess) => {
        if (uploadSuccess)
            reloadData();
        setAct(false)
    };

    const renderAddButton = () => (
        <>
            <MDButton disabled={((chargeBack || published) && uploadCategory === 'Cloud Consumption')} variant="gradient" color="info" startIcon={<Icon>{((chargeBack || published) && uploadCategory === 'Cloud Consumption') ? 'lock' : 'cloud_upload'}</Icon>} onClick={() => { setAct(true) }}>
                Upload
            </MDButton>
        </>
    )

    if (loading) {
        return <YASkeleton variant="dashboard-loading" />;
    }
    if (_err) {
        console.error(_err)
    }

    rows?.map((item) => {
        item["createdByUser__name"] = item["createdByUser.name"];
    })

    if (rulesLoading) {
        return <YASkeleton variant="dashboard-loading" />;
    }

    if (rulesLoading === false && levels === null) {
        return (
            <div>
                no data
            </div>
        );
    }
    const actionComponent = () => (
        <>
            <MDBox display="flex">
                {(chargeBack || published) ? null :
                    <MDButton disabled={consumptionDelete} data-testid={"delete"} variant="gradient" color="info" startIcon={consumptionDelete ? <CircularProgress color="white" size={15} /> : <Icon>{'delete'}</Icon>} onClick={deleteConsumption} sx={{ marginLeft: "5px" }}>{`Delete ${uploadCategory === 'Cloud Consumption' ? 'cloud' : 'mobile'} Data`}</MDButton>
                }
                {(chargeBack || published) && uploadCategory === 'Cloud Consumption' && <Alert sx={{ fontSize: 13 }} severity="info"> {chargeBack || (published && uploadCategory === 'Cloud Consumption') ? chargeBack ? "Chargeback Created" : "Cloud Spend Published" : null}</Alert>}
                <MDButton disabled={((chargeBack || published) && uploadCategory === 'Cloud Consumption')} data-testid={"upload"} variant="gradient" color="info" startIcon={<Icon>{((chargeBack || published) && uploadCategory === 'Cloud Consumption') ? 'lock' : 'cloud_upload'}</Icon>} onClick={() => { setAct(true) }} sx={{ marginLeft: "5px" }}>
                    Upload
                </MDButton>
            </MDBox>
        </>
    )

    return (
        <>

            {/* <PageHeader
                title={"Usage Data"}
                subtitle={`Manage your ${uploadCategory.toLowerCase()} data`}
                primaryActionComponent={actionComponent}
                settingsHeaderFormat={true}
            /> */}
            {act && <DataloadDialog title={`Upload for ${monthFilterName}, ${yearFilterName}`} uploadConfig={uploadConfig} onClose={handleUploadDialogClose} />}
            <MDBox p={3} pt={1}>
                {
                    (!loading || (rows.length === 0 && search && filtersState)) &&

                    <Card sx={{ height: "100%" }} px={0}>
                        <ServeSideDataTable
                            containerMaxHeight={containerHeight ?? 500}
                            primaryRender={actionComponent()}
                            yearFilter={yearFilter}
                            monthFilter={monthFilter}
                            table={{ columns, rows }}
                            showTotalEntries={true}
                            isSorted={true}
                            newStyle1={true}
                            noEndBorder
                            entriesPerPage={true}
                            canSearch={true}
                            canFilter={true}
                            loading={loading}
                            filtersState={filtersState}
                            onFiltersStateUpdate={handleOnFiltersStateUpdate}
                            serverSideDataPaging={serverSideDataPaging}
                            serverSideFetching={serverSideDataPaging}
                            schemaName={'uploadedFile'}
                            lookUpList={lookUpList}
                            setLookUpList={setLookUpList}
                            pgIndx={pgIndx}
                            setPgIndx={setPgIndx}
                            onPageOptionsChange={onPageOptionsChange}
                            pageSizeVal={pageSize}
                            totalNoOfPages={totalNoOfPages}
                            totalItems={totalItems}
                            setSrch={setSearch}
                            srch={search}
                            handlePageOption={handlePageOption}
                            filterDef={filterDef}
                            setFilterDef={setFilterDef}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </Card>

                }
                {
                    (!loading && rows?.length === 0 && !search && !filtersState) && (
                        <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
                            <EmptyState
                                yearFilter={yearFilter}
                                monthFilter={monthFilter}
                                size="large"
                                image={new_item_img}
                                title={"No Dataloads Yet"}
                                description={"Click on the 'upload' button to start a new dataload."}
                                actions={renderAddButton}
                            />
                        </MDBox>
                    )
                }
            </MDBox>
            {
                (yearFilter && monthFilter) && openErr && <DataloadErrorsDialog info={ufData} rows={errors} onErrDialogClose={handleErrDialogClose} />
            }
            {
                openMf && <DataloadMfDialog info={ufData} onMfDialogClose={handleMfDialogClose} ufDef={ufDef}/> 
            }
        </>
    );
};

export default AnimatedRoute(Consumption);