import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import numeral from "numeral";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import { useAppController } from "context";
import { formatCurrencyNumeral } from "utils";


const BusinessCaseHeader = (props) => {
    const { headerDetails } = props;
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;

    return (
        <>
            <MDBox display="flex" flexDirection="row">
                <MDBox display="flex" flexDirection="column" maxWidth='15%'>
                    <Tooltip title={headerDetails["name"]}>
                        <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2} sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 2,
                        }}>{headerDetails["name"]}</MDTypography>
                    </Tooltip>
                    <MDTypography component="span" variant="subtitle2">Name</MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad" maxWidth='15%'>
                    <Tooltip title={headerDetails['description']}>
                        <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2} sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 2,
                        }}>{headerDetails['description']}</MDTypography>
                    </Tooltip>
                    <MDTypography component="span" variant="subtitle2">Description</MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                    <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["statusName"]}</MDTypography>
                    <MDTypography component="span" variant="subtitle2">Status</MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                    <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails['startDate'] ? moment(headerDetails['startDate']).format("MMM DD YYYY") : ""}</MDTypography>
                    <MDTypography component="span" variant="subtitle2">Start Date</MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                    <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails['endDate'] ? moment(headerDetails['endDate']).format("MMM DD YYYY") : ""}</MDTypography>
                    <MDTypography component="span" variant="subtitle2">End Date</MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                    <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["solutionOfferingName"]}</MDTypography>
                    <MDTypography component="span" variant="subtitle2">Solution Offering</MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                    <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{formatCurrencyNumeral(headerDetails['totalAmount'], systemCurrencyDetails)}</MDTypography>
                    <MDTypography component="span" variant="subtitle2">Total Estimate</MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="column">
                <MDTypography mt={2} component="span" fontWeight={"medium"}>Migration Strategy: <MDTypography component="span" variant="subtitle2">{headerDetails['rStrategy']}</MDTypography></MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column">
                <MDTypography mt={1} component="span" fontWeight={"medium"}>Objective: <MDTypography component="span" variant="subtitle2">{headerDetails['objective']}</MDTypography></MDTypography>
            </MDBox>
        </>
    )
};

export default BusinessCaseHeader;