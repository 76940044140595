import _ from "lodash";
import moment from "moment";
import { monthsArray } from "utils/budget";
// import fetchRequest from "utils/fetchRequest";

export const financialYearStartEndMonthRanges = {
    "Jan": ["00", "11"],
    "Feb": ["01", "00"],
    "Mar": ["02", "01"],
    "Apr": ["03", "02"],
    "May": ["04", "03"],
    "Jun": ["05", "04"],
    "Jul": ["06", "05"],
    "Aug": ["07", "06"],
    "Sep": ["08", "07"],
    "Oct": ["09", "08"],
    "Nov": ["10", "09"],
    "Dec": ["11", "10"]
};

const getCurrYearStartDateRange = (year, startMonth) => {
    const startEndMonthRange = financialYearStartEndMonthRanges[startMonth];
    return moment(new Date(year, Number(startEndMonthRange[0]), 1)).format("YYYY-MM-DD");
}

const getCurrYearEndDateRange = (year, startMonth) => {
    const startEndMonthRange = financialYearStartEndMonthRanges[startMonth];
    if (startMonth === "Jan")
        return moment(new Date(year, Number(startEndMonthRange[1]) + 1, 0)).format("YYYY-MM-DD");
    return moment(new Date(year + 1, Number(startEndMonthRange[1]) + 1, 0)).format("YYYY-MM-DD");
}

const getPrevYearStartDateRange = (year, startMonth) => {
    const startEndMonthRange = financialYearStartEndMonthRanges[startMonth];
    return moment(new Date(year - 1, Number(startEndMonthRange[0]), 1)).format("YYYY-MM-DD");
}

const getPrevYearEndDateRange = (year, startMonth) => {
    const startEndMonthRange = financialYearStartEndMonthRanges[startMonth];
    if (startMonth === "Jan")
        return moment(new Date(year - 1, Number(startEndMonthRange[1]) + 1, 0)).format("YYYY-MM-DD");
    return moment(new Date(year, Number(startEndMonthRange[1]) + 1, 0)).format("YYYY-MM-DD");
}

const compareDateRangeTemplate = [
    "CURR_YEAR",
    "PREV_YEAR"
];

export const yearNameFormats = {
    "fullYear": (yearStr) => yearStr,
    "fyFullYear": (yearStr) => yearStr.substring(2),
    "fromTo": (yearStr) => yearStr.substring(0, 4),
    "fyEndFullYear": (yearStr) => { return String(Number(yearStr.substring(2)) - 1) },
}

const getYearFilterValue = (filters, financialYearNameFormat) => {
    const val = filters.find(filter => (filter.queryName === "Years.year" || filter.queryName === "YearsDuck.year"))?.values;
    if (val && Array.isArray(val)) {
        return yearNameFormats[financialYearNameFormat](val[0]);
    }
    return null;
}

export const yearFinancialNameFormats = {
    "fullYear": (yearStr) => yearStr,
    "fyFullYear": (yearStr) => `FY${yearStr}`,
    "fromTo": (yearStr) => `${yearStr}-${String(Number(yearStr) + 1).substring(2)}`,
    "fyEndFullYear": (yearStr) => `FY${String(Number(yearStr) + 1)}`
}

export const getFinancialYearName = (val, financialYearNameFormat = "fullYear") => {
    if (!val)
        return '';
    return yearFinancialNameFormats[financialYearNameFormat](val);
}

const getCurrentFinancialYear = (val, startMonth = "Jan") => {
    const startEndMonthRange = financialYearStartEndMonthRanges[startMonth];
    return new Date().getMonth() >= Number(startEndMonthRange[0]) ? Number(val) : Number(val) - 1;
}

const getCurrentFinancialYearName = (val, startMonth, financialYearNameFormat = "fullYear") => {
    if (!val)
        return '';
    return yearFinancialNameFormats[financialYearNameFormat](getCurrentFinancialYear(val, startMonth));
}

const getPrevFinancialYearName = (val, startMonth, financialYearNameFormat = "fullYear") => {
    if (!val)
        return '';
    return yearFinancialNameFormats[financialYearNameFormat](getCurrentFinancialYear(val, startMonth) - 1);
}

const getPrevYearsDateRange = (rangeCount, startMonth) => {
    let fromDate, endDate;
    const year = new Date().getFullYear();
    const startEndMonthRange = financialYearStartEndMonthRanges[startMonth];
    if (startMonth === "Jan")
        fromDate = moment(new Date(year - rangeCount, Number(startEndMonthRange[0]), 1)).format("YYYY-MM-DD");
    else
        fromDate = moment(new Date(year - (rangeCount + 1), Number(startEndMonthRange[0]), 1)).format("YYYY-MM-DD");
    endDate = moment(new Date(year - 1, Number(startEndMonthRange[1]) + 1, 0)).format("YYYY-MM-DD");
    return [fromDate, endDate];
}

const getNextYearsDateRange = (rangeCount, startMonth) => {
    let fromDate, endDate;
    const year = new Date().getFullYear();
    const startEndMonthRange = financialYearStartEndMonthRanges[startMonth];
    if (startMonth === "Jan")
        fromDate = moment(new Date(year + 1, Number(startEndMonthRange[0]), 1)).format("YYYY-MM-DD");
    else
        fromDate = moment(new Date(year + 1, Number(startEndMonthRange[0]), 1)).format("YYYY-MM-DD");
    endDate = moment(new Date(year + rangeCount, Number(startEndMonthRange[1]) + 1, 0)).format("YYYY-MM-DD");
    return [fromDate, endDate];
}

function getYtdRange(startDateStr, month) {
    const startDate = new Date(startDateStr);
    if (isNaN(startDate.getTime())) {
      return "Invalid date format";
    }
  
    const result = {};
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    for (let i = 1; i <= 11; i++) {
      const nextMonth = new Date(startDate);
      nextMonth.setMonth(startDate.getMonth() + i);
  
      const monthName = months[nextMonth.getMonth()];
      const year = nextMonth.getFullYear();
      result[monthName] = moment(`${year}-${("0" + (nextMonth.getMonth() + 1)).slice(-2)}-01`).endOf('month').format("YYYY-MM-DD");
    }
  
    return result[month];
  }

export const getTimlineDateRangeByType = (rangeType, rangeCount, startMonth = "Jan") => {
    let fromDate, endDate;

    if (rangeType === 'nLastMonths') {
        fromDate = moment().subtract(rangeCount, 'months').endOf('month').format("YYYY-MM-01");
        endDate = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
    } else if (rangeType === 'nLastMonthsIncCurrent') {
        fromDate = moment().subtract(rangeCount, 'months').endOf('month').format("YYYY-MM-01");
        endDate = moment().endOf('month').format("YYYY-MM-DD");
    } else if (rangeType === 'nLastYears') {
        return getPrevYearsDateRange(rangeCount, startMonth);
    } else if (rangeType === 'nLastYearsIncCurrent') {
        fromDate = getPrevYearsDateRange(rangeCount, startMonth)[0];
        endDate = moment().endOf('month').format("YYYY-MM-DD");
    } else if (rangeType === 'nNextMonths') {
        fromDate = moment().add(1, 'months').endOf('month').format("YYYY-MM-01");
        endDate = moment().add(rangeCount, 'months').endOf('month').format("YYYY-MM-DD");
    } else if (rangeType === 'nNextMonthsIncCurrent') {
        fromDate = moment().format("YYYY-MM-01");
        endDate = moment().add(rangeCount, 'months').endOf('month').format("YYYY-MM-DD");
    } else if (rangeType === 'nNextYears') {
        return getNextYearsDateRange(rangeCount, startMonth);
    } else if (rangeType === 'nNextYearsIncCurrent') {
        fromDate = moment().format("YYYY-MM-01");
        endDate = getNextYearsDateRange(rangeCount, startMonth)[1];
    } else if (rangeType === 'ytd') {
        fromDate = getPrevYearsDateRange(0, startMonth)[0];
        endDate = getYtdRange(fromDate, moment().format("MMM"));
    } else if (rangeType === 'prevYtd') {
        fromDate = getPrevYearsDateRange(1, startMonth)[0];
        endDate = getYtdRange(fromDate, moment().format("MMM"));
    } else {
        throw new Error("rangeType must be 'month' or 'year'");
    }

    return [fromDate, endDate];
}

const quartersArray = { 1: "Q1", 2: "Q1", 3: "Q1", 4: "Q2", 5: "Q2", 6: "Q2", 7: "Q3", 8: "Q3", 9: "Q3", 10: "Q4", 11: "Q4", 12: "Q4" };

export const getMonthsQuarters = (financialYearStartMonth = 'Jan') => {
    let quarters = {};
    const monthIndex = monthsArray.indexOf(financialYearStartMonth)
    let index = 1;
    monthsArray.forEach((m, i) => {
        if (i >= monthIndex) {
            quarters[m] = quartersArray[index];
            index++;
        }
    });
    monthsArray.forEach((m, i) => {
        if (i < monthIndex) {
            quarters[m] = quartersArray[index];
            index++;
        }
    });

    return quarters;
};

const preQuartersMap = { "Q2": "Q1", "Q3": "Q2", "Q4": "Q3", "Q1": "Q4" };
export const getDefaultReportVariables = (financialYearNameFormat, financialYearStartMonth = 'Jan') => {
    const quarters = getMonthsQuarters(financialYearStartMonth);
    const currentMonth = monthsArray[new Date().getMonth()];
    const previousMonth = monthsArray[new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1];
    const currentQuarter = quarters[currentMonth];
    return ({
        firstMonth: financialYearStartMonth,
        currentYear: getCurrentFinancialYearName(new Date().getFullYear(), financialYearStartMonth, financialYearNameFormat),
        previousYear: getPrevFinancialYearName(new Date().getFullYear(), financialYearStartMonth, financialYearNameFormat),
        currentMonth,
        previousMonth,
        currentQuarter,
        previousQuarter: preQuartersMap[currentQuarter]
    });
};

export const setTimeDimensions = (vizState, vizOptions, defaultSelectedFilters, appVariables) => {
    const financialYearStartMonth = (appVariables || {})["firstMonth"] || "Jan";
    const financialYearNameFormat = (appVariables || {})["yearNameFormat"] || "fullYear";
    const queryType = vizOptions.queryType;
    let query = vizState.query;

    let duckCube = ""
    if (vizOptions.duck) duckCube = "Duck"
    let currentYear = 1900;
    let compareDateRange = [];
    const yearFilterValue = getYearFilterValue(defaultSelectedFilters, financialYearNameFormat);
    if (yearFilterValue) {
        currentYear = Number(yearFilterValue);
    }
    if (["CompareWithPrevYear", "CompareWithPrevYearTrend", "CompareWithPrevYearTrendFiltered"].includes(queryType)) {
        compareDateRange = compareDateRangeTemplate.map(r => {
            if (r === "CURR_YEAR") {
                return [getCurrYearStartDateRange(currentYear, financialYearStartMonth), getCurrYearEndDateRange(currentYear, financialYearStartMonth)]
            }
            else if (r === "PREV_YEAR") {
                return [getPrevYearStartDateRange(currentYear, financialYearStartMonth), getPrevYearEndDateRange(currentYear, financialYearStartMonth)]
            }
            return r;
        })
    }
    else {
        compareDateRange = [[getCurrYearStartDateRange(currentYear, financialYearStartMonth), getCurrYearEndDateRange(currentYear, financialYearStartMonth)]];
    }
    if (query.timeDimensions?.length > 0) {
        query.timeDimensions[0]["compareDateRange"] = compareDateRange;
        query.timeDimensions[0]["granularity"] = vizOptions.granularity || 'month';
        if (query.timeDimensions[0]["granularity"] === "month" && !(query.dimensions || []).includes(`Months${duckCube}.month`))
            query.dimensions = [...(query.dimensions || []), `Months${duckCube}.month`];
        // if (!(query.dimensions || []).includes("Years.year"))
        //     query.dimensions = [...(query.dimensions || []), "Years.year"];
    }

}

export const isTimeDimensionQuery = (query) => {
    const timeDimensions = Array.isArray(query) ? query[0].timeDimensions : query.timeDimensions;
    return timeDimensions && timeDimensions.length > 0;
    //return ["CompareWithPrevYear", "YTD", "CompareWithPrevYearTrend", "CompareWithPrevYearTrendByDimension"].includes(queryType)
}

const ignoreFilter = (widget, filterName) => {
    let duckCube = ""
    if (widget.vizOptions.duck) duckCube = "Duck"
    if (isTimeDimensionQuery(widget.vizState.query)) {
        const granularity = widget.vizOptions.granularity || 'month';
        if (widget.vizOptions.queryType === "CompareWithPrevYearTrendFiltered")
            return [`Years${duckCube}.year`].includes(filterName);
        else {
            if (granularity === 'year' && [`Years${duckCube}.year`].includes(filterName))
                return true;
            else if (granularity === 'month' && [`Years${duckCube}.year`, `Months${duckCube}.month`].includes(filterName))
                return true;
            else
                return false;
        }
    }
    return (widget.vizState.ignoreFiltersForAutoset || []).length > 0 && widget.vizState.ignoreFiltersForAutoset.includes(filterName);
}

const applyDefaultFilter1ForQuery = (filter, query) => {
    if (!query["filters"])
        query["filters"] = []
    let fils = []
    let found = false
    query["filters"].map((f) => {
        if (f.member === filter.queryName) {
            filter.values = filter.session ?
                sessionStorage[filter.defaultValue[0]]?.split(",") :
                filter.defaultValue;

            found = true
            return fils.push({ ...f })
        } else {
            return fils.push({ ...f })
        }
    })
    if (!found) {
        fils.push({
            "member": filter.queryName,
            "operator": "equals",
            "values": filter.session ?
                sessionStorage[filter.defaultValue[0]]?.split(",") :
                filter.defaultValue
        });
    } query["filters"] = fils

}

const applyDefaultFilterForCubeOptions = (filter, cubeOptions) => {
    if(cubeOptions?.cubeId) {
        if (!cubeOptions.vizData["filters"])
            cubeOptions.vizData["filters"] = []

        const cubeOptionsFilter = cubeOptions.vizData.filters.find(f => f.name === filter.queryName);
        if (!cubeOptionsFilter) {
            cubeOptions.vizData.filters.push({
                type: "DIMENSION",
                name: filter.queryName,
                title: filter.name,
                dataType: "string",
                operator: "equals",
                values: filter.values || []
            });
        }
        else
            cubeOptionsFilter.values = filter.values || [];
    }
}

export const applyDefaultFilters = (def) => {
    if (!def["filters"])
        def["filters"] = []
    def.filters.forEach((filter) => {
        if (filter.defaultValue && filter.defaultValue.length > 0) {
            filter.defaultSelected = true;
            filter.values = filter.session ?
                sessionStorage[filter.defaultValue[0]]?.split(",") :
                filter.defaultValue;

            def.widgets.forEach((widget) => {
                const ignoreFiltersForAutosetVal = ignoreFilter(widget, filter.queryName);
                if (widget.vizState.query.length == undefined) {
                    if (!ignoreFiltersForAutosetVal) {
                        applyDefaultFilter1ForQuery(filter, widget.vizState.query)
                        if (widget.cubeOptions)
                            applyDefaultFilterForCubeOptions(filter, widget.cubeOptions);
                    }
                }
                else {
                    let i = 0;
                    widget.vizState.query.forEach((query) => {
                        if (!(widget.vizOptions.queryType === "allCategories" && filter.queryName === widget.vizOptions.category && i == 0)) {
                            if (!ignoreFiltersForAutosetVal) {
                                applyDefaultFilter1ForQuery(filter, query)
                                if (widget.cubeOptions)
                                    applyDefaultFilterForCubeOptions(filter, widget.cubeOptions);
                            }
                        }
                        i++;
                    })
                }
            })
        }
    })
    // apply time dimesions config
    const defaultSelectedFilters = def.filters.filter(f => f.defaultSelected);
    def.widgets.filter(({ vizState }) => isTimeDimensionQuery(vizState.query))?.forEach(({ vizState, vizOptions }) => {
        setTimeDimensions(vizState, vizOptions, defaultSelectedFilters, def.variables);
    });

    return def
}

const setSelectedFilter1ForQuery = (query, member, operator, val) => {
    let selectedFilter = query["filters"]?.find(f => f.member === member);
    if (selectedFilter) {
        if (val) selectedFilter.operator = "equals"
        selectedFilter.values = val;
    }
    else {
        query["filters"].push({
            "member": member,
            "operator": operator,
            "values": val
        });
    }
}

export const setSelectedFilter = (def, val, name, member, operator) => {
    def.filters.map((filter) => {
        if (filter.name === name) {
            if (filter.session)
                sessionStorage[filter.name] = val;
            filter.values = val;
        }
    })
    def.widgets.map((widget) => {
        const ignoreFiltersForAutosetVal = ignoreFilter(widget, member);
        if (widget.vizState.query.length == undefined) {
            if (!ignoreFiltersForAutosetVal) {
                setSelectedFilter1ForQuery(widget.vizState.query, member, operator, val)
                if (widget.cubeOptions)
                    applyDefaultFilterForCubeOptions({ queryName: member, name, values: val }, widget.cubeOptions);
            }
        }
        else {
            let i = 0;
            widget.vizState.query.forEach((query) => {
                if (!(widget.vizOptions.queryType === "allCategories" && member === widget.vizOptions.category && i == 0)) {
                    if (!ignoreFiltersForAutosetVal)
                        setSelectedFilter1ForQuery(query, member, operator, val)
                }
                i++;
            })
        }
    })
    // apply time dimesions config
    if ((member === "Years.year") || (member === "YearsDuck.year")) {
        const selectedFilters = def.filters.filter(f => f.queryName === member);
        def.widgets.filter(({ vizState }) => isTimeDimensionQuery(vizState.query))?.forEach(({ vizState, vizOptions }) => {
            setTimeDimensions(vizState, vizOptions, selectedFilters, def.variables);
        });
    }
    return def;
}

const deleteSelectedFilter1ForQuery = (filter, query) => {
    const newVizFilters = query["filters"]?.filter(f => filter.query.dimensions[0] !== f.member);
    query["filters"] = newVizFilters;
}

export const deleteSelectedFilter = (def, deletedFilter) => {
    let filter = def.filters?.find(f => f.name === deletedFilter.name);
    if (filter) {
        filter.values = []
        filter.selected = false;
        filter.defaultSelected = false;
    }
    def.widgets.forEach((widget) => {
        if (widget.vizState.query.length == undefined) {
            deleteSelectedFilter1ForQuery(filter, widget.vizState.query)
        }
        else {
            let i = 0;
            widget.vizState.query.forEach((query) => {
                if (!(widget.vizOptions.queryType === "allCategories" && deletedFilter.name === widget.vizOptions.category && i == 0)) {
                    deleteSelectedFilter1ForQuery(filter, query)
                }
                i++;
            })
        }
    })

    return def
}

export const setDrilldownFilters = (def, passedFilters, isDashboard) => {
    if (passedFilters && passedFilters.length > 0) {
        let pfil = []   
        passedFilters.forEach((fil) => {
            if (fil.operator !== "set")
                pfil.push(fil)
        })
        passedFilters = [...pfil]
        def.filters.forEach((filter) => {
            passedFilters.forEach((fil) => {
                if (filter.queryName === fil.name) {
                    filter.values = fil.values;
                    if (fil.values.length)
                        filter.operator = "equals"
                    filter.defaultSelected = !isDashboard ? true : false;
                    isDashboard ? filter.selected = true : '';
                    isDashboard ? filter.passed = true : '';
                }
            })
        })
        def.widgets.forEach((widget) => {
            if (widget.vizState.query.length == undefined) {
                if (!widget.vizState.query["filters"])
                    widget.vizState.query["filters"] = []
                widget.vizState.query["filters"].forEach((f) => {
                    let passedFil = _.find(passedFilters, { name: f.member })
                    if (passedFil && !ignoreFilter(widget, f.member)) {
                        f.values = passedFil.values
                        if (f.values.length)
                            f.operator = "equals"
                        if (widget.cubeOptions) {
                            const fieldName = def.filters?.find(fl => fl.queryName === f.member)?.name;
                            if (fieldName)
                                applyDefaultFilterForCubeOptions({ queryName: f.member, name: fieldName, values: passedFil.values }, widget.cubeOptions);
                        }
                    }
                })
            }
            // "operator": fil.name.indexOf('vendorId') > 0 ? "set" : "equals",
            passedFilters.forEach((fil) => {
                if (widget.vizState.query.length == undefined) {
                    if (!_.find(widget.vizState.query["filters"], { member: fil.name })) {
                        let qryDim = widget.vizState.query["measures"][0]?.substring(0, widget.vizState.query["measures"][0]?.indexOf('.'))
                        if (!ignoreFilter(widget, fil.name)) {
                            widget.vizState.query["filters"].push({
                                "member": fil.name.indexOf('vendorId') > 0 ? qryDim + ".vendorId" : fil.name,  // NEED TO VERIFY THIS -SY
                                "operator": fil.operator ? fil.operator : "equals",
                                "values": fil.values
                            })
                            if (widget.cubeOptions) {
                                const fieldName = def.filters?.find(fl => fl.queryName === fil.name)?.name;
                                if (fieldName)
                                    applyDefaultFilterForCubeOptions({ queryName: fil.name, name: fieldName, values: fil.values }, widget.cubeOptions);
                            }
                        }
                    }
                }
                else {
                    setDrilldownFilter1ForMultipleQuery(fil, widget, def)
                }
            })

        })
        // apply time dimesions config
        const defaultSelectedFilters = def.filters.filter(f => f.defaultSelected);
        def.widgets.filter(({ vizState }) => isTimeDimensionQuery(vizState.query))?.forEach(({ vizState, vizOptions }) => {
            setTimeDimensions(vizState, vizOptions, defaultSelectedFilters, def.variables);
        });
    }
    return def
}

const setDrilldownFilter1ForMultipleQuery = (fil, widget, def) => {
    let i = 0;
    widget.vizState.query.forEach((query) => {
        if (!(widget.vizOptions.queryType === "allCategories" && fil.name === widget.vizOptions.category && i == 0)) {
            if (!_.find(query["filters"], { member: fil.name })) {
                let qryDim = query["measures"][0]?.substring(0, query["measures"][0].indexOf('.'))
                if (!ignoreFilter(widget, fil.name))
                    query["filters"].push({
                        "member": fil.name.indexOf('vendorId') > 0 ? qryDim + ".vendorId" : fil.name,
                        "operator": fil.values.length ? "equals" : (fil.operator ? fil.operator : "equals"),
                        "values": fil.values
                    })
                if (widget.cubeOptions) {
                    const fieldName = def.filters?.find(fl => fl.queryName === fil.name)?.name;
                    if (fieldName)
                        applyDefaultFilterForCubeOptions({ queryName: fil.name, name: fieldName, values: fil.values }, widget.cubeOptions);
                }
            }
            else {
                if (!ignoreFilter(widget, fil.name)) {
                    _.find(query["filters"], { member: fil.name }).values = fil.values
                    _.find(query["filters"], { member: fil.name }).operator = fil.values.length ? "equals" : (fil.operator ? fil.operator : "equals")
                }
            }
        }
        else {
            if (!ignoreFilter(widget, fil.name)) {
                if (_.find(query["filters"], { member: fil.name })) {
                    _.find(query["filters"], { member: fil.name }).values = fil.values 
                    _.find(query["filters"], { member: fil.name }).operator = fil.values.length ? "equals" : (fil.operator ? fil.operator : "equals")
                }
            }
        }
        i++;
    })
}

export const setFilter2Query = (def, previousMonth, previousYear) => {
    def?.widgets.forEach(widget => {
        if (widget.vizState.rankVariance) {
            widget.vizState.query.map((query, i) => {
                if (i === 1) {
                    query.filters.map(filter => {
                        if ((filter.member === "Months.month") || (filter.member === "MonthsDuck.month")) {
                            filter.values = previousMonth.length > 0 ? previousMonth : ''
                        }
                        if ((filter.member === "Years.year") || (filter.member === "YearsDuck.year")) {
                            filter.values = previousYear.length > 0 ? previousYear : ''
                        }
                    })
                }
            })
        }
    });
}