import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
// import { useResizeDetector } from 'react-resize-detector';
import colors from "assets/theme/base/cloudForecasts/colors";
import { useNavigate, useLocation } from "react-router-dom";
// import numeral from 'numeral';
import { getDrilldownPath_CF, removeSessionFilter_CF } from 'utils';
import { useYADialog } from "components/YADialog";
import DashboardItem from 'components/DashboardItem_CF';
import _ from 'lodash';
import { parseTableResultset_CF } from 'utils/charts';
import { isTimeDimensionQuery } from 'utils/dashboard';
import { useDashboardContext } from 'components/DashboardContext';
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import fetchRequest from "utils/fetchRequest";
import { useAppController } from "context";
import { formatCurrencyNumeral } from "utils";

// eslint-disable-next-line no-undef
require("highcharts/modules/exporting")(Highcharts);
// eslint-disable-next-line no-undef
require("highcharts/highcharts-more")(Highcharts);
// eslint-disable-next-line no-undef
require("highcharts/modules/solid-gauge")(Highcharts);

function GaugeGraph(props) { 
    const {showReport, currentFilters, vizOptions, chartRef, name, subname, dispname, lable, sublable, ragThresholds, variance, data, setRagAccuracy } = props;
    let thresholddavg =ragThresholds[2]/2
    const maxValue = (Math.ceil((Math.round(ragThresholds[2]+ thresholddavg < 100 ?  (ragThresholds[2]+ thresholddavg) : 100))/10))*10
    let navigate = useNavigate()
    let location = useLocation()
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;

    var value = {}
    value.y = data ? (Math.abs(data) > maxValue ? maxValue : Math.abs(data)) : Math.abs(data);
    var overind = data ? (Math.abs(data) > maxValue ? '+' : '') : '';
    // let ragThresholds = [0,...ragThreshold,100];
    let ind = 3;
    for (var i = 0; i < ragThresholds.length; i++) {
        if (value.y <= ragThresholds[i]) {
            ind = i;
            break;
        }
    }
    if (ind === 0) ind = 1;
    value.color = colors.ragColors[ind-1]
    setRagAccuracy(vizOptions.ragAccurancy[ind-1])
    let fHalf = maxValue/3;
    let sHalf = fHalf*2;
    let tHalf = maxValue; 
    let a = fHalf/ragThresholds[1];
    let b = fHalf/(ragThresholds[2] - ragThresholds[1]);
    let c = fHalf/(maxValue - ragThresholds[2]);
    let sign = false;
    // let asz = ragThresholds[1] - ragThresholds[2]
    //   value.y = 42;
    if(value.y <= ragThresholds[1]) 
     value.position = value.y *a;
    else if(value.y > ragThresholds[1] && value.y <= ragThresholds[2]) {
        value.position = (value.y - ragThresholds[1])*b + fHalf;
    }
    else if(value.y > ragThresholds[2] && value.y <= ragThresholds[3] ) {
        value.position = (value.y - ragThresholds[2])*c + sHalf;
        if(value.position > maxValue) {
            value.position = maxValue;
            sign = true;
            value.end = value.position.toString() + "+";
                
        }
    }
    // if(value.y <= fHalf){
    // value.x = value.y * (fHalf/ragThresholds[1]);
    // // }
   
    // // if(value.y > ragThresholds[1] && value.y < sHalf) {
    //     if(value.x > fHalf && value.x < ragThresholds[2]) {
    // //    value.x = Math.round((Math.abs(sHalf/ragThresholds[2])   *  (value.x - fHalf)) + fHalf);
    //    value.x = (Math.abs(sHalf/ragThresholds[2])   *  (value.x - fHalf)) + fHalf;

    //     }
    //    if(value.x > sHalf) {
    // //    value.x = Math.round((Math.abs(tHalf/ragThresholds[3])   *  (value.x - sHalf)));
    //    value.x = (Math.abs(tHalf/ragThresholds[3])   *  (value.x - sHalf)) + sHalf;  
    //   }
 
    // let di;
    //  value.y = 2.333*5;
    // if(value.y <= fHalf){
    //    di= fHalf/ragThresholds[1];
    //    value['x'] = Math.round(value.y/di);
    // }
    // else if(value.y >fHalf && value.y<=sHalf){
    //     di= sHalf/ragThresholds[2];
    //     value['x'] = Math.round(value.y/di);
    // }
    // value['x'] = Math.round(value.y * 2.3);

    var opts = vizOptions.gaugeSeries ?{
        chart: {
            type: 'gauge',
            height: 200,
            width:  250,
            backgroundColor:colors.chartBackground
        },
        title: '',
        pane: {
            center: ['50%', '65%'],
            size: '140%',
            startAngle: -100,
            endAngle: 100,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '75%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            outside: true,
            distance: 70,
            formatter: function () {
                return '<span style="font-size:12px">' + (lable + lable ? ': <b>' : '')  + name + 
                        '</b><br/>' + sublable + (sublable ? ': <b>' : '') + (dispname ? dispname : 'NOT KNOWN') + 
                        '</b><br/>Actual: <b>' + formatCurrencyNumeral(Number(props.value).toFixed(2), systemCurrencyDetails) + 
                        '</b><br/>' + (vizOptions.compareTo === 'prior' ? 'Prior Year YTD' : vizOptions.compareTo === 'budget' ? 'Budget YTD' : 'Forecast') + ': <b>' + formatCurrencyNumeral(Number(props.priorvalue).toFixed(2), systemCurrencyDetails)  + 
                        '</b><br/>Variance: <b>' + formatCurrencyNumeral(Number(variance).toFixed(2), systemCurrencyDetails) + ' (' + value.y + overind +  '</b>%)</span>'
            }     
        },
        // the value axis
        yAxis: {
            plotBands: [
            //     {
            //     from: 0,
            //     to: tHalf,
            //     color: {
            //                 // linearGradient: { x1: 1, x2: 1, y1: 0, y2: 0 },
            //                 linearGradient: {x:1, y:0},
            //                 stops: [
            //                     [ragThresholds[1]/100, '#6B8E23'],
            //                     [ragThresholds[2]/100, '#e48400'],
            //                     [ragThresholds[3]/100, 'red']
            //                     // [1, '#6B8E23']
            //                 ]
            //             },
            //     thickness: 30.3,
            // }
            {
                from: 0,
                to: fHalf,
                thickness: 30.3,
                color: '#79ce6f',
            
                
            },
            {
                from: fHalf,
                to: sHalf,
                thickness: 30.3,
                color: '#f59150',
              
            },
            {
                from: sHalf,
                to: tHalf,
                thickness: 30.3,
                color: '#b8092c',   
            }
        ] ,
        // stops: [
        //      [0, '#DF5353'],
        //      [half1 / ragThresholds[2], '#DF5353'],
        //      [(half1 + half2)/ragThresholds[2], '#DDDF0D'],
        //      [half3, '#55BF3B']
        // ],
            lineWidth: 0,
            tickWidth: 0,
            tickPixelInterval:30,
            minorTickInterval: null,
            tickAmount: 1,
            labels: {
                y: 16
            },
            visible: true,
            tickPositioner: function() {
                return [0, maxValue];    
            },            
            min: 0,
            max: maxValue,
            title: {
                style: {
                    fontSize: '0.5rem',
                    fontWeight: 400,
                },
                text: dispname ? dispname : 'NOT KNOWN',
                y: -40 
            }            
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'RAG Variance',
            data: [[value['position']]],
            // data:[value],
            dataLabels: {
                enalbled:true,
            // format: '',
            formatter: function () {

                // let di;
                // if(this.y <= fHalf){
                //     di= fHalf/ragThresholds[1];
                //     this.y = Math.round(this.y/di);
                //  }
                //  else if(this.y >fHalf && this.y<=sHalf){
                //     di= sHalf/ragThresholds[2];
                //     this.y = Math.round(this.y/di);
                // } 

                    // '<div style="text-align:center">' +
                    // '<span style="font-size:12px">' + numeral(variance.toFixed(2)).format('$0,0') + '</span><br>' +
                    // '<span style="font-size:12px">({y}</span>' + overind + 
                    // '<span style="font-size:10px;opacity:0.7">%)</span>' +
                    // '</div>'

                    // return '<div style="border: none">' +
                    // '<div align="center" style="font-size:18px; padding-left:"2px">&nbsp&nbsp&nbsp&nbsp'+ Math.round(value.y) + overind+'%</div>' + '<br>' +
                    // '<div align="center" style="font-size:12px; padding-left:"2px; opacity:0.7">From Actual</div>' +
                    // '</div>'
                    if(!sign) {
                        return '<div style="border: none">' +
                        '<div align="center" style="font-size:18px; padding-left:"2px">&nbsp&nbsp&nbsp&nbsp'+Math.round(value.y)+ overind+'%</div>' + '<br>' +
                        '<div align="center" style="font-size:12px; padding-left:"2px; opacity:0.7">From Actual</div>' +
                        '</div>' 
                    }
                    else {
                        return '<div style="border: none">' +
                        '<div align="center" style="font-size:18px; padding-left:"2px">&nbsp&nbsp&nbsp&nbsp'+value.end+ overind+'%</div>' + '<br>' +
                        '<div align="center" style="font-size:12px; padding-left:"2px; opacity:0.7">From Actual</div>' +
                        '</div>' 
                    }  
                   

                    
            },

                borderWidth: 0,
                style: {
                    fontSize: '16px',
                    textAlign: 'center'
                }
                   

            },
            tooltip: {
                valueSuffix: ' %'
            },

            dial: {
                radius: '70%',
                backgroundColor: "", //'#cccafa',
                baseWidth: 15,
                baseLength: '0%',
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: "", //'#cccafa',
                radius: 7.5
            }
        }]
    }: {
        chart: {
            type: vizOptions.gaugeType ? vizOptions.gaugeType : 'solidgauge',
            height: 150,
            width: vizOptions.width ? vizOptions.width : 200,
            backgroundColor:colors.chartBackground
        },
        title: '',
        pane: {
            center: ['50%', '70%'],
            size: '140%',
            startAngle: -110,
            endAngle: 110,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            outside: true,
            distance: 70,
            formatter: function () {
                return '<span style="font-size:12px">' + (lable + lable ? ': <b>' : '')  + name + 
                        '</b><br/>' + sublable + (sublable ? ': <b>' : '') + (dispname ? dispname : 'NOT KNOWN') + 
                        '</b><br/>Actual: <b>' + formatCurrencyNumeral(Number(props.value).toFixed(2), systemCurrencyDetails)  + 
                        '</b><br/>' + (vizOptions.compareTo === 'prior' ? 'Prior Year YTD' : vizOptions.compareTo === 'budget' ? 'Budget YTD' : 'Forecast') + ': <b>' + formatCurrencyNumeral(Number(props.priorvalue).toFixed(2), systemCurrencyDetails) + 
                        '</b><br/>Variance: <b>' + formatCurrencyNumeral(Number(variance).toFixed(2), systemCurrencyDetails)+ ' (' + value.y + overind +  '</b>%)</span>'
            }     
        },
        // the value axis
        yAxis: {
            // stops: [
            //     [0.0, '#55BF3B'], // green
            //     [0.08, '#DDDF0D'], // yellow
            //     [0.2, '#DF5353'], // red
            // ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 1,
            labels: {
                y: 16
            },
            visible: false,
            tickPositioner: function() {
                return [0, maxValue];    
            },            
            min: 0,
            max: maxValue,
            title: {
                style: {
                    fontSize: '0.75rem',
                    fontWeight: 400,
                },
                text: dispname ? dispname : 'NOT KNOWN',
                y: -40 
            }            
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 35,
                    borderWidth: 0,
                    useHTML: true
                }
            },
            series: {
                // cursor: "pointer",
                point: {
                    events: {
                        click: function () {
                            var obj = Object.assign([], [...currentFilters]);
                            if (vizOptions.category)
                                if (obj.find((({name}) => name === vizOptions.category))) 
                                {
                                    _.remove(obj, {name: vizOptions.category})
                                    obj.push({ "name": vizOptions.category, "values": [name] })
                                }
                                else
                                {
                                    obj.push({ "name": vizOptions.category, "values": [name] })
                                }   
                               if (vizOptions.subCategory)
                                if (obj.find((({name}) => name === vizOptions.subCategory))) 
                                {
                                    _.remove(obj, {name: vizOptions.subCategory})
                                    obj.push({ "name": vizOptions.subCategory, "values": [subname] })
                                }
                                else
                                {
                                    obj.push({ "name": vizOptions.subCategory, "values": [subname] })
                                }
                                if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                                    vizOptions.excludeFilters.map((fil) => {
                                        if (obj.find((({name}) => name === fil)))
                                            _.remove(obj, {name: fil})                                
                                    })
                                }
                            vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath_CF(location.pathname, vizOptions.drillTo), { state: obj})
                            vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && showReport(vizOptions["popupTo"], obj, null);
                        }
                    }
                }
            }            
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'RAG Variance',
            data: [value],
            dataLabels: {
                y: -25,
                format:
                    // '<div style="text-align:center">' +
                    // '<span style="font-size:12px">' + numeral(variance.toFixed(2)).format('$0,0') + '</span><br>' +
                    // '<span style="font-size:12px">({y}</span>' + overind + 
                    // '<span style="font-size:10px;opacity:0.7">%)</span>' +
                    // '</div>'
                    '<div style="text-align:center">' +
                    '<span style="font-size:18px">{y}'+overind+'%<br></span>' + 
                    '<span style="font-size:12px;opacity:0.7">From Actual</span>' +
                    '</div>'

            },
            tooltip: {
                valueSuffix: ' %'
            }
        }]
    };      

    return (
        <div style={{display: 'inline-block', position: 'relative'}}>
                    {/* <div style={{textAlign: 'left', fontWeight: 600, fontSize: '30px', padding: '0 0 0 10px', margin: 0}}></div> */}
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
            {/* <div style={{textAlign: 'center', padding: 0, margin: 0}}>{subname ? subname : ' - '}</div> */}
        </div>
    );
}

const GaugeChartRenderer_CF = ({ loading, title, subtitle, chartHelpContextKey, resultSet, vizOptions, vizState }) => {

    const chartRef = createRef();
    // const { ref: rref } = useResizeDetector();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const [state,] = useDashboardContext();
    const {showReport} = useYADialog();
    const [ragThresholds, setRagThresholds] = useState([0, 9.99, 20, 100])
    const [ragAccurancy,setRagAccuracy] = useState("")
    let bannerIcon = vizOptions.bannerIcon
    let range = {};
    let categories = new Set();
    let currentFilters
    
    if(loading)
       return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

    let parsedResultset = [];
    if (isTimeDimensionQuery(resultSet.loadResponses[0].query)) {
        parsedResultset = parseTableResultset_CF(resultSet, state, vizOptions)
        currentFilters = removeSessionFilter_CF(resultSet.loadResponses[0].query.filters, vizOptions)
    }
    else {
        parsedResultset = resultSet.tablePivot();
        currentFilters = removeSessionFilter_CF(resultSet.loadResponse.pivotQuery.filters, vizOptions)
    }

    useEffect(() => {
        getSettingsValues()
    }, [])

    const getSettingsValues = async () => {
        const [err, data] = await fetchRequest.get(`/api/forecastInput/category`);
        if (err) {
            console.log(err);
        } else {
            let ragThresholdHigh = data.filter(
                (elem) => elem.name === 'ragThresholdGreen'
            );
            let ragThresholdLow = data.filter(
                (elem) => elem.name === 'ragThresholdAmber'
            );
            let threshold = ragThresholds.slice(); // Create a copy of the ragThresholds array

            if (ragThresholdHigh.length > 0) {
                threshold[1] = Number(ragThresholdHigh[0].value);
            }
            if (ragThresholdLow.length > 0) {
                threshold[2] = Number(ragThresholdLow[0].value);
            }
            setRagThresholds(threshold);
        }
    };
    // var count = vizOptions.series.length
    // var graphData = convertRStoGraph(parsedResultset, vizOptions.wigetType === "leftrightbar" ? colors.varianceColors : count === 1 ? colors.singleDataColors : colors.graphColors, "bar", vizOptions)


    range["series1"] = {
        name: "series1",
        data: [{name: 'Forecast Accuracy', data: []}],
    };
    parsedResultset.forEach((item) => {
        categories.add(item[vizOptions.category]);
        if (range["series1"].data[0].name === 'Forecast Accuracy') {
            range["series1"] = {
                name: "series1",
                data: [],
            };
        }

        range["series1"].data.push({
            name: vizOptions.useNameTitle ? vizOptions.name : item[vizOptions.category],
            subname: item[vizOptions.subCategory],
            dispname: item[vizOptions.displayCategory],
            lable: vizOptions.categoryName,
            sublable: vizOptions.subCategoryName,
            value: item[vizOptions.banner.value1],
            priorValue: item[vizOptions.banner.value2],
            // variance: item[vizOptions.banner.value2] ? item[vizOptions.banner.value1] - item[vizOptions.banner.value2] : 0,
            variance: item[vizOptions.banner.value1] - item[vizOptions.banner.value2],
            variancePercentage: item[vizOptions.banner.value2] ? Math.abs(Math.round(((item[vizOptions.banner.value1] - item[vizOptions.banner.value2]) / item[vizOptions.banner.value2]) * 100)) : item[vizOptions.banner.value1] ? 100 : 0
        });

    });
    const nodata = categories?.size === 0;
    let data = {};

    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = 5.8;
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])

    Object.values(range)[0]?.data.map((item) => {
        if (!data[item.name]) {
            data[item.name] = {
                name: item.name,
                subdata: [],
            };
        }            
        data[item.name].subdata.push(
            {
                name: item.name,
                subname: item.subname,
                dispname: vizOptions.useNameTitle ? ' ' : item.dispname,
                lable: item.lable,
                sublable: item.sublable,
                value: item.value,
                priorValue: item.priorValue,
                variance: item.variance,
                variancePercentage: item.variancePercentage
            }
        )
    })
    return(
        <div>
         { Object.values(data).map(element => {

//calculation for risponsive screen size for RAG reports 

            const numberOfItems = Object.values(element.subdata).length
            const totWidthScreen = window.innerWidth
            const itemInLine = Math.floor((totWidthScreen-95)/200)
            const numberOfLine = Math.ceil(numberOfItems/itemInLine)
            const heightOfDiv = numberOfLine*200


            return( toggleType === 'table' && tableVizOptions ?
            <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet}/> :
                <DashboardItem key={element.name} nodata={nodata} addGap={true} title={element.name} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} noOptions={true} p={0} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={resultSet?.tablePivot()}>
                         <MDBox style={{textAlign: 'left', padding: '0 0 0 10px', margin: 0}} display="flex" flexDirection="row" color={colors.linkColour}>
                { bannerIcon ? <><Icon sx={{backgroundColor: "#F8F4EA", borderRadius: "5px"}} fontSize="20px">{bannerIcon}</Icon>&nbsp;</> : '' }
                <MDTypography style={{textAlign: 'left', padding: '0 0 0 6px', margin: '0px 0 0 0'}} lineHeight={1} variant="h4" component="h2" textOverflow="ellipsis" whiteSpace="nowrap">{ragAccurancy ? ragAccurancy : ''}</MDTypography>
            </MDBox>
                    
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'left', height: heightOfDiv, marginBottom: '-50px' }}>
                        {element.subdata.map((item) => {
                            return (
                                <>
                                <GaugeGraph key={item.name} chartRef={chartRef} showReport={showReport} currentFilters={currentFilters} vizOptions={vizOptions} name={item.name} subname={item.subname} dispname={item.dispname} lable={item.lable} sublable={item.sublable} value={item.value} priorvalue={item.priorValue} ragThresholds={ragThresholds} variance={item.variance} setRagAccuracy={setRagAccuracy} data={item.priorValue > 0 ? Math.round(100 * item.variance / item.priorValue) : (item.value > 0 ? 100 : 0)}/>
                                </>
                            )
                        })}
                    </div>
                </DashboardItem>
            )
        })
    }
    </div>
    );
}

export default GaugeChartRenderer_CF;