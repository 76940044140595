import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { Icon, Card, IconButton, Tooltip, Stack } from "@mui/material";
import Switch from '@mui/material/Switch';
import { useCallback, useContext, useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useYADialog } from 'components/YADialog';
import { CubeContext } from '@cubejs-client/react';
import moment from 'moment';
import YASkeleton from "components/YASkeleton";
import { CircularProgress } from "@mui/material";

const RefreshStatus = ({ refreshStatus }) => {
    if (!refreshStatus)
        return <MDTypography variant="caption" color="dark">Fetching....</MDTypography>
    if (refreshStatus === 'Refreshing') {
        return <>
            <MDTypography variant="caption" color="dark">{refreshStatus}&nbsp;
                <CircularProgress
                    size={12}
                    thickness={5}
                    sx={() => ({
                        color: "#ff7c17",
                        mt: "2px",
                        backgroundColor: "transparent",
                        position: "absolute",
                        zIndex: 1,
                    })}
                />
            </MDTypography>
        </>
    }
    return <>
        <MDTypography variant="caption" color="dark">{refreshStatus}&nbsp;
            <Icon sx={{ color: "#88ad02", pt: "2px", fontSize: "18px!important" }}>done</Icon>
        </MDTypography>
    </>
}

const LastRefreshDetails = ({ data }) => {
    const { cubeApi } = useContext(CubeContext);
    const [details, setDetails] = useState({ loading: true, result: null });

    useEffect(() => {
        refreshDetails();
        const intervalId = setInterval(() => {
            refreshDetails();
        }, 30000) // in milliseconds
        return () => clearInterval(intervalId)
    }, []);

    const refreshDetails = useCallback(async () => {
        setDetails({ loading: true, result: null });
        const result = await fetchCubeRefreshDetails(cubeApi, data);
        setDetails({ loading: false, result });
    }, [setDetails, fetchCubeRefreshDetails]);

    const refreshStatus = details?.result?.refreshStatus;
    const lastRefreshed = details?.result?.lastRefreshed;

    return <Stack gap={3} flexDirection={"row"} alignItems="center">
        <MDTypography sx={{ width: 160 }} variant="caption" color="dark">{lastRefreshed}</MDTypography>
        <RefreshStatus refreshStatus={refreshStatus} />
    </Stack>
}

const fetchCubeRefreshDetails = async (cubeApi, result) => {

    let query = { "measures": [`${result.cubeQueryColumn}`] }
    try {
        const response = await cubeApi.load(query);
        const isContinueWait = JSON.stringify(response).includes("Continue wait");
        if(isContinueWait){
            console.log("Continue wait")
            result.refreshStatus = "Refreshing"
            return result;
        }

        if (response?.loadResponses[0]?.data[0][result.cubeQueryColumn]) {
            result.lastRefreshed = moment(response?.loadResponses[0]?.data[0][result.cubeQueryColumn]).format("DD MMM YYYY HH:mm:ss")
        } else {
            result.lastRefreshed = moment(response?.loadResponses[0]?.lastRefreshTime).format("DD MMM YYYY HH:mm:ss")
        }
        if ((moment(result.lastRefreshed) - moment(result.refreshRequested)) >= 0) {
            result.refreshStatus = "Refreshed"
        } else {
            result.refreshStatus = "Refreshing"
        }
    } catch (error) {
        result.lastRefreshed = null
        result.refreshStatus = "Unknown"
    }
    return result;
}


const buildColumns = () => {
    return [
        {
            "Header": "Actions",
            "disableSorting": true,
            "accessor": "actions",
            "maxWidth": 75,
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Cube Name",
            "accessor": "cubeName",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Refresh Condition",
            "accessor": "details",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Refreshed Cube(s)",
            "accessor": "cubeList",
            "Cell": ({ cell: { value } }) => {
                return value.map(v => <MDTypography key={v} variant="caption" color="dark">
                    [{v.replace("WithBudget", "").replace("Details", "").replace("WithForecasts", "").replace("WithBusinessUnit", "")}]&nbsp;
                </MDTypography>)
            }
        },
        {
            "Header": "Refresh Requested",
            "accessor": "refreshRequested",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value ? moment(value).format("DD MMM YYYY HH:mm:ss") : ""}
                </MDTypography>
            }
        },
        // {
        //     "Header": "Last Refreshed",
        //     "accessor": "lastRefreshed",
        //     "Cell": ({ cell: { value } }) => {
        //         return <MDTypography variant="caption" color="dark">
        //             {value}
        //         </MDTypography>
        //     }
        // },
        {
            "Header": "Last Refreshed",
            "accessor": "lastRefreshed",
            "width": 500,
            "Cell": ({ row: { original } }) => {
                return <LastRefreshDetails data={original} />
            }
        }
    ]
}

const buildRows = (data, setRefresh, showSnackbar) => {

    const onRefresh = async (cubeKey) => {
        let cubeVal = cubeKey.charAt(0).toLowerCase() + cubeKey.slice(1);
        var [err, data] = await fetchRequest.post(`/api/dataflow/cubeRefreshKey/${cubeVal}?force=true`);
        if (err) {
            console.err(err)
        }
        else {
            setRefresh(Math.random());
            showSnackbar(data, "success")
        }
    }


    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, "__")] = r[k]
            });
            row["actions"] = (
                <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
                    <Tooltip title="Refresh" placement="top">
                        <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onRefresh(r["cube"])}>
                            <Icon fontSize="small" >refresh</Icon>
                        </IconButton>
                    </Tooltip>
                    {/* <MDTypography display="flex" alignItems="center" component="a" href="#" onClick={() => onRefresh(r["cube"])} variant="caption" color="text" fontWeight="medium">
                        <Icon fontSize="small" >refresh</Icon>&nbsp;Refresh
                    </MDTypography> */}
                </MDBox>
            )
            rows.push(row);
        });
    }
    rows.sort((a, b) => {
        if (a.cubeName < b.cubeName) { return -1; }
        if (a.cubeName > b.cubeName) { return 1; }
        return 0;
    }
    )
    return rows;
}

const CubeRefreshKey = () => {
    const { showSnackbar } = useYADialog();
    const [refresh, setRefresh] = useState(null);
    const handleError = useHandleError();
    const [rows, setRows] = useState([]);
    const [autoRefreshStatus, setAutoRefreshStatus] = useState(false)
    const columns = buildColumns();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/dataflow/getCubeRefreshKey`);
            if (err) {
                handleError(err);
                setLoading(false)
            }
            else {
                data = data.filter((obj) => {
                    return obj.cube !== "all";
                });
                if (loading)
                    setRows(buildRows(data, setRefresh, showSnackbar));
                setLoading(false)
            }
        }
        getList()
    }, [refresh]);

    useEffect(() => {
        async function getFiancialYearDetails() {
            var [, data] = await fetchRequest.get(`/api/settings/category/financialYearSetup`);
            if (data) {
                let refreshStatus = data.find(item => item.name === "autoCubeRefresh")?.value
                if (refreshStatus === "true") {
                    setAutoRefreshStatus(true)
                }
                else
                    setAutoRefreshStatus(false)
            }
        }
        getFiancialYearDetails()
    }, [])

    const onRefreshAll = async (cubeKey) => {
        let cubeVal = cubeKey.charAt(0).toLowerCase() + cubeKey.slice(1);
        var [err, data] = await fetchRequest.post(`/api/dataflow/cubeRefreshKey/${cubeVal}?force=true`);
        if (err) {
            console.log(err)
        }
        else {
            setRefresh(Math.random());
            showSnackbar(data, "success")
        }
    }

    const label = { inputProps: { 'aria-label': 'Color switch demo' } };
    const autoCubeVal = `Auto Cube Refresh (${autoRefreshStatus ? "ON" : "OFF"})`
    const handleChange = async (e) => {
        setAutoRefreshStatus(e.target.checked)
        if (e.target.checked === true) onRefreshAll('all')
        var [err, data] = await fetchRequest.post(`/api/settings/category/autoCubeRefresh/${e.target.checked}`)
        if (err)
            console.log(err)
        else
            showSnackbar(data, "success")
    }

    if (loading) {
        return <YASkeleton variant="loading" />;
    }

    return (
        <>
            <MDBox color="text" pt={0} mt={0} display="flex" flexDirection="row" justifyContent="space-between">
                <PageHeader title="Cube Refresh" subtitle="Manually refresh cubes on an ad hoc basis, or in cases where an automatic cube refresh has not occurred." />
                <MDBox
                    mt={2.5}
                    mr={3}
                    pt={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <MDTypography variant="h5" color="dark">{autoCubeVal}</MDTypography>
                    <Switch {...label} checked={autoRefreshStatus} onChange={handleChange} />
                    <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => onRefreshAll("all")}
                    >
                        Refresh All
                    </MDButton>
                </MDBox>

            </MDBox>
            <MDBox p={3} pt={1}>
                <Card sx={{ height: "100%" }} px={0}>
                    <DataTable
                        table={{ columns, rows }}
                        showTotalEntries={true}
                        isSorted={true}
                        newStyle1={true}
                        noEndBorder
                        entriesPerPage={true}
                        canSearch={true}
                    />
                </Card>
            </MDBox>

        </>
    );
};

export default AnimatedRoute(CubeRefreshKey);



