import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { Icon, Card, IconButton, Tooltip } from "@mui/material";
import colors from "assets/theme/base/colors";
import { useEffect, useState } from "react";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useYADialog } from "components/YADialog";
import EmptyState from "components/EmptyState";
import new_item_img from "assets/svg/add_new.svg";
import MDButton from "components/MDButton";
import moment from "moment";
import BusinessCaseForm from "./components/BusinessCaseForm";
import { Link, useNavigate } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import { useAppController } from "context";
import { formatCurrencyNumeral } from "utils";

const buildColumns = (systemCurrencyDetails) => {
    const columns = [
        { Header: "Actions", accessor: "actions", "align": "left", "disableSorting": true, minWidth: 70 },
        {
            Header: "Name", accessor: "name", Cell: ({ cell: { row: { original }, value } }) => {
                return <Link to={`/business-cases/${original["id"]}/business-case-details`}>
                    <MDTypography display="flex" alignItems="center" variant="caption" color="info" fontWeight="medium">{value}</MDTypography>
                </Link>;
            }
        },
        { Header: "Description", accessor: "description", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Status", accessor: "statusName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Start Date", accessor: "startDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY") : ""}</MDTypography> } },
        { Header: "End Date", accessor: "endDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY") : ""}</MDTypography> } },
        { Header: "Solution Offering", accessor: "solutionOffering__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Objective", accessor: "objective", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Migration Strategy", accessor: "rStrategy", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Created On", accessor: "createdAt", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
        { Header: "Total Estimate", align:"right", accessor: "totalAmount", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? formatCurrencyNumeral(value, systemCurrencyDetails) : `${systemCurrencyDetails?.code || '$'}0`}</MDTypography> } },
        { Header: "Created By", accessor: "createdByUser__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography> } },
    ];
    return columns;
}

const buildRows = (data, onEdit, onDelete, onRevert) => {
    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, "__")] = r[k]
            });
            // row["actions"] = (r["status"] === "DRAFT" && 
            //     <MDBox display="flex" alignItems="flex-start" justifyContent="flex-start" mt={{ xs: 2, sm: 0 }}>
            //         <MDTypography display="flex" alignItems="center" component="a" href="#" onClick={() => onEdit(row["id"])} variant="caption" color="text" fontWeight="medium">
            //             <Icon fontSize="small" >edit</Icon>&nbsp;Edit
            //         </MDTypography>
            //         <MDTypography display="flex" ml={2} component="a" href="#" onClick={() => onDelete(row["id"])} variant="caption" color="text" fontWeight="medium">
            //             <Icon fontSize="small" color="error">delete</Icon>
            //         </MDTypography>
            //         <MDTypography display="flex" alignItems="center" ml={1.5} component="a" href="#" onClick={() => onRevert(row["id"])} variant="caption" color="text" fontWeight="medium">
            //             <Icon fontSize="small" >flip_camera_android</Icon>&nbsp;Revert
            //         </MDTypography>
            //     </MDBox>
            // )
            row["actions"] = (
                // (r["status"] === "DRAFT") ?
                //     (
                        <>
                        <MDBox display="flex" alignItems="flex-start" justifyContent="flex-start" mt={{ xs: 2, sm: 0 }}>
                            <Tooltip title={`Edit${r["status"] === "DRAFT" ? '' : ' Disabled'}`} placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1.5 }} onClick={() => r["status"] === "DRAFT" ? onEdit(row["id"]) : null}>
                                    <Icon fontSize="small" color={r["status"] === "DRAFT" ? 'info' : 'disabled'}>edit</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={`Delete${r["status"] === "DRAFT" ? '' : ' Disabled'}`} placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1.5  }}    onClick={() => r["status"] === "DRAFT" ? onDelete(row["id"]) : null} >
                                    <Icon fontSize="small" color={r["status"] === "DRAFT" ? 'error' : 'disabled'} >delete</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={`Revert${r["status"] !== "DRAFT" ? '' : ' Disabled'}`} placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1.5  }}    onClick={() => r["status"] !== "DRAFT" ? onRevert(row["id"]) : null} >
                                <Icon fontSize="small" color={r["status"] !== "DRAFT" ? 'info' : 'disabled'} >flip_camera_android</Icon>
                                </IconButton>
                            </Tooltip>
                            {/* <MDTypography display="flex" alignItems="center" ml={1.5} component="a" href="#" onClick={() => onEdit(row["id"])} variant="caption" color="text" fontWeight="medium">
                                <Icon fontSize="small" >edit</Icon>&nbsp;Edit
                            </MDTypography>
                            <MDTypography display="flex" ml={1.5} component="a" href="#" onClick={() => onDelete(row["id"])} variant="caption" color="text" fontWeight="medium">
                                <Icon fontSize="small" color="error">delete</Icon>
                            </MDTypography> */}
                        </MDBox>
                        </>
                        
                    // ) : 
                    // (
                    //     <MDBox display="flex" alignItems="flex-start" justifyContent="flex-start" mt={{ xs: 2, sm: 0 }}>
                    //         <Tooltip title="Revert" placement="top">
                    //             <IconButton sx={{ padding: 0, paddingRight: 1.5  }}    onClick={() => onRevert(row["id"])} >
                    //             <Icon fontSize="small" color='info' >flip_camera_android</Icon>
                    //             </IconButton>
                    //         </Tooltip>
                    //         {/* <MDTypography display="flex" alignItems="center" component="a" href="#" onClick={() => onRevert(row["id"])} variant="caption" color="text" fontWeight="medium">
                    //             <Icon fontSize="small" >flip_camera_android</Icon>&nbsp;Revert
                    //         </MDTypography> */}
                    //     </MDBox>
                    // )
                )
                    
            rows.push(row);
        });
    }
    return rows;
}

const BusinessCase = () => {

    const handleError = useHandleError();
    const { showCustomForm, showPrompt, showSnackbar } = useYADialog();
    const [step, setStep] = useState("LOADING");
    const [rows, setRows] = useState([]);
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;
    const columns = buildColumns(systemCurrencyDetails);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const handleClose = () => {
        setRefresh(Math.random())
    }

    const handleAddNewClose = (businessCaseId) => {
        navigate(`/business-cases/${businessCaseId}/business-case-details`);
    }

    const handleEdit = (pkId) => {
        showCustomForm(`Edit Business Case`, () => <BusinessCaseForm onClose={handleClose} mode="edit" pkId={pkId} />, handleClose, "edit", pkId,"sm");
    }

    async function handleDeleteSuccess(id) {
        var [err, response] = await fetchRequest.post(`/api/businessCases/delete/${id}`);
        if (err) {
            handleError(err);
        }
        else {
            setRefresh(Math.random())
            showSnackbar(response?.message, "success");
        }
    }

    const handleDelete = (pkId) => {
        showPrompt("Delete", "The Business Case will be deleted along with all the business Items", () => handleDeleteSuccess(pkId));
    }

    const handleOnSelectionClearClick = () => {
        setRefresh(Math.random())
      }

    const handleComareMultiple = (values) => {
        var obj = [
            {
                name: "BusinessCaseDetails.businessCaseName",
                operator: "equals",
                values: values
            }
        ]
        navigate("/dashboard/compare-business-case", { state: obj })
    }

    async function handleRevertSuccess(id) {
        var [err, response] = await fetchRequest.post(`/api/businessCases/revert/${id}`);
        if (err) {
            handleError(err);
        }
        else {
            setRefresh(Math.random())
            showSnackbar(response?.message, "success");
        }
    }

    const handleRevert = (pkId) => {
        showPrompt("Revert", "Status will be reverted back to \"Draft\".", () => handleRevertSuccess(pkId));
    }

    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/businessCases/list`);
            if (err) {
                handleError(err);
            }
            else {
                if (data && Array.isArray(data) && data.length > 0) {
                    setRows(buildRows(data, handleEdit, handleDelete, handleRevert));
                    setStep("LOADED");
                }
                else {
                    setStep("EMPTY");
                }
            }
        }
        getList();
    }, [refresh])

    const handleAddButtonClick = () => {
        showCustomForm(`New Business Case`, () => <BusinessCaseForm onClose={handleAddNewClose} />, null, null, null,'sm',handleClose);
    }

    const renderAddButton = () => (
        <MDButton variant="gradient" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
            New Business Case
        </MDButton>
    )

    if (step === "LOADING") {
        return <YASkeleton variant="dashboard-loading" />;
    }

    return (
        <>
            <MDBox bgColor={colors.dashboardBackground} minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0, md: 6, sm: 6, xs: 6 }}>
                <PageHeader title="Business Cases" subtitle="Screen to manage Business cases" primaryActionComponent={step !== "EMPTY" ? renderAddButton : null} />
                <MDBox p={3}>
                {
                    step === "LOADED" && (
                        <Card sx={{ height: "100%" }} px={0}>
                            <DataTable
                                isSelectable={true}
                                compareMultiple={true}
                                onSelectionClearClick={handleOnSelectionClearClick}
                                onComareMultiple={handleComareMultiple}
                                table={{ columns, rows }}
                                showTotalEntries={true}
                                isSorted={true}
                                newStyle1={true}
                                noEndBorder
                                entriesPerPage={true}
                                canSearch={true}
                            />
                        </Card>
                    )
                }
                {
                    step === "EMPTY" && (
                        <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
                            <EmptyState
                                size="large"
                                image={new_item_img}
                                title={"No Business Cases Yet"}
                                description={"Click on the '+ new business case' button to create a new business case."}
                                actions={renderAddButton}
                            />
                        </MDBox>
                    )
                }
                </MDBox>
            </MDBox>
        </>
    );
};

export default AnimatedRoute(BusinessCase);