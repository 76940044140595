import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate, useLocation } from "react-router-dom";
import { formatAmount, getDrilldownPath_CF, removeSessionFilter } from 'utils';
import colors from "assets/theme/base/cloudForecasts/colors";
import { useResizeDetector, withResizeDetector } from 'react-resize-detector';
import { useYADialog } from "components/YADialog";
import DashboardItem from 'components/DashboardItem_CF';
import { useAppController } from "context";
import _ from 'lodash';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { useDashboardContext } from 'components/DashboardContext';
import { isTimeDimensionQuery } from 'utils/dashboard';
// import { convertRStoGraph } from "utils"
// import { parseTableResultset } from 'utils/charts';
import { convertRStoGraph_CF } from 'utils/charts';
import { parseAndMergeTableResultset_CF } from 'utils/charts';
import useFetchRequest from 'hooks/useFetchRequest';

const ColumnChartRenderer_CF = ({ loading, title, subtitle, chartHelpContextKey, resultSet, vizOptions, vizState, cubeOptions }) => {
    
    const [state,] = useDashboardContext();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const chartRef = createRef();
    const { width, height, ref: rref } = useResizeDetector();
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;
    const { showReport } = useYADialog();
    let navigate = useNavigate()
    let location = useLocation()
    let currentFilters
    if (loading)
        return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

    let parsedResultset = [];
    if (isTimeDimensionQuery(resultSet.loadResponses[0].query)) {
        parsedResultset = parseAndMergeTableResultset_CF(resultSet, state, vizOptions)
        currentFilters = removeSessionFilter(resultSet.loadResponses[0].query.filters, vizOptions)
    }
    else {
        parsedResultset = resultSet.tablePivot();
        currentFilters = removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions);
    }
   
    var count = vizOptions.series.length;
    var graphData = convertRStoGraph_CF(parsedResultset, count === 1 ? colors.singleDataColors : vizOptions.deviation || vizOptions.rolling ? colors.deviationColors : colors.graphColors, "column", vizOptions)
    const { response } = vizOptions?.rolling ? useFetchRequest(`/api/forecastInput/forecastRolling/${sessionStorage["yearId"]}?teamName=${sessionStorage["Forecast Team"]}`) : ""
    const categoriesForRollingMonths = response?.map(i => i.name)
    let actualRangeForRollingMonths = response?.map(i => i.actualAmount)
    let forecastRangeForRollingMonths = response?.map(i => i.forecastedAmount)
    for (let i = 0; i < actualRangeForRollingMonths?.length; i++) {
        if (actualRangeForRollingMonths[i] == null) {
            actualRangeForRollingMonths[i] = 0;
        }
    }
    for (let i = 0; i < forecastRangeForRollingMonths?.length; i++) {
        if (forecastRangeForRollingMonths[i] == null) {
            forecastRangeForRollingMonths[i] = 0;
        }
    }
    vizOptions?.rolling && Object.values(graphData.range).length > 0 ? Object.values(graphData.range)[0].data = actualRangeForRollingMonths : ""
    vizOptions?.rolling && Object.values(graphData.range).length > 0 ? Object.values(graphData.range)[1].data = forecastRangeForRollingMonths : ""

    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        let isInArray = (name, array) => {
            return array.some(item => item.name === name);
        }
        let parsedColumns = []
        parsedResultset.length ?
            parsedResultset.forEach(item => {
                Object.keys(item).map(key => {
                    let col = {
                        "name": key,
                        "displayName": String(key).match(/[^|]*$/g)[0],
                        "type": !isNaN(Number(item[key])) ? (vizOptions.units ? 'number' : "currency") : 'string'
                    }
                    if (!isInArray(col.name, parsedColumns))
                        parsedColumns.push(col)
                })
            })
            : parsedColumns = null
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = parsedResultset.length && parsedColumns.length ? [...parsedColumns] : [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = vizOptions?.tableHeight ? vizOptions?.tableHeight : 5.8;
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])

    var opts = {
        chart: {
            // height: location.pathname.indexOf("report") > 0 ? 450 : 250, 
            width: width,
            height: height,
            type: 'column', style: { fontFamily: 'inherit', fontSize: '14px', },
            spacingBottom: 0,
            // spacingTop: 0,
            spacingRight: 0,
            spacingLeft: 3,
            backgroundColor: colors.chartBackground
        },
        title: { text: '' },
        exporting: { enabled: false },
        lang: { thousandsSep: ',' },
        credits: { enabled: false },
        tooltip: {
            outside: false,
            formatter: function () {
                return `<b>${this.point.category}</b><br/>${this.point.series.name}: <b>${ systemCurrencyDetails?.code || '$'}${Highcharts.numberFormat(this.point.y, 0, ".", ",")}</b>`;
            }
        },
        xAxis: {
            categories: vizOptions?.rolling ? categoriesForRollingMonths : Array.from(graphData.categories)
        },
        yAxis: [{
            // visible:false,
            // gridLineColor: 'transparent',
            reversed: false,
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return formatAmount(Math.abs(this.value), null, systemCurrencyDetails).replace(/ /g, '').replace('.0', '');
                }
            },
        }
        ],
        plotOptions: {
            series: {
                cursor: "pointer",
                groupPadding: 0.1,
                // pointPadding: 0,
                borderWidth: 0,
                borderRadius: 4,
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                point: {
                    events: {
                        click: function (event) {
                            var obj = Object.assign([], [...currentFilters]);
                            if (vizOptions.category)
                                if (obj.find((({ name }) => name === vizOptions.category))) {
                                    _.remove(obj, { name: vizOptions.category })
                                    obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                                else {
                                    obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                            if (obj.find((({ name }) => name === "Years.year")) && (vizOptions.series[0].name.indexOf(".previousYear") !== -1) && vizOptions["popupTo"] && (vizOptions["popupTo"] !== "")) {
                                _.remove(obj, { name: "Years.year" })
                                obj.push({ name: "Years.year", "values": [event.point.series.name.replace(" YTD", "")] })
                            }
                            var popupkey = ""
                            var popupTo = vizOptions["popupTo"] ? vizOptions["popupTo"] : ''
                            if (vizOptions["popupToKey"]) {
                                popupkey = obj.find((({ name }) => name === vizOptions["popupToKey"])) ? obj.find((({ name }) => name === vizOptions["popupToKey"])).values[0] : ''
                                popupTo =  vizOptions["popupTo"] ? vizOptions["popupTo"].replace("changeme",popupkey.toLowerCase()).replace(' ','') : ''
                            }
                     
                            if (vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && state) {
                                if (obj.find((({ name }) => name === "Years.year")))
                                    _.remove(obj, { name: "Years.year" })
                                obj.unshift({name: "Years.year", "values": state.filters[0].values})
                            }
                            
                            if (vizOptions?.conditionalFilter) {
                                vizOptions?.conditionalFilter.map((item) =>{
                                    obj.map((fil) => {
                                        if(fil.name == item.if.filter){
                                            fil.values.map((i) => {
                                                if(i == item.if.value){
                                                    obj.push({ "name": item.then.filter, "values": [item.then.value] })
                                                }
                                            })
                                        }
                                    })
                                })
                            }
                            
                            let obj1 = []
                            if (vizOptions?.transformDrillFilters) {
                                obj?.map((filter)=> { 
                                    vizOptions.transformDrillFilters.map(fil => {  
                                    if (fil.from === filter.name)
                                        obj1.push(
                                            {
                                                name : fil.to,
                                                operator : filter.operator,
                                                values: filter.values
                                            }
                                        )       
                                  })
                                })
                            }

                            if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                                vizOptions.excludeFilters.map((fil) => {
                                    if (obj.find((({name}) => name === fil)))
                                        _.remove(obj, {name: fil})                                
                                })
                            }
                            
                            obj = [...obj, ...obj1]

                            vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath_CF(location.pathname, vizOptions.drillTo), { state: obj })
                            popupTo && popupTo !== "" && showReport(popupTo, obj, null);
                        }
                    }
                }
            },
            column: {
                grouping: false,
                borderWidth: 0,
                dataLabels: {
                    style: {
                        fontWeight: 'small'
                    },
                    enabled: true,
                    formatter: function () {
                        const Barheight = this.point.shapeArgs.height
                        if (Barheight < 10 && this.y !== 0) {
                            return formatAmount(this.y, null, systemCurrencyDetails).replace(/ /g, "").replace(".0", "")
                        } else {
                            return null
                        }
                    }
                },
            }
        },
        series: Object.values(graphData.range)
    }
    const nodata = graphData.categories?.size === 0;
    let navigateToPage = () => {
        vizOptions["linkTo"] && vizOptions["linkTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.linkTo : getDrilldownPath_CF(location.pathname, vizOptions.linkTo), { state: {} })
    }
    const parsedResultsetData = parsedResultset?.length > 0 ?  parsedResultset : resultSet?.tablePivot();
    return (toggleType === 'table' && tableVizOptions ?
    <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet} parsedResultset={parsedResultset}/> :
        <DashboardItem nodata={nodata} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={parsedResultsetData} cubeOptions={cubeOptions}>
            <div ref={rref} style={{ position: 'relative', height: '100%' }}>
                <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 }}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
                    {vizOptions["linkTo"] && vizOptions["linkTo"] !== "" &&
                        <MDBox display="flex" color={colors.linkColour ? colors.linkColour : "dark"} flexDirection="row" justifyContent="flex-end">
                            <MDTypography style={{ position: 'absolute', bottom: '-10px', right: '5px' }} variant="button" sx={{ "&:hover": { cursor: 'pointer' } }} fontWeight="medium" color={colors.linkColour ? colors.linkColour : "dark"} px={1} whiteSpace="nowrap" onClick={() => { navigateToPage() }}>
                                {vizOptions.linkText.toUpperCase()}
                            </MDTypography>
                            <Icon>east</Icon>
                        </MDBox>
                    }
                </div>
            </div>
        </DashboardItem>
    )
}

export default withResizeDetector(ColumnChartRenderer_CF);
