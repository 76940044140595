import { useCubeQuery } from '@cubejs-client/react';
import FilterChip from 'components/FilterChipCF';
import { useMemo, useState } from 'react';

function FilterItem({ filter, setFilter, onDelete, sessionFilters, teamForMember, role, headerShadow }) {
  const { resultSet, isLoading, error } = useCubeQuery(filter.query);
  const [searchVal, setSearchVal] = useState('')
  // console.log("SearchFromItem=====>",searchVal)

  const cloudEngineer = role.includes("cloudEngineer") && !role.includes('admin') && !role.includes('cloudCostOwner') ? true : false

  const handleSearch = (value) => {
    setSearchVal(value)
    return searchVal
  }

  const options = useMemo(() => {
    return teamForMember && filter.queryName === 'CloudTeams.name' && cloudEngineer ? teamForMember.map(team => team.name) : resultSet ? resultSet.tablePivot().map(item => item[filter.query.dimensions[0]] || "(null)") : []
  }, [filter, resultSet])

  const handleOnFilterDelete = (id) => {
    if (onDelete)
      onDelete(filter, id)
  }

  const handleOnFilterSave = (selectedFilter) => {
    setFilter({
      name: selectedFilter.name,
      type: selectedFilter.type,
      dimension: filter.query.dimensions[0],
      operator: selectedFilter.operator,
      values: selectedFilter.values,
      session: sessionFilters
    });
  };

  if (error) {
    console.error(error)
  }

  const filterValue = { name: filter.name, operator: filter.operator || "equals", values: filter.values };
  if (options.length > 0) {
    return (
      <FilterChip isCubeFilter={true} valueLimit={filter.valueLimit} openOnMount={!filter.defaultSelected} dismissible={!filter.required} defaultSelected={filter.defaultSelected} dataType={filter.filterType || "textbox"} name={filter.name} field={filter.name} filterValue={filterValue} options={options} optionsLoading={isLoading} onFilterDelete={handleOnFilterDelete} onFilterSave={handleOnFilterSave} sessionFilters={sessionFilters} onSearchVal={handleSearch} searchVal={searchVal} defaultFilter={filter.defaultFilter} singleMonth={filter.singleMonth} headerShadow={headerShadow} />
    )
  } else {
    return null
  }

}

export default FilterItem;