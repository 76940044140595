import MDBox from "components/MDBox";
import Logo from "./components/Logo";
import MDTypography from "components/MDTypography";

const AppPersonalization = () => {
    return (
        <MDBox flex={1} px={4} pt={3}>
        <MDBox display="flex" flexDirection="column">
                <MDTypography data-testid={"Logo"} variant="button" color="dark" fontWeight="medium">App Personalization</MDTypography>
                <MDTypography data-testid={"Manage license with ease"} mb={2} variant="button" color="text">Manage App Personalization</MDTypography>
        <MDBox>
                <Logo />
            </MDBox>
        </MDBox>
        </MDBox>
    );
}

export default AppPersonalization;