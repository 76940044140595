import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { Icon, Card, FormControlLabel, Switch as MDSwitch, CircularProgress, Tooltip, Badge, IconButton } from "@mui/material";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { getDomain } from 'utils';
import PageHeader from "components/PageHeader";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useYADialog } from "components/YADialog";
import EmptyState from "components/EmptyState";
import new_item_img from "assets/svg/add_new.svg";
import no_data_img from "assets/svg/no_data.svg";
import MDButton from "components/MDButton";
// import numeral from "numeral";
import BudgetItemForm from "components/BudgetItemForm";
import * as XLSX from 'xlsx';
import Axios from "axios";
import { useImmer } from "use-immer";
import { useParams, useLocation } from "react-router-dom";
import AnimatedRoute from "components/AnimatedRoute";
import moment from "moment";
import BudgetHeader from "components/BudgetHeader";
// import MDBadge from "components/MDBadge";
import { applyVariables } from "utils/budget";
import MDAvatar from "components/MDAvatar";
import CommentsDrawer from "components/CommentsDrawer";
import { getFiscalMonthsArray } from "utils/budget";
// import colors from "assets/theme/base/colors";
import { getChargeBackMonthly } from "utils";
import FilteredUploadedFiles from "components/FilteredUploadedFiles";
import FilteredUploadRules from 'components/FilteredUploadRules';
import { useNotificationSubscription } from 'hooks/useNotificationManager';
import { NotificationType } from 'constants';
import useBudgetLookups from "hooks/useBudgetLookups";
import { useAppController } from "context";
import { formatCurrencyNumeral } from "utils";


const BudgetContext = createContext();

const getDefaultColumnsList = (type, systemCurrencyDetails) => {
    return [
        // { Header: "Account Code", accessor: "account__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account", accessor: "account__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor", accessor: "vendor__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Expense Type", accessor: "expenseType__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Assumption", accessor: "assumption", Cell: ({ cell: { value } }) => { return (value || "") !== "" ? <Tooltip sx={{ px: 0, m: 0 }} placement="top" title={value}><MDTypography variant="caption" color="dark">{value}</MDTypography></Tooltip> : null } },
        { Header: "Notes", accessor: "notes", Cell: ({ cell: { value } }) => { return (value || "") !== "" ? <Tooltip sx={{ px: 0, m: 0 }} placement="top" title={value}><MDTypography variant="caption" color="dark">{value}</MDTypography></Tooltip> : null } },
        // { Header: "Cost Pool", accessor: "costPool__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        // { Header: "Sub Cost Pool", accessor: "subCostPool__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        // { Header: "Tower", accessor: "tower__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        // { Header: "Sub Tower", accessor: "subTower__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        // { Header: "Solution Offering", accessor: "solutionOffering__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        // { Header: "Business Unit", accessor: "businessUnit__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "CPI", accessor: "enableCpi", "disableSorting": true, Cell: ({ cell: { value } }) => { return value ? <Icon color="info" sx={{ fontSize: "18px!important" }}>done</Icon> : <span></span> } },
        { Header: "LPI", accessor: "enableLpi", "disableSorting": true, Cell: ({ cell: { value } }) => { return value ? <Icon color="info" sx={{ fontSize: "18px!important" }}>done</Icon> : <span></span> } },
        // { Header: "Spend", accessor: "spend", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value).format('$0,0')}</MDTypography> } },
        { Header: `${type === "Budget" ? "Forecast" : "Budget"}`, accessor: "budget", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
        { Header: "YTD Spend", accessor: "ytdSpend", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
        { Header: "Remaining Budget", accessor: "budgetLeft", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
        {
            Header: `${type}`, accessor: "total", align: "right", Cell: ({ cell: { row: { original }, value } }) => {
                // const val = applyVariables(Number(value), Number(original?.varPercentage));
                const val = Number(value);
                const prev = Number(original["prevtotal"] || 0)
                // if (status !== "DRAFT") {
                    if (Math.trunc(prev) < Math.trunc(val))
                        return <Tooltip title={`over ${type === "Budget" ? "forecast" : "budget"} by ` + formatCurrencyNumeral(val - prev, systemCurrencyDetails)} placement="top"><MDTypography ml={1} variant="caption" color="dark"><Icon color="error" sx={{ ml: .25, pt: .40, fontSize: "16px!important"}}>arrow_upward</Icon>{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography></Tooltip>
                        // return <MDBadge container circular badgeContent={numeral(val).format('$0,0')} color="error" variant="gradient" size="sm" title={"Increased by " + numeral(val - prev).format('$0,0')} />
                    else if (Math.trunc(prev) > Math.trunc(val))
                        return <Tooltip title={`under ${type === "Budget" ? "forecast" : "budget"} by ` + formatCurrencyNumeral(prev - val, systemCurrencyDetails)} placement="top"><MDTypography ml={1} variant="caption" color="dark"><Icon color="success" sx={{ ml: .25, pt: .40, fontSize: "16px!important"}}>arrow_downward</Icon>{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography></Tooltip>
                        // return <><MDBadge container circular badgeContent={numeral(val).format('$0,0')} color="success" variant="gradient" size="sm" title={"Decreased by " + numeral(prev - val).format('$0,0')} /><Icon color="success" size="md">arrow_downward</Icon></>
                    return <MDTypography ml={1} variant="caption" color="dark">{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography>
                // } else {
                //     return <MDTypography ml={1} variant="caption" color="dark">{numeral(val).format('$0,0')}</MDTypography>
                // }
            }
        },
        { Header: "Variance", accessor: "variance", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } }
    ];
}

const getDefaultExportColumns = () => {
    return [
        { Header: "Expense Type", accessor: "expenseType__name" },
        { Header: "Cost Center Code", accessor: "costCentre__code" },
        { Header: "Cost Center Name", accessor: "costCentre__name" },
        { Header: "Account Code", accessor: "account__code" },
        { Header: "Account Name", accessor: "account__name" },
        { Header: "Vendor Code", accessor: "vendor__code" },
        { Header: "Vendor Name", accessor: "vendor__name" },
        { Header: "Assumption", accessor: "assumption" },
        { Header: "Notes", accessor: "notes" },
        // { Header: "Cost Pool", accessor: "costPool__name" },
        // { Header: "Sub Cost Pool", accessor: "subCostPool__name" },
        // { Header: "Tower", accessor: "tower__name" },
        // { Header: "Sub Tower", accessor: "subTower__name" },
        // { Header: "Solution Offering", accessor: "solutionOffering__name" },
        // { Header: "Business Unit", accessor: "businessUnit__name" },
        // { Header: `${type}`, accessor: "total" },
    ];
}

const defaultBudgetCostCentreListColumns =(systemCurrencyDetails)=> [
    {
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
                <Icon fontSize="medium">{row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
            </span>
        ),
        width: 50,
        disableSorting: true
    },
    { Header: "Actions", accessor: "actions", "align": "left", "disableSorting": true, minWidth: 100, maxWidth: 100 },
    { Header: "Cost Center", accessor: "costCentre__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Allocated Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
    {
        Header: "Requested Amount", accessor: "actualAmount", align: "right", Cell: ({ cell: { row: { original }, value } }) => {
            if (original["status"] !== "WIP") {
                // const val = applyVariables(Number(value), Number(original?.varPercentage));
                const val = Number(value);
                const newval = Number(original?.amount || 0)
                if (Math.trunc(newval) < Math.trunc(val))
                    return <Tooltip title={"Increased by " + formatCurrencyNumeral(val - newval, systemCurrencyDetails)} placement="top"><MDTypography ml={1} variant="caption" color="dark"><Icon color="error" sx={{ ml: .25, pt: .40, fontSize: "16px!important"}}>arrow_upward</Icon>{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography></Tooltip>
                    // return <MDBadge container circular badgeContent={numeral(val).format('$0,0')} color="error" variant="gradient" size="sm" title={"Increased by " + numeral(val - newval).format('$0,0')} />
                else if (Math.trunc(newval) > Math.trunc(val))
                    return <Tooltip title={"Decreased by " + formatCurrencyNumeral(newval - val, systemCurrencyDetails)} placement="top"><MDTypography ml={1} variant="caption" color="dark"><Icon color="success" sx={{ ml: .25, pt: .40, fontSize: "16px!important"}}>arrow_downward</Icon>{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography></Tooltip>
                    // return <MDBadge container circular badgeContent={numeral(val).format('$0,0')} color="success" variant="gradient" size="sm" title={"Decreased by " + numeral(newval - val).format('$0,0')} />
                return <MDTypography ml={1} variant="caption" color="dark">{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography>
            }
            return <span></span>
        }
    },
    { Header: "Variance", accessor: "variance", align: "right", Cell: ({ cell: { row: { original }, value } }) => { 
        if (original["status"] !== "WIP") {
            return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> 
        }
        return <span></span>
    } },
    { Header: "Status", accessor: "statusName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Submitted On", accessor: "createdAt", Cell: ({ cell: { row: { original }, value } }) => { return <MDTypography variant="caption" color="dark">{original["status"] !== "WIP" ? (value ? moment(value).format("MMM DD YYYY") : "") : ""}</MDTypography> } },
    { Header: "Submitted By", accessor: "createdByUser__name", Cell: ({ cell: { row: { original }, value } }) => { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start">{original["status"] !== "WIP" ? <><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</> : ""}</MDTypography> } },
];

const getDefaultBudgetListColumns = (type, systemCurrencyDetails) => {
    return [
        {
            Header: () => null, 
            id: 'expander', 
            Cell: ({ row }) => (
                <span {...row.getToggleRowExpandedProps()}>
                    <Icon fontSize="medium">{row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
                </span>
            ),
            width: 50,
            disableSorting: true
        },
        { Header: "Cost Center", accessor: "costCentre__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: `${type === "Budget" ? "Forecast" : "Budget"}`, accessor: "budget", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
        { Header: "YTD Spend", accessor: "ytdSpend", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
        { Header: "Remaining Budget", accessor: "budgetLeft", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
        { Header: `${type}`, accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
        { Header: `Variance`, accessor: "variance", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography> } },
        { Header: "Status", accessor: "budgetHeader__status", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } }
        // { Header: "Submitted By", accessor: "updatedByUser__name", Cell: ({ cell: { row: { original }, value } }) => { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start">{original["status"] !== "WIP" ? <><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</> : ""}</MDTypography> } },
    ]
}

const buildRows = (headerDetails, data, onEdit, onDelete, onComment) => {
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    // const firstMonthIndex =  months.indexOf(headerDetails.financialYearStartMonth)
    let lockMonths = []
    for (let i = 0; i < headerDetails.lockMonths; i++)
    {
        lockMonths.push(months[i])
    }  

    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            const varPercentage = ((r["enableCpi"] ? (headerDetails?.cpi || 0) : 0)) + ((r["enableLpi"] ? (headerDetails?.lpi || 0) : 0))
            let row = {};
            Object.keys(r).forEach((k) => {
                if (["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].includes(k))
                    row[k.replace(/\./g, "__")] = applyVariables(Number(r[k]), Number(varPercentage));
                else
                    row[k.replace(/\./g, "__")] = r[k]
            });

            let ytdSpend = 0
            lockMonths?.map(m => {
                ytdSpend = ytdSpend + (row[m] || 0)
            })
            ytdSpend = Math.trunc(ytdSpend)

            const total = Math.trunc(
                (row?.jan || 0) +
                (row?.feb || 0) +
                (row?.mar || 0) +
                (row?.apr || 0) +
                (row?.may || 0) +
                (row?.jun || 0) +
                (row?.jul || 0) +
                (row?.aug || 0) +
                (row?.sep || 0) +
                (row?.oct || 0) +
                (row?.nov || 0) +
                (row?.dec || 0));

            const prevtotal = Math.trunc(
                (row?.prevjan || 0) +
                (row?.prevfeb || 0) +
                (row?.prevmar || 0) +
                (row?.prevapr || 0) +
                (row?.prevmay || 0) +
                (row?.prevjun || 0) +
                (row?.prevjul || 0) +
                (row?.prevaug || 0) +
                (row?.prevsep || 0) +
                (row?.prevoct || 0) +
                (row?.prevnov || 0) +
                (row?.prevdec || 0));

            row["total"] = total;
            row["prevtotal"] = prevtotal;
            row["budget"] = prevtotal;
            row["ytdSpend"] = ytdSpend
            row["budgetLeft"] = prevtotal - ytdSpend
            row["variance"] = total - prevtotal;
            row["actions"] = (
                <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
                    <Tooltip title="Comment" placement="top">
                        <MDTypography display="flex" alignItems="center" component="a" href="#" onClick={() => (!["APPROVED", "CANCELED"].includes(headerDetails?.status) || row?.hasComments) ?  onComment(row["id"]) : null} variant="caption" color="text" fontWeight="medium" minWidth="25px" maxWidth= "25px">
                            {
                                Boolean(row?.hasComments) && (
                                    <Badge variant="dot" badgeContent="" color="info">
                                        <Icon fontSize="small" >comment</Icon>&nbsp;
                                    </Badge>
                                )
                            }
                            {
                                !row?.hasComments && (
                                    <Icon fontSize="small" color={ !["APPROVED", "CANCELED"].includes(headerDetails?.status) ? 'text' : 'disabled'}>comment</Icon>
                                )
                            }
                        </MDTypography>
                    </Tooltip>
                    {
                        headerDetails?.status && (
                            <>
                                <Tooltip title={`Edit${headerDetails?.status === "DRAFT" ? '' : ' Disabled'}`} placement="top">
                                    <MDTypography display="flex" alignItems="center" ml={1.5} component="a" href="#" onClick={() => headerDetails?.status === "DRAFT" ? onEdit(row["id"]) : null} variant="caption" color="text" fontWeight="medium">
                                        <Icon fontSize="small" color={headerDetails?.status === "DRAFT" ? 'info' : 'disabled'} >edit</Icon>
                                    </MDTypography>
                                </Tooltip>
                                <Tooltip title={`Delete${headerDetails?.status === "DRAFT" ? '' : ' Disabled'}`} placement="top">
                                    <MDTypography display="flex" alignItems="center" ml={1.5} component="a" href="#" onClick={() => headerDetails?.status === "DRAFT" ? onDelete(row["id"]) : null} variant="caption" color="text" fontWeight="medium">
                                        <Icon fontSize="small" color={headerDetails?.status === "DRAFT" ? 'error' : 'disabled'}>delete</Icon>
                                    </MDTypography>
                                </Tooltip>
                            </>
                        )
                    }
                </MDBox>
            );
            rows.push(row);
        });
    }
    return rows.sort((a, b) => Math.abs(b.variance) - Math.abs(a.variance));
}

const buildCcBudgetRows = (data, onReject) => {
    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, "__")] = r[k]
            });
            row["actions"] = row["status"] === "SUBMITTED" ? (
                <Tooltip title="Reject" placement="top">
                    <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onReject(row["costCentreId"])}>
                        <Icon fontSize="small" color="error">close</Icon>
                    </IconButton>
                </Tooltip>
                // <MDTypography color="error" display="flex" alignItems="center" component="a" href="#" onClick={() => onReject(row["costCentreId"])} variant="caption" fontWeight="medium">
                //     <Icon fontSize="small" color="error">close</Icon>&nbsp;Reject
                // </MDTypography>
            ) : undefined;
            rows.push(row);
        });
    }
    return rows.sort((a, b) => Math.abs(b.variance) - Math.abs(a.variance));
}

const SubRowAsync = ({ budgetId, headerDetails, columnsList, lookups, setRefresh, row: { original } }) => {
    const { showCustomDrawer, hideDrawer, showPrompt, showSnackbar, showAlert, showCustomForm } = useYADialog();
    const handleError = useHandleError();
    const [step, setStep] = useState("LOADING");
    const [rows, setRows] = useImmer([]);
    const { monthlyView } = useContext(BudgetContext);
    const [refreshSubRow, setRefreshSubRow] = useState(null);
    const commentsDrawerRef = useRef();
    const domain = getDomain();
    const { expenseTypes, accounts, costCentres, vendors } = lookups;

    const handleClose = () => {
        setRefreshSubRow(Math.random())
    }

    const handleCloseCommentsDrawer = () => {
        hideDrawer(commentsDrawerRef.current);
        handleClose();
    };

    const handleEdit = (pkId) => {
        showCustomForm(`Edit ${headerDetails.type} Item`, () => <BudgetItemForm onClose={handleClose} mode="edit" budgetId={budgetId} headerDetails={headerDetails} pkId={pkId} accounts={accounts} expenseTypes={expenseTypes} costCentres={costCentres} vendors={vendors} />, handleClose, "edit", pkId, "md");
    }

    const deleteBudgetItem = async (pkId) => {
        const response = await Axios.delete(`${domain}/api/budgets/budgetItem/${pkId}`);
        if (response.data && response.data.result === true) {
            showSnackbar(response.data?.message, "success");
            handleClose(true);
            setRefresh(Math.random())
        }
        else {
            showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
    }

    const handleDeleteSuccess = (pkId) => {
        deleteBudgetItem(pkId);
    }
    const handleDelete = (pkId) => {
        showPrompt("Delete", "Are you sure you want to delete?", () => handleDeleteSuccess(pkId));
    }

    const handleDeleteMultiple = (selectedRows) => {
        showPrompt('Delete', 'Are you sure you want to delete?', () => deleteMultipleBudgetItem(selectedRows));
    };

    const deleteMultipleBudgetItem = async (pkId) => {
        const response = await Axios.delete(`${domain}/api/budgets/multipleBudgetItem`, { data: pkId });
        if (response.data && response.data.result === true) {
            showSnackbar(response.data?.message, "success");
            handleClose(true);
            setRefresh(Math.random())
        }
        else {
            showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
    }


    const handleComment = (commentTypePkId) => {
        // const mode = headerDetails?.status === "DRAFT" ? "edit" : "";
        commentsDrawerRef.current = showCustomDrawer(() => <CommentsDrawer mode="edit" commentType="budget-item" commentTypePkId={commentTypePkId} onClose={handleCloseCommentsDrawer} />, 500, "temporary");
    };

    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/budgets/${budgetId}/detailsListByCostCentre/${original?.costCentreId}`);
            if (err) {
                handleError(err);
            }
            else {
                if (data && Array.isArray(data) && data.length > 0) {
                    setRows(buildRows(headerDetails, data, handleEdit, handleDelete, handleComment));
                    setStep("LOADED");
                }
                else {
                    setStep("EMPTY");
                }
            }
        }
        if (headerDetails)
            getList();
    }, [headerDetails, refreshSubRow])

    const columns = columnsList.filter(c => {
        if (original?.status === "SUBMITTED") {
            if (c.Header === "CPI") return headerDetails?.enableCpi;
            if (c.Header === "LPI") return headerDetails?.enableLpi;
        }
        if (!monthlyView && (["costPool__name", "subCostPool__name", "tower__name", "subTower__name", "jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].includes(c.accessor)))
            return false;
        return c.Header !== "Cost Center" && c.Header !== "CPI" && c.Header !== "LPI";
    });

    if (step === "EMPTY") {
        return (<MDBox mt={1} textAlign="center" size="20">
            <MDTypography variant="button" fontWeight="bold" color="text">No Data</MDTypography>
        </MDBox>);
    }

    return step === "LOADED" ? (
        <Card sx={{ height: "100%", mt: 1 }} px={0}>
            <DataTable
                variant="subtable"
                table={{ columns, rows }}
                showTotalEntries={true}
                isSorted={true}
                entriesPerPage={{ defaultValue: 15, entries: [15, 50, 100, 150, 200] }}
                pageSizeVal={15}
                // canSearch={headerDetails?.status === "DRAFT" ? true : false}
                canSearch={true}
                canFilter={true}
                // hideFooterForMinRecords={true}
                deleteMultiple={true}
                isSelectable={headerDetails?.status === "DRAFT" ? true : false}
                onDeleteMultiple={handleDeleteMultiple}
            />
        </Card>
    ) : (<MDBox mt={1} textAlign="center" size="20">
        <CircularProgress color="info" />
    </MDBox>);
}

const getExportColumnsList = (financialYearStartMonth, type) => {
    const columnsListVal = [...getDefaultExportColumns(type)];
    const fiscalMonthsArray = getFiscalMonthsArray(financialYearStartMonth);
    fiscalMonthsArray.forEach(fiscalMonth => {
        columnsListVal.push({ Header: fiscalMonth, accessor: fiscalMonth.toLowerCase() });
    });
    return columnsListVal;
};

const getColumnsList = (financialYearStartMonth, headerDetails, systemCurrencyDetails) => {
    const columnsListVal = getDefaultColumnsList(headerDetails?.type, systemCurrencyDetails)
    const fiscalMonthsArray = getFiscalMonthsArray(financialYearStartMonth);
    fiscalMonthsArray.forEach(fiscalMonth => {
        columnsListVal.push({
            Header: fiscalMonth, accessor: fiscalMonth.toLowerCase(), align: "right", Cell: ({ cell: { row: { original }, value } }) => {
                // const val = applyVariables(Number(value), Number(original?.varPercentage));
                const val = Number(value);
                const prev = Number(original["prev" + fiscalMonth.toLowerCase()] || 0)
                // if (status !== "DRAFT") {
                    if (Math.trunc(prev) < Math.trunc(val))
                        return <Tooltip title={`over ${headerDetails?.type === "Budget" ? "forecast" : "budget"} by ` + formatCurrencyNumeral(val - prev, systemCurrencyDetails)} placement="top"><MDTypography ml={1} variant="caption" color="dark"><Icon color="error" sx={{ ml: .25, pt: .40, fontSize: "16px!important"}}>arrow_upward</Icon>{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography></Tooltip>
                        // return <MDBadge container circular badgeContent={numeral(val).format('$0,0')} color="error" variant="gradient" size="sm" title={"Increased by " + numeral(val - prev).format('$0,0')} />
                    else if (Math.trunc(prev) > Math.trunc(val))
                        return <Tooltip title={`under ${headerDetails?.type === "Budget" ? "forecast" : "budget"} by ` + formatCurrencyNumeral(prev - val, systemCurrencyDetails)} placement="top"><MDTypography ml={1} variant="caption" color="dark"><Icon color="success" sx={{ ml: .25, pt: .40, fontSize: "16px!important"}}>arrow_downward</Icon>{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography></Tooltip>
                        // return <MDBadge container circular badgeContent={numeral(val).format('$0,0')} color="success" variant="gradient" size="sm" title={"Decreased by " + numeral(prev - val).format('$0,0')} />
                    return <MDTypography ml={1} variant="caption" color="dark">{formatCurrencyNumeral(val, systemCurrencyDetails)}</MDTypography>
                // } else {
                //     return <MDTypography ml={1} variant="caption" color="dark">{numeral(val).format('$0,0')}</MDTypography>
                // }
            }
        });
    });
    columnsListVal.unshift({ Header: "Actions", accessor: "actions", "align": "left", "disableSorting": true, minWidth: 120, maxWidth: 120 });
    return columnsListVal;
};
const BudgetDetails = () => {
    const { budgetId } = useParams();
    const { loading: lookupsLoading, expenseTypes, accounts, costCentres, vendors } = useBudgetLookups({"isCCOwner": false});

    if (lookupsLoading) {
        return <YASkeleton variant="dashboard-loading" />;
    }

    return <BudgetDetailsContainer budgetId={budgetId} lookups={{ expenseTypes, accounts, costCentres, vendors }} />
}


const BudgetDetailsContainer = ({ budgetId, lookups }) => {

    const domain = getDomain();
    const handleError = useHandleError();
    const { showUploadDialog, showCustomForm, showAlert, showPrompt, showSnackbar } = useYADialog();
    const [headerDetails, setHeaderDetails] = useState(null);
    const [step, setStep] = useState("LOADING");
    const [bStep, setBStep] = useState("LOADING");
    const [rows] = useImmer([]);
    const [budgetCostCentreListRows, setBudgetCostCentreListRows] = useImmer([]);
    const [refresh, setRefresh] = useState(null);
    const [monthlyView, setMonthlyView] = useState(false);
    const [chargeBack, setChargeBack] = useState(false)
    const [typeFilter, setTypeFilter] = useState("manage")
    const { expenseTypes, accounts, costCentres, vendors } = lookups;
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;
    // const commentsDrawerRef = useRef();

    const tabStyles = (_theme, { selected }) => ({
        color: selected ? "#435EC3" : "#adadad",
        textTransform: "none",
        backgroundColor: "#F7F8FD",
        "& .MuiButtonBase-root": {
            fontSize: "18px!important",
            transform: "none",
            backgroundColor: "#435EC3",

        },
        "&::after": selected ? {
            content: '""',
            position: "absolute",
            bottom: 0,
            height: 4,
            width: "60%",
            borderRadius: "0px",
            backgroundColor: "#435EC3"
        } : {}
    });

    const handleClose = (reload = false) => {
        if(reload)
            setRefresh(Math.random())
    }

    useEffect(async () => {
        if (headerDetails?.yearNameId) {
            const chargeBackMonthly = await getChargeBackMonthly(headerDetails.yearNameId);
            if (chargeBackMonthly.length > 0) {
                setChargeBack(true)
            } else {
                setChargeBack(false)
            }
        }
    }, [refresh, headerDetails])

    // const handleCloseCommentsDrawer = () => {
    //     hideDrawer(commentsDrawerRef.current);
    //     if (headerDetails?.status === "DRAFT")
    //         handleClose();
    // };

    // const handleComment = (commentTypePkId) => {
    //     const mode = headerDetails?.status === "DRAFT" ? "edit" : "";
    //     commentsDrawerRef.current = showCustomDrawer(() => <CommentsDrawer mode={mode} commentType="budget-item" commentTypePkId={commentTypePkId} onClose={handleCloseCommentsDrawer} />, 500, "temporary");
    // };

    // const handleEdit = (pkId) => {
    //     console.log(expenseTypes)
    //     showCustomForm(`Edit ${headerDetails.type} Item`, () => <BudgetItemForm onClose={handleClose} mode="edit" budgetId={budgetId} headerDetails={headerDetails} pkId={pkId} accounts={accounts} expenseTypes={expenseTypes} costCentres={costCentres} vendors={vendors} />, handleClose, "edit", pkId, "md");
    //     // setRows(draft => {
    //     //     let selectedRow = draft.find(r => r.id === pkId);
    //     //     if (selectedRow)
    //     //         selectedRow.isEditMode = true;
    //     // });
    // }

    // const deleteBudgetItem = async (pkId) => {
    //     const response = await Axios.delete(`${domain}/api/budgets/budgetItem/${pkId}`);
    //     if (response.data && response.data.result === true) {
    //         showSnackbar(response.data?.message, "success");
    //         handleClose(true);
    //     }
    //     else {
    //         showAlert("Delete", "Something went wrong. Contact your administrator.");
    //     }
    // }

    // const handleDeleteSuccess = (pkId) => {
    //     deleteBudgetItem(pkId);
    // }

    // const handleDelete = (pkId) => {
    //     showPrompt("Delete", "Are you sure you want to delete?", () => handleDeleteSuccess(pkId));
    // }

    const rejectCCBudget = async (pkId) => {
        const response = await Axios.post(`${domain}/api/budgets/${budgetId}/costcentrereject/${pkId}`);
        if (response.data && response.data.result === true) {
            showSnackbar(response.data?.message, "success");
            handleClose(true);
        }
        else {
            showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
    }

    const handleReject = (pkId) => {
        showPrompt(`Reject ${headerDetails["type"]}`, `Are you sure you want to reject the ${headerDetails["type"].toLowerCase()}?`, () => rejectCCBudget(pkId));
    }

    useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
        if (notification.details?.destinationTable && notification.details?.destinationTable === "budgetDetail") {
            setRefresh(Math.random());
        }
    });

    useEffect(() => {
        async function getDetails() {
            var [err, data] = await fetchRequest.get(`/api/budgets/${budgetId}`);
            if (err) {
                handleError(err);
            }
            else {
                if (data) {
                    setHeaderDetails(data);
                }
            }
        }
        getDetails();
    }, [refresh])

    useEffect(() => {
        async function getBudgetList() {
            var err, data
            if (headerDetails && (headerDetails?.status === "DRAFT" || headerDetails?.status === "CANCELED" || headerDetails?.status === "APPROVED")) {
                setStep("LOADING");
                [err, data] = await fetchRequest.get(`/api/budgets/${budgetId}/detailsListByCC`);
            }
            else {
                setBStep("LOADING");
                [err, data] = await fetchRequest.get(`/api/budgets/${budgetId}/budgetCostCentreList`);
            }

            if (err) {
                handleError(err);
            }
            else {
                if (data && Array.isArray(data) && data.length > 0) {
                    setBudgetCostCentreListRows(buildCcBudgetRows(data, handleReject));
                    setStep("LOADED");
                    setBStep("LOADED");
}
                else {
                    setStep("EMPTY");
                    setBStep("EMPTY");
                }
            }
        }
        if (headerDetails)
            getBudgetList();
        else
            setStep("EMPTY");
    }, [headerDetails, refresh])

    // useEffect(() => {
    //     async function getList() {
    //         setStep("LOADING");
    //         var [err, data] = await fetchRequest.get(`/api/budgets/${budgetId}/detailsListByCC`);
    //         if (err) {
    //             handleError(err);
    //         }
    //         else {
    //             if (data && Array.isArray(data) && data.length > 0) {
    //                 setBudgetCostCentreListRows(buildCcBudgetRows(data, handleReject));
    //                 setStep("LOADED");
    //             }
    //             else {
    //                 setStep("EMPTY");
    //             }
    //         }
    //     }
    //     console.log('here',headerDetails)
    //     if (headerDetails && (headerDetails?.status === "DRAFT" || headerDetails?.status === "CANCELED" || headerDetails?.status === "APPROVED"))
    //         getList();
    //     else
    //         setStep("EMPTY");
    // }, [headerDetails, refresh])

    // useEffect(() => {
    //     async function getBudgetCostCentreList() {
    //         setBStep("LOADING");
    //         var [err, data] = await fetchRequest.get(`/api/budgets/${budgetId}/budgetCostCentreList`);
    //         if (err) {
    //             handleError(err);
    //         }
    //         else {
    //             if (data && Array.isArray(data) && data.length > 0) {
    //                 setBudgetCostCentreListRows(buildCcBudgetRows(data, handleReject));
    //                 setBStep("LOADED");
    //             }
    //             else {
    //                 setBStep("EMPTY");
    //             }
    //         }
    //     }
    //     console.log(headerDetails)
    //     if (headerDetails && (headerDetails?.status !== "DRAFT" && headerDetails?.status !== "CANCELED" && headerDetails?.status !== "APPROVED"))
    //         getBudgetCostCentreList();
    //     else
    //         setStep("EMPTY");
    // }, [headerDetails, refresh])

    const handleAddButtonClick = () => {
        showCustomForm(`New ${headerDetails.type} Item`, () => <BudgetItemForm budgetId={budgetId} headerDetails={headerDetails} accounts={accounts} expenseTypes={expenseTypes} costCentres={costCentres} vendors={vendors} onClose={handleClose} />, null, null, null, 'md', handleClose);
    }

    const handleShowMonthlyView = (checked) => {
        setMonthlyView(checked)
    }

    const handleCsvExport = async () => {
        const exportColumns = getExportColumnsList(headerDetails?.financialYearStartMonth, headerDetails?.type);
        var [err, budgetData] = await fetchRequest.get(`/api/budgets/${budgetId}/detailsList`);
        if (err) {
            handleError(err);
        }
        else {
            var data = [];
            budgetData?.forEach(element => {
                let obj = {}
                Object.keys(element).forEach((k) => {
                    element[k.replace(/\./g, "__")] = element[k]
                })
                exportColumns?.forEach((e) => {
                    if (typeof element[e.accessor] == "number")
                        obj[e.Header] = Math.round(element[e.accessor])
                    else
                        obj[e.Header] = element[e.accessor]
                })
                data.push(obj)
            });

            const wb = XLSX.utils.book_new()
            const ws = XLSX.utils.json_to_sheet(data)
            XLSX.utils.book_append_sheet(wb, ws, '')
            XLSX.writeFile(wb, `${headerDetails["type"]} ${moment(Date()).format("YYYYMMDDHHmmss")}.csv`)
            await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded ${headerDetails["type"]} data from ${headerDetails?.name}` })
        }
    }

    const handleImportButtonClick = () => {
        const uploadConfig = {
            uploadType: "budgetDetail",
            yearFilter: headerDetails?.yearNameId,
            pkId: budgetId
        };
        showUploadDialog(`Import ${headerDetails["type"]} File`, uploadConfig, handleUploadDialogClose);
    }

    const handleLoadButtonClick = async () => {
        var [err, data] = await fetchRequest.post(`/api/budgets/${budgetId}/loadfromct`);
        if (err) {
            if (err.data?.message) {
                showSnackbar(err.data?.message, "error")
            } else {
                showAlert(`Load From CT ${headerDetails["type"]}`, "Something went wrong. Contact your administrator.");
            }
        }
        else {
            if (data && data.result === true){
                showSnackbar(data.message, "success");
                handleClose(true);
            }
            else
                showAlert(`Load From CT ${headerDetails["type"]}`, "Something went wrong. Contact your administrator.");
        }
    }

    const addButton = headerDetails?.status === "DRAFT" ? (
        <MDBox color="text" display="flex" alignItems="center">
            <FormControlLabel
                control={
                    <MDSwitch name={"showMonthlyView"} checked={monthlyView}
                        variant="standard"
                        color="success"
                        onChange={
                            (_, checked) => {
                                handleShowMonthlyView(checked)
                            }
                        }
                    />
                }
                label={"Show Detailed View"}
                labelPlacement="end"
            />
            <MDButton variant="gradient" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={handleImportButtonClick}>
                Import File
            </MDButton>
            <MDButton sx={{ ml: 4, mr: 3 }} variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
                New Item
            </MDButton>
            {/* <Tooltip title="Download csv">
                <Icon sx={{ cursor: "pointer", fontWeight: "normal" }} fontSize="medium" onClick={handleCsvExport}>
                    download
                </Icon>
            </Tooltip> */}
        </MDBox>
    ) : (
        <MDBox color="text" display="flex" alignItems="center">
            <FormControlLabel
                control={
                    <MDSwitch name={"showMonthlyView"} checked={monthlyView}
                        variant="standard"
                        color="success"
                        onChange={
                            (_, checked) => {
                                handleShowMonthlyView(checked)
                            }
                        }
                    />
                }
                label={"Show Detailed View"}
                labelPlacement="end"
                sx={{ mr: 3 }}
            />
            {/* <Tooltip title="Download csv">
                <Icon sx={{ cursor: "pointer", fontWeight: "normal" }} fontSize="medium" onClick={handleCsvExport}>
                    download
                </Icon>
            </Tooltip> */}
        </MDBox>
    );

    const addButton1 = (<FormControlLabel
        control={
            <MDSwitch name={"showMonthlyView"} checked={monthlyView}
                variant="standard"
                color="success"
                onChange={
                    (_, checked) => {
                        handleShowMonthlyView(checked)
                    }
                }
            />
        }
        label={"Show Detailed View"}
        labelPlacement="end"
        sx={{ mr: 3 }}
    />);

    const columnsList = getColumnsList(headerDetails?.financialYearStartMonth, headerDetails, systemCurrencyDetails);

    const mandatory = rows.every(item => item.costCentre__code !== null && item.account__code !== null);

    const budgetCostCentreListColumns = defaultBudgetCostCentreListColumns(systemCurrencyDetails);
    const budgetListColumns = getDefaultBudgetListColumns(headerDetails?.type, systemCurrencyDetails);


    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <SubRowAsync
                lookups={lookups}
                setRefresh={setRefresh}
                budgetId={budgetId}
                headerDetails={headerDetails}
                columnsList={columnsList}
                row={row}
            />
        ),
        [budgetId, headerDetails]
    );

    if (step === "LOADING" || bStep === "LOADING") {
        return <YASkeleton variant="dashboard-loading" />;
    }

    const sendBudget = async () => {
        var [err, data] = await fetchRequest.post(`/api/budgets/${budgetId}/submit`);
        if (err) {
            console.error(err)
            // handleError(err);
            showAlert("Error", err.data?.message || "Something went wrong. Contact your administrator.");
        }
        else {
            if (data && data.result === true) {
                showSnackbar(data.message, "success");
                handleClose(true);
            }
            else {
                showAlert("Error", data.message || "Something went wrong. Contact your administrator.");
            }
        }
    }

    const resetBudget = async () => {
        var [err, data] = await fetchRequest.delete(`/api/budgets/${budgetId}/reset`);
        if (err) {
            console.error(err)
            // handleError(err);
            showAlert("Submit", "Something went wrong. Contact your administrator.");
        }
        else {
            if (data && data.result === true) {
                showSnackbar(data.message, "success");
                handleClose(true);
            }
            else {
                showAlert("Submit", "Something went wrong. Contact your administrator.");
            }
        }
    }

    const handleSendButtonClick = () => {
        if (mandatory === false) {
            showAlert("Unable to submit.", `Account and Cost Center are required for each ${headerDetails["type"].toLowerCase()} item.`);
        }
        else {
            showPrompt(`Submit ${headerDetails["type"]}`, `Are you sure you want to submit the ${headerDetails["type"].toLowerCase()}?`, () => sendBudget());
        }
    }

    const handleApproveButtonClick = () => {
        showPrompt(`Approve ${headerDetails["type"]}`, `Are you sure you want to approve the ${headerDetails["type"].toLowerCase()}?`, () => sendBudget());
    }

    const handleResetButtonClick = () => {
        showPrompt(`Reset ${headerDetails["type"]}`, `Are you sure you want to reset the ${headerDetails["type"].toLowerCase()}? Resetting a ${headerDetails["type"].toLowerCase()} will permanently delete all the ${headerDetails["type"].toLowerCase()} line items.`, () => resetBudget());
    }

    const cancelBudget = async (pkId) => {
        const response = await Axios.delete(`${domain}/api/budgets/${pkId}`);
        if (response.data && response.data.result === true) {
            showSnackbar(`${headerDetails["type"]} canceled successfully`, "success");
            handleClose(true);
        }
        else {
            showAlert(`Cancel ${headerDetails["type"]}`, "Something went wrong. Contact your administrator.");
        }
    }

    const handleCancelButtonClick = () => {
        showPrompt(`Cancel ${headerDetails["type"]}`, `Are you sure you want to cancel the ${headerDetails["type"].toLowerCase()}?`, () => cancelBudget(budgetId));
    }

    const loadBudget = async () => {
        var [err, data] = await fetchRequest.post(`/api/budgets/${budgetId}/loadintoct`);
        if (err) {
            if (err.data?.message) {
                showSnackbar(err.data?.message, "error")
            } else {
                showAlert(`Set as Baseline ${headerDetails["type"]}`, "Something went wrong. Contact your administrator.");
            }
        }
        else {
            if (data && data.result === true)
                showSnackbar(data.message, "success");
            else
                showAlert(`Set as Baseline ${headerDetails["type"]}`, "Something went wrong. Contact your administrator.");
        }
    }

    const handleLoadBudgetButtonClick = () => {
        showPrompt(`Set as Baseline ${headerDetails["type"]}`, `The ${headerDetails["type"].toLowerCase()} for this year will replace the existing baseline ${headerDetails["type"].toLowerCase()}. Are you sure you want to set this ${headerDetails["type"].toLowerCase()} as the new baseline?`, () => loadBudget(budgetId));
    }

    // const renderAddButton = () => (
    //     <MDBox>
    //         {
    //             (headerDetails["status"] === "DRAFT" && step !== "EMPTY") && (
    //                 <MDButton variant="gradient" color="info" onClick={handleSendButtonClick}>Send</MDButton>
    //             )
    //         }
    //         {
    //             (headerDetails["status"] === "WIP" && bStep !== "EMPTY") && (
    //                 <MDButton variant="gradient" color="info" onClick={handleApproveButtonClick}>Approve</MDButton>
    //             )
    //         }
    //     </MDBox>
    // )

    // const viewUploadRule = () => {
    //     setUploadRuleView(true)
    //     setUploadedFileView(false)
    // }

    const getSecondaryActions = () => {
        let actions = [];
        if (headerDetails["status"] === "WIP" && bStep !== "EMPTY")
            actions.push({ label: "Approve", onClick: handleApproveButtonClick });
        if (headerDetails["status"] === "DRAFT" && step !== "EMPTY") {
            actions.push({ label: "Submit", onClick: handleSendButtonClick });
            actions.push({ label: "Reset", onClick: handleResetButtonClick });
        }
        if (headerDetails["status"] === "DRAFT" || headerDetails["status"] === "WIP")
            actions.push({ label: "Cancel", onClick: handleCancelButtonClick });
        if (headerDetails["status"] === "APPROVED")
            actions.push({ label: "Set as Baseline " + headerDetails["type"], onClick: handleLoadBudgetButtonClick, disabled: chargeBack });
        // uploadFileView ? actions.push({ label: "View Budget Details", onClick: budgetDetails}) : actions.push({ label: "View Uploaded Files", onClick: viewUploadedFiles})
        // uploadRuleView ? actions.push({ label: "View Budget Details", onClick: budgetDetails}) : actions.push({ label: "View Upload Rule", onClick: viewUploadRule})
        return actions;
    }

    const handleUploadDialogClose = (uploadSuccess) => {
        if (uploadSuccess)
            handleClose(true);
    };


    const renderOptions = () => (
        <MDBox display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
            <MDButton variant="gradient" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={handleImportButtonClick}>
                Import File
            </MDButton>
            <MDButton  variant="outlined" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={handleLoadButtonClick}>
                Load From CT
            </MDButton>
            <MDButton variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
                New {headerDetails["type"]} Item
            </MDButton>
        </MDBox>
    )

    const renderHeader = () => (<BudgetHeader headerDetails={headerDetails} />)
    const route = useLocation().pathname.split("/").slice(1);
    let drillDown = { keyMatch: "budget", routeMatch: `/${route[0]}` }
    let previoustitle = `${headerDetails["type"]} Management`

    const getMenuActions = () => {
        let actions = [];
        actions.push({ label: "Download", onClick: handleCsvExport })
        return actions;
    }

    return (
        <BudgetContext.Provider value={{ monthlyView }}>
            <MDBox minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0, md: 6, sm: 6, xs: 6 }}>
                <PageHeader title={headerDetails["type"] + " Details"} subtitle="" keyMatch={drillDown.keyMatch} routeMatch={drillDown.routeMatch} previoustitle={previoustitle} />
                <PageHeader headerComponent={renderHeader} secondaryActions={getSecondaryActions} hideBreadcrumbs={true} noTopPadding={true} />
                <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", borderBottom: "1px solid #edeef3", borderTop: "1px solid #e3e3e3", display: "inline-flex" }} justifyContent="space-between">
                    <MDBox display="flex">
                        <MDButton data-testid={"details"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "manage" })} onClick={() => setTypeFilter("manage")}>
                            <Icon color="dark" fontSize="50%">business_center</Icon>
                            DETAILS
                        </MDButton>
                        <MDButton data-testid={"upload"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "upload" })} onClick={() => setTypeFilter("upload")} >
                            <Icon color="dark" fontSize="50%">file_present</Icon>
                            UPLOADED FILES
                        </MDButton>
                        {/* <MDButton data-testid = {"uploadRule"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "uploadRule" })} onClick={() => setTypeFilter("uploadRule")} >
                        <Icon color="dark" fontSize="50%" sx={{ transform: "rotate(360deg)" }}>file_present</Icon>
                        UPLOAD RULES
                    </MDButton> */}
                    </MDBox>
                </MDBox>
                <MDBox p={3} pt={1}>
                    {
                        (headerDetails?.status === "DRAFT" || headerDetails?.status === "CANCELED" || headerDetails?.status === "APPROVED") && (
                            <>
                                {
                                    (step === 'LOADED' && typeFilter === 'manage') && (
                                        <MDBox>
                                            <Card sx={{ height: "100%", mt: 1 }} px={0}>
                                            <DataTable
                                                    table={{ columns : budgetListColumns, rows: budgetCostCentreListRows }}
                                                    showTotalEntries={true}
                                                    isSorted={true}
                                                    entriesPerPage={{ defaultValue: 15, entries: [15, 50, 100, 150, 200] }}
                                                    pageSizeVal={15}
                                                    canSearch={true}
                                                    primaryActions={addButton}
                                                    menuActionRender={getMenuActions()}
                                                    renderRowSubComponent={renderRowSubComponent}
                                                    canFilter={true}
                                                />
                                            </Card>
                                        </MDBox>
                                    )
                                }
                                {
                                    (step === 'EMPTY' && typeFilter === 'manage') && (
                                        <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
                                            <EmptyState
                                                size="large"
                                                image={new_item_img}
                                                title={headerDetails?.status === "CANCELED" ? `${headerDetails["type"]} Canceled` : `Start Creating Your ${headerDetails["type"]} Now`}
                                                description={headerDetails?.status === "CANCELED" ? "" : `Click on the 'import file' button to import all the ${headerDetails["type"].toLowerCase()} data at once or click on the 'new ${headerDetails["type"].toLowerCase()} item' button to add individual line items.`}
                                                actions={headerDetails?.status === "CANCELED" ? undefined : renderOptions}
                                            />
                                        </MDBox>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        (headerDetails?.status !== "DRAFT" && headerDetails?.status !== "CANCELED" && headerDetails?.status !== "APPROVED") && (
                            <>
                                {
                                    (bStep === "LOADED" && typeFilter === 'manage') && (
                                        <MDBox>
                                            <Card sx={{ height: "100%", mt: 1 }} px={0}>
                                                <DataTable
                                                    table={{ columns: budgetCostCentreListColumns, rows: budgetCostCentreListRows }}
                                                    showTotalEntries={true}
                                                    isSorted={true}
                                                    entriesPerPage={{ defaultValue: 15, entries: [15, 50, 100, 150, 200] }}
                                                    pageSizeVal={15}
                                                    canSearch={true}
                                                    primaryActions={addButton1}
                                                    menuActionRender={getMenuActions()}
                                                    renderRowSubComponent={renderRowSubComponent}
                                                    canFilter={true}
                                                />
                                            </Card>
                                        </MDBox>
                                    )
                                }
                                {
                                    (bStep === "EMPTY" && typeFilter === 'manage') && (
                                        <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
                                            <EmptyState
                                                size="large"
                                                image={no_data_img}
                                                title={"No Data"}
                                            // description={""}
                                            />
                                        </MDBox>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        typeFilter === 'upload' && (
                            <Card sx={{ height: '100%' }} px={0}>
                                <FilteredUploadedFiles containerHeight="calc(100vh - 370px)" canFilter={true} fileType={"Budget"} />
                            </Card>
                        )
                    }
                    {
                        typeFilter === 'uploadRule' && (
                            <Card sx={{ height: '100%' }} px={0}>
                                <FilteredUploadRules containerHeight="calc(100vh - 500px)" tableName={"budgetDetail"} />
                            </Card>
                        )
                    }
                </MDBox>
            </MDBox>
        </BudgetContext.Provider>
    );
};

export default AnimatedRoute(BudgetDetails);