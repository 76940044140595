import { Alert, Card, Icon, IconButton, Modal, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from 'react';
import UploadStep from "components/DataUpload/components/UploadStep";
import PreviewStep from "components/DataUpload/components/PreviewStep";
import MappingStep from "components/DataUpload/components/MappingStep";
import fetchRequest from "utils/fetchRequest";
import { NotificationType, NotificationStatus } from "constants";
import { useYADialog } from "components/YADialog";
import { generateUUID } from 'utils';



const LoadRuleModal = (props) => {
  const { yearFilter, monthFilter, uploadType, onClose, onLoadRuleSave, reloadData, columnFilterCondition, updateAllocation, editValue } = props;
  const [activeStep, setActiveStep] = useState(uploadType ? "upload" : "select-type");
  const { addNotification } = useYADialog();
  const { showSnackbar } = useYADialog();


  const handleUploadDialogClose = () => {
    if (reloadData) {
      reloadData();
    }
    if (onClose) onClose();
  }

  const [uploadSubType] = useState(uploadType);
  const [selectedFile, setSelectedFile] = useState(null)
  const [fileName, setFileName] = useState(null);
  const [ws, setWs] = useState(null);
  const [sourceFields, setSourceFields] = useState([]);
  const [mappingFields, setMappingFields] = useState([]);
  const [ruleName, setRuleName] = useState(props.ruleName || null);
  const [ruleNameError, setRuleNameError] = useState(false)

  const ruleReadOnly = props.ruleName ? true : false

  const handleOnFileUpload = (file, fileName1, ws1, sourceFields1) => {
    setSelectedFile(file)
    setFileName(fileName1);
    setWs(ws1);
    setSourceFields(sourceFields1);
    setActiveStep("preview");
  }

  const handleOnMappingBack = () => {
    setActiveStep("preview");
  }

  const handleOnMappingNext = async (mappingFields1) => {   
    setMappingFields(mappingFields1);
    const uploadId = await uploadFileToDuck(selectedFile, fileName, ws, mappingFields1)    
    onLoadRuleSave(ruleName, uploadId)
    handleUploadDialogClose()
    setActiveStep("finish");
  }

  const handleNext = async () => {
    switch (activeStep) {
      case "select-type": {
        setActiveStep("upload");
        break;
      }
      case "upload": {
        break;
      }
      case "preview": {        
        if (ruleName && ruleName.trim() !== "") {    
          if(!ruleReadOnly){   
            const [err, data] = await fetchRequest.get(`/api/dataflow/checkRuleName/${yearFilter}/${monthFilter}/${ruleName}`);            
            if (err) {
              setRuleNameError(true)
            } else {
              if (data && data.nameCount === 0) {
                setActiveStep("mapping");
              } else {
                setRuleNameError(true)
              }
            }
          }
          else{
            setActiveStep("mapping");
          }
          } 
         else {          
          setRuleNameError(true)
        }
        break;
      }
      case "mapping": {
        break;
      }
      case "finish": {
        break;
      }
      default:
        break;
    }
  };

  const handleBack = () => {
    switch (activeStep) {
      case "select-type": {
        break;
      }
      case "upload": {
        !uploadType && setActiveStep("select-type");
        break;
      }
      case "preview": {
        setActiveStep("upload");
        break;
      }
      case "mapping": {
        setActiveStep("finish");
        break;
      }
      case "finish": {
        break;
      }

      default:
        break;
    }
  };

  const getPresignedUrl = async (fId) => {
    return await fetchRequest.get(`/api/blob/presignedPost/${fId}`);
  }
  
  const uploadFileToDuck = async (selectedFile, fileName, fileData, mappingFields) => {
          var uf = {}
          uf.originalFileName = ruleName+'_'+fileName
          uf.mappingFields = JSON.stringify([...mappingFields, { ruleName: ruleName}])
          uf.yearNameId = yearFilter
          uf.monthNameId = monthFilter
          uf.totalRecords = fileData.length
          // uf.loadedRecords = fileData.length
          uf.destinationTable = "towerConsumption"
          // uf.fileStatus = "Loaded"
          // uf.source = 'GL'          

          const requestId = generateUUID();
          const notification = ({ requestId, type: NotificationType.ProcessFile, message: ` File ${fileName} is uploading`, status: NotificationStatus.Processing, persist: true});
          addNotification(notification);
    
          const [resError, response] = await fetchRequest.post(`/api/dataload/createupload`, uf);
          if (resError) {
            addNotification({
              type: NotificationType.Error,
              message: "Error in processing file",
              status: NotificationStatus.Error,
              persist: true
          });
            console.error("An error occured while creating upload");
            console.error(resError);
            if (resError.data?.message) {
              showSnackbar(resError.data?.message, "error")
              // setReload(Math.random());
            } else {
              showSnackbar("An error occured while processing your request.", "error");
            }
            return false;
          }
    
    
          const [presignedUrlError, presignedUrlResponse] = await getPresignedUrl(response.id);
          if (presignedUrlError) {
            console.error("An error occured while getting presigned url");
            console.error(presignedUrlError);
            if (presignedUrlError.data?.message) {
              showSnackbar(presignedUrlError.data?.message, "error")
              // setReload(Math.random());
            } else {
              showSnackbar("An error occured while processing your request.", "error");
            }
            return false;
          }
    
          const options = new URL(presignedUrlResponse).host.indexOf("blob.core.windows.net") !== -1 ? {
            headers: {
              'X-Ms-Blob-Type': 'BlockBlob',
              'X-Ms-Version': '2023-11-03'
            }
          } : null;
          const [putError,] = await fetchRequest.put(presignedUrlResponse, selectedFile, options);
          if (putError) {
            console.error("An error occured while uploading to blob store");
            console.error(putError);
            showSnackbar("An error occured while processing your request.", "error");
            return false;
          }

          const [uploadedError, uploadedResponse] = await fetchRequest.post(`/api/dataload/uploaded`, { fileId: response.id, requestId: requestId, columnFilterCondition: columnFilterCondition, updateAllocation: updateAllocation, editValue: editValue});
            if (uploadedError) {
                console.error("An error occured while updating file status");
                console.error(uploadedError);
                // setError(true);
                // setFileProcessingState("error");
                return false;
            }
            else if (uploadedResponse?.result === false) {
                console.error("An error occured while updating file status");
                // setError(true);
                // setFileProcessingState("error");
                return false;
            }
            return response["id"]
          // ceSelected.map((a) => a["allocationFileId"] = response.id)
        
  }
  let enableNextBtn = false;
  let enableBackBtn = false;  

  switch (activeStep) {
    case "select-type": {
      enableBackBtn = false;
      if (uploadSubType)
        enableNextBtn = true;
      break;
    }
    case "upload": {
      enableBackBtn = uploadType ? false : true;
      enableNextBtn = false;
      break;
    }
    case "preview": {
      enableBackBtn = true;
      if (ws?.length > 0)
        enableNextBtn = true;
      break;
    }
    case "mapping": {
      enableBackBtn = true;
      if (mappingFields?.length > 0)
        enableNextBtn = true;
      break;
    }
    case "finish": {
      enableBackBtn = false;
      enableNextBtn = false;
      break;
    }
    default:
      break;
  }
  // ${monthFilterName}, FY ${yearFilterName}

  return (
    <Modal open={true} onClose={handleUploadDialogClose}>
      <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <Card
          sx={{
            overflow: 'hidden',
            width: (activeStep === "preview" || activeStep === "review") ? "calc(100vw - 32px)" : undefined,
            height: (activeStep === "preview" || activeStep === "review") ? "calc(100vh - 32px)" : undefined
          }}
        >
          <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
              <MDTypography variant="h6" component="span" color="text">
                { uploadType === 'towerConsumption' ? `Upload Allocation file` : `Upload consumption file`}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <IconButton onClick={handleUploadDialogClose} title="Close">
                <Icon>close</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
          {
            activeStep === "upload" && (
              <>
              <Alert sx={{fontSize:13}} severity="warning">All existing rules will be deleted.</Alert>
              <UploadStep uploadSubType={uploadSubType} onFileUpload={handleOnFileUpload} />
              </>
            )
          }
          {
            activeStep === "preview" && (
              <>
              <MDBox display="grid" justifyContent="center" alignItems="center" >

                  <TextField focused
                    label="Rule Name" sx={{ width: 300, textAlign: "center" }} id="trPortion" name="trPortion" value={ruleName} disabled={ruleReadOnly}
                    onChange={(e) => { setRuleNameError(false); setRuleName(e.target.value) }} margin="dense" variant="outlined" >
                  </TextField>
                  {ruleNameError && <MDTypography variant="caption" color="error">Please give an unique name to the rule</MDTypography>}
                </MDBox>
              <PreviewStep uploadSubType={uploadSubType} ws={ws} />
              </>
            )
          }
          {
            activeStep === "mapping" && (
              <MappingStep uploadSubType={uploadSubType} sourceFields={sourceFields} onMappingBack={handleOnMappingBack} onMappingNext={handleOnMappingNext} hasMore={true} ws = { ws } />
            )
          }
          {
            activeStep !== "mapping" && (
              <MDBox px={2.5} pb={2} pt={1} display="flex" justifyContent="space-between" alignItems="center">
                <MDBox>
                  {
                    enableBackBtn && (
                      <MDButton
                        size="medium"
                        color="info"
                        startIcon={<Icon>arrow_back_ios</Icon>}
                        onClick={handleBack}
                      >
                        Prev
                      </MDButton>
                    )
                  }
                </MDBox>
                {
                  activeStep === "preview" && <MDTypography color="text" variant="subtitle2" fontWeight="medium">Data Preview</MDTypography>
                }
                <MDBox>
                { activeStep !== "mapping" && enableNextBtn && (
                      <MDButton
                        size="medium"
                        color="info"
                        endIcon={<Icon>arrow_forward_ios</Icon>}
                        onClick={handleNext}
                      >
                        {"Next"}
                      </MDButton>
                    )
                  }
                   { activeStep === "mapping" && (
                      <MDButton
                        size="medium"
                        color="info"
                        endIcon={<Icon>arrow_forward_ios</Icon>}
                        onClick={handleNext}
                        disabled = {!enableNextBtn}
                      >
                        {"Finish"}
                      </MDButton>
                    )
                  }
                </MDBox>
              </MDBox>
            )
          }
        </Card>
      </MDBox>
    </Modal>
  )
};

export default LoadRuleModal;