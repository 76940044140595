import MDBox from 'components/MDBox';
// import DataTable from 'components/DataTable';
import ServeSideDataTable from 'components/ServeSideDataTable';
import MDTypography from 'components/MDTypography';
import { Icon, Card, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
// import colors from "assets/theme/base/colors";
import fetchRequest from 'utils/fetchRequest';
import PageHeader from 'components/PageHeader';
import AnimatedRoute from 'components/AnimatedRoute';
import YASkeleton from 'components/YASkeleton';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import useHandleError from 'hooks/useHandleError';
import MDButton from 'components/MDButton';
import moment from 'moment';
// import numeral from 'numeral';
import { useImmer } from 'use-immer';
// import useFetchRequest from "hooks/useFetchRequest";
// import { Autocomplete } from "@mui/material";
// import MDInput from "components/MDInput";
import * as XLSX from 'xlsx';
import { sanitizeCell } from 'utils';
import { useAppController } from "context";
import { formatCurrencyNumeral } from "utils";


const buildColumns = (masterDef, defaultDateFormat, systemCurrencyDetails) => {
  const columns = [];
  if (Array.isArray(masterDef.fields) && masterDef.fields.length > 0) {
    masterDef.fields?.filter(f => !f.hidden)?.forEach((f) => {
      let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
      col['Header'] = f.displayName;
      col['id'] = f.name;
      col['accessor'] = f.schemaName;
      col['Cell'] = ({ cell: { value } }) => {
        if (f.dataType === "currency")
          return <MDTypography key={f.schemaName} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography>
        else if (f.dataType === "datepicker")
          return <MDTypography key={f.schemaName} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
        else if (f.dataType === "portion")
          return <MDTypography key={f.schemaName} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value * 100}%</MDTypography>
        return <MDTypography key={f.schemaName} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value}</MDTypography>
      };
      col['dataType'] = f.filterType || f.type
      col['disableFilters'] = f.disableFilters || false,
        columns.push(col);
    });
  }

  return columns;
};
const buildRows = (pkColumn, data,) => {
  const rows = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach((r) => {
      let row = {};
      Object.keys(r).forEach((k) => {
        row[k.replace(/\./g, '__')] = r[k];
      });
      rows.push(row);
    });
  }
  return rows;
};


const UnallocatedSpend = (props) => {
  const { yearFilter, monthFilter, serverSideDataPaging } = props;
  const [step, setStep] = useState('LOADING');
  const handleError = useHandleError();
  const [masterDef, setMasterDef] = useImmer(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [ pgIndx, setPgIndx] = useState( serverSideDataPaging ? 0 : null)
  const [ pageSize, setPageSize] = useState( serverSideDataPaging ? 50 : null)
  const [ totalNoOfPages, setTotalNoOfPages ] = useState(0)
  const [ totalItems, setTotalItems ] = useState(null)
  const defaultDateFormat = "DD/MM/YYYY";
  const [ search, setSearch ] = useState(null)
  const [lookUpList, setLookUpList] = useImmer({});
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
  const [ controller ] = useAppController();
  const { systemCurrencyDetails } = controller;
  const [filters, setFilters] = useImmer([]);
  const [ filterDef, setFilterDef ] = useImmer([])
  // const { response: rulesRes, error: rulesErr, loading: rulesLoading } = useFetchRequest(`/api/dataflow/categories`);
  // const [yearFilter, setYearFilter] = useState(null);
  // const [monthFilter, setMonthFilter] = useState(null);
  // const [levels, setLevels] = useImmer([]);

  let handlePageOption = () => {
    onPageOptionsChange({ currentPage: 0, pageSize: pageSize })
  }

  const onPageOptionsChange = (pageOption) => {
    // if (serverSideDataPaging) {
    //   setLoading(true) 
    // }
    setPgIndx(pageOption.currentPage);
    setPageSize(pageOption.pageSize)
  }

  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
        draft.globalFilter = latestGlobalFilter;
        draft.filters = latestFilters;
    });
    if (serverSideDataPaging) {
      setFilters( draft => {
        const filters = latestFilters?.filters?.map(f => f.id)
        if ( filters && filters.length > 0) {
          draft.push(...filters) 
        }
      })
    }
  }
  
  const getParsedAppliedFilters = () => {
    const filters = []
    filtersState['filters']?.forEach( filter => {
      if (filter.value.values?.length > 0) {
        const obj = {}
        obj['name'] = filter.id,
        obj['operator'] = filter.value.operator,
        obj['value'] = filter.value.values
        obj['type'] = filter.value.type
        filters.push(obj) 
      }
    })
    if (filters.length > 0) {
      return filters 
    } else {
      return null
    }
  };

  // useEffect(() => {
  //   if (!rulesLoading) {
  //     if (rulesErr !== null) {
  //       handleError(rulesErr);
  //     }
  //     else if (rulesRes !== null) {
  //       let currentYearIndex = rulesRes.years?.length - 1;
  //       const currentMonthNumber = (new Date().getMonth()) + 1;
  //       const currentMonth = rulesRes.months.find(m => m.id === currentMonthNumber);
  //       const currentFinancialYear = rulesRes.currentFinancialYear;
  //       if (currentFinancialYear) {
  //         const index = rulesRes.years?.map(y => y.name).indexOf(currentFinancialYear.value);
  //         if (index > -1)
  //           currentYearIndex = index;
  //       }
  //       setLevels(rulesRes);
  //       setYearFilter(rulesRes.years[currentYearIndex]?.id);
  //       setMonthFilter(currentMonth?.id);
  //     }
  //   }
  // }, [rulesLoading, rulesRes]);
  useEffect(() => {
    async function getMasterDef() {
      var [err, data] = await fetchRequest.get(`/api/unAllocated/unAllocatedSpendDef`);
      if (err) {
        handleError(err);
      } else {
        setMasterDef(data);
        setColumns(buildColumns(data, defaultDateFormat, systemCurrencyDetails));
      }
    }
    getMasterDef();
  }, []);

  useEffect(() => {
    async function getList() {
      setLoading(true);
      setStep('LOADING');
      if (yearFilter && monthFilter) {
        var [err, data] = await fetchRequest.get(`/api/unAllocated/unAllocatedSpend/${yearFilter}/${monthFilter}`);
        if (err) {
          handleError(err);
        } else {
          if (data && Array.isArray(data) && data.length > 0) {
            setRows(buildRows(masterDef.pkColumn || 'id', data, masterDef.fields));
            setStep('LOADED');
          } else {
            setRows([]);
            setStep('EMPTY');
          }
        }
        setLoading(false);
      }
    }
    if (masterDef && !serverSideDataPaging) {
      getList();
    }
  }, [masterDef, yearFilter, monthFilter]);

  console.log(search);
  

  useEffect(() => {
    async function getList() {
      setLoading(true);
      setStep('LOADING');
      if (yearFilter && monthFilter) {
        const appliedFilters = getParsedAppliedFilters()
        const filters = { filters: appliedFilters ? JSON.stringify(appliedFilters) : null, searchString: search ? search?.trim() : null}
        var [err, response] = await fetchRequest.post(`/api/unAllocated/unAllocatedSpend/${yearFilter}/${monthFilter}?pgIndx=${pgIndx}&pageSize=${pageSize}`, filters);
        if (err) {
          handleError(err);
        } else {
          const { data, totalItems, totalPages }  = response
          if (data && Array.isArray(data) && data.length > 0) {
            setRows(buildRows(masterDef.pkColumn || 'id', data, masterDef.fields));
            setTotalItems(totalItems)
            setTotalNoOfPages(totalPages)
            setStep('LOADED');
          } else {
            setRows([]);
            setTotalItems(totalItems)
            setTotalNoOfPages(totalPages)
            setStep('EMPTY');
          }
        }
        setLoading(false);
      }
    }
    if (masterDef && serverSideDataPaging) {
      getList();
    }
  }, [masterDef, yearFilter, monthFilter, pageSize, pgIndx, search, filtersState.filters]);

  if (step === 'LOADING') {
    return <YASkeleton variant="dashboard-loading" />;
  }

  const { displayName, desc, message, canFilter } = masterDef;

  const handleDownload = async () => {
    if (columns && rows) {
      var data = [];
      rows.forEach(element => {
        let obj = {}
        columns.forEach((e) => {
          if(element[e.accessor]){
            element[e.accessor] = sanitizeCell(element[e.accessor])
          }
          if (e.type === 'date' && element[e.accessor] !== null) {
            element[e.accessor] = moment(element[e.accessor]).format(defaultDateFormat);
          }
          obj[e.Header] = element[e.accessor]
        })
        data.push(obj)
      });
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(data)
      XLSX.utils.book_append_sheet(wb, ws, 'test')
      XLSX.writeFile(wb, `Unallocated Asset Spend ${moment(Date()).format("YYYYMMDDHHmmss")}.csv`)
      await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded [Unallocated Asset Spend] list` })
    }

    handleCloseMenuItem();
  }

  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  };



  const renderPrimaryActions = () => {
    return (
      <>
        <Menu
          anchorEl={openMenu}
          anchorReference={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(openMenu)}
          onClose={handleCloseMenu}
        >
            <MenuItem data-testid={"download"} key={'download'} onClick={handleDownload}>{"Download"}</MenuItem>
        </Menu>

        &nbsp;
        <MDButton
          disableRipple
          color="dark"
          variant="text"
          onClick={handleOpenMenu}
          sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
          iconOnly
        >
          <Icon px={0} py={0}>more_horiz</Icon>
        </MDButton>

      </>
    )
  }




  // const renderFilters = () => (
  //   <>
  //     <MDBox display="flex">
  //       <Autocomplete
  //         disableClearable={true}
  //         value={yearFilter}
  //         options={levels.years}
  //         onChange={(event, newValue) => {
  //           setYearFilter(newValue.id)
  //         }}
  //         color="text"
  //         fontWeight="medium"
  //         sx={{
  //           ml: 1.5,
  //           "& .MuiOutlinedInput-root": {
  //             height: 42,
  //             minWidth: 130,
  //             boxShadow: "0 8px 16px #1a488e1f"
  //           },
  //           "& .MuiOutlinedInput-input": {
  //             fontSize: 14
  //           },
  //           "& .MuiOutlinedInput-input.MuiAutocomplete-input": {
  //             padding: .5
  //           }
  //         }}
  //         isOptionEqualToValue={(option, value) => {
  //           return option.id === value
  //         }}
  //         getOptionLabel={option => {
  //           if (typeof option === "number")
  //             return levels.years.find(op => op.id === option)?.name;
  //           return option.name
  //         }}
  //         renderInput={(params) => <MDInput data-testid={"yearfilter"} label="Year" {...params} />}
  //       />
  //       <Autocomplete
  //         disableClearable={true}
  //         value={monthFilter}
  //         options={levels.months}
  //         onChange={(event, newValue) => {
  //           setMonthFilter(newValue.id)
  //         }}
  //         color="text"
  //         fontWeight="medium"
  //         sx={{
  //           ml: 0.5,
  //           "& .MuiOutlinedInput-root": {
  //             height: 42,
  //             width: 100,
  //             boxShadow: "0 8px 16px #1a488e1f"
  //           },
  //           "& .MuiOutlinedInput-input": {
  //             fontSize: 14
  //           },
  //           "& .MuiOutlinedInput-input.MuiAutocomplete-input": {
  //             padding: .5
  //           }
  //         }}
  //         isOptionEqualToValue={(option, value) => {
  //           return option.id === value
  //         }}
  //         getOptionLabel={option => {
  //           if (typeof option === "number")
  //             return levels.months.find(op => op.id === option)?.name;
  //           return option.name
  //         }}
  //         renderInput={(params) => <MDInput data-testid={"monthfilter"} label="Month"{...params} />}
  //       />
  //     </MDBox>
  //   </>
  // )
  return (
    <>
        <PageHeader title={displayName} subtitle={desc} message={message}  primaryActionComponent={renderPrimaryActions} hideBreadcrumbs={true} anchor={displayName} settingsHeaderFormat={true}/>
        <MDBox p={3}>
          {(step === 'EMPTY' && (!loading && rows?.length === 0 && !search && !filtersState)) && (
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight="calc(100vh - 300px)"
            >
              <EmptyState
                size="large"
                image={new_item_img}
                title={`No ${displayName} Yet`}
              />
            </MDBox>
          )}
          {(step === 'LOADED' || (step === 'EMPTY' && (masterDef.filters?.length > 0 || search || filtersState))) &&
            <>
              <Card sx={{ height: '100%' }} px={0}>
                <ServeSideDataTable
                  containerMaxHeight={500}
                  table={{ columns, rows }}
                  showTotalEntries={true}
                  isSorted={true}
                  newStyle1={true}
                  noEndBorder
                  entriesPerPage={true}
                  canSearch={true}
                  // primaryActions={renderPrimaryActions()}
                  canFilter={canFilter}
                  filtersState={filtersState}
                  onFiltersStateUpdate={handleOnFiltersStateUpdate}
                  serverSideDataPaging={serverSideDataPaging}
                  serverSideFetching={serverSideDataPaging}
                  schemaName={'asset'}
                  lookUpList={lookUpList}
                  setLookUpList={setLookUpList}
                  loading={loading}
                  pgIndx={pgIndx}
                  setPgIndx={setPgIndx}
                  onPageOptionsChange={onPageOptionsChange}
                  pageSizeVal={pageSize}
                  totalNoOfPages={totalNoOfPages}
                  totalItems={totalItems}
                  setSrch={setSearch}
                  srch={search}
                  handlePageOption={handlePageOption}
                  filterDef={filterDef}
                  setFilterDef={setFilterDef}
                  filters={filters}
                  setFilters={setFilters}
                />

              </Card>
            </>
          }

        </MDBox>
    </>
  );
};

export default AnimatedRoute(UnallocatedSpend);