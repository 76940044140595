import { Alert, Avatar, CircularProgress, Icon, Stack, Tooltip } from "@mui/material";
import AnimatedComponent from "components/AnimatedComponent";
import EmptyState from "components/EmptyState";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useYADialog } from "components/YADialog";
import YASkeleton from "components/YASkeleton";
import { useAppController } from "context";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { parseJsonString } from "utils";
import fetchRequest from "utils/fetchRequest";

const checkboxStyles = ({ palette: { yellow, white, dark, secondary }, functions: { pxToRem } }, { enabled }) => ({
    height: pxToRem(20),
    width: pxToRem(20),
    backgroundColor: enabled ? yellow.main : white.main,
    color: enabled ? dark.main : secondary.main,
    fontSize: enabled ? pxToRem(14) : pxToRem(22),
})


const LicenseDetails = ({ expiresOn, enabledPackages, disabledPackages }) => {

    const [controller,] = useAppController();
    const { appDef } = controller;
    const licenseSupportEmail = appDef.licenseSupportEmail

    return <>
        {
            enabledPackages?.length > 0 &&
            <MDBox pt={2}>
                {
                    expiresOn &&
                    <Stack mt={1} mb={3} gap={1}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">License expires on</MDTypography>
                        <MDTypography variant="caption" color="text">{moment(expiresOn).format("MMM DD, YYYY")}</MDTypography>
                    </Stack>
                }
                <MDTypography variant="caption" color="text" fontWeight="medium">Available Features</MDTypography>
                <MDBox mt={1} px={3.5} py={3} borderRadius="6px" border="1px solid #ddd" width="100%" maxWidth={600}>
                    <Stack gap={3}>
                        {
                            enabledPackages?.map(pkg => (
                                <Stack key={pkg.name} gap={3} flexDirection="row" alignItems="center">
                                    <Avatar sx={theme => checkboxStyles(theme, { enabled: true })}>
                                        <Icon>done</Icon>
                                    </Avatar>
                                    <Stack gap={.5}>
                                        <MDTypography variant="caption" color="dark" fontWeight="medium">{pkg.displayName}</MDTypography>
                                        <MDTypography variant="caption" color="text">{pkg.desc}</MDTypography>
                                    </Stack>
                                </Stack>
                            ))
                        }
                        {
                            disabledPackages?.map(pkg => (
                                <Tooltip key={pkg.name} arrow={false} placement="top-start" title="Unlock these packages by uploading a valid license file.">
                                    <Stack gap={3} flexDirection="row" alignItems="center">
                                        <Avatar sx={theme => checkboxStyles(theme, { enabled: false })}>
                                            <Icon>radio_button_unchecked</Icon>
                                        </Avatar>
                                        <Stack gap={.5}>
                                            <MDTypography variant="caption" color="dark" fontWeight="medium">{pkg.displayName}</MDTypography>
                                            <MDTypography variant="caption" color="text">{pkg.desc}</MDTypography>
                                        </Stack>
                                    </Stack>
                                </Tooltip>
                            ))
                        }
                    </Stack>
                </MDBox>
            </MDBox>
        }
        <Alert severity="info"
            sx={{ width: 600, marginTop: 3, marginBottom: 5, fontSize: "14px", textAlign: "left" }}
        >
            <MDTypography variant="button" component="p" fontWeight="medium">Need Help?</MDTypography>
            {"If you have any questions or need assistance with your license activation or upgrade, please contact us at "}
            <a href={`mailto:${licenseSupportEmail}`} target="_blank" rel="noreferrer">{licenseSupportEmail}</a>
        </Alert>
    </>
}

const dropBoxStyles = ({ palette: { white, info }, functions: { pxToRem } }, { isDragActive }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `${pxToRem(2)} dashed #ddd`,
    borderRadius: pxToRem(6),
    py: 3,
    maxWidth: 600,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#f0f8ff",
    },
    "& .dropBox-icon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        height: pxToRem(56),
        width: pxToRem(56),
        fontSize: pxToRem(56),
        mb: 1.5,
        color: info.main,
        ...(isDragActive && {
            fontSize: pxToRem(36),
            color: white.main,
            backgroundColor: info.main,
        })
    }
})

const Licensing = () => {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(false);
    const [validFile, setValidFile] = useState(true);
    const [licFile, setLicFile] = useState(null);
    const [licenseDetails, setLicenseDetails] = useState(null);
    const { showAlert, showSnackbar } = useYADialog();
    const navigate = useNavigate();

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        maxFiles: 1,
        onDrop: async (acceptedFiles) => {
            try {
                var reader = new FileReader();
                reader.onload = function (e) {
                    const parsedFile = parseJsonString(e.target.result);
                    if ((parsedFile?.signature || "").trim() !== "") {
                        setValidFile(true);
                        setLicFile(acceptedFiles[0]);
                    }
                    else
                        setValidFile(false);
                };

                const fileExtension = (acceptedFiles[0]?.name || "").split(".").pop().toLowerCase();
                if (fileExtension !== "lic")
                    setValidFile(false);
                else {
                    reader.readAsText(acceptedFiles[0]);
                }
            } catch (err) {
                // showAlert("Upload a valid .xlsx or .csv file");
                setValidFile(false);
                console.error("Upload a valid .lic file", err);
                // setFileChecking(false);
            }
        }
    });

    // console.log(acceptedFiles, fileRejections)

    useEffect(() => {
        async function getLicenseDetails() {
            var [err, data] = await fetchRequest.get(`/api/license/details`);
            if (err) {
                console.error(err);
                setError(err);
            }
            else {
                setLicenseDetails(data);
            }
            setLoading(false);
        }
        setLoading(true);
        getLicenseDetails();
    }, [])

    const handleOnLicFileUpload = async () => {

        setSubmitting(true);
        const data = new FormData();
        data.append("licFile", licFile);
        const [err, response] = await fetchRequest.post(`/api/license`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (err) {
            showAlert('Licensing', 'Something went wrong. Contact your administrator.');
        }
        else
            if (response && response.result === true) {
                showSnackbar(response.message || 'License applied successfully', 'success');
                navigate("/admin/settings/licensing", { replace: true });
                setTimeout(() => {
                    // reload app to get new permissions
                    window.location.reload();
                }, 1000);
            }
            else if (response && response.result === false) {
                showAlert('Licensing', response.message || 'Something went wrong. Contact your administrator.');
            }
        setSubmitting(false);

    };

    if (loading) {
        return <YASkeleton variant="loading" />;
    }

    if (error) {
        return <MDBox width="100%" display="flex" alignItems="center" justifyContent="center" p={4}>
            <EmptyState
                size="medium"
                variant="error"
                iconName="error"
                title={"Oops something went wrong!"}
                description={"Contact your administrator for more information."}
            />
        </MDBox>;
    }

    const expiresOn = licenseDetails?.expiresOn;
    const enabledPackages = licenseDetails?.packages?.filter(l => l.enabled === true);
    const disabledPackages = licenseDetails?.packages?.filter(l => l.enabled !== true);
    const licensed = enabledPackages?.length > 0;
    const licenseErrors = licenseDetails?.errors?.length > 0;

    return <MDBox flex={1} px={4} pt={3}>
        <MDBox display="flex" flexDirection="column">
            <MDTypography data-testid={"Licensing"} variant="button" color="dark" fontWeight="medium">Licensing</MDTypography>
            <MDTypography data-testid={"Manage license with ease"} mb={2} variant="button" color="text">Manage license with ease</MDTypography>
            {licenseErrors &&
                <Alert severity="error"
                    sx={{ width: 600, marginTop: 3, marginBottom: 5, fontSize: "14px", textAlign: "left" }}
                >
                    <MDTypography variant="button" component="p" fontWeight="medium">License activation error</MDTypography>
                    {"An error occured while activation the license. Please contact your administrator for more details."}
                </Alert>
            }
            <MDTypography mt={2} mb={2} variant="caption" color="text" fontWeight="medium">{licensed ? "Upgrade License" : "License Activation"}</MDTypography>
            <MDBox {...getRootProps({ className: 'dropzone' })} sx={theme => dropBoxStyles(theme, { isDragActive })}>
                <input {...getInputProps()} />
                <MDBox className="dropBox-icon">
                    <Icon>cloud_upload</Icon>
                </MDBox>
                <MDTypography variant="button" color="dark">{"Drag & drop your file here or click to select a file"}</MDTypography>
                <MDTypography variant="caption" color="text" component="span" textAlign="center" mt={.5}>
                    {".lic files only"}
                </MDTypography>
            </MDBox>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" maxWidth={600} mt={1} px={1}>
                {
                    validFile && <MDTypography variant="button" color="dark" fontWeight="medium">{licFile?.name}</MDTypography>
                }
                {
                    !validFile && <MDTypography variant="button" color="error" fontWeight="medium">Select a valid license file.</MDTypography>
                }
                <MDButton disabled={submitting || !(licFile && validFile)} startIcon={submitting && <CircularProgress color="white" size={15} />} variant="gradient" color="info" size="small" onClick={handleOnLicFileUpload}>
                    {licensed ? "Upgrade" : "Activate"}
                </MDButton>
            </Stack>
            <LicenseDetails
                expiresOn={expiresOn}
                enabledPackages={enabledPackages}
                disabledPackages={disabledPackages}
            />
        </MDBox>
    </MDBox>
}

export default AnimatedComponent(Licensing);