import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate, useLocation } from "react-router-dom";
import { formatAmount, getDrilldownPath } from 'utils';
import colors from "assets/theme/base/colors";
import { useResizeDetector } from 'react-resize-detector';
import { useYADialog } from "components/YADialog";
import { convertRStoGraphYearlyLine, removeSessionFilter } from "utils"
import { multipleStackConvertYearlyLine, multipleStackConvertGroupedLine } from 'utils/charts';
import DashboardItem from 'components/DashboardItem';
import { useAppController } from "context";
import _ from 'lodash';

const StackedGroupedColumnChartRenderer = ({ loading, title, subtitle, chartHelpContextKey, resultSet, vizOptions, vizState, cubeOptions }) => {
    let parsedResultset = [];
    parsedResultset = resultSet.tablePivot();
    const chartRef = createRef();
    const { width, height, ref: rref } = useResizeDetector();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const {showReport} = useYADialog();
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;
    let navigate = useNavigate()
    let location = useLocation()

    if(loading)
       return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>
    
    let currentFilters

    currentFilters = removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions)


    var count
    var graphData
    var seriesCount = vizOptions.series?.length || 1;

    if (vizOptions.plotType && vizOptions.plotType === 'stackedMultiple') {
        graphData = !vizOptions.groupedStackedColumn ?  multipleStackConvertYearlyLine(parsedResultset, seriesCount === 1 ? colors.singleDataColors : colors, vizOptions) : multipleStackConvertGroupedLine(parsedResultset, seriesCount === 1 ? colors.singleDataColors : colors, vizOptions)
    } else {
        count = vizOptions.series.length;
        graphData = convertRStoGraphYearlyLine(resultSet, count === 1 ? colors.singleDataColors : colors.stackedgraphcolors, vizOptions)
    }

    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        let isInArray = (name, array) => {
            return array.some(item => item.name === name);
        }
        let parsedColumns = []
        parsedResultset.length ?
            parsedResultset.forEach(item => {
                Object.keys(item).map(key => {
                    let col = {
                        "name": key,
                        "displayName": String(key).match(/[^|]*$/g)[0],
                        "type": !isNaN(Number(item[key])) ? (vizOptions.units ? 'number' : "currency") : 'string'
                    }
                    if (!isInArray(col.name, parsedColumns))
                        parsedColumns.push(col)
                })
            })
            : parsedColumns = null
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = parsedResultset.length && parsedColumns.length ? [...parsedColumns] : [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = vizOptions?.tableHeight ? vizOptions?.tableHeight : 5.8;
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])

    var opts = {
        chart: {
            width: width,
            height: height,
            style: { fontFamily: 'inherit', fontSize: '14px', },
            spacingBottom: 0,
            // spacingTop: 0,
            spacingRight: 0,
            spacingLeft: 0,
        },
        title: { text: '' },
        exporting: { enabled: false },
        lang: { thousandsSep: ',' },
        credits: { enabled: false },
        legend: vizOptions.plotType && vizOptions.plotType === 'stackedMultiple' ? {
            labelFormatter: function () {
                {
                    return this.options.stack;
                }
            }
        } : {
            enabled: true
        },
        xAxis: {
            categories: Array.from(graphData.categories)
        },
        tooltip: {
            outside: false,
            formatter: function () {
                return `<b>${this.point.category}</b><br/>${this.point.series.name}: <b>${systemCurrencyDetails?.code || '$'}${Highcharts.numberFormat(this.point.y, 0, ".", ",")}</b>`;
            }
        },
        yAxis: [
            {
                labels: {
                    formatter: function () {
                        return formatAmount(this.value, null, systemCurrencyDetails).replace(/ /g, "").replace(".0", "");
                    },
                },
                title: {
                    text: vizOptions?.yAxisRight? vizOptions?.yAxisRight : "Year to Date Spend",
                },
                opposite: true,
            },
            {
                title: {
                    text: "Monthly Spend",
                },
                labels: {
                    formatter: function () {
                        return formatAmount(this.value, null, systemCurrencyDetails).replace(/ /g, "").replace(".0", "");
                    },
                },
            },
        ],
        plotOptions: {
            column: {
                stacking: "normal"
            },
            series: {
                cursor: "pointer",
                groupPadding: 0.1,
                // pointPadding: 0,
                borderWidth: 0,
                borderRadius: 4,
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                events: {
                    legendItemClick: function () {
                        return false; // Prevent the legendItemClick event
                    }
                },
                point: {
                    events: {
                        click: function (event) {
                            var obj = Object.assign([], [...currentFilters]);
                            if (obj.find((({name}) => name === "Months.month")))
                                _.remove(obj, {name: "Months.month"})                            
                            if (vizOptions.category)
                                // if (!obj.find((({name}) => name === vizOptions.category))) 
                                if (obj.find((({name}) => name === vizOptions.category))) 
                                {
                                   _.remove(obj, {name: vizOptions.category})
                                   obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                                else
                                {
                                   obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                                if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                                    vizOptions.excludeFilters.map((fil) => {
                                        if (obj.find((({name}) => name === fil)))
                                            _.remove(obj, {name: fil})                                
                                    })
                                }
                            vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath(location.pathname, vizOptions.drillTo), { state: obj})
                            vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && showReport(vizOptions["popupTo"], obj, null);
                        }
                    }
                }
            }
        },
        series: Object.values(graphData.range)

    }
    const nodata = graphData.categories?.size === 0;
    const parsedResultsetData = parsedResultset?.length > 0 ?  parsedResultset : resultSet?.tablePivot();
    return ( toggleType === 'table' && tableVizOptions ?
    <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet} parsedResultset={parsedResultset} cubeOptions= {cubeOptions}/> :
        <DashboardItem nodata={nodata} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={parsedResultsetData} cubeOptions= {cubeOptions}>
            <div ref={rref} style={{position: 'relative', height: '100%'}}>
                <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 }}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
                </div>
            </div>
        </DashboardItem>
    )
}

export default StackedGroupedColumnChartRenderer;