import { CircularProgress, Icon, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import Textbox from "components/YAForm/components/Textbox";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import { useYADialog } from "components/YADialog";
import fetchRequest from "utils/fetchRequest";
import YASkeleton from "components/YASkeleton";
import MDTypography from "components/MDTypography";
import Dropdown from "components/YAForm/components/Dropdown";
import { parseJsonString } from "utils";
import FilterContainer from "../FilterContainer";
import { useDropzone } from "react-dropzone";
import Integer from "components/YAForm/components/Integer";

const hoursOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "6", label: "6" },
    { value: "12", label: "12" },
];

const daysOptions = Array(20).fill(1).map((_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));

const timeframeOptions = [
    { value: "currentMonth", label: "Current Month" }, 
    { value: "currentYear", label: "Current Year" }, 
    { value: "nLastMonths", label: "Last n months" },
    { value: "nLastMonthsIncCurrent", label: "Last n months (include current)" },
    { value: "nNextMonths", label: "Next n months" },
    { value: "nNextMonthsIncCurrent", label: "Next n months (include current)" },
    { value: "nLastYears", label: "Last n years" },
    { value: "nLastYearsIncCurrent", label: "Last n years (include current)" },
    { value: "nNextYears", label: "Next n years" },
    { value: "nNextYearsIncCurrent", label: "Next n years (include current)" },
    { value: "ytd", label: "YTD" },
    { value: "prevYtd", label: " Previous YTD" },
];

const dropBoxStyles = ({ palette: { white, info }, functions: { pxToRem } }, { isDragActive }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `${pxToRem(2)} dashed #ddd`,
    borderRadius: pxToRem(6),
    py: 3,
    my: 1,
    maxWidth: 600,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#f0f8ff",
    },
    "& .dropBox-icon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        height: pxToRem(56),
        width: pxToRem(56),
        fontSize: pxToRem(56),
        mb: 1.5,
        color: info.main,
        ...(isDragActive && {
            fontSize: pxToRem(36),
            color: white.main,
            backgroundColor: info.main,
        })
    }
})

const AlertForm = (props) => {
    const { alertId, mode, onClose } = props;
    const [loading, setLoading] = useState(false);
    const [metricDetails, setMetricDetails] = useState({ state: "LOADING", data: null });
    const { showSnackbar, showAlert } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const [importing, setImporting] = useState(false);
    const [validFile, setValidFile] = useState(true);
    const [alertsFile, setAlertFile] = useState(null);

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        maxFiles: 1,
        onDrop: async (acceptedFiles) => {
            try {
                var reader = new FileReader();
                reader.onload = function (e) {
                    const parsedFile = parseJsonString(e.target.result);
                    if ( parsedFile?.alerts) {
                        setValidFile(true);
                        setAlertFile(acceptedFiles[0]);
                    }
                    else
                        setValidFile(false);
                };

                const fileExtension = (acceptedFiles[0]?.name || "").split(".").pop().toLowerCase();
                if (fileExtension !== "json")
                    setValidFile(false);
                else {
                    reader.readAsText(acceptedFiles[0]);
                }
            } catch (err) {
                showAlert("Upload a valid .json file");
                setValidFile(false);
                console.error("Upload a valid .json file", err);
                // setFileChecking(false);
            }
        }
    });

    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    const watchedFields = watch(["metricId", "type", "triggerConditionType", "thresholdPosition", "scheduleFrequency", "timeframe"]);
    const watchedFieldsObj = useMemo(() => {
        return {
            metricId: watchedFields[0],
            type: watchedFields[1],
            triggerConditionType: watchedFields[2],
            thresholdPosition: watchedFields[3],
            scheduleFrequency: watchedFields[4],
            timeframe: watchedFields[5]
        };
    }, watchedFields)

    async function getFormData() {
        setLoading(true);
        const [error, data] = await fetchRequest.get(`/api/alert/${alertId}`);
        if (error)
            console.error(error);
        setValue("name", data["name"]);
        setValue("desc", data["desc"]);
        setValue("metricId", data["metricId"]);
        const alertConfig = parseJsonString(data["config"]);
        // setValue("metricAggregationType", alertConfig?.metricAggregationType || "SUM");
        setValue("timeframe", alertConfig?.timeframe);
        setValue("timeRangeCount", alertConfig?.timeRangeCount);
        setValue("scheduleFrequency", alertConfig?.scheduleFrequency || "HOURLY");
        setValue("scheduleFrequencyHourlyHour", alertConfig?.scheduleFrequencyHourlyHour || "1");
        setValue("scheduleFrequencyMonthlyDay", alertConfig?.scheduleFrequencyMonthlyDay || "1");
        setValue("scheduleFrequencyMonthlyMonth", alertConfig?.scheduleFrequencyMonthlyMonth || "1");
        const filterConfig = parseJsonString(data["filter"]);
        setValue("filter", filterConfig);
        setValue("type", data["type"]);
        setValue("triggerEvent", data["triggerEvent"]);
        // setValue("triggerCronExpression", data["triggerCronExpression"]);
        setValue("triggerConditionType", data["triggerConditionType"]);
        setValue("thresholdPosition", data["thresholdPosition"]);
        setValue("thresholdValue", data["thresholdValue"]);
        setValue("thresholdValue2", data["thresholdValue2"]);
        setValue("notificationChannelId", data["alertNotification.notificationChannelId"]);
        const channelConfig = parseJsonString(data["alertNotification.channelConfig"]);
        setValue("channelMessage", channelConfig?.channelMessage);
        setValue("active", data["active"] || false);
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            setValue("scheduleFrequency", "HOURLY");
            setValue("scheduleFrequencyHourlyHour", "1");
            setValue("scheduleFrequencyMonthlyDay", "1");
            setValue("scheduleFrequencyMonthlyMonth", "1");
            setValue("active", true);
        }
    }, [mode]);

    useEffect(() => {
        async function getMetricDetails(id) {
            var [err, data] = await fetchRequest.get(`/api/metric/${id}`);
            if (err) {
                console.error(err);
                setMetricDetails({ state: "ERROR", data: null });
            }
            else {
                if (data) {
                    setMetricDetails({ state: "LOADED", data: data });
                }
                else {
                    setMetricDetails({ state: "EMPTY", data: null });
                }
            }
        }
        if (watchedFieldsObj.metricId)
            getMetricDetails(watchedFieldsObj.metricId);
    }, [watchedFieldsObj.metricId])

    const handleClose = useCallback((data) => {
        if (onClose) onClose(data);
    }, []);

    const onSubmit = async formdata => {
        if (mode === "import") {
            setImporting(true);
            const data = new FormData();
            data.append("alertFile", alertsFile);
            const [err, response] = await fetchRequest.post(`/api/alert/import`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setImporting(false);
            if (err) {
                showAlert('Import Alert', 'Something went wrong. Contact your administrator.');
            }
            else
                if (response && response.result === true) {
                    handleClose({ dashboardId: response.dashboardId });
                    onDialogClose();
                    showSnackbar(response.message || 'Alerts imported successfully', 'success');
                }
                else if (response && response.result === false) {
                    showAlert('Import Alert', response.message || 'Something went wrong. Contact your administrator.');
                }
        } else {
            const url = mode === "edit" ?
            `/api/alert/${alertId}`
            : `/api/alert/new`;

            const [error, data] = await fetchRequest.post(url, JSON.stringify(formdata));
            if (!error) {
                if (data && data.result === false) {
                    if (Array.isArray(data.errors) && data.errors.length > 0) {
                        data.errors.forEach((e) => {
                            setError(e.field, { type: "manual", message: e.message });
                        });
                    }
                }
                else {
                    handleClose({ notificationChannelId: data.notificationChannelId });
                    onDialogClose();
                    showSnackbar(data.message, "success");
                }
            }
            else {
                showAlert("Create Alert", error?.data?.message || "Something went wrong. Contact your administrator.");
                console.error(error);
            }
        }
    };

    if (loading) {
        return (
            <MDBox pt={1} px={3} pb={2} minWidth={400}>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
            </MDBox>
        )
    }

    const rangeThresholdPositon = ["WITHIN_THRESHOLD", "OUTOF_THRESHOLD"].includes(watchedFieldsObj["thresholdPosition"])

    const metricConfig = parseJsonString(metricDetails.data?.config);

    const showTimeRangeCount = !["currentMonth", "currentYear", "ytd", "prevYtd"].includes(watchedFieldsObj.timeframe);

    const timeRangeCountLabel = ["nLastMonths", "nLastMonthsIncCurrent", "nNextMonths", "nNextMonthsIncCurrent"].includes(watchedFieldsObj.timeframe) ? "No. of Months." : "No. of Years.";

    return (
        <MDBox pt={1} px={3} pb={2} minWidth={400}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            {
                mode === 'import' && <MDBox mb={3}>
                    <MDTypography mt={2} mb={1} variant="caption" color="text" fontWeight="medium">Select Alert File</MDTypography>
                    <MDBox {...getRootProps({ className: 'dropzone' })} sx={theme => dropBoxStyles(theme, { isDragActive })}>
                        <input {...getInputProps()} />
                        <MDBox className="dropBox-icon">
                            <Icon>cloud_upload</Icon>
                        </MDBox>
                        <MDTypography variant="button" color="dark">{"Drag & drop your file here or click to select a file"}</MDTypography>
                        <MDTypography variant="caption" color="text" component="span" textAlign="center" mt={.5}>
                            {".json files only"}
                        </MDTypography>
                    </MDBox>
                    {
                        validFile && <MDTypography variant="button" color="dark" fontWeight="medium">{alertsFile?.name}</MDTypography>
                    }
                    {
                        !validFile && <MDTypography variant="button" color="error" fontWeight="medium">Select a valid alert file.</MDTypography>
                    }
                </MDBox>
            }
            { mode !== 'import' && 
            <>
            <MDBox mb={2}>
                    <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The name assigned to the alert.", impact: "", severity: "", name: "name", displayName: "Alert name", required: true }} errorMessage={errors["name"] && errors["name"].message} />
                </MDBox>
                <MDBox mb={2}>
                    <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "A brief details about the alert.", impact: "Description will not be visible in the Alerting screen.", severity: "Low", name: "desc", displayName: "Description" }} errorMessage={errors["desc"] && errors["desc"].message} />
                </MDBox>
                <MDBox mt={6} mb={2}>
                    <MDTypography variant="button" fontWeight="medium">Select a metric</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                    <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "Specifies the metric for which the alert will be triggered. For example, for Application spend, vendor spend or monthly spend.", impact: "", severity: "", name: "metricId", displayName: "Metric", required: true, dataSource: { type: "custom", url: "/api/alert/lookup/metric" } }} errorMessage={errors["metricId"] && errors["metricId"].message} />
                </MDBox>
                {
                    watchedFieldsObj.metricId && <>
                        {/* <MDBox mb={2}>
                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The method used to aggregate metric data, such as sum or average.", impact: "", severity: "", name: "metricAggregationType", displayName: "Aggregation type", required: true, dataSource: { type: "static", data: [{ value: "SUM", label: "Sum" }, { value: "AVG", label: "Average" }] } }} errorMessage={errors["metricAggregationType"] && errors["metricAggregationType"].message} />
                        </MDBox> */}
                        <MDBox mb={2}>
                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The period over which the metric data is aggregated and evaluated, such as monthly, or yearly.", impact: "", severity: "", name: "timeframe", displayName: "Timeframe", required: true, dataSource: { type: "static", data: timeframeOptions } }} errorMessage={errors["timeframe"] && errors["timeframe"].message} />
                        </MDBox>
                        {
                            showTimeRangeCount &&
                                <MDBox mb={2}>
                                    <Integer watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: timeRangeCountLabel, impact: "", severity: "", name: "timeRangeCount", displayName: timeRangeCountLabel, required: showTimeRangeCount }} errorMessage={errors["timeRangeCount"] && errors["timeRangeCount"].message} />
                                </MDBox>
                        }
                        {
                            (metricConfig?.availableFilters || [])?.length > 0 && <>
                                <MDBox mt={6} mb={2}>
                                    <MDTypography variant="button" fontWeight="medium">Add filters</MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <FilterContainer metricId={watchedFieldsObj.metricId} defaultFilters={metricConfig?.availableFilters} setValue={setValue} control={control} errorMessage={errors["filter"] && errors["filter"].message}/>
                                </MDBox>
                            </>
                        }
                    </>
                }
                <MDBox mt={6} mb={2}>
                    <MDTypography variant="button" fontWeight="medium">Configure trigger condition</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                    <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The type of condition that must be fulfilled in order to initiate the alert, such as the threshold value or any absence of value.", impact: "", severity: "", name: "triggerConditionType", displayName: "Condition type", required: true, dataSource: { type: "static", data: [{ value: "ABSENCE_OF_VALUE", label: "Absence of value" }, { value: "THRESHOLD", label: "Threshold" }] } }} errorMessage={errors["triggerConditionType"] && errors["triggerConditionType"].message} />
                </MDBox>
                {
                    watchedFieldsObj["triggerConditionType"] && watchedFieldsObj["triggerConditionType"] === "THRESHOLD" && (
                        <>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "This field appears when Condition type is set to Threshold. It specifies the reference point for the threshold, such as above or below a certain value.", impact: "",severity: "", name: "thresholdPosition", displayName: "Threshold Position", required: true, dataSource: { type: "static", data: [{ value: "ABOVE_THRESHOLD", label: "Above threshold" }, { value: "BELOW_THRESHOLD", label: "Below threshold" }, { value: "WITHIN_THRESHOLD", label: "Within threshold range" }, { value: "OUTOF_THRESHOLD", label: "Out of threshold range" }] } }} errorMessage={errors["thresholdPosition"] && errors["thresholdPosition"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "This field appears when Condition type is set to Threshold. It represents a specific value that the metric must reach or exceed to trigger the alert.", impact: "", severity: "", name: "thresholdValue", displayName: rangeThresholdPositon ? "Lower Threshold Value" : "Threshold Value", required: true }} errorMessage={errors["thresholdValue"] && errors["thresholdValue"].message} />
                            </MDBox>
                            {
                                rangeThresholdPositon && (
                                    <MDBox mb={2}>
                                        <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "", impact: "", severity: "", name: "thresholdValue2", displayName: "Upper Threshold Value", required: true }} errorMessage={errors["thresholdValue2"] && errors["thresholdValue2"].message} />
                                    </MDBox>
                                )
                            }
                        </>
                    )
                }
                <MDBox mt={6} mb={2}>
                    <MDTypography variant="button" fontWeight="medium">Configure trigger schedule</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                    <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "Specifies the type of trigger for the alert, such as scheduled-based or application event-based. This indicates when the alert will be activated.", impact: "", severity: "", name: "type", displayName: "Trigger type", required: true, dataSource: { type: "static", data: [{ value: "SCHEDULE", label: "Scheduled trigger" }, { value: "EVENT", label: "Application event trigger" }] } }} errorMessage={errors["type"] && errors["type"].message} />
                </MDBox>
                {
                    watchedFieldsObj["type"] && watchedFieldsObj["type"] === "SCHEDULE" && (
                        <>
                            <MDBox mb={4}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "This field appears when Trigger type is set to Scheduled trigger. Used to specify how often alert conditions are checked. If conditions are satisfied, then alert will be sent.", impact: "", severity: "", name: "scheduleFrequency", displayName: "Frequency", required: true, dataSource: { type: "static", data: [{ value: "HOURLY", label: "Hourly" }, { value: "DAILY", label: "Daily" }, { value: "MONTHLY", label: "Monthly" }] } }} errorMessage={errors["scheduleFrequency"] && errors["scheduleFrequency"].message} />
                            </MDBox>
                            {
                                watchedFieldsObj.scheduleFrequency === "HOURLY" && (
                                    <MDBox mb={2}>
                                        <Stack direction={"row"} spacing={2}>
                                            <MDTypography variant="button">Every</MDTypography>
                                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "", impact: "", severity: "", name: "scheduleFrequencyHourlyHour", required: true, dataSource: { type: "static", data: hoursOptions } }} errorMessage={errors["scheduleFrequency"] && errors["scheduleFrequency"].message} />
                                            <MDTypography variant="button">Hour(s).</MDTypography>
                                        </Stack>
                                    </MDBox>
                                )
                            }
                            {
                                watchedFieldsObj.scheduleFrequency === "DAILY" && (
                                    <MDBox mb={2}>
                                        <Stack direction={"row"} spacing={2}>
                                            <MDTypography variant="button">Every day. Start at 12:00 AM.</MDTypography>
                                            {/* <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "scheduleFrequencyDaily", required: true, dataSource: { type: "static", data: [{ value: "1", label: "1" }, { value: "2", label: "2" }, { value: "3", label: "3" }, { value: "4", label: "4" }] } }} errorMessage={errors["scheduleFrequency"] && errors["scheduleFrequency"].message} />
                                            <MDTypography variant="button">Hour(s)</MDTypography> */}
                                        </Stack>
                                    </MDBox>
                                )
                            }
                            {
                                watchedFieldsObj.scheduleFrequency === "MONTHLY" && (
                                    <MDBox mb={2}>
                                        <Stack direction={"row"} spacing={2} mb={2}>
                                            <MDTypography variant="button">Day</MDTypography>
                                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "", impact: "", severity: "", name: "scheduleFrequencyMonthlyDay", required: true, dataSource: { type: "static", data: daysOptions } }} errorMessage={errors["scheduleFrequency"] && errors["scheduleFrequency"].message} />
                                            <MDTypography variant="button">of every</MDTypography>
                                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "", impact: "", severity: "", name: "scheduleFrequencyMonthlyMonth", required: true, dataSource: { type: "static", data: [{ value: "1", label: "1" }, { value: "2", label: "2" }, { value: "3", label: "3" }, { value: "4", label: "4" }, { value: "6", label: "6" }] } }} errorMessage={errors["scheduleFrequency"] && errors["scheduleFrequency"].message} />
                                            <MDTypography variant="button">month(s).</MDTypography>
                                        </Stack>
                                        <MDTypography variant="button">Start at 12:00 AM.</MDTypography>
                                    </MDBox>
                                )
                            }
                            {/* <MDBox mb={2}>
                                <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ name: "triggerCronExpression", displayName: "Cron expression", required: true }} errorMessage={errors["triggerCronExpression"] && errors["triggerCronExpression"].message} />
                            </MDBox> */}
                        </>
                    )
                }
                {
                    watchedFieldsObj["type"] && watchedFieldsObj["type"] === "EVENT" && (
                        <MDBox mb={2}>
                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "This field appears when Trigger type is set to Application event trigger. It defines specific events within the application that can trigger the alert, for example, spend loaded.", impact: "", severity: "", name: "triggerEvent", displayName: "Application Event", required: true, dataSource: { type: "custom", url: "/api/alert/lookup/event" } }} errorMessage={errors["triggerEvent"] && errors["triggerEvent"].message} />
                        </MDBox>
                    )
                }
                <MDBox mt={6} mb={2}>
                    <MDTypography variant="button" fontWeight="medium">Configure notification</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                    <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The channel through which the alerts will be sent.", impact: "", severity: "", name: "notificationChannelId", displayName: "Notification channel", required: true, dataSource: { type: "custom", url: "/api/alert/lookup/notificationChannel" } }} errorMessage={errors["notificationChannelId"] && errors["notificationChannelId"].message} />
                </MDBox>
                <MDBox mb={2}>
                    <Textbox textarea rows={10} watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The custom message that will be sent through the notification channel.", impact: "", severity: "", name: "channelMessage", displayName: "Channel message", required: true }} errorMessage={errors["channelMessage"] && errors["channelMessage"].message} />
                </MDBox>
            </>
            }
            <MDBox mt={4} mb={1} textAlign="right">
            {
                mode !== 'import' &&
                <MDButton type="submit" disabled={isSubmitting} variant="gradient" color="info" startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                    Save
                </MDButton>
            }
            {
                mode === 'import' &&
                <MDButton type="submit" disabled={importing || !(alertsFile && validFile)} variant="gradient" color="info" startIcon={importing ? <CircularProgress color="white" size={15} /> : <Icon>upload</Icon>}>
                    Import
                </MDButton>
            }
            </MDBox>
            </form>
        </MDBox>
    );
};

export default AlertForm;