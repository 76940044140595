import { useEffect, useState } from "react";
import { Autocomplete, Icon, IconButton, styled, Tooltip, Checkbox, tooltipClasses, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import MDButton from "components/MDButton";
import useFetchRequest from "hooks/useFetchRequest";
import YASkeleton from "components/YASkeleton";
import useHandleError from "hooks/useHandleError";
import { DataStoreInterfaceHandler } from "components/FilteredUploadedFiles/helpers/DataStoreInterfaceHandler";
import useYADialog from "components/YADialog/useYADialog";
import fetchRequest from "utils/fetchRequest";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: 'none',
        border: 'none',
        fontSize: 11,
    },
}));

const MappingStep = (props) => {

    const { uploadSubType, sourceFields, onMappingBack, onMappingNext, hasMore, dataStoreMapping, onChange, disableDataStoreCreate, ws } = props;
    const [fieldsAutoMapped, setFieldsAutoMapped] = useState(false);
    const [resourceFields, setResourceFields] = useState([]);
    const [mappingFields, setMappingFields] = useState([]);
    const [mappingErrors, setMappingErrors] = useState([]);
    const [mappingSource, setMappingSource] = useState(null);
    const [dataStoresOptions, setDataStoresOptions] = useState(null);
    const [ischecked, setCheck] = useState(false);
    const { response: dataRes, error: dataErr, loading: dataLoading } = useFetchRequest(`/api/dataflow/resource/${uploadSubType}`);
    const { response: dataStore, error: dataStoreErr, loading: dataStoreLoading } = useFetchRequest(`api/master/data-store-mapping/list`);
    const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
    const handleError = useHandleError();
    const [dataDef, setDatadef] = useState([])
    const [templateSelected, setTemplateSelected] = useState(false)
    let dataStoreExcludes = ["BUConsumption", "businessCaseCloud", "businessCaseNonCloud", "budgetDetail", "costCentreBudgetDetail", "Tower Consumption", "Business Unit Consumption"]
    let isdataStoreExclude = dataStoreExcludes.find(elem => elem === uploadSubType)

    let getAllDef = async () => {
        var [err, data] = await fetchRequest.get(`/api/templates/list`);
        var [err1, dataRes1] = await fetchRequest.get(`/api/dataflow/assetTypes`);
        var [err2, dataDef] = await fetchRequest.get(`/api/dataflow/mappingFields`);

        if (err || err1 || err2) {
            handleError(err);
        } else {
            let data1 = Object.assign([], data)
            data1.map(data => {
                data["mappingFields"] = dataDef.find(itm => itm.key === data.name)?.mappingFields
                data["destinationTable"] = data.name
                data["fileType"] = dataDef.find(itm => itm.key === data.name)?.type
            })
            dataRes1.map(data => {
                data["destinationTable"] = data["name"]
                data["fileType"] = "Assets"
                data["type"] = "Asset"
                data1.push(data)
            })
            setDatadef(data1)

        }
    }

    useEffect(() => {
        if (dataRes !== null) {
            setResourceFields(dataRes.fields);
            !dataStore?.find((el) => el['name'] === 'Latest Uploaded Mapping') && dataRes?.mappings ? dataStore?.push({ name: 'Latest Uploaded Mapping', destinationTable: dataRes?.destinationTable, mappingFields: dataRes?.mappings }) : ''
            const options = dataStore ? dataStore?.filter((el) => el?.destinationTable === uploadSubType).map((el) => el['name'].toString()) : []
            setDataStoresOptions(options)
            setMappingSource(options.find(o => o === 'Latest Uploaded Mapping') && !mappingSource ? options.find(o => o === 'Latest Uploaded Mapping') : mappingSource)
            const dataStoreMappings = dataStore ? dataStore?.filter((el) => el['name'] === mappingSource).map((el) => JSON.parse(el['mappingFields'])) : [];
            let mappingFieldsArr = [];
            dataRes?.fields.forEach((fld) => {
                if (!fld.mappingHidden) {
                    const mappedField = _.find(dataStoreMappings[0], { 'destinationColumn': fld.schemaName ? fld.schemaName : fld.name })
                    if (mappedField && mappedField.sourceColumn && sourceFields.includes(mappedField.sourceColumn))
                        mappingFieldsArr.push({ 'destinationColumn': fld.schemaName ? fld.schemaName : fld.name, 'mandatory': fld.required, 'sourceColumn': mappedField.sourceColumn, 'dataType': fld.type })
                    else if (sourceFields.find(field => fld.displayName === field) && !templateSelected) {
                        mappingFieldsArr.push({ 'destinationColumn': fld.schemaName ? fld.schemaName : fld.name, 'mandatory': fld.required, 'sourceColumn': sourceFields.find(field => fld.displayName.toLowerCase() === field.toLowerCase()), 'dataType': fld.type })
                    }
                    else {
                        mappingFieldsArr.push({ 'destinationColumn': fld.schemaName ? fld.schemaName : fld.name, 'sourceColumn': null, 'mandatory': fld.required, 'dataType': fld.type })
                    }
                }
            })
            setMappingFields(mappingFieldsArr);

            let requiredFieldsAutomapped = true;
            dataRes.fields.filter(fld => fld.required).forEach((fld) => {
                const mappedField = _.find(mappingFieldsArr, { 'destinationColumn': fld.schemaName });
                if (!mappedField?.sourceColumn) {
                    requiredFieldsAutomapped = false;
                }
            })
            !templateSelected && setFieldsAutoMapped(requiredFieldsAutomapped);
            getAllDef()
        }
    }, [dataRes, mappingSource, dataStore]);

    if (dataLoading || dataStoreLoading) {
        return <MDBox height="400px" width="600px"><YASkeleton variant="loading" /></MDBox>;
    }
    if (dataErr || dataStoreErr) {
        if (dataStoreErr) console.error(dataStoreErr)
        if (dataErr) console.error(dataErr)
    }

    const validateMapping = () => {
        let requiredFieldsMapped = true;
        let mappingErrorsArr = [];
        resourceFields.filter(fld => fld.required).forEach((fld) => {
            const mappedField = _.find(mappingFields, { 'destinationColumn': fld.schemaName });
            if (!mappedField?.sourceColumn) {
                if (requiredFieldsMapped)
                    requiredFieldsMapped = false;
                mappingErrorsArr.push(fld.schemaName);
            }
        });

        if (mappingErrorsArr?.length > 0)
            setMappingErrors(mappingErrorsArr);

        return requiredFieldsMapped;
    }

    const sourceFieldsOptions = sourceFields
        ? sourceFields.filter((el) => !mappingFields.includes(el.toString())).map((el) => el.toString())
        : [];

    const setSourceField = (destField, sourceField) => {
        var arr = [...mappingFields]
        if (_.find(arr, { 'destinationColumn': destField })) {
            // if (sourceField)
            _.find(arr, { 'destinationColumn': destField }).sourceColumn = sourceField ?? null
            // else
            //     arr = _.filter(arr, (f) => f.destinationColumn !== destField)
        }
        else {
            // if (sourceField)
            arr.push({ 'destinationColumn': destField, 'sourceColumn': sourceField ?? null })
        }
        setMappingFields(arr)
    }

    const handleBack = () => {
        if (onMappingBack)
            onMappingBack();
    }
    const handleMappingNext = () => {
        if (dataStoreMapping) {
            onChange(mappingFields)
        }
        if (onMappingNext)
            onMappingNext(mappingFields);
    }

    const handleNext = () => {
        if (validateMapping()) {
            if (ischecked) {
                let data = dataDef.find(obj => obj.destinationTable === uploadSubType)
                const modelData = {};
                modelData["Name"] = data.displayName
                modelData["DestinationTable"] = uploadSubType === 'benchmark' ? 'budget' : uploadSubType
                modelData["FileType"] = data.fileType
                modelData["MappingFields"] = JSON.stringify(mappingFields);
                modelData["DefaultFields"] = "[]"
                let handleAutomate = async () => {
                    let enableInterfaceForm = false
                    await DataStoreInterfaceHandler(modelData, showAlert, showPrompt, showSnackbar, showCustomForm, enableInterfaceForm, handleMappingNext)
                }
                handleAutomate()
            } else {
                handleMappingNext()
            }

        }
    }
    const handleCheck = () => {
        setCheck(!ischecked)
    }

    const getTableContent = (option) => {
        if (!option) return null;
        const topRecords = Array.isArray(ws)
            ? [...new Map(ws
                .filter(record => record[option] != null)
                .map(record => [record[option], record])
            ).values()]
                .slice(0, 3)
            : [];

        return (
            <TableContainer component={Paper} sx={{ minWidth: 200, maxWidth: 285, boxShadow: "none", background: "white"}}>
                <Table size="small" sx={{ borderRadius: 3, border: "2px solid #f5f5f5", padding: 2 }}>
                    <TableHead  sx={{ background: "#f5f5f5" }}>
                        <TableRow>
                            <TableCell align="center"><b>{option}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topRecords.length > 0 ? (
                            topRecords.map((record, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{record[option]}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align="center">No data</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderOption = (props, option) => (
        <li {...props}>
            <LightTooltip title={getTableContent(option)} arrow={false} placement="right">
                <MDTypography sx={{ fontSize: 13 }}>{option}</MDTypography>
            </LightTooltip>
        </li>
    );

    return (
        <>
            {/* {
                !fieldsAutoMapped && dataStoresOptions?.length > 0 && (<MDBox flex={1} p={3}>
                    <MDTypography variant="h5" fontWeight="light" color="text" component="span" display="block" textAlign="left" pb={1}>
                        Data Store Mapping
                    </MDTypography>
                    <Autocomplete
                    // disableClearable
                    value={mappingSource}
                    defaultValue={dataStoresOptions?.length > 0 && dataStoresOptions.find( o => o === 'Latest Uploaded Mapping') ? 
                    () => { 
                        setMappingSource(dataStoresOptions.find( o => o === 'Latest Uploaded Mapping')); 
                        return dataStoresOptions.find( o => o === 'Latest Uploaded Mapping')
                    } : null}
                    options={dataStoresOptions}
                    onChange={(event, newValue) => {
                        setMappingSource(newValue)
                    }}
                    size="small"
                    fullWidth
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            height: 42
                        },
                        "& .MuiOutlinedInput-input": {
                            fontSize: 13
                        }
                    }}
                    renderInput={(params) => <MDInput placeholder="please choose" {...params} />}
                    />
                </MDBox>)
            } */}
            {
                !fieldsAutoMapped && (
                    <>
                        {
                            dataStoresOptions?.length > 0 && !isdataStoreExclude && !disableDataStoreCreate &&
                            <MDBox flex={1} p={3}>
                                <MDTypography variant="h5" fontWeight="light" color="text" component="span" display="block" textAlign="left" pb={1}>
                                    Data Mapping Templates
                                </MDTypography>
                                <Autocomplete
                                    // disableClearable
                                    value={mappingSource}
                                    defaultValue={dataStoresOptions?.length > 0 && dataStoresOptions.find(o => o === 'Latest Uploaded Mapping') && !mappingSource ?
                                        () => {
                                            setMappingSource(dataStoresOptions.find(o => o === 'Latest Uploaded Mapping') ? dataStoresOptions.find(o => o === 'Latest Uploaded Mapping') : mappingSource);
                                            return dataStoresOptions.find(o => o === 'Latest Uploaded Mapping') ? dataStoresOptions.find(o => o === 'Latest Uploaded Mapping') : mappingSource
                                        } : mappingSource}
                                    options={dataStoresOptions}
                                    onChange={(event, newValue) => {
                                        setMappingSource(newValue)
                                        setTemplateSelected(true)
                                    }}
                                    size="small"
                                    fullWidth
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            height: 42
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            fontSize: 13
                                        }
                                    }}
                                    renderInput={(params) => <MDInput placeholder="please choose" {...params} />}
                                />
                            </MDBox>
                        }
                        <MDBox height="400px" width="600px" overflow="scroll" p={3}>
                            <MDBox>
                                <MDTypography variant="h5" fontWeight="light" color="text" component="span" display="block" textAlign="left" pb={1}>
                                    Mandatory fields
                                </MDTypography>
                                {
                                    resourceFields?.filter(f => f.required === true).map((f, i) => {
                                        var destField = f.schemaName;
                                        var selectedColumn;
                                        if (mappingFields.length > 0) {
                                            selectedColumn = _.find(mappingFields, { 'destinationColumn': destField }) ? _.find(mappingFields, { 'destinationColumn': destField }).sourceColumn : undefined;
                                            if (!sourceFields.includes(selectedColumn))
                                                selectedColumn = "";
                                        }
                                        const hasError = mappingErrors?.includes(destField);
                                        return (
                                            <MDBox key={`key${i}`} display="flex" pb={1.5}>
                                                <MDBox sx={{ "&:hover": { "& .helpIcon": { visibility: 'visible' } } }} flex={1}>
                                                    <MDTypography variant="caption" fontWeight="medium" color="text">{f.displayName}</MDTypography>
                                                    {f.toolTip?.length >= 0 &&
                                                        <IconButton className="helpIcon"
                                                            sx={({ palette: { text } }) => ({
                                                                // marginLeft: .15,
                                                                // marginBottom: 1,
                                                                paddingTop: 1,
                                                                marginRight: -2,
                                                                color: "#979191",
                                                                visibility: 'hidden',
                                                                "&:hover": {
                                                                    color: text.main
                                                                }
                                                            })}
                                                            size="small"
                                                        //   onClick={() => {
                                                        //     // showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, pageName) :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp') :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                                                        //     window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                                                        //   }}
                                                        >
                                                            <Tooltip placement="right" title={f.toolTip ? f.toolTip : f.displayName}>
                                                                <Icon>help</Icon>
                                                            </Tooltip>
                                                        </IconButton>
                                                    }
                                                </MDBox>
                                                <MDBox flex={1}>
                                                    <Autocomplete
                                                        // disableClearable
                                                        value={selectedColumn || null}
                                                        options={sourceFieldsOptions}
                                                        onChange={(event, newValue) => {
                                                            setSourceField(destField, newValue)
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        sx={{
                                                            "& .MuiOutlinedInput-root": {
                                                                height: 42
                                                            },
                                                            "& .MuiOutlinedInput-input": {
                                                                fontSize: 13
                                                            }
                                                        }}
                                                        renderInput={(params) =>
                                                            <LightTooltip title={getTableContent(selectedColumn)} arrow={false} placement="right">
                                                                <MDInput
                                                                    {...params}
                                                                    error={hasError}
                                                                    helperText={hasError ? "Required" : undefined}
                                                                    placeholder="Please choose"
                                                                />
                                                            </LightTooltip>}
                                                        renderOption={renderOption}
                                                    />
                                                    {/* {errors[destField] && <MDTypography variant="caption" color="error">Please select a Source Field or 'Ignored'</MDTypography>} */}
                                                </MDBox>
                                            </MDBox>)
                                    })
                                }
                            </MDBox>

                            <MDBox mt={3}>
                                <MDTypography variant="h5" fontWeight="light" color="text" component="span" display="block" textAlign="left" pb={1}>
                                    Optional fields
                                </MDTypography>
                                {
                                    resourceFields?.filter(f => !f.required).map((f, i) => {
                                        var destField = f.schemaName;
                                        var selectedColumn;
                                        if (mappingFields?.length > 0) {
                                            selectedColumn = _.find(mappingFields, { 'destinationColumn': destField }) ? _.find(mappingFields, { 'destinationColumn': destField }).sourceColumn : undefined;
                                            if (!sourceFields.includes(selectedColumn))
                                                selectedColumn = undefined;
                                        }
                                        return (
                                            <MDBox key={`key${i}`} display="flex" pb={1.5}>
                                                <MDBox sx={{ "&:hover": { "& .helpIcon": { visibility: 'visible' } } }} flex={1}>
                                                    <MDTypography variant="caption" fontWeight="medium" color="text">{f.displayName}</MDTypography>
                                                    {f.toolTip?.length >= 0 &&
                                                        <IconButton className="helpIcon"
                                                            sx={({ palette: { text } }) => ({
                                                                // marginLeft: .15,
                                                                // marginBottom: 1,
                                                                paddingTop: 1,
                                                                marginRight: -2,
                                                                color: "#979191",
                                                                visibility: 'hidden',
                                                                "&:hover": {
                                                                    color: text.main
                                                                }
                                                            })}
                                                            size="small"
                                                        //   onClick={() => {
                                                        //     // showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, pageName) :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp') :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                                                        //     window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                                                        //   }}
                                                        >
                                                            <Tooltip placement="right" title={f.toolTip ? f.toolTip : f.displayName}>
                                                                <Icon>help</Icon>
                                                            </Tooltip>
                                                        </IconButton>
                                                    }
                                                </MDBox>
                                                <MDBox flex={1}>
                                                    <Autocomplete
                                                        // disableClearable
                                                        value={selectedColumn || null}
                                                        options={sourceFieldsOptions}
                                                        onChange={(event, newValue) => {
                                                            setSourceField(destField, newValue)
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        sx={{
                                                            "& .MuiOutlinedInput-root": {
                                                                height: 42
                                                            },
                                                            "& .MuiOutlinedInput-input": {
                                                                fontSize: 13
                                                            }
                                                        }}
                                                        renderInput={(params) =>
                                                            <LightTooltip title={getTableContent(selectedColumn)} arrow={false} placement="right">
                                                                <MDInput
                                                                    {...params}
                                                                    placeholder="Please choose"
                                                                />
                                                            </LightTooltip>}
                                                        renderOption={renderOption}
                                                    />
                                                    {/* {errors[destField] && <MDTypography variant="caption" color="error">Please select a Source Field or 'Ignored'</MDTypography>} */}
                                                </MDBox>
                                            </MDBox>)
                                    })
                                }
                            </MDBox>
                        </MDBox>
                    </>
                )
            }
            {
                fieldsAutoMapped && (
                    <MDBox height="400px" width="620px" p={3} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <MDBox sx={() => ({
                            height: 60,
                            width: 60,
                            borderRadius: "50%",
                            backgroundColor: "#4CAF50",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        })
                        }>
                            <Icon
                                fontSize="50"
                                sx={{
                                    fontSize: 35,
                                    color: "#fff"
                                }}>checklist_rtl</Icon>
                        </MDBox>
                        <MDTypography mt={2} color="text" variant="subtitle1" fontWeight="light">All required fields have been automapped successfully.</MDTypography>
                        <MDTypography my={2} color="text" variant="button" fontWeight="medium">Want to edit field mapping?</MDTypography>
                        <MDButton variant="outlined" color="info" startIcon={<Icon>edit</Icon>} onClick={() => { setFieldsAutoMapped(false) }}>
                            edit mapping
                        </MDButton>
                    </MDBox>
                )
            }
            <MDBox px={2.5} pb={2} pt={1} display="flex" justifyContent="space-between" alignItems="center">
                <MDBox>
                    <MDButton
                        size="medium"
                        color="info"
                        startIcon={<Icon>arrow_back_ios</Icon>}
                        onClick={handleBack}
                    >
                        Prev
                    </MDButton>
                </MDBox>
                {
                    !isdataStoreExclude && !disableDataStoreCreate &&
                    <MDTypography my={2} color="text" variant="button" fontWeight="medium">
                        <Checkbox sx={{ p: 0, mr: 1, "& .MuiSvgIcon-root": { border: "1px solid #c5c9cc", borderRadius: "4px" } }} checked={ischecked} onClick={handleCheck} /> Save field mapping for automation.
                    </MDTypography>
                }
                {/* <MDTypography color="text" variant="subtitle2" fontWeight="medium">   <Checkbox checked={true} />Field Mapping</MDTypography> */}
                <MDBox>
                    <MDButton
                        size="medium"
                        color="info"
                        endIcon={<Icon>arrow_forward_ios</Icon>}
                        onClick={handleNext}
                    >
                        {hasMore ? "Next" : "Finish"}
                    </MDButton>
                </MDBox>
            </MDBox>
        </>
    );

};

export default MappingStep;