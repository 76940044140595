import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts/highcharts.src.js'
import VennModule from "highcharts/modules/venn.js";
import HighchartsReact from 'highcharts-react-official';
import { useLocation, useNavigate } from "react-router-dom";
import { useResizeDetector } from 'react-resize-detector';
import { getDrilldownPath } from 'utils';
import colors from "assets/theme/base/colors";
import DashboardItem from 'components/DashboardItem';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { useAppController } from "context";

VennModule(Highcharts)

const VennChartRenderer = ({ loading, title, subtitle, chartHelpContextKey, resultSet, vizOptions, vizState }) => {
    
    const chartRef = createRef();
    const { width, height, ref: rref } = useResizeDetector();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const [measure1, setMeasure1] = useState(null);
    const [measure2, setMeasure2] = useState(null)
    const [varianceObj, setVarianceObj] = useState(null)
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;
    let range = [];
    let color =[]
    let navigate = useNavigate()
    let location = useLocation()

    if(loading)
       return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

    resultSet.tablePivot().forEach(item => {
        if (vizOptions && vizOptions.series) {
            vizOptions.series.forEach((col) => {
                if (col.value === vizOptions.measure1 && !measure1 && col.name) {
                    setMeasure1({ name: col.value, displayName: col.name, value: Number(item[col['value']]) })
                }
                if (col.value === vizOptions.measure2 && !measure2 && col.name) {
                    setMeasure2({ name: col.value, displayName: col.name, value: Number(item[col['value']]) })
                }
                range.push({
                    name: col['name'] ? col['name'] : measure1?.value > measure2?.value ? measure2?.displayName : measure1?.displayName,
                    sets: Array.from(col['sets'] || []),
                    value: col['name'] ? Number(item[col['value']]) : measure1?.value > measure2?.value ? measure2?.value : measure1?.value,
                    hideLabel: col['name'] ? false : true
                })
            })
        }
    });

    range.sort((a, b) => {
        if (!varianceObj && measure1 && measure2) {
            let datavariance = measure2.value > 0 ? measure1.value - measure2.value : measure1.value
            let variancePercentage = measure2.value > 0 ? Math.round((datavariance / measure2.value) * 100) : 100
            setVarianceObj({ "variance": datavariance, variancePercentage })
        }
        if (a.name && b.name) {
            if (b.value < a.value) {
                color.push('rgb(250, 30, 89)', '#e3e3e3')
            } else {
                color.push('#e3e3e3', 'rgb(191, 219, 56);')
            }
            return b.value - a.value
        } else {
            return b.value
        }
    })

    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = 5.8;
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])

    var opts = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'venn',
            width: width, 
            height: height*0.945,
            style: {fontFamily: 'inherit', paddingTop: '0', fontSize: '20px', color:"#9EAEE5"},
            backgroundColor:colors.chartBackground
        },
        title: '',
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },        
        plotOptions: {
            venn: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: vizOptions.title ? '15px':'12px',
                        fontFamily: 'inherit',
                        fontWeight: 400,
                        },                          
                    formatter: function () {
                        let p = `${this.point.name}` || ""
                        if (p.length && p?.indexOf("∩") >= 0) 
                            p = ""
                        if (!this.point.hideLabel)
                            return p;
                    }
                },
                point: {              
                    cumulative: 100,
                },
            },
            states: {
                inactive: {
                    opacity: 1
                }
            }
        },
        tooltip: {
            outside: false,
            formatter: function () {
                let p = `${this.point.name}` || ""
                // if (p.length && p?.indexOf("∩") >= 0)
                //     p = "Overlapped "
                return `<span style="font-size:12px"> <b>${p} : ${systemCurrencyDetails?.code || '$'}${Highcharts.numberFormat(this.point.value, 0, ".", ",")}` +
                    '</b><br/>' + '<b>Variance </b> :' + (varianceObj.variance > 0 ?
                        `<span style="color:#ff1a1a"><b>${systemCurrencyDetails?.code || '$'}`
                        + Highcharts.numberFormat(varianceObj.variance, 0, ".", ",")
                        :
                        '<span style="color:#009900"><b>' + (varianceObj.variance < 0 ? '-' : '') + `${systemCurrencyDetails?.code || '$'}`
                        + Highcharts.numberFormat(Math.abs(varianceObj.variance), 0, ".", ","))
                    + '</b> (' + Math.abs(varianceObj.variancePercentage) + '%)' +
                    '</span>' + (measure2?.value > 0 ?
                        (measure1?.value > measure2?.value ?
                            ' <span style="color:#ff1a1a">▲</span>'
                            :
                            ' <span style="color:#009900">▼</span>') : (measure1?.value > 0 ?
                                ' <span style="color:#ff1a1a">▲</span>'
                                :
                                '')) +
                    '</b>' + '</span>'
            }
        },
        series: [ {
            name: 'Spend',
            colors: color,
            data: (range??[]).concat([]),
        }]
    }
    let navigateToPage = (linkTo) => {
        linkTo && linkTo !== "" && navigate(location.pathname === "/" ? linkTo : getDrilldownPath(location.pathname, linkTo), {state: {}})
    }
    const nodata = range.length === 0;
    return (
        toggleType === 'table' && tableVizOptions ?
        <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet}/> :
        <DashboardItem nodata={nodata} hideToggle={true} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={resultSet?.tablePivot()}>
            <div ref={rref} style={{position: 'relative', height: '100%'}}>
                <div style={{position: 'absolute', left: 0, top: 0, bottom: 0, right: 0}}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
                    {vizOptions["linkTo"] && vizOptions["linkTo"] !== "" &&
                        <MDBox display="flex" color={colors.linkColour ? colors.linkColour : "dark"} flexDirection="row" justifyContent="flex-end">
                        <MDTypography variant="button" sx={{ "&:hover": { cursor: 'pointer' }}} fontWeight="medium" color={colors.linkColour ? colors.linkColour : "dark"} px={1} whiteSpace="nowrap" onClick={() => { navigateToPage(vizOptions["linkTo"])}}>
                            {vizOptions.linkText.toUpperCase()} 
                        </MDTypography>
                        <Icon>east</Icon>
                        </MDBox>
                    }
                </div>
            </div>
        </DashboardItem>
    )
}

export default VennChartRenderer;