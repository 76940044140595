import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import numeral from "numeral";
import { useAppController } from "context";
import { formatCurrencyNumeral } from "utils";


const BudgetHeader = (props) => {
    const { headerDetails } = props;
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;
    return (
        <MDBox display="flex">
            <MDBox display="flex" flexDirection="column">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["name"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Name</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["yearName.name"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Year</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["statusName"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Status</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["type"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Type</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{formatCurrencyNumeral(headerDetails["budget"], systemCurrencyDetails)}</MDTypography>
                <MDTypography component="span" variant="subtitle2">{(headerDetails["type"] === "Forecast" ? "Budget" : "Forecast")}</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{formatCurrencyNumeral(headerDetails["amount"], systemCurrencyDetails)}</MDTypography>
                <MDTypography component="span" variant="subtitle2">{headerDetails["type"]}</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{formatCurrencyNumeral(headerDetails["amount"] - headerDetails["budget"], systemCurrencyDetails)}</MDTypography>
                <MDTypography component="span" variant="subtitle2">{(headerDetails["type"] === "Forecast" ? "Budget" : "Forecast")} Variance</MDTypography>
            </MDBox>
            {
                headerDetails?.lockMonths && (
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["lockMonthName"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Months locked</MDTypography>
            </MDBox>
                )
            }
            {
                headerDetails?.enableCpi === true && headerDetails?.enableCpi !== 0 && (
                    <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                        <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2} textAlign="center">{headerDetails["cpi"]}</MDTypography>
                        <MDTypography component="span" variant="subtitle2">CPI %</MDTypography>
                    </MDBox>
                )
            }
            {
                headerDetails?.enableLpi === true && headerDetails?.enableLpi !== 0 && (
                    <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                        <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2} textAlign="center">{headerDetails["lpi"]}</MDTypography>
                        <MDTypography component="span" variant="subtitle2">LPI %</MDTypography>
                    </MDBox>
                )
            }
            <MDBox display="flex" flexDirection="column" ml={10} pl={2}>
                {/* <FormControlLabel
                    control={<MDSwitch name={"name"} checked={false} onChange={(_, checked) => { }} />}
                    label={"Display calculated amounts"}
                    labelPlacement="end"
                /> */}
                {/* <MDTypography component="span" variant="subtitle2">Display calcualted amounts</MDTypography> */}
            </MDBox>
        </MDBox>
    )
};

export default BudgetHeader;