import { Card, Modal, Icon, IconButton, Autocomplete, Alert } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from 'react';
import useFetchRequest from "hooks/useFetchRequest";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import fetchRequest from "utils/fetchRequest";
import { generateUUID } from "utils";
import { NotificationType, NotificationStatus } from "constants";
import { useYADialog } from "components/YADialog";

const CopyRulesDialog = (props) => {
  const { onClose, variant, typeFilter, yearFilter, yearFilterName, monthFilter, monthFilterName, asset } = props;
  const [yearNameId, setYearNameId] = useState(null)
  const { addNotification } = useYADialog();
  const [rulesLength, setRulesLength] = useState(null);
  const { response: categories} = useFetchRequest(`/api/dataflow/categories/`);

  const copyVariantToRoute = {
    "copyCPRules": "costPoolMapping",
    "copyTRRules": "towerMapping",
    "copyOfferingRules": "solution",
    "copyBURules": "businessunitMapping",
    "copyAssetRules": "assetRules",
    "copyAssets": "asset",
  }

  const { response: existingRules, error: _err } = variant!= "copyAssets" && useFetchRequest(`/api/dataflow/${copyVariantToRoute[variant]}/${yearFilter}/${monthFilter}`)
  if(_err){
    console.log(_err);
  }

  if(!rulesLength && existingRules && existingRules?.length > 0){
    setRulesLength(existingRules?.length)
  }
  
  const handleUploadDialogClose = () => {
    if (onClose) onClose();
  }

  const handleCopyRules = async () => {
    const requestId = generateUUID();
    const notification = ({ requestId, type: NotificationType.CopyRules, message: asset ? `The assets are getting copied.` :`The rules are getting copied.`, status: NotificationStatus.Processing, persist: true });
    addNotification(notification);

    const obj = {
      toYearNameId: yearNameId,
      fromYearNameId: yearFilter,
      fromMonthNameId: monthFilter,
      variant: variant,
      requestId: requestId,
      assetType: asset,
      fromMonthName: monthFilterName,
      fromYearName: yearFilterName,
    }

    let [err, data] = await fetchRequest.post(`/api/dataflow/copyRulesToYear/`, JSON.stringify(obj));
    console.log(data , "data");
    
    if (err) {
      console.log(err, "err");
      return;
    }
    handleUploadDialogClose();
  }

  const saveButton  = variant == "copyAssets"  ? `Copy ${typeFilter}` : variant == "copyBURules" || variant == "copyOfferingRules" ? "Copy Mapping" : "Copy Rules";

  return (
    <Modal open={true} onClose={handleUploadDialogClose}>
      <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <Card sx={{ height: "500px", width: "500px", overflow: 'hidden' }}>
          <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
              {variant === "copyCPRules" && <MDTypography variant="h6" component="span" color="text">Copy Cost Pool Rules</MDTypography>}
              {variant === "copyTRRules" && <MDTypography variant="h6" component="span" color="text">Copy Tower Rules</MDTypography>}
              {variant === "copyOfferingRules" && <MDTypography variant="h6" component="span" color="text">Copy Solution Mapping</MDTypography>}
              {variant === "copySolutionOfferingRules" && <MDTypography variant="h6" component="span" color="text">Copy Solution Rules</MDTypography>}
              {variant === "copyBURules" && <MDTypography variant="h6" component="span" color="text">Copy Business Unit Mapping</MDTypography>}
              {variant === "copyAssetRules" && <MDTypography variant="h6" component="span" color="text">Copy Asset Rules</MDTypography>}
              {variant === "copyTeamRules" && <MDTypography variant="h6" component="span" color="text">Copy Cloud Team Rules</MDTypography>}
              {variant === "copyAssets" && <MDTypography variant="h6" component="span" color="text">Copy {typeFilter}</MDTypography>}

            </MDBox>
            <MDBox display="flex">
              <IconButton onClick={handleUploadDialogClose} title="Close">
                <Icon>close</Icon>
              </IconButton>
            </MDBox>
          </MDBox>

          {
            (variant != "copyAssets" && rulesLength == null) ? <Alert sx={{fontSize:13}} severity="warning">No data found for the selected month and year.</Alert> 
            : <Alert sx={{fontSize:13}} severity="warning">Data will be copied to all the months of the choosen year. Existing data will be deleted.</Alert>
          }
          <MDBox sx={{ height: "290px" }} display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
            <MDTypography mb={1} variant="subtitle1" fontWeight="light" color="text" component="span" >
              {/* Copying {variant === "copyFiles" ? "files" : "rules"} from */}
            </MDTypography>
            {variant === "copyFiles" && (
              <MDTypography variant="button" fontWeight="light" color="text" component="span" >
                {/* Will copy all the files in the background (except spend) */}
              </MDTypography>
            )
            }
            <MDBox mt={2} display="flex">
              <MDBox shadow="xl" mt={1} sx={{ borderRadius: '8px', border: "1px solid #cdcdcd" }}>
                <MDTypography mx={4} my={4} variant="h4" fontWeight="medium" color="dark" component="span" >
                  {`${yearFilterName}                ${monthFilterName}`}
                </MDTypography>
              </MDBox>

            </MDBox>
            <MDBox mt={1} >
              <Icon sx={{ marginTop: 2, color: "#7b809a", fontSize: "38px!important" }}>south</Icon>
            </MDBox>
            <MDBox mt={1} >
              <Autocomplete
                disableClearable={true}
                value={yearNameId}
                disabled={ (variant!= "copyAssets" && rulesLength == null) && true }
                options={categories?.years}
                onChange={(event, newValue) => {
                  setYearNameId(newValue.id)
                }}
                color="text"
                fontWeight="medium"
                sx={{
                  ml: 1.5,
                  "& .MuiOutlinedInput-root": {
                    height: 42,
                    width: 132,
                    boxShadow: "0 8px 16px #1a488e1f"
                  },
                  "& .MuiOutlinedInput-input": {
                    fontSize: 14
                  },
                  "& .MuiOutlinedInput-input.MuiAutocomplete-input": {
                    padding: .5
                  }
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value
                }}
                getOptionLabel={option => {
                  if (typeof option === "number")
                    return categories?.years?.find(op => op.id === option)?.name;
                  return option.name
                }}
                renderInput={(params) => <MDInput label="Year" {...params} />}
              />
            </MDBox>
          </MDBox>
          <MDBox textAlign="right">
            <MDButton name="copyRules" variant="gradient" disabled = {!yearNameId && true} color="info" sx={{ mt: 2, mx: 2 }} startIcon={""} onClick={handleCopyRules}>{saveButton}</MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  )
}

export default CopyRulesDialog;