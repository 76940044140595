// import _ from "lodash";
import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
// import DynamicDropdown from "../DynamicDropdown";
import Dropdown from "./components/Dropdown"
import DynamicDropdown from "./components/DynamicDropdown";
import DatePicker  from "./components/Datepicker";
import Textbox from "components/YAForm/components/Textbox";
import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import moment from 'moment';

const RecommendationForm = (props) => {
    const { pkId, mode, onClose } = props;
    const { showSnackbar } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const [loading, setLoading] = useState(false);
    const domain = getDomain();

    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/recommendation/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);

        setValue("recommendationId", response.data["recommendationId"]);
        setValue("source", response.data["source"]);
        setValue("assetType", response.data["assetType"]);
        setValue("assetCode", response.data["assetCode"]);
        setValue("category", response.data["category"]);
        setValue("details", response.data["details"]);
        setValue("status", response.data["status"]);
        setValue("cloudResourceId", response.data["cloudResourceId"]);
        setValue("complexity", response.data["complexity"]);
        setValue("value", response.data["value"]);
        setValue("recommendationDate", response.data["recommendationDate"]);
        setValue("potentialSavings", response.data["potentialSavings"]);
        setValue("realisedSavings", response.data["realisedSavings"]);
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            setValue("recommendationId", null)
            setValue("source", null);
            setValue("assetType", null);
            setValue("assetCode", null);
            setValue("category", null);
            setValue("details", null);
            setValue("status", null);
            setValue("cloudResourceId", null);
            setValue("complexity", null);
            setValue("value", null);
            setValue("recommendationDate", new Date());
            setValue("potentialSavings", null);
            setValue("realisedSavings", null);
        }
    }, [mode]);

    const onSubmit = async data => {
        let url = mode === "edit" ? `${domain}/api/recommendation/${pkId}` : `${domain}/api/recommendation/new`;
        data.recommendationDate = (data.recommendationDate === null) ? moment(new Date()).format("YYYY-MM-DD") :moment(data?.recommendationDate).format("YYYY-MM-DD");
        data.recommendationId = data.recommendationId?.trim();
        const response = await Axios.post(url, data);
        if (response.data && response.data.result === false) {
            if (Array.isArray(response.data.errors) && response.data.errors.length > 0) {
                response.data.errors.forEach((e) => {
                    setError(e.field, { type: "manual", message: e.message });
                });
            }
        }
        else {
            handleClose();
            onDialogClose();
            showSnackbar(response.data.message, "success");
        }
    };

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={1000}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }

        return (
            <MDBox pt={1} px={3} pb={2} maxWidth={1000}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox>
                        <MDBox mb={2}>
                                <Textbox 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    textarea={true}
                                    fieldDef={
                                        { 
                                            name: "recommendationId",
                                            displayName: "Recommendation ID",
                                            required: true,
                                            unique: true,
                                            toolTip: "Unique identifier for the recommendation.",
                                            impact:"The realized savings amount will not appear on dashboards or reports.",
                                            severity: "Medium",
                                        }
                                    } 
                                    errorMessage={errors["recommendationId"] && errors["recommendationId"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "source", 
                                            displayName: "Source", 
                                            required: true, 
                                            dataSource: { 
                                                type: "static", 
                                                data: [
                                                    { "value": "manual", "label": "Manual" },
                                                    { "value": "aws", "label": "AWS"},
                                                    { "value": "azure", "label": "AZURE"}
                                                ] 
                                            } ,
                                            toolTip: "The source from which the recommendation is taken.",
                                            impact:"",
                                            severity: "",
                                        }
                                    } 
                                    errorMessage={errors["source"] && errors["source"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "assetType", 
                                            displayName: "Asset Type", 
                                            required: true, 
                                            dataSource: { 
                                                type: "custom", 
                                                url: "/api/dataflow/assetTypes"
                                            } ,
                                            toolTip: "Asset type for which recommendation is being created.",
                                            impact:"",
                                            severity: "",
                                        }
                                    } 
                                    errorMessage={errors["assetType"] && errors["assetType"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <DynamicDropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control}
                                    mode={mode} 
                                    fieldDef={
                                        { 
                                            name: "assetCode", 
                                            displayName: "Asset Code", 
                                            required: true, 
                                            dataSource: { 
                                                assetCode: true,
                                                type: "custom",
                                                parentFields: ["assetType"],
                                                url: "/api/dataflow/listItemValues/:assetType/code?toAsset=true"
                                            } ,
                                            toolTip: "Asset Code of the asset for which the recommendation is created.",
                                            impact:"",
                                            severity: "",
                                        }
                                    } 
                                    errorMessage={errors["assetCode"] && errors["assetCode"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "category", 
                                            displayName: "Category", 
                                            required: true,
                                            dataSource: { 
                                                type: "static",
                                                data: [
                                                    {"value" : "costoptimization","label" : "Cost Optimization"}
                                                ]
                                            },
                                            toolTip: "Category of recommendation (e.g. Cloud Optimization).",
                                            impact:"", 
                                            severity: "",
                                        }
                                    } 
                                    errorMessage={errors["category"] && errors["category"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Textbox 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    textarea={true}
                                    fieldDef={
                                        { 
                                            name: "details", 
                                            displayName: "Details", 
                                            required: true,
                                            toolTip: "Detail description about the recommendation.",
                                            impact:"",
                                            severity: "",
                                        }
                                    } 
                                    errorMessage={errors["details"] && errors["details"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "status", 
                                            displayName: "Status", 
                                            required: true, 
                                            toolTip: "The status of the recommendation, whether it is new, in progress, completed, or dismissed.",
                                            impact:"",
                                            severity: "",
                                            dataSource: { 
                                                type: "static",
                                                defaultValue: mode !== 'edit' ?"new" : '',
                                                data: [
                                                    { "value": "new", "label": "New"},
                                                    { "value": "inprogress", "label": "In Progress" },
                                                    { "value": "completed", "label": "Completed" },
                                                    { "value": "dismissed", "label": "Dismissed" },
                                                ] 
                                            } 
                                        }
                                    } 
                                    errorMessage={errors["status"] && errors["status"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Textbox 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "cloudResourceId", 
                                            displayName: "Cloud Resource Id", 
                                            required: false,
                                            toolTip: "The cloud resource Id for AWS or Azure resource for which the recommendation is created.",
                                            impact:"The recommended values will not be displayed on cloud-related dashboards.",
                                            severity: "Low",
                                        }
                                    } 
                                    errorMessage={errors["cloudResourceId"] && errors["cloudResourceId"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "complexity", 
                                            displayName: "Complexity", 
                                            required: true, 
                                            toolTip: "The complexity of the recommendation, whether it is very high, high, medium, or low.",
                                            impact:"",
                                            severity: "",
                                            dataSource: { 
                                                type: "static", 
                                                defaultValue: mode !== 'edit' ?"low" : '',
                                                data: [
                                                    { "value": "low", "label": "Low" },
                                                    { "value": "medium", "label": "Medium" },
                                                    { "value": "high", "label": "High" },
                                                    { "value": "veryhigh", "label": "Very High" }
                                                ] 
                                            } 
                                        }
                                    } 
                                    errorMessage={errors["complexity"] && errors["complexity"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "value", 
                                            displayName: "Value", 
                                            required: true, 
                                            toolTip: "The outcome of the recommendation, whether it is very high, high, medium, or low.",
                                            impact:"",
                                            severity: "",
                                            dataSource: { 
                                                type: "static", 
                                                defaultValue: mode !== 'edit' ?"low" : '',
                                                data: [
                                                    { "value": "low", "label": "Low" },
                                                    { "value": "medium", "label": "Medium" },
                                                    { "value": "high", "label": "High" },
                                                    { "value": "veryhigh", "label": "Very High" }
                                                ] 
                                            } 
                                        }
                                    } 
                                    errorMessage={errors["value"] && errors["value"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <DatePicker
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "recommendationDate", 
                                            displayName: "Recommendation Date", 
                                            defaultValue: mode !== 'edit' ?new Date() : '',
                                            required: false,
                                            toolTip: "The date when the recommendation was created.",
                                            impact:"If not specified, the system will consider the date when the recommendation was created.",
                                            severity: "Low",
                                            schemaName: "recommendationDate",
                                            type: "datepicker",
                                            unique: true,
                                            view:"year"
                                        }
                                    } 
                                    errorMessage={errors["recommendationDate"] && errors["recommendationDate"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Textbox 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "potentialSavings", 
                                            displayName: "Potential Saving", 
                                            required: false,
                                            type: "currency",
                                            toolTip: "Expected savings as per the recommendation.",
                                            impact:"The potential saving amount will not appear on the dashboards, or reports.",
                                            severity: "Medium",
                                    
                                        }
                                    } 
                                    errorMessage={errors["potentialSavings"] && errors["potentialSavings"].message} 
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Textbox 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    fieldDef={
                                        { 
                                            name: "realisedSavings", 
                                            displayName: "Realized Saving", 
                                            required: false,
                                            type: "currency",
                                            toolTip: "Actual savings after taking the recommended action.",
                                            impact:"The realized savings amount will not appear on dashboards or reports.",
                                            severity: "Medium",
                                        }
                                    } 
                                    errorMessage={errors["realisedSavings"] && errors["realisedSavings"].message} 
                                />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox mt={3} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};

export default RecommendationForm;