import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon, List, ListItem, TextField, Autocomplete, CircularProgress, Card, Alert, Tooltip, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import MDButton from "components/MDButton";
import { useYADialog } from "components/YADialog";
import YAScrollbar from "components/YAScrollbar";
import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import moment from 'moment';
// import numeral from 'numeral';
import { useAppController } from 'context';
import Axios from "axios";
import { getDomain, ActiveStatusReverse, Roleformat } from 'utils';
import TBMTaxonomy from "./components/TBMTaxonomy";
import { useImmer } from 'use-immer';
import Licensing from "./components/Licensing";
import { useParams } from "react-router-dom";
import TableRenderer from "./components/TableRenderer";
// import AssetTag from "./components/AssetTag";
import TeamMemberPopup from "components/TeamMemberPopup";
import TeamMappingRules from "./components/TeamMappingRules";
import NotificationChannels from "./components/NotificationChannels";
// import CloudSpend from "./components/CloudSpend";
// import UploadRules from "./components/UploadRules";
// import ViewRenderer from "./components/ViewRenderer";
import AppPersonalization from "./components/AppPersonalization";
import { formatCurrencyNumeral } from "utils";

const buildColumns = (masterDef, defaultDateFormat, systemCurrencyDetails) => {
  const columns = [];
  if (!masterDef.readonly)
  columns.push({
    Header: 'Actions',
    accessor: 'actions',
    align: 'left',
    disableSorting: true,
    disableFilters: true,
    maxWidth: 70
  });
  if (Array.isArray(masterDef.fields) && masterDef.fields.length > 0) {
    masterDef.fields.forEach((f) => {
      if (!f.hidden) {
        let col = { align: f.align || 'left' };
        let accessor = f.schemaName;
        if (f.type === 'dropdown' && f.dataSource.type === ('dynamic' || 'custom')) {
          accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
        }
        col['Header'] = f.displayName;
        col['accessor'] = accessor;
        col['Cell'] = ({ cell: { value } }) => {
          if (f.type === "currency")
            return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{formatCurrencyNumeral(value, systemCurrencyDetails)}</MDTypography>
          else if (f.type === "datepicker")
            if (f.view === "year")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format("YYYY") : ""}</MDTypography>
            else
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>

          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value}</MDTypography>
        };
        col['dataType'] = f.filterType || f.type
        col['disableFilters'] = f.disableFilters || false,
          columns.push(col);
      }
    }); 
  }
  if(masterDef.name === 'year')
    columns.push({
      Header: 'From',
      accessor: 'from',
      align: 'left',
      disableSorting: true,
      disableFilters: true,
   },
   {
      Header: 'To',
      accessor: 'to',
      align: 'left',
      disableSorting: true,
      disableFilters: true,
   })
  
  return columns;
};

const buildRows = (pkColumn, data, onEdit, onDelete, masterDef, onAdd, selectedCategoryDef, additonallinkData) => {
  masterDef && masterDef.schemaName && masterDef.schemaName === "user" ? data = ActiveStatusReverse(data) : data
  masterDef && masterDef.schemaName && masterDef.schemaName === "user" ? data = Roleformat(data, masterDef) : data
  let addmember = (selectedCategoryDef, row) => {
    let dataCheck
    if (additonallinkData) {
      let dataKey = `${selectedCategoryDef.name}.name`
      dataCheck = additonallinkData.find(item => item[dataKey] === row?.name)
    }
    let formdetails = { "selectedCategoryDef": selectedCategoryDef, "row": row }
    if (selectedCategoryDef && selectedCategoryDef.additonallink) {

      return (
        <MDTypography
          display="flex"
          alignItems="center"
          ml={3}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => onAdd(formdetails)}
        >
          <Icon fontSize="small" color={dataCheck ? 'success' : "info"}>
            {
              selectedCategoryDef.additonallinkicon ? selectedCategoryDef.additonallinkicon : 'add'
            }
          </Icon>
          &nbsp; {selectedCategoryDef.additionallinktext ? selectedCategoryDef.additionallinktext : 'Add'}
        </MDTypography>
      )
    }
  }
  let editEnable = (r) => {
    if (onEdit) {
      return (
        <>
          {selectedCategoryDef.name == "cloudTeamMapping" ?
            <Tooltip title="Add Exception" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onEdit(r[pkColumn])}>
                <Icon fontSize="small" color="info">add</Icon>
              </IconButton>
            </Tooltip>
            // <MDTypography
            //   display="flex"
            //   alignItems="center"
            //   component="a"
            //   href="#"
            //   onClick={() => onEdit(r[pkColumn])}
            //   variant="caption"
            //   color="text"
            //   fontWeight="medium"
            // >
            //   <Icon fontSize="small" color="info">add</Icon>&nbsp;Add Exception
            // </MDTypography>
            :
            <Tooltip title="Edit" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onEdit(r[pkColumn])}>
                <Icon fontSize="small">edit</Icon>
              </IconButton>
            </Tooltip>
            // <MDTypography
            //   display="flex"
            //   alignItems="center"
            //   component="a"
            //   href="#"
            //   onClick={() => onEdit(r[pkColumn])}
            //   variant="caption"
            //   color="text"
            //   fontWeight="medium"
            // >
            //   <Icon fontSize="small">edit</Icon>&nbsp;Edit
            // </MDTypography>

          }
        </>
      )
    }
  }
  const rows = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach((r) => {
      let row = {};
      if (r)
        Object.keys(r).forEach((k) => {
          row[k.replace(/\./g, '__')] = r[k];
        });
      row['actions'] =
        r?.taxonomy === true ? (
          <span></span>
        ) : (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
            {editEnable(r)}
            {
              addmember(selectedCategoryDef, r)
            }
            {masterDef?.name !== 'tenant' &&
              <Tooltip title="Delete" placement="top">
                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onDelete(r[pkColumn])}>
                  <Icon fontSize="small" color="error">delete</Icon>
                </IconButton>
              </Tooltip>
              // <MDTypography
              //   display="flex"
              //   alignItems="center"
              //   ml={3}
              //   component="a"
              //   href="#"
              //   onClick={() => onDelete(r[pkColumn])}
              //   variant="caption"
              //   color="text"
              //   fontWeight="medium"
              // >
              //   <Icon fontSize="small" color="error">
              //     delete
              //   </Icon>
              //   &nbsp;Delete
              // </MDTypography>
            }
          </MDBox>
        );
      rows.push(row);
    });
  }
  return rows;
};

const Masters = (props) => {
  const { masterId, selectedCategoryDef } = props;
  const [step, setStep] = useState('LOADING');
  const handleError = useHandleError();
  const [masterDef, setMasterDef] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [showTeamMember, setshowTeamMember] = useState(false)
  const [controller,] = useAppController();
  const { appDef, systemCurrencyDetails } = controller;
  const defaultDateFormat = (appDef?.settings && appDef?.settings?.dateFormat) || "DD/MM/YYYY";

  const { showForm, showAlert, showPrompt, showSnackbar } = useYADialog();
  let filters = [
    {
      id: 'disabled',
      value: {
        type: 'switch',
        operator: 'eq',
        session: false,
        values: ["Yes"]
      }
    }
  ]
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: filters });
  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }


  const handleAdd = (item) => {
    setRowData(item)
    setshowTeamMember(true)

  }

  const handleTeamMemberClose = () => {
    setshowTeamMember(false)
    setRefresh(Math.random());
  }

  const handleClose = () => {
    setRefresh(Math.random());
  };

  const handleEdit = (pkId) => {
    let filteredDef = { ...masterDef };
    filteredDef.fields = masterDef.fields.filter(item => !item.hidefromTheForm);
    showForm(
      `Edit ${filteredDef.singularDisplayName || filteredDef.displayName}`,
      filteredDef,
      handleClose,
      'edit',
      pkId,
      filteredDef.alertMessage
    );
  };

  const deleteMaster = async (pkId) => {
    const [err, data] = await fetchRequest.delete(`/api/master/${masterId}/${pkId}`);
    if (err) {
      if (err.data.message === "AdminRoleChangeFail") {
        showAlert("Attention", "Assign another admin before deleting this user.")
      } else
        showAlert('Delete', 'Something went wrong. Contact your administrator.');
    }
    else
      if (data && data.result === true) {
        showSnackbar('Data deleted successfully', 'success');
        handleClose();
      }
      else if (data && data.result === false) {
        showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
      }
  };

  const handleDeleteSuccess = (pkId) => {
    deleteMaster(pkId);
  };

  const handleDelete = (pkId) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => handleDeleteSuccess(pkId));
  };

  useEffect(() => {
    async function getMasterDef() {
      var [err, data] = await fetchRequest.get(`/api/master/${masterId}`);
      if (err) {
        handleError(err);
      } else {
        setMasterDef(data);
        setColumns(buildColumns(data, defaultDateFormat, systemCurrencyDetails));
      }
    }
    getMasterDef();
  }, [masterId, selectedCategoryDef]);

  useEffect(() => {
    let additonallinkData
    async function getList() {
      if (selectedCategoryDef.additonallink) {
        let [err, data] = await fetchRequest.get(`/api/master/${selectedCategoryDef.additonallink}/list`);
        if (err) {
          handleError(err);
        } else {
          if (data && Array.isArray(data)) {
            additonallinkData = data
          }
        }
      }
      var [err, data] = await fetchRequest.get(`/api/master/${masterId}/list`);
      if (err) {
        handleError(err);
      } else {
        if (data && Array.isArray(data) && data.length > 0) {
          if(masterId === 'year') {
            data.forEach(item => {
              const startDate = new Date(item.startDate);
              const startMonth = startDate.getMonth(); 
              const startYear = startDate.getFullYear();
              item.from = `${startDate.toLocaleString('default', { month: 'short' })} ${startYear}`;
              let toMonth = startMonth === 0 ? 11 : startMonth - 1; 
              let toYear = startMonth === 0 ? startYear : startYear + 1; 
              if (startMonth === 0) {
                toYear = startYear;
                toMonth = 11;
              }
              item.to = `${new Date(toYear, toMonth).toLocaleString('default', { month: 'short' })} ${toYear}`;
            });
          }
          setRows(buildRows(masterDef.pkColumn || 'id', data, handleEdit, handleDelete, masterDef, handleAdd, selectedCategoryDef, additonallinkData));
          setStep('LOADED');
        } else {
          setStep('EMPTY');
        }
      }
    }
    if (masterDef) {
      getList();
    }
  }, [masterId, masterDef, refresh]);


  if (step === 'LOADING') {
    return <YASkeleton variant="loading" />;
  }

  const { displayName, singularDisplayName, desc, message, canFilter } = masterDef;

  const handleAddButtonClick = () => {
    let filteredDef = { ...masterDef };
    filteredDef.fields = masterDef.fields.filter(item => !item.hidefromTheForm);
  
    showForm(`New ${filteredDef.singularDisplayName || filteredDef.displayName}`, filteredDef, handleClose);
  };

  const renderPrimaryActions = !masterDef.readonly && masterId !== "tenant" ? (
    <MDButton
      data-testid={"addnew"}
      variant="outlined"
      color="info"
      startIcon={<Icon>add</Icon>}
      onClick={handleAddButtonClick}
    >
      Add New
    </MDButton>
  ) : undefined;

  const renderAddButton = () =>
    !masterDef.readonly ? (
      <MDButton
        data-testid={"addnew"}
        variant="outlined"
        color="info"
        startIcon={<Icon>add</Icon>}
        onClick={handleAddButtonClick}
      >
        Add New
      </MDButton>
    ) : undefined;

  return (
    <MDBox flex={1} display="flex" flexDirection="column" px={4} py={3}>
      {showTeamMember && <TeamMemberPopup handleTeamMemberClose={handleTeamMemberClose} data={rowData} buildColumns={buildColumns} buildRows={buildRows} defaultDateFormat />}
      <MDTypography data-testid={displayName?.toLowerCase().replaceAll(' ', '')} variant="button" color="dark" fontWeight="medium">{displayName}</MDTypography>
      <MDTypography data-testid={desc?.toLowerCase().replaceAll(' ', '')} mb={1} variant="button" color="text">{desc}</MDTypography>
      {
        message &&
        <Alert severity="warning"
          sx={{ marginTop: 1, marginBottom: 1, fontSize: "14px", textAlign: "left" }}
        >{message}</Alert>
      }
      <MDBox pt={1}>
        {step === 'LOADED' && (
          <Card sx={{ height: '100%' }} px={0}>
            <DataTable
              table={{ columns, rows }}
              showTotalEntries={true}
              isSelectable={selectedCategoryDef?.name == "cloudTeamMapping" ? true : false}
              isSorted={true}
              newStyle1={true}
              noEndBorder
              entriesPerPage={true}
              canSearch={true}
              canFilter={canFilter}
              hideFooterForMinRecords={true}
              primaryActions={renderPrimaryActions}
              filtersState={filtersState}
              onFiltersStateUpdate={handleOnFiltersStateUpdate}
            />
          </Card>
        )}
        {step === 'EMPTY' && (
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100vh - 300px)"
          >
            <EmptyState
              size="large"
              image={new_item_img}
              title={`No ${displayName} Yet`}
              description={
                !masterDef.readonly && masterId !== "tenant" ?
                  `Click on the '+ add new' button to add a new ${(
                    singularDisplayName || displayName
                  ).toLowerCase()}.`
                  : undefined
              }
              actions={masterId !== 'tenant' && renderAddButton}
            />
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

const AppSettingDropdown = (props) => {
  const { name, displayName, required, helperText, type, dataSource, defaultValue, value, actions, refreshKey, onSave, setDef } = props;
  const [val, setVal] = useState(value);
  const [options, setOptions] = useState(dataSource?.type === 'static' ? dataSource.data : []);
  const [loading, setLoading] = useState(dataSource?.type !== 'static');
  const [error, setError] = useState(false);
  const [thresholdError, setThresholdError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSnackbar, showAlert } = useYADialog();

  useEffect(() => {
    async function getOptions() {
      setLoading(true);
      if (type !== "integer") {
        const [err, data] = await fetchRequest.get(`/api/settings/${name}`);
        if (err)
          console.error(err)
        setOptions(data || []);
        setLoading(false);
      }
    }
    if (dataSource?.type !== 'static') getOptions();
  }, [name, refreshKey]);

  useEffect(() => {
    if (val !== value)
      setVal(value);
  }, [value]);

  const handlePostClientActions = () => {
    if (actions?.length > 0) {
      actions.filter(a => a.location === "client")?.forEach(a => {
        if (a.type === "updateStorage") {
          if (sessionStorage && a.key !== 'Month')
            sessionStorage[a.key] = val;
        }
        else if (a.type === "reloadWindow") {
          setTimeout(() => {
            // reload app to get new permissions
            window.location.reload();
          }, 1000);
        }
      })
    }
  }

  useEffect(async () => {
    if (name === 'defaultChargeBackReport') {
      const [err, data] = await fetchRequest.get(`/api/settings/defaultChargeBackReport/${val}`);
      if (err) {
        console.error(err)
      }
      if (data) {
        setDef(data)
      }
    }
  }, [val])

  const handleSave = async (evt) => {
    evt.preventDefault();
    setError(false);
    const domain = getDomain();

    const err = required && (!val || val === "");

    if (!err) {
      setIsSubmitting(true);

      const [_err, data] = await fetchRequest.post(`/api/settings/${name}`, { [name]: val });
      if (_err) {
        if (_err.data.message) {
          showAlert("Error", _err.data.message);
        } else
          showAlert("Error", "Something went wrong. Contact your administrator.");
      }
      else {
        if (data && data.result === false) {
          if (Array.isArray(data.errors) && data.errors.length > 0) {
            let errorsObj = {};
            data.errors.forEach((e) => errorsObj[e.field] = true);
            setError(errorsObj);
          }
        }
        else {
          const appRes = await Axios.get(`${domain}/api/app?${("nc=" + Math.random()).replace(".", "")}`);
          if (typeof (Storage) !== "undefined")
            appRes.data.defaults?.map((item) => {
              if ((!sessionStorage[item.name]) || (item.name === 'Month')) {
                return sessionStorage[item.name] = item.value
              }
            })
          showSnackbar(data.message, "success");
          handlePostClientActions();

          if (onSave) onSave();
        }
      }

      setIsSubmitting(false);

    } else {
      setError(true);
    }

  }

  const handleCancel = () => {
    setThresholdError("")
    setError(false);
    setVal(value);
  }
  const handletextInput = (e) => {
    setThresholdError("")
    setError(false);
    e.preventDefault
    setVal(e.target.value)
  }
  const handleRagThreshold = async (e) => {
    if (val === '') {
      setError(true)
      setThresholdError('This field cannot be empty. Please enter a value')
      return
    }
    if (isNaN(val) || Number(val) <= 0) {
      setError(true)
      setThresholdError('Please enter a positive number')
      return
    }
    if (Number(val) > 100) {
      setError(true)
      setThresholdError('Please enter a number between 0 and 100')
      return
    }
    if (thresholdError === "") {
      handleSave(e)
    }
  }
  if (type === "integer") {
    return (
      <MDBox display="flex" alignItems="flex-start" marginBottom={2}>
        <MDBox>
          <MDTypography mt={2} mb={.75} variant="caption" color="text" fontWeight="medium">{displayName} <br />
            <TextField
              // label={displayName}
              value={val}
              sx={{ width: 400 }}
              size="small"
              helperText={thresholdError !== '' ? thresholdError : helperText}
              error={Boolean(error)}
              onChange={handletextInput}
            />
          </MDTypography>
        </MDBox>
        {
          value !== val &&
          <>
            <MDButton
              size='small'
              iconOnly
              variant="gradient"
              color="success"
              disabled={isSubmitting}
              sx={{ marginTop: 4.5, marginLeft: 2, height: 30, width: 30 }}
              onClick={handleRagThreshold}
            >
              {isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>done</Icon>}
            </MDButton>
            <MDButton
              size='small'
              iconOnly
              variant="gradient"
              color="error"
              disabled={isSubmitting}
              sx={{ marginTop: 4.5, marginLeft: 1, height: 30, width: 30 }}
              onClick={handleCancel}
            >
              <Icon>close</Icon>
            </MDButton>
          </>
        }

      </MDBox>
    )
  } else {
    return (
      <MDBox display="flex" alignItems="flex-start" marginBottom={2}>
        <MDBox>
          <MDTypography mt={2} mb={.75} variant="caption" color="text" fontWeight="medium">{displayName}</MDTypography>
          <Autocomplete
            // multiple
            // limitTags={1}
            loading={loading}
            // disableClearable={true}
            onChange={(event, item) => {
              const value = Array.isArray(item) ? item?.map(i => i.value) : item?.value;
              setVal(value);
            }}
            defaultValue={defaultValue}
            options={options}
            value={val}
            getOptionLabel={(option) => {
              if (typeof option === 'number')
                return options.find((op) => op.value === option)?.label || '';
              if (typeof option === 'string')
                return (
                  options.find((op) => String(op.value)?.toLowerCase() === option?.toLowerCase())?.label || ''
                );
              return option?.label || '';
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                // label={displayName}
                sx={{ width: 400 }}
                size="small"
                helperText={helperText}
                error={error}
              />
            )}
          />
        </MDBox>
        {
          value !== val &&
          <>
            <MDButton
              size='small'
              iconOnly
              variant="gradient"
              color="success"
              disabled={isSubmitting}
              sx={{ marginTop: 4.5, marginLeft: 2, height: 30, width: 30 }}
              onClick={handleSave}
            >
              {isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>done</Icon>}
            </MDButton>
            <MDButton
              size='small'
              iconOnly
              variant="gradient"
              color="error"
              disabled={isSubmitting}
              sx={{ marginTop: 4.5, marginLeft: 1, height: 30, width: 30 }}
              onClick={handleCancel}
            >
              <Icon>close</Icon>
            </MDButton>
          </>
        }

      </MDBox>
    );
  }
};

const AppSettingTextbox = (props) => {
  const { name, displayName, required, textArea, rows, helperText, maxLength, value, actions, onSave } = props;
  const [val, setVal] = useState(value);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSnackbar, showAlert } = useYADialog();

  useEffect(() => {
    if (val !== value)
      setVal(value);
  }, [value]);

  const handlePostClientActions = () => {
    if (actions?.length > 0) {
      actions.filter(a => a.location === "client")?.forEach(a => {
        if (a.type === "updateStorage") {
          if (sessionStorage && a.key !== 'Month')
            sessionStorage[a.key] = val;
        }
      })
    }
  }

  const handleSave = async (evt) => {
    evt.preventDefault();
    setError(false);
    setErrorMessage(null);

    const domain = getDomain();

    const err = required && String(val || "").trim() === "";

    if (!err) {
      setIsSubmitting(true);

      const [_err, data] = await fetchRequest.post(`/api/settings/${name}`, { [name]: val });
      if (_err) {
        if (_err.data.message) {
          showAlert("Error", _err.data.message);
        } else
          showAlert("Error", "Something went wrong. Contact your administrator.");
      }
      else {
        if (data && data.result === false) {
          setError(true);
          setErrorMessage('An error occurred. Please try again.')
        }
        else {
          const appRes = await Axios.get(`${domain}/api/app?${("nc=" + Math.random()).replace(".", "")}`);
          if (typeof (Storage) !== "undefined")
            appRes.data.defaults?.map((item) => {
              if ((!sessionStorage[item.name]) || (item.name === 'Month')) {
                sessionStorage[item.name] = item.value
              }
            })
          showSnackbar(data.message, "success");
          handlePostClientActions();

          if (onSave) onSave();
        }
      }

      setIsSubmitting(false);

    } else {
      setError(true);
      setErrorMessage('This field cannot be empty. Please enter a value.')
    }
  }

  const handleCancel = () => {
    setError(false);
    setErrorMessage(null);
    setVal(value);
  }
  const handletextInput = (e) => {
    e.preventDefault();
    setError(false);
    setErrorMessage(null);
    setVal(e.target.value);
  }

  return (
    <MDBox display="flex" alignItems="flex-start" marginBottom={2}>
      <MDBox>
        <MDBox mt={2} mb={1} display="flex">
          <MDTypography variant="caption" color="text" fontWeight="medium">{displayName}</MDTypography>
          <MDTypography ml={1} variant="caption" color="text" fontWeight="light">{!required && "(Optional)"}</MDTypography>
          {
            textArea && <MDTypography variant="caption" color="text" ml="auto">
              {`${(val || "").trim().length}/${maxLength || 500}`}
            </MDTypography>
          }
        </MDBox>
        <TextField
          required={required}
          multiline={textArea}
          rows={textArea ? rows || 4 : undefined}
          value={val}
          sx={{ width: textArea ? 600 : 400 }}
          size="small"
          helperText={errorMessage ? errorMessage : helperText}
          error={Boolean(error)}
          onChange={handletextInput}
          inputProps={{ maxLength: maxLength || 500 }}
        />
      </MDBox>
      {
        value !== val &&
        <>
          <MDButton
            size='small'
            iconOnly
            variant="gradient"
            color="success"
            disabled={isSubmitting}
            sx={{ marginTop: 4.5, marginLeft: 2, height: 30, width: 30 }}
            onClick={handleSave}
          >
            {isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>done</Icon>}
          </MDButton>
          <MDButton
            size='small'
            iconOnly
            variant="gradient"
            color="error"
            disabled={isSubmitting}
            sx={{ marginTop: 4.5, marginLeft: 1, height: 30, width: 30 }}
            onClick={handleCancel}
          >
            <Icon>close</Icon>
          </MDButton>
        </>
      }

    </MDBox>
  )

};

const AppSettingsByCategory = (props) => {
  const { settingsDef } = props;
  const handleError = useHandleError();
  const [refresh, setRefresh] = useState(null);
  const [loading, setLoading] = useState(true);
  const [def, setDef] = useState(null)
  const [formValues, setFormValues] = useState([]);

  useEffect(() => {
    async function getSettingsValues() {
      var [err, data] = await fetchRequest.get(`/api/settings/category/${settingsDef?.name}`);
      if (err) {
        handleError(err);
      }
      else {
        setFormValues(data);
      }
      setLoading(false);
    }
    getSettingsValues();
  }, [settingsDef, refresh])

  const handleOnSave = () => {
    setRefresh(Math.random());
  };

  if (loading) {
    return <YASkeleton variant="loading" />;
  }

  return <MDBox flex={1} display="flex" flexDirection="column" px={4} py={3}>
    <MDTypography data-testid={settingsDef?.displayName} variant="button" color="dark" fontWeight="medium">{settingsDef?.displayName}</MDTypography>
    <MDTypography data-testid={settingsDef?.desc} mb={1} variant="button" color="text">{settingsDef?.desc}</MDTypography>
    {
      settingsDef?.settings.filter(({hidden}) => !hidden)?.map(
        s => {
          const formValue = formValues?.find(v => v.name === s.name)?.value;
          if (s.type === 'textbox')
            return <AppSettingTextbox
              key={s.name}
              name={s.name}
              required={s.required}
              displayName={s.displayName}
              maxLength={s.maxLength}
              value={formValue}
              helperText={s.helperText}
              type={s.type}
              actions={s.actions}
              onSave={handleOnSave}
            />
          else if (s.type === 'textarea')
            return <AppSettingTextbox
              key={s.name}
              name={s.name}
              textArea={true}
              rows={s.rows}
              required={s.required}
              displayName={s.displayName}
              maxLength={s.maxLength}
              value={formValue}
              helperText={s.helperText}
              type={s.type}
              actions={s.actions}
              onSave={handleOnSave}
            />
          return <AppSettingDropdown
            key={s.name}
            name={s.name}
            required={s.required}
            displayName={s.displayName}
            defaultValue={s.defaultValue}
            value={formValue}
            dataSource={s.dataSource}
            helperText={s.helperText}
            type={s.type}
            actions={s.actions}
            refreshKey={refresh}
            onSave={handleOnSave}
            setDef={setDef}
          />
        }
      )
    }
    {
      (def && settingsDef?.name === "chargeBackDefinition") &&
      <TableRenderer
        title={'Chargeback'}
        subtitle={''}
        chartHelpContextKey={''}
        vizState={JSON.parse(def).vizState}
        vizOptions={JSON.parse(def).vizOptions}
        applyFilters={true}
      />
    }
  </MDBox>
}

const AppSettings = () => {
  const { settingId } = useParams();
  const handleError = useHandleError();
  const [appSettingsDef, setAppSettingsDef] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedCategoryDef, setSelectedCategoryDef] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);
  const [yearFilterName, setYearFilterName] = useState(null);
  const [monthFilterName, setMonthFilterName] = useState(null)
  const [years, setYears] = useState(null);
  const [months, setMonths] = useState([]);
  const [monthFilter, setMonthFilter] = useState();
  const [published, setPublished] = useState(false)

  useEffect(() => {
    async function getSettings() {
      var [err, data] = await fetchRequest.get(`/api/settings`);
      if (err) {
        handleError(err);
      }
      else {
        setAppSettingsDef(data);
        if (data) {
          if (settingId) {
            let defaultSettingCategory = null;
            data?.categories?.forEach(pc => {
              const settingCategory = pc?.categories?.find(c => c.name === settingId);
              if (settingCategory) {
                defaultSettingCategory = settingCategory;
                return;
              }
            })
            if (defaultSettingCategory)
              setSelectedCategoryDef(defaultSettingCategory);
            else
              setSelectedCategoryDef(data?.categories[0]?.categories[0]);
          }
          else
            setSelectedCategoryDef(data?.categories[0]?.categories[0]);
        }
      }
      setLoading(false);
    }
    getSettings();
  }, [])

  if (loading) {
    return <YASkeleton variant="dashboard-loading" />;
  }

  const renderMainCategories = () => {
    return appSettingsDef?.categories?.map(
      c => <MDBox key={c.name}>
        <MDTypography ml={1.25} variant="button" color="text" fontWeight="medium">{c.displayName}</MDTypography>
        <List sx={{ mt: .5, mb: 2 }}>
          {
            c?.categories?.map(cc => (
              <ListItem key={cc.name}>
                <MDButton
                  data-testid={cc.displayName?.toLowerCase().replaceAll(' ', '')}
                  name={cc.name}
                  variant="text"
                  color="info"
                  sx={{
                    textTransform: "none",
                    py: .8,
                    px: 1.5,
                    mb: .25,
                    background: selectedCategoryDef?.name === cc.name ? 'rgba(28, 32, 77, 0.04)' : "inherit"
                  }}
                  onClick={() => setSelectedCategoryDef(cc)}
                >
                  {cc.displayName}
                </MDButton>
              </ListItem>
            ))
          }
        </List>
      </MDBox>
    )
  }

  const renderSetting = () => {
    switch (selectedCategoryDef.type) {
      case "master":
        return <Masters masterId={selectedCategoryDef.master} selectedCategoryDef={selectedCategoryDef} />;
      // case "assetTag":
      //   return <ViewRenderer masterId={"asset-tag-fields"} />
      case "tbmTaxonomy":
        return <TBMTaxonomy selectedCategoryDef={selectedCategoryDef} />;
      case "licensing":
        return <Licensing />;
      case "notificationChannel" :
        return <NotificationChannels/>;
      case "appPersonalization":
        return <AppPersonalization />;
      case "rules":
        return (
          <TeamMappingRules
            years={years}
            months={months}
            yearFilter={yearFilter}
            yearFilterName={yearFilterName}
            monthFilter={monthFilter}
            monthFilterName={monthFilterName}
            published={published}
            setYears={setYears}
            setMonths={setMonths}
            setYearFilter={setYearFilter}
            setYearFilterName={setYearFilterName}
            setMonthFilterName={setMonthFilterName}
            setMonthFilter={setMonthFilter}
            setPublished={setPublished}
          />);
      // case "uploadRules":
      //   return (
      //     <UploadRules/>);
      // case "load":
      //   return (
      //     <CloudSpend
      //       years={years}
      //       months={months}
      //       yearFilter={yearFilter}
      //       yearFilterName={yearFilterName}
      //       monthFilter={monthFilter}
      //       monthFilterName={monthFilterName}
      //       setYears={setYears}
      //       setMonths={setMonths}
      //       setYearFilter={setYearFilter}
      //       setYearFilterName={setYearFilterName}
      //       setMonthFilterName={setMonthFilterName}
      //       setMonthFilter={setMonthFilter}
      //     />);
      default:
        return <AppSettingsByCategory settingsDef={selectedCategoryDef} />;
    }
  }

  return (
    <>
      <PageHeader title={appSettingsDef?.displayName} subtitle={appSettingsDef?.desc} />
      <MDBox width="100%" height="calc(100vh - 156px)" px={3} pt={1}>
        <MDBox borderRadius="6px" border="1px solid #ddd" width="100%" height="100%" display="flex" overflow="hidden">
          <MDBox minWidth="280px" borderRight="1px solid #ddd" px={3} py={2}>
            <YAScrollbar>
              {renderMainCategories()}
            </YAScrollbar>
          </MDBox>
          <YAScrollbar>
            {renderSetting()}
          </YAScrollbar>
        </MDBox>
      </MDBox>
    </>
  );
};

export default AnimatedRoute(AppSettings);