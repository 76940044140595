import { Card, Modal, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import DataTable from "components/DataTable";
import ServeSideDataTable from "components/ServeSideDataTable";
// import useFetchRequest from "hooks/useFetchRequest";
import fetchRequest from "utils/fetchRequest";
// import numeral from "numeral";
// import YASkeleton from "components/YASkeleton";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import { normalizeCurrency } from "utils/table";
import { useEffect, useState } from "react";
import { useAppController } from "context";
import { formatCurrencyNumeral } from "utils";

const SpendDialog = (props) => {
    const { onClose, yearFilterName, monthFilterName, typeFilter } = props;

    const handleDialogClose = () => {
        if (onClose) onClose();
    }

    return (
        <Modal open={true} onClose={handleDialogClose}>
            <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Card sx={{ height: "100%", width: "100%", overflow: 'hidden' }}>
                    <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox>
                            <MDTypography variant="h6" component="span" color="text">
                                {typeFilter == "Capabilities" ? "Asset Mapping" : typeFilter=="Solution" ? "Asset to Solution Mapping" : typeFilter == "Business Unit" ? "Solution to BU Mapping" : typeFilter} For {monthFilterName} {yearFilterName}
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex">
                            <IconButton onClick={handleDialogClose} title="Close">
                                <Icon>close</Icon>
                            </IconButton>
                        </MDBox>
                    </MDBox>
                    <ShowData {...props} />
                </Card>
            </MDBox>
        </Modal>
    )
}

const ShowData = (props) => {
    const { yearFilter, monthFilter, typeFilter, serverSideDataPaging } = props;
    const [ pgIndx, setPgIndx] = useState( serverSideDataPaging ? 0 : null)
    const [ pageSize, setPageSize] = useState( serverSideDataPaging ? 50 : null)
    const [ totalNoOfPages, setTotalNoOfPages ] = useState(0)
    const [ totalItems, setTotalItems ] = useState(null)
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;  
    const [ search, setSearch ] = useState(null)
    const [ rows, setRows ] = useState([])
    const [ _err, setErr ] = useState(null)
    const [ loading, setLoading ]= useState(true)

    let handlePageOption = () => {
        onPageOptionsChange({ currentPage: 0, pageSize: pageSize })
    }

    const onPageOptionsChange = (pageOption) => {
        // if (serverSideDataPaging) {
        //     setLoading(true) 
        // }
        setPgIndx(pageOption.currentPage);
        setPageSize(pageOption.pageSize)
    }

    useEffect(async () => {
        if (serverSideDataPaging) {
            setLoading(true)
            const url = `/api/dataflow/${typeFilter.toLowerCase().replace(' ','')}/${yearFilter}/${monthFilter}?pgIndx=${pgIndx}&pageSize=${pageSize}`;
            const filters = { filters: null, searchString: search ? search?.trim() : null}
            // const filters = { filters: null, searchString: null}
            const [ _err, response ] = await fetchRequest.post(url, filters)
            if (!_err) {
                const { data, totalItems, totalPages }  = response
                const rows = data.map((row) => {
                    let r = {};
                    Object.keys(row).forEach((k) => {
                        r[k.replace(/\./g, "__")] = row[k];
                    });
                    return r;
                });
                setRows(rows)
                setTotalItems(totalItems)
                setTotalNoOfPages(totalPages)
                setLoading(false)
            } else {
                setErr(_err)
                setLoading(false)
            }   
        }
    },[pageSize, pgIndx, search])

    useEffect(async () => {
        if (!serverSideDataPaging) {
            setLoading(true)
            const url = `/api/dataflow/${typeFilter.toLowerCase().replace(' ','')}/${yearFilter}/${monthFilter}`;
            const [ _err, data ] = await fetchRequest.get(url)
            if (!_err) {
                const rows = data.map((row) => {
                    let r = {};
                    Object.keys(row).forEach((k) => {
                        r[k.replace(/\./g, "__")] = row[k];
                    });
                    return r;
                });
                setRows(rows)
                setTotalItems(totalItems)
                setLoading(false)
            } else {
                setErr(_err)
                setLoading(false)
            }
        }
    },[])
    

    let columns = []
    if (typeFilter === "Spend") 
        columns = [
            { Header: "Transaction Date", accessor: "transactionDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("DD/MM/YYYY") : ""}</MDTypography> } },
            { Header: "Journal ID", accessor: "journalID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Expense Type", accessor: "expenseType__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Code", accessor: "account__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Name", accessor: "account__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Code", accessor: "costCentre__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Name", accessor: "costCentre__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Code", accessor: "vendor__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Name", accessor: "vendor__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
            { Header: "Journal Line", accessor: "journalLine", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Invoice", accessor: "invoiceNumber", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "RGT Model", accessor: "rgtModel", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Type", accessor: "costType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Exclusion Type", accessor: "exclusionType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Source", accessor: "source",dataType: "textbox",disableFilters: false,Cell: ({ cell: { value } }) => {return (<MDTypography variant="caption" color="dark">{value}</MDTypography>)} },
            { Header: "Custom String 1", accessor: "customString1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 2", accessor: "customString2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 3", accessor: "customString3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 4", accessor: "customString4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 5", accessor: "customString5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 1", accessor: "customDate1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 2", accessor: "customDate2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 3", accessor: "customDate3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 4", accessor: "customDate4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 5", accessor: "customDate5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 1", accessor: "customNumber1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 2", accessor: "customNumber2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 3", accessor: "customNumber3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 4", accessor: "customNumber4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 5", accessor: "customNumber5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "File", accessor: "uploadedFile__originalFileName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Created On", accessor: "createdAt", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
            { Header: "Created By", accessor: "createdByUser__name", Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start"><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>}} },
        ]
    else if(typeFilter=="Budget")
        columns = [
            { Header: "Expense Type", accessor: "expenseType__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Code", accessor: "account__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Name", accessor: "account__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Code", accessor: "costCentre__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Name", accessor: "costCentre__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Code", accessor: "vendor__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Name", accessor: "vendor__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Budget", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
            { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 1", accessor: "customString1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 2", accessor: "customString2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 3", accessor: "customString3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 4", accessor: "customString4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 5", accessor: "customString5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 1", accessor: "customDate1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 2", accessor: "customDate2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 3", accessor: "customDate3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 4", accessor: "customDate4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 5", accessor: "customDate5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 1", accessor: "customNumber1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 2", accessor: "customNumber2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 3", accessor: "customNumber3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 4", accessor: "customNumber4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 5", accessor: "customNumber5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "File", accessor: "uploadedFile__originalFileName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Created On", accessor: "createdAt", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
            { Header: "Created By", accessor: "createdByUser__name", Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>} } },
        ]
    else if(typeFilter=="Benchmark")
        columns = [
            { Header: "Expense Type", accessor: "expenseType__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Code", accessor: "account__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Name", accessor: "account__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Code", accessor: "costCentre__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Name", accessor: "costCentre__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Code", accessor: "vendor__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Name", accessor: "vendor__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Pool", accessor: "costPool__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Sub Cost Pool", accessor: "subCostPool__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Tower", accessor: "tower__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Sub Tower", accessor: "subTower__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Solution Offering Code", accessor: "solutionOffering__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Solution Offering Name", accessor: "solutionOffering__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Business Unit Code", accessor: "businessUnit__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Business Unit Name", accessor: "businessUnit__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Benchmark", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
            { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 1", accessor: "customString1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 2", accessor: "customString2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 3", accessor: "customString3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 4", accessor: "customString4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom String 5", accessor: "customString5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 1", accessor: "customDate1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 2", accessor: "customDate2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 3", accessor: "customDate3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 4", accessor: "customDate4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Date 5", accessor: "customDate5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 1", accessor: "customNumber1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 2", accessor: "customNumber2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 3", accessor: "customNumber3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 4", accessor: "customNumber4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Custom Number 5", accessor: "customNumber5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "File", accessor: "uploadedFile__originalFileName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Created On", accessor: "createdAt", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
            { Header: "Created By", accessor: "createdByUser__name", Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>} } },
        ]
    
    else if(typeFilter === "Forecast")
        columns = [
            { Header: "Expense Type", accessor: "expenseType__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Code", accessor: "account__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Name", accessor: "account__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Code", accessor: "costCentre__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Name", accessor: "costCentre__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Code", accessor: "vendor__code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Name", accessor: "vendor__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Forecast", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
            { Header: "File", accessor: "uploadedFile__originalFileName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Created On", accessor: "createdAt", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
            { Header: "Created By", accessor: "createdByUser__name", Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>} } },
        ]

    else if(typeFilter=="Solution")
        columns = [
            { Header: "Asset Type", accessor: "assetType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Asset Code", accessor: "capabilityCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Asset Name", accessor: "applicationName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Offering Code", accessor: "offeringCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Offering Name", accessor: "offeringName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Portion", accessor: "portion", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Amount", accessor: "spend", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
        ]
    else if(typeFilter=="Capabilities")
        columns = [
            { Header: "File", accessor: "originalFileName",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "From Asset", accessor: "fromAsset",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Code", accessor: "fromCode",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "To Asset", accessor: "toAsset",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Code", accessor: "toCode",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Portion", accessor: "portion",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            // { Header: "From Amount", accessor: "amount",dataType: "currency",align:"right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0.00')}</MDTypography> } },
            // { Header: "To Amount", accessor: "spend",dataType: "currency",align:"right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0.00')}</MDTypography> } },
        ]
    else if(typeFilter=="Business Unit")
        columns = [
            { Header: "Business Unit Code", accessor: "businessUnitCode",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Solution Offering Code", accessor: "solutionOfferingCode",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Usage", accessor: "usage",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        ]
    // if (loading) return <YASkeleton variant="loading" />

    if (loading === false && rows && rows.length === 0 && !search) {
        return (
            <div>
                {_err}
                no data
            </div>
        );
    }
    
    return (
        <ServeSideDataTable
            variant="tile"
            table={{ columns, rows }}
            containerMaxHeight={"calc(100vh - 226px)"}
            showTotalEntries={true}
            isSorted={true}
            newStyle1={true}
            noEndBorder
            entriesPerPage={true}
            canSearch={true}
            canFilter = {typeFilter == true}
            serverSideDataPaging={serverSideDataPaging}
            pgIndx={pgIndx}
            setPgIndx={setPgIndx}
            onPageOptionsChange={onPageOptionsChange}
            pageSizeVal={pageSize}
            totalNoOfPages={totalNoOfPages}
            totalItems={totalItems}
            loading={loading}
            setSrch={setSearch}
            srch={search}
            handlePageOption={handlePageOption}
        />
    )
}

export default SpendDialog;